%get-full-height {
	@include flexbox();
	@include flex-dir(column);
	-webkit-flex: 1 1 1px;
	-moz-box-flex: 1;
	flex: 1 1 1px;
	min-height: 0;
	height: 100%;
}
.report-assessment-preview {
	position: relative;
	@extend %get-full-height;

	@media only screen and (max-width: 1365px) {
		height: calc(100vh - 204px);
	}
	@media only screen and (max-width: 991px) {
		height: calc(100vh - 275px);
	}
	@media only screen and (max-width: 767px) {
		height: calc(100vh - 270px);
	}
	.report-preview-slider {
		@include flexbox();
		@include flex-dir(column);
		height: 100%;
		> .slick-list {
			height: 100% !important;
			max-height: 100% !important;

			.slick-slide {
				> div {
					height: calc(100vh - 275px);
				}
			}
		}
		.-questionSlider {
			@media only screen and (max-width: 1365px) {
				max-width: 900px;
			}
			@media only screen and (max-width: 1365px) {
				max-width: 780px;
			}
			@media only screen and (max-width: 991px) {
				max-width: 650px;
			}
			@media only screen and (max-width: 767px) {
				max-width: 500px;
			}
			@media only screen and (max-width: 640px) {
				max-width: 360px;
			}
			@media only screen and (max-width: 480px) {
				max-width: 290px;
			}
			@media only screen and (max-width: 400px) {
				max-width: 280px;
			}

			.slider-set-questions {
				@media only screen and (max-width: 640px) {
					padding: 20px 10px;
				}
			}

			.slick-slide {
				> div {
					height: auto !important;
				}
			}
			.slick-arrow {
				width: 30px !important;
				height: 30px !important;
				background: #e9e7e7 !important;
				border-radius: 50px !important;
				display: flex !important;
				align-items: center !important;
				justify-content: center !important;
				top: 0;
				bottom: 0;
				margin: auto;
				&::before {
					color: #4f3f43 !important;
					font-size: 13px;
					margin: 0 !important;
					background: #e9e7e7 !important;
				}
				@media (min-width: $screen-lg) {
					&:hover {
						background: $app-brand-primary;
						&::before {
							color: $white;
						}
					}
				}
				&.slick-prev {
					left: -40px;
					right: inherit;
					@media only screen and (max-width: 640px) {
						left: -15px;
					}
					&::before {
						content: '\e949';
						font-family: 'qualify-tms';
						transform: rotate(180deg);
					}
				}
				&.slick-next {
					right: -40px;
					left: inherit;
					@media only screen and (max-width: 640px) {
						right: -15px;
					}
					&::before {
						content: '\e949';
						font-family: 'qualify-tms';
					}
				}
				&.slick-disabled {
					pointer-events: none;
				}
			}
		}
		.report-preview-slider-common {
			@include flexbox();
			@include flex-dir(column);
			height: 100%;
			.report-preview-header {
				@include flex-shrink(0);
				padding: 13px 0;
				@media only screen and (max-width: 1365px) {
					padding-top: 0;
				}
				.app-c-title {
					margin: 0;
					font-size: 20px;
				}
				.info-link {
					font-size: 18px;
					margin-right: 12px;
				}
			}
			.report-preview-slide-content {
				@include flex-grow(1);
				overflow: auto;
				height: calc(100% - 110px);
				display: flex;
				@media only screen and (max-width: 1365px) {
					height: calc(100% - 28px);
				}
				@media only screen and (max-width: 991px) {
					height: calc(100% - 53px);
				}
				@media only screen and (max-width: 767px) {
					height: calc(100% - 100px);
					.horizontal-slider-lesson {
						padding-bottom: 30px !important;
					}
				}
				.media-wrapper {
					//max-width: 740px;
					max-height: calc(100vh - 400px);
					img {
						width: 100%;
						height: 100%;
						object-fit: contain;
						max-height: calc(100vh - 400px);
						background: whitesmoke;
					}
					.expand-button {
						position: absolute;
						top: 10px;
						right: 10px;
						background: #e9e7e7;
						font-size: 12px;
						color: #61636f;
						width: 30px;
						height: 30px;
						@include border-radius(50%);
						&:hover {
							background: $app-brand-primary;
							color: $white;
						}
					}
				}
			}
		}

		.preview-slider-vertically-center {
			margin: auto;
			width: 100%;
		}
		.preview-text-only {
			border-left: 3px solid #000;
			padding-left: 40px;
			width: 100%;
			padding-right: 40px;
			@media only screen and (max-width: 767px) {
				padding-left: 20px;
			}
		}
		.preview-slider-media-text {
			display: grid;
			grid-template-columns: 1fr 1fr;
			width: 100%;
			@media only screen and (max-width: 1200px) {
				display: block;
			}
			.preview-slider-left-box {
				padding-right: 20px;
				@media only screen and (max-width: 1200px) {
					padding-right: 0;
					padding-bottom: 20px;
				}
			}
			.preview-slider-right-box {
				padding-left: 20px;
				@media only screen and (max-width: 1200px) {
					padding-left: 0;
					padding-top: 20px;
				}
			}
			.media-wrapper {
			}
		}
	}
	.slick-arrow {
		display: block;
		position: absolute;
		width: 40px;
		height: 40px;
		right: 0;
		top: inherit;
		left: inherit;
		z-index: 3;
		border-radius: 50%;
		// &:hover {
		// 	background: $app-brand-primary-dark;
		// }
		&.slick-disabled {
			&::before {
				opacity: 0.3;
			}
		}
		&::before {
			background: $app-brand-primary;
			position: absolute;
			top: 0;
			left: 0;
			color: #fff;
			display: block;
			width: 100%;
			height: 100%;
			@include border-radius(50%);
			@include flexbox();
			@include align-items(center);
			@include justify-content(center);
			font-size: 12px;
			opacity: 1;
		}
		&:hover {
			&::before {
				background: $app-brand-primary-dark;
			}
		}
		&.slick-prev {
			bottom: 40px;
			@media only screen and (max-width: 1200px) {
				bottom: 34px;
			}
			@media only screen and (max-width: 991px) {
				bottom: 34px;
			}
			&::before {
				content: '\e923';
				font-family: 'qualify-tms';
			}
		}
		&.slick-next {
			bottom: -10px;
			@media only screen and (max-width: 1200px) {
				bottom: -10px;
			}
			@media only screen and (max-width: 991px) {
				bottom: -11px;
			}
			&::before {
				content: '\e972';
				font-family: 'qualify-tms';
			}
		}
	}

	.preview-slider-nav-group {
		z-index: 2;
		position: absolute;
		bottom: 10px;
		right: 50px;
		@include flexbox;
		@include align-items(center);
		&.single-slide {
			right: 80px;
			@media (max-width: $screen-lg) {
				right: 0;
			}
			.preview-plain-btn {
				right: 60px;
				@media (max-width: 640px) {
					right: 73px;
				}
			}
		}
		.rtl &,
		[dir='rtl'] & {
			right: auto;
			left: 60px;
		}
		@include media-mobile-view {
			background: $white;
			box-shadow: 0px -9px 21px rgba(0, 0, 0, 0.131638);
			width: 100%;
			right: 0;
			bottom: 0;
			padding: 10px 20px;
			@include justify-content(center);
			@include align-items(center);
			position: fixed;
		}

		.slide-count {
			color: $dune;
			font-size: $text-xxl;
			margin-right: 20px;
			.rtl &,
			[dir='rtl'] & {
				margin-right: 0;
				margin-left: 20px;
			}
			@include media-mobile-view {
				font-size: $text-xl;
				margin: 0;
			}
		}
		.preview-plain-btn {
			background-color: $app-brand-primary;
			color: $white;
			display: block;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			@include flexbox;
			@include justify-content(center);
			@include align-items(center);
			font-size: 12px;
			text-decoration: none;
			&:hover {
				background-color: $app-brand-primary-dark;
			}
			@include media-mobile-view {
				position: absolute;
				top: -20px;
				right: 113px;
				width: 40px;
				height: 40px;
				.rtl &,
				[dir='rtl'] & {
					right: auto;
					left: 50px;
				}
			}
			@media only screen and (max-width: 767px) {
				right: 73px;
			}
			&:focus {
				text-decoration: none;
			}
		}
	}
}

.report-preview-slide-content {
	.preview-text-only {
		border-left: 3px solid var(--slideText);
		padding: 0 0 0px 45px;
		margin-left: 0px;
		width: 100%;
	}
}
