.app-c-flex-card-wrapper {
	display: flex;
	flex-direction: row;
	position: relative;
	margin: 0px -4px;
	flex: 1;
	&.wrap {
		flex-wrap: wrap;
	}
	.app-c-flex-card-wrapper__box {
		flex: 1;
	}
	.app-c-flex-card-wrapper__card {
		@include boxShadow(0px 2px 8px rgba(0, 0, 0, 0.135216));
		@include border-radius(8px);
		background: #fff;
		margin: 0px 4px;
		flex: 1;
		padding: 24px;
		margin-bottom: 8px;
		&.-none {
			margin: 0;
			@include boxShadow(none);
			@include border-radius(0);
		}
		&.one-third {
			flex-basis: (calc(100% / 3));
			max-width: (calc(100% / 3));
			flex: 1;
		}

		&.app-c-pathway {
			flex-grow: 1;
			flex-basis: 100%;
			width: 500px;
		}
		&.app-c-course-progress {
			flex-basis: 55%;
		}
		&.app-c-skill-profile {
			flex-basis: 43%;
			.app-c-pathway__wrapper {
				@include flexbox;
				@include justify-content(space-between);
				@include align-items(flex-start);
				@media (max-width: $screen-lg) {
					@include flex-direction(column);
					.app-c-course-more {
						margin-bottom: 20px;
					}
				}
				h5 {
					margin-bottom: 4px !important;
				}
				.app-c-course-more {
					background-color: transparent;
					border: 0;
					color: $app-brand-primary;
					text-transform: capitalize;
				}
			}
			.app-c-skill-indicators {
				ul {
					@include flexbox;
					@include align-items(center);
					list-style: none;
					li {
						margin-right: 10px;
						position: relative;
						margin-left: 15px;
						font-size: $text-md;
						&:before {
							content: '';
							position: absolute;
							top: 4px;
							left: -17px;
							width: 10px;
							height: 10px;
							border-radius: 50%;
						}
						&.before-progress {
							&:before {
								background-color: #a6aac7;
							}
						}
						&.after-progress {
							&:before {
								background-color: $app-brand-primary;
							}
						}
						&.peer-progress {
							&:before {
								background-color: #1caad9;
							}
						}
					}
				}
			}
			.course-progress-bar-wrapper {
				max-height: 350px;
				overflow-y: auto;
				overflow-x: hidden;
				margin: 0 -10px;
				padding: 0 10px;
				.app-c-progress-bar-wrapper {
					cursor: pointer;
					.progress {
						height: 12px;
						margin-bottom: 10px;
						&.before-progress {
							.progress-bar {
								background-color: #a6aac7;
							}
						}
						&.after-progress {
							.progress-bar {
								background-color: $app-brand-primary;
							}
						}
						&.peer-progress {
							.progress-bar {
								background-color: #1caad9;
							}
						}
					}
				}
				.app-c-progress-bar {
					padding: 8px 11px;
					margin: 0 -8px 8px;
					border-radius: 8px;
					cursor: pointer;
					transition: 0.3s background ease-in-out;
					&:hover {
						background: $gray02;
						.progress {
							background-color: $white;
						}
					}
					&:last-child {
						margin-bottom: 0px;
					}
				}
			}
		}
		h5 {
			font-size: 16px;
			font-weight: $font-weight-semibold;
		}
	}
	&.app-c-course-details {
		@media screen and (max-width: 980px) {
			display: block !important;
			margin: 3px -4px;
		}
	}
}

.app-c-profile-tag {
	height: 116px;
	min-height: 116px;
	@include flexbox();
	@include flex-direction(column);
	@include justify-content(center);
	@include align-items(flex-start);
	padding: 24px;
	@media (max-width: 767px) {
		height: 100%;
	}
	.app-c-profile-tag-holder {
		@include flexbox();
		@include flex-direction(row);
		@include justify-content(flex-start);
		@include align-items(center);
		h4 {
			font-size: 20px;
			line-height: 16px;
			margin-bottom: 6px;
		}
	}
	.app-c-profile-tag__avatar {
		min-width: 60px;
		width: 60px;
		height: 60px;
		min-height: 60px;
		@include border-radius(50%);
		background: $app-brand-primary;
		@include flexbox();
		@include flex-direction(column);
		@include justify-content(center);
		@include align-items(center);
		margin-right: 20px;
		@media (max-width: 767px) {
			margin-right: 0px;
		}
		.rtl &,
		[dir='rtl'] & {
			margin-right: 0;
			margin-left: 20px;
			text-align: left;
			@media (max-width: 767px) {
				margin-left: 0px;
			}
		}
		img {
			width: 100%;
			height: 100%;
			@include border-radius(50%);
		}
	}
}
.app-c-status-wrapper {
	margin: 0px 0px 0px 0px;
	.app-c-status-tile {
		flex: 1;
		margin: 0px 4px;
		margin-bottom: 8px;
		padding: 32px 24px 16px 24px;
		position: relative;
		.app-c-status__icon {
			position: absolute;
			top: 32px;
			right: 24px;
		}
		.app-c-status__count {
			display: block;
			font-size: 32px;
			color: $app-brand-primary;
			font-weight: 500;
			line-height: 29px;
			margin-bottom: 10px;
		}
	}
}
.app-c-subText {
	color: rgba(97, 99, 111, 1);
	font-size: $text-md;
	line-height: 13px;
	.rtl &,
	[dir='rtl'] & {
		text-align: right;
	}
}
.app-c-pathway__wrapper {
	position: relative;
	margin: 0px -4px;
	overflow: hidden;
	h5 {
		margin-bottom: 16px !important;
	}

	&.-no-path {
		.react-multi-carousel-list {
			padding-bottom: 16px;
			&::before {
				display: none;
			}
		}
	}
	.react-multi-carousel-list {
		//padding-top: 40px;
		overflow: visible;
		overflow-y: unset;
		padding-bottom: 26px;
		position: relative;
		&::before {
			position: absolute;
			width: 99%;
			content: '';
			height: 1px;
			margin: auto;
			left: 0;
			right: 0;
			bottom: 5px;
			display: block;
			border-bottom: 1px dashed $app-brand-primary;
		}
	}
}

.app-c-pathway-box {
	position: relative;
	padding-left: 72px;
	border: 1px solid var(--theme-primary-color-200);
	@include border-radius(8px);
	margin: 0px 4px;
	cursor: pointer;
	user-select: none;
	@include transition(0.2s ease-in);
	&:hover {
		@include boxShadow(0px 2px 8px rgba(0, 0, 0, 0.135216));
	}
	&.-no-path {
		.app-c-status-tag {
			&::after {
				display: none;
			}
		}
	}
	h6 {
		line-height: 16.59px;
		font-weight: $font-weight-semibold;
		margin-bottom: 4px;
		font-size: 14px;
		user-select: none;
	}
	.app-c-subText {
		line-height: 16px;
		display: block;
	}
	picture {
		position: absolute;
		margin: auto;
		left: 10.22px;
		top: 0;
		bottom: 0;
		height: 50px;
		width: 50px;
		display: flex;
		@include border-radius(6px);
		user-select: none;
		img {
			@include border-radius(6px);
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.app-c-pathway__texting {
		height: 72px;
		position: relative;
		padding: 12px 12px 12px 0px;
		user-select: none;
		h6 {
			max-width: 80%;
			@include truncate();
		}
		.app-c-icon-btn {
			position: absolute;
			top: 8px;
			right: 8px;
			width: 19px;
			height: 16px;
			padding: 0;
			background: transparent;
			font-size: $text-xl;
			color: $app-brand-primary;
		}
		.app-c-status-tag {
			position: absolute;
			left: 0;
			bottom: -10px;
			&::after {
				position: absolute;
				margin: auto;
				bottom: -17px;
				left: 0;
				right: 0;
				content: '';
				width: 9px;
				height: 9px;
				display: block;
				border: 2px solid $app-brand-primary;
				@include border-radius(50%);
				background: #fff;
			}
			&.completed {
				&::after {
					background-color: $app-brand-primary;
				}
			}
		}
		.app-c-subText {
			@include truncate();
		}
	}
}
.less-widget {
	@include boxShadow(0px 2px 8px rgba(0, 0, 0, 0.135216));
	@include border-radius(8px);
	background: #fff;
	margin: 0px 4px;
	flex: 1;
	padding: 0 24px 24px;
	margin-bottom: 8px;
	.-temp-widget {
		@include flexbox;
		@include align-items(center);
		@media (min-width: 992px) {
			max-height: 250px;
		}
		@media (max-width: 767px) {
			@include flex-direction(column);
		}
		.temp-performance {
			max-width: 300px;
			position: relative;
			width: 100%;
			margin-right: 30px;
		}
		.app-c-legend-full-w {
			width: 100%;
			@include flexbox;
			@include justify-content(center);
			@include align-items(center);
			@media (max-width: 767px) {
				@include flex-direction(column);
			}
			ul {
				// width: 100%;
				@include flexbox;
				// @include flex-wrap(wrap);
				@include justify-content(center);
				@include flex-wrap(wrap);

				li {
					list-style: none;
					position: relative;
					text-align: left;
					// max-width: 170px;
					// width: 100%;
					white-space: nowrap;
					margin: 15px 30px;
					@media (max-width: 992px) {
						margin-bottom: 30px;
						width: 100%;
					}
					&::before {
						position: absolute;
						width: 10px;
						height: 10px;
						display: block;
						content: '';
						border-radius: 50%;
						top: 17px;
						left: -21px;
					}
					&.complete {
						&::before {
							background: #17217f;
						}
					}
					&.inprog {
						&::before {
							background: #ffcd00;
						}
					}
					&.overdue {
						&::before {
							background: #e5455b;
						}
					}
					&.inactive {
						&::before {
							background: #bec1d3;
						}
					}
					.legends-value {
						color: $app-brand-primary;
						font-size: $xl;
						font-weight: $font-weight-semibold;
						display: inline;
						position: relative;
						&:after {
							content: '%';
							position: absolute;
							top: 0px;
							font-size: 17px;
							margin-left: 4px;
						}
					}
					.legends-title {
						color: #656565;
						line-height: 10px;
					}
				}
			}
		}
	}
}

.section-header-cm-dash {
	margin: 20px 10px;
}
