.app-l-editor-holder {
	padding: 30px;

	h1 {
		font-size: 20px;
		color: #1e1919;
		margin-bottom: 0;
		font-weight: 500;
		background: #ececec;
		padding: 15px 20px;
		border: 1px solid #ccc;
		border-bottom: 0;
	}

	.tox {
		margin-bottom: 20px;
	}
}

.ce-header {
	@media (max-width: 767px) {
		padding: 0.2em 0 0.5em 0 !important;
		font-size: 28px !important;
	}
	@media (max-width: 640px) {
		font-size: 20px !important;
	}
}
