.app-l-carousal-data {
	width: 100%;
	height: 100%;
	min-height: 200px;
	padding: 8px 0px;

	@include flexbox();
	@include flex-direction(column);

	.app-l-carousal-header {
		width: 100%;
		position: relative;
		padding: 8px 16px;

		@include flexbox();
		@include align-items(center);
		@include justify-content(flex-start);
		@include flex-shrink(0);

		.app-l-window-exit,
		.app-l-viewer-close {
			width: 32px;
			height: 32px;
			padding: 4px;
			cursor: pointer;

			@include flexbox();
			@include align-items(center);
			@include justify-content(flex-start);
			@include flex-shrink(0);

			&:hover {
				i {
					color: $black;
				}
			}

			i {
				font-size: 16px;
				color: $text-dark-medium;
				line-height: normal;

				@include transition(color 0.2s ease);
			}

			+ .app-l-heading--h3 {
				margin-left: 8px;
			}
		}

		.app-l-heading--h3 {
			margin: 0;
		}

		.app-l-viewer-close {
			margin-left: auto;
		}
	}

	.app-l-carousal-body {
		width: 100%;
		height: 100%;
		max-height: 100%;
		min-height: 100px;
		padding: 0 16px;

		@include flexbox();
		@include align-items(center);

		.app-l-carousal-widget {
			width: 100%;
			height: 100%;
			max-height: 100%;
			min-height: 0;
			min-width: 500px;

			@include flexbox();
			@include flex-direction(column);
			@include align-items(center);
			@include justify-content(center);

			@media screen and (max-width: 767px) {
				min-width: 100%;
			}

			.carousel {
				max-height: 100%;
				min-height: 0;
				height: 100%;
				width: 100%;

				@include flexbox();
				@include flex-direction(column);
				@include align-items(center);
				@include justify-content(center);

				.carousel-inner {
					height: auto;
					max-height: 100%;
					min-height: 0;

					@include flexbox();

					.carousel-item {
						height: 100%;
						max-height: 100%;
						min-height: 0;

						.item {
							height: auto;
							max-height: 100%;
							min-height: 0;

							> img {
								height: auto !important;
								width: auto !important;
								max-height: 500px !important;
								max-height: calc(100vh - 200px) !important;
								min-height: 0;
								margin: auto;

								@media screen and (max-width: 767px) {
									max-height: 500px;
								}
							}
						}
					}
				}

				&:hover {
					.carousel-indicators {
						background-color: rgba(0, 0, 0, 0.15);
					}
				}

				.carousel-control {
					opacity: 1;
					width: 40px;

					&.left {
						&:hover {
							.carousel-control-prev-icon {
								border-color: $white;
								background-color: rgba(0, 0, 0, 0.4);

								@include boxShadow(0px 1px 5px 1px rgba(0, 0, 0, 0.5));

								&:after {
									color: $white;
								}
							}
						}

						.carousel-control-prev-icon {
							width: 40px;
							height: 40px;
							background-image: none;
							position: relative;
							border: solid 1px;
							border-color: $text-light-normal;
							background-color: $white;
							padding: 0px 8px 0 12px;
							line-height: 18px;
							cursor: pointer;

							@include flexbox();
							@include align-items(center);
							@include justify-content(center);
							@include border-radius(4px);
							@include boxShadow(0px 3px 6px rgba(63, 63, 63, 0.1));
							@include transition(
								border-color 0.3s ease-in-out,
								box-shadow 0.3s ease-in-out,
								background-color 0.3s ease-in-out
							);

							&:after {
								margin: auto;
								padding: 0px 4px 0px 0px;

								content: '\e90a';
								font-size: 18px;
								color: $text-dark-medium;
								display: block;

								@include transition(color 0.3s ease-in-out);
							}
						}
					}

					&.right {
						&:hover {
							.carousel-control-next-icon {
								border-color: $white;
								background-color: rgba(0, 0, 0, 0.4);

								@include boxShadow(0px 1px 5px 1px rgba(0, 0, 0, 0.5));

								&:after {
									color: $white;
								}
							}
						}

						.carousel-control-next-icon {
							width: 40px;
							height: 40px;
							background-image: none;
							position: relative;
							border: solid 1px;
							border-color: $text-light-normal;
							background-color: $white;
							padding: 0px 12px 0 8px;
							line-height: 18px;
							cursor: pointer;

							@include flexbox();
							@include align-items(center);
							@include justify-content(center);
							@include border-radius(4px);
							@include transition(
								border-color 0.3s ease-in-out,
								box-shadow 0.3s ease-in-out,
								background-color 0.3s ease-in-out
							);

							&:after {
								margin: auto;
								padding: 0px 0px 0px 4px;

								content: '\e909';
								font-size: 18px;
								color: $text-dark-medium;
								display: block;

								@include transition(color 0.3s ease-in-out);
							}
						}
					}
				}

				.carousel-indicators {
					@include transition(background-color 0.3s ease-in-out);
					@include border-radius(20px);

					&:hover {
						background-color: rgba(0, 0, 0, 0.5);
					}
				}
			}
		}
	}
}

.app-l-document-download {
	min-width: 200px;
	max-width: 100%;
	min-height: 200px;
	max-height: 100%;

	@include flexbox();
	@include align-items(center);
	@include justify-content(center);
	@include flex-direction(column);

	.app-l-doc-img-holder {
		padding: 16px 8px;
		max-width: 100%;
		max-height: 100%;

		@include flexbox();

		i {
			font-size: 56px;
			color: $text-dark-normal;
		}
	}
	.app-l-doc-link {
		padding: 0 8px;
		cursor: pointer;
		max-width: 100%;
		max-height: 100%;
		min-width: 0;

		@include flex-shrink(0);

		&:hover {
			a {
				color: $black;
			}
		}

		a {
			margin: 0;
			text-align: center;
			color: $text-dark-normal;
			font-size: 12px;
			text-transform: capitalize;
			text-decoration: none;
			min-width: 0;
			display: block;

			&:focus {
				text-decoration: underline;
			}

			@include truncate();
			@include transition(color 0.3s ease-in-out);
		}
	}
}
