// Rubik

/* rubik-300 - latin */
@font-face {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 300;
	src: url('../../../assets/fonts/custom-fonts/rubik/rubik-300.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('../../../assets/fonts/custom-fonts/rubik/rubik-300.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */ url('../../../assets/fonts/custom-fonts/rubik/rubik-300.woff2')
			format('woff2'),
		/* Super Modern Browsers */ url('../../../assets/fonts/custom-fonts/rubik/rubik-300.woff')
			format('woff'),
		/* Modern Browsers */ url('../../../assets/fonts/custom-fonts/rubik/rubik-300.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('../../../assets/fonts/custom-fonts/rubik/rubik-300.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-regular - latin */
@font-face {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	src: url('../../../assets/fonts/custom-fonts/rubik/rubik-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('../../../assets/fonts/custom-fonts/rubik/rubik-regular.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */ url('../../../assets/fonts/custom-fonts/rubik/rubik-regular.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('../../../assets/fonts/custom-fonts/rubik/rubik-regular.woff') format('woff'),
		/* Modern Browsers */ url('../../../assets/fonts/custom-fonts/rubik/rubik-regular.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('../../../assets/fonts/custom-fonts/rubik/rubik-regular.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-500 - latin */
@font-face {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 500;
	src: url('../../../assets/fonts/custom-fonts/rubik/rubik-500.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('../../../assets/fonts/custom-fonts/rubik/rubik-500.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */ url('../../../assets/fonts/custom-fonts/rubik/rubik-500.woff2')
			format('woff2'),
		/* Super Modern Browsers */ url('../../../assets/fonts/custom-fonts/rubik/rubik-500.woff')
			format('woff'),
		/* Modern Browsers */ url('../../../assets/fonts/custom-fonts/rubik/rubik-500.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('../../../assets/fonts/custom-fonts/rubik/rubik-500.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-600 - latin */
@font-face {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 600;
	src: url('../../../assets/fonts/custom-fonts/rubik/rubik-600.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('../../../assets/fonts/custom-fonts/rubik/rubik-600.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */ url('../../../assets/fonts/custom-fonts/rubik/rubik-600.woff2')
			format('woff2'),
		/* Super Modern Browsers */ url('../../../assets/fonts/custom-fonts/rubik/rubik-600.woff')
			format('woff'),
		/* Modern Browsers */ url('../../../assets/fonts/custom-fonts/rubik/rubik-600.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('../../../assets/fonts/custom-fonts/rubik/rubik-600.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-700 - latin */
@font-face {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 700;
	src: url('../../../assets/fonts/custom-fonts/rubik/rubik-700.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('../../../assets/fonts/custom-fonts/rubik/rubik-700.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */ url('../../../assets/fonts/custom-fonts/rubik/rubik-700.woff2')
			format('woff2'),
		/* Super Modern Browsers */ url('../../../assets/fonts/custom-fonts/rubik/rubik-700.woff')
			format('woff'),
		/* Modern Browsers */ url('../../../assets/fonts/custom-fonts/rubik/rubik-700.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('../../../assets/fonts/custom-fonts/rubik/rubik-700.svg#Rubik') format('svg'); /* Legacy iOS */
}
