.app-c-multiselect {
	.dropdown-container {
		border: none;
		box-shadow: none !important;
		.dropdown-heading {
			padding: 0px;
			height: auto;
		}
		.dropdown-heading-value {
			span {
				display: inline-block;
				padding: 7px 8px;
				font-size: 12px;
				line-height: normal;
				color: #1e1919;
				background: #e9ebf4;
				color: #1e1919;
				@include border-radius(5px);
				word-break: break-all;
				white-space: normal;
			}
		}
		&:focus-within {
			border: none;
			box-shadow: none !important;
		}
		.dropdown-heading-dropdown-arrow {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			right: 0px;
			left: auto;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-top: 5px solid #1e1919;
			.rtl &,
			[dir='rtl'] & {
				right: auto;
				left: 0px;
			}
		}
		.clear-selected-button {
			margin-right: 8px;
			svg {
				scale: 0.8;
			}
		}
	}
}

.search {
	display: inline-block;
}

.right {
	position: relative;
}

.right img {
	position: absolute;
	top: 5px;
	z-index: -1;
}

.right img:nth-of-type(1) {
	right: 40px;
}

.right img:nth-of-type(2) {
	right: 60px;
}

.search input[type='search'] {
	width: 15px;
	padding-left: 10px;
	color: transparent;
	cursor: pointer;
	z-index: 5;
}

.search input[type='search']:hover {
	background-color: #fff;
}

.search input[type='search']:focus {
	width: 600px;
	padding-left: 32px;
	color: #4a83c0;
	background-color: #fff;
	cursor: auto;
}

.search input:-moz-placeholder {
	color: transparent;
}

.search input::-webkit-input-placeholder {
	color: transparent;
}
// Custom select
.app-c-custom-select {
	position: relative;
	.form-control {
		padding-right: 22px;
		appearance: none;
		&:focus {
			outline: none;
			@include noShadow();
			border-color: $app-brand-primary;
		}
	}
	&::after {
		content: '\e972';
		pointer-events: none;
		position: absolute;
		right: 10.33px;
		top: 14px;
		font-size: 9.33px;
		color: $text-color;
		font-weight: $font-weight-semibold;
		font-family: 'qualify-tms';
	}
}

// Normal Select
.app-c-normal-select {
	position: relative;
	.form-control {
		padding-right: 28px;
		appearance: none;
		font-size: 14px;
		height: 40px;
	}
	&::after {
		content: '\e972';
		pointer-events: none;
		position: absolute;
		right: 13px;
		top: 13.5px;
		font-size: 10px;
		color: rgba(97, 99, 111, 1);
		font-weight: $font-weight-semibold;
		font-family: 'qualify-tms';
	}
}

.app-c-phone-number-field {
	border: 1px solid $generic-border-color;
	@include border-radius(4px);
	@include flexbox();
	.country-flag {
		position: relative;
		background: $box-gray;
		@include flexbox();
		@include justify-content(flex-start);
		@include align-items(center);
		padding: 7px 29px 7px 13px;
		border: none;
		@include border-radius(6px 0px 0px 6px);
		font-size: 0;
		@include transition(background ease 0.2s);
		&:hover {
			background: $box-gray-hover;
		}

		img {
			all: unset;
			// max-width: 20px;
			max-height: 18px;
			max-width: 22px;
			padding: 0;
			margin: 0;
			line-height: normal;
			border: none;
			outline: none;
			font-size: 0;
			display: inline-block;
		}

		&::after {
			content: '\e972';
			font-size: 8px;
			font-family: 'qualify-tms';
			color: $text-muted;
			display: block;
			right: 12px;
			width: 8px;
			top: 9px;
			bottom: 0;
			margin: auto;
			position: absolute;
			line-height: 20px;
			pointer-events: none;
		}
	}
	.app-c-phone-code {
		position: relative;
		padding: 0px 8px;
		//padding-right: 5px;
		appearance: none;
		line-height: 38px;
		font-size: $text-lg;
		display: inline-block;
		white-space: nowrap;

		&::after {
			content: '';
			display: block;
			height: 18px;
			width: 2px;
			right: -1px;
			top: 0;
			bottom: 0;
			margin: auto;
			border-right: 1px solid $generic-border-color;
			position: absolute;
		}
		.form-control {
			padding: 0px 6px !important;
			//padding-right: 5px;
			appearance: none;
			line-height: 34px;
		}
	}
	.form-control {
		border: none;
		min-height: 38px !important;
		height: 38px !important;
		line-height: 38px;
		@include border-radius(0px);
		background: none;
		padding: 0px 6px !important;
	}
}

.country-drop-list {
	position: absolute;
	top: 70px;
	left: 0;
	background: #fff;
	@include boxShadow(0px 9px 21px 0px rgba(0, 0, 0, 0.1316));
	width: 100%;
	overflow: auto;
	z-index: 2;
	@include border-radius(6px);
	@include transition(all ease 0.2s);
	z-index: 3;

	ul {
		padding: 0;
		margin: 0;
	}
	li {
		height: 48px;
		@include flexbox();
		@include justify-content(space-between);
		@include align-items(center);
		padding: 10px 13px 10px 13px;
		font-size: $text-lg;
		@include transition(all ease 0.2s);
		cursor: pointer;
		&:hover,
		&.active {
			background: $box-gray;
		}
		&:first-child {
			@include border-radius(6px 6px 0px 0px);
		}
		&:first-child {
			@include border-radius(0px 0px 6px 6px);
		}
		.country-drop-list__code {
			min-width: 46px;
			flex-shrink: 0;
			text-align: right;
		}
	}
	.country-drop-list__holder {
		@include flexbox();
		@include justify-content(space-between);
		@include align-items(center);
		flex-grow: 1;
		justify-content: flex-start;
		@media screen and (max-width: 767px) {
			max-width: 79%;
		}
	}
	.country-drop-list__flag {
		font-size: 0;
		display: inline-block;
		border: none;
		outline: none;
		margin-right: 16px;
		.rtl &,
		[dir='rtl'] & {
			margin-right: 0;
			margin-left: 16px;
		}
		img {
			border: none;
			outline: none;
			font-size: 0;
			display: inline-block;
			height: 14px;
			width: 22px;
		}
	}
	.country-drop-list__text {
		@include truncate();
		max-width: 280px;
		@media (max-width: 1200px) and (min-width: 992px) {
			max-width: 126px;
		}
	}
}
