@import '../core/mixins/filters';
@import '../core/mixins/border-radius';
@import '../variables/variables';

.css-1pahdxg-control {
	padding: 0px;
}
.css-1wa3eu0-placeholder {
	color: $text-placeholder;
	font-size: $text-lg;
	padding-left: 8px;
}
.css-yk16xz-control {
	@include border-radius(6px);
	&:focus {
		outline: none;
		box-shadow: none;
		border: 0;
	}
	.css-g1d714-ValueContainer {
		padding: 0;
		padding-left: 4px;
		&:focus {
			outline: none;
			box-shadow: none;
		}
	}
}
.css-1pahdxg-control {
	border: none !important;
	min-height: inherit;
	border-color: inherit;
	outline: none;
	.css-g1d714-ValueContainer {
		padding-left: 4px;
	}
}

.css-tlfecz-indicatorContainer {
	color: #61636f !important;
}
.css-yk16xz-control,
.css-1pahdxg-control {
	.css-1rhbuit-multiValue {
		background-color: #e9ebf4;
		@include border-radius(5px);
		padding: 7px 32px 7px 8px;
		position: relative;
		line-height: 14.22px;
		font-weight: 400;
		color: #1e1919;
		font-size: 14px;
		.css-12jo7m5 {
			padding: 0;
		}
	}
	.css-xb97g8 {
		color: #1e1919;
		position: absolute;
		right: 0px;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 26px;
		height: 28px;
		padding: 0;
		text-align: center;
		@include border-radius(0px 5px 5px 0px);
		transition: all ease 0.2s;
		cursor: pointer;
		&::after {
			content: '\e916';
			font-family: 'qualify-tms';
			font-size: 7px;
			text-align: center;
			width: 100%;
			height: 100%;
			line-height: 28px;
		}
		&:hover {
			background-color: #dce0f1;
			color: #1e1919;
		}

		.css-tj5bde-Svg {
			display: none !important;
		}
		svg {
			display: none !important;
		}
	}
	&:focus {
		outline: none;
		box-shadow: none;
		.css-1rhbuit-multiValue {
			background-color: #e9ebf4;
			@include border-radius(5px);
			padding: 7px 32px 7px 8px;
			position: relative;
			line-height: normal;
			font-size: $text-lg;
		}
		.css-xb97g8 {
			color: #1e1919;
			&:hover {
				background-color: #dce0f1;
				color: #1e1919;
			}
			.css-tj5bde-Svg {
				display: none !important;
			}
			svg {
				display: none !important;
			}
		}
	}
}

// Multiselect 2

.tags-app-c-multiselect {
	position: relative;
	&::after {
		content: '\e972';
		color: #61636f;
		font-family: 'qualify-tms';
		position: absolute;
		right: 5px;
		top: 13px;
		font-size: 10px;
		pointer-events: none;
		.rt &,
		[dir='rtl'] & {
			right: 0;
			left: 5px;
		}
	}
	.go1075022653 {
		padding: 0 !important;
		position: relative;

		&::before {
			content: '\e92b';
			font-family: 'qualify-tms';
			top: 12px;
			left: 13px;
			position: absolute;
			font-size: 14px;
			color: #61636f;
		}
		input {
			&[type='text'] {
				background: #f4f5f9 50%;
				border: 1px solid #edf0f4 !important;
				@include border-radius(6px);
				padding-left: 40px;
				font-size: 14px;
			}
		}
		.clear-selected-button {
			display: none !important;
		}
	}

	.go626121005 {
		span {
			color: $text-color;
			font-size: $text-lg;
			word-break: break-all;
		}
		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			/* When the checkbox is checked, add a blue background */
			&:checked ~ span {
				/* Show the checkmark when checked */
				&::before {
					display: block;
					background: $app-brand-primary;
					border: 1px solid $app-brand-primary;
					content: '\e902';
					color: #fff;
					font-family: 'qualify-tms';
					font-size: 9px;
					text-align: center;
					line-height: 16px;
				}
			}
		}
		span {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			padding-left: 28px;

			/* Create the checkmark/indicator (hidden when not checked) */
			&::before {
				content: '';
				position: absolute;
				display: block;
				width: 16px;
				height: 16px;
				background: #fff;
				border: 1px solid #bec1d2;
				@include border-radius(3px);
				top: 3px;
				left: 2px;
			}
		}
	}

	.go2646822163 {
		border: none;
		padding: 0;
		&:focus,
		&:hover {
			border: none;
			box-shadow: none;
		}
	}
	.dropdown-heading-value {
		span {
			background: none !important;
			.custom-tag {
				background: #e9ebf4 !important;
			}
		}
	}
	.dropdown-container {
		.clear-selected-button {
			display: none;
		}
	}

	.dropdown-search-clear-icon {
		&.gray {
			display: none;
		}
	}
	&.width-60 {
		.newTag-button {
			left: 635px;
		}
		.go3425554998 {
			&.dropdown-content {
				width: 687px !important;
				@media screen and (max-width: 575px) {
					width: 270px !important;
				}
			}
		}
	}
	.go3425554998 {
		padding-bottom: 18px;
		&.dropdown-content {
			width: 387px;
			z-index: 10;
			@media screen and (max-width: 767px) {
				//width: 100%;
			}
			@media screen and (max-width: 575px) {
				// width: 270px;
				width: calc(100% - -37px);
				input {
					padding-left: 34px;
					display: block;
					font-size: 10px;
					padding-right: 23px;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					word-break: normal;
					&::-webkit-input-placeholder {
						/* Chrome/Opera/Safari */
						font-size: 12px;
					}
					&::-moz-placeholder {
						/* Firefox 19+ */
						font-size: 12px;
					}
					&:-ms-input-placeholder {
						/* IE 10+ */
						font-size: 12px;
					}
					&:-moz-placeholder {
						/* Firefox 18- */
						font-size: 12px;
					}
				}
				input[placeholder] {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
			.panel-content {
				padding: 16px;
				overflow: visible;
				max-height: inherit;
				background: #fff;
				ul {
					max-height: 125px;
					overflow: auto;
					display: flex;
					flex-wrap: wrap;
					position: relative;
					margin-right: -8px;
					padding-top: 3px;
					margin-top: 0;
					@media screen and (max-width: 757px) {
						flex-wrap: wrap;
					}
					li {
						flex: 0 50%;
						padding: 4px 0px;
						border-bottom: 0;
						@media screen and (max-width: 575px) {
							flex: 0 100%;
						}
					}
				}
			}
		}
	}
	.go3973839879 {
		background: none !important;
		line-height: 21px;
		padding: 0;
		margin: 0px 0px 7px 0px;
		label {
			padding: 10px !important;
		}
	}

	.go1075022653 {
		border-bottom: none !important;
		padding: 5px;
		margin-bottom: 18px;
		input {
			border: 1px solid #fafafa !important;
			border-radius: 7px !important;
			background: #f4f5f9;
			padding: 10px 10px 10px 35px !important;
		}
	}
}

//Multiselect Filter

.multiselect-filter {
	&.new-multi-filter {
		.go3425554998 {
			flex-direction: column;
			display: flex;
			margin: 0;
			.go2343601875 {
				flex-direction: column;
				display: flex;
				margin: 0;
				li {
					padding: 0;
					width: 100%;
					border: 0;
				}
			}
		}
	}
	.go2139093995 {
		padding: 0 !important;
		.dropdown-heading-value {
			display: none;
		}
		.go1003880112 {
			&.clear-selected-button {
				min-width: 60px;
				padding: 2px 10px;
				border-radius: 50px;
				// box-shadow: 0 2px 10px rgb(39 27 10 / 21%);
				// @include boxShadow(0 2px 10px rgb(39 27 10 / 21%));
				color: $app-brand-primary;
				float: right;
				min-height: auto;
				height: auto;
				font-size: 12px;
				position: absolute;
				top: 2px;
				right: -15px;
				div {
					background: white;
					padding: 3px 8px;
					border-radius: 5px;
					top: 13px !important;
				}
				&:hover {
					div {
						color: #ffffff !important;
						background: #1a277f;
					}
				}
			}
		}
		.dropdown-heading-dropdown-arrow {
			&.gray {
				display: none;
			}
		}
		.go3703752690 {
			&.search-clear-button {
				position: absolute;
				display: none;
			}
		}
	}
	.go2343601875 {
		max-height: 182px;
		overflow-y: auto;
	}
}
.tags-app-c-multiselect {
	position: relative;
	.newTag-button {
		z-index: 1000;
		position: absolute;
		left: 340px;
		background: none;
		bottom: -58px;
		width: 30px;
		height: 30px;
		color: #aeafb7;
		transition: all ease 0.2s;
		border: none;
		&:hover {
			color: $app-brand-primary;
		}
		@media screen and (max-width: 575px) {
			left: auto;
			right: 15px;
		}
	}
}

// Dev styles
.tags-app-c-multiselect {
	&.brdr {
		border: 1px solid #d2d2d6;
		padding: 2.5px;
		position: relative;
		@include border-radius(6px);
		margin-bottom: 24px;
		padding-right: 38px;
		&::after {
			content: '\e972';
			font-family: 'qualify-tms';
			right: 15px;
			top: 12px;
			position: absolute;
			color: rgba(97, 99, 111, 1);
			font-size: 10px;
		}
		.go2646822163 {
			background: none;
		}
		.go1075022653 {
			&::before {
				top: 9px;
			}
		}
		.newTag-button {
			border: none;
			bottom: -56px;
		}
	}
}
.app-c-multiselect {
	.dropdown-container {
		.dropdown-heading {
			.dropdown-heading-dropdown-arrow {
				display: none;
			}
		}
		.dropdown-heading-value {
			span {
				padding: 0px;
				&.gray {
					.custom-tag {
						background: none !important;
					}
				}
			}

			.custom-tag {
				margin: 2.5px !important;
				background: rgba(233, 235, 244, 1);
				@include border-radius(5px);
				padding: 7px 7px 7px 7px;
				position: relative;
				line-height: 14.22px;
				font-weight: 400;
				color: #1e1919;
				font-size: 12px;
				cursor: pointer;
			}
		}
	}
	.go1075022653 {
		border-bottom: 1px solid #ccc;
		input[type='text'] {
			padding: 10px;
		}
	}
}
.react-datepicker__triangle {
	left: 220px !important;
}

// Normal select
.app-c-normal-select {
	.css-yk16xz-control {
		padding-left: 13px !important;
		.rtl &,
		[dir='rtl'] & {
			padding-left: inherit !important;
			padding-right: 13px !important;
		}
	}
	.css-1pahdxg-control {
		padding-left: 13px !important;
		.rtl &,
		[dir='rtl'] & {
			padding-left: inherit !important;
			padding-right: 13px !important;
		}
	}
	.css-g1d714-ValueContainer {
		padding-left: 0 !important;
		.rtl &,
		[dir='rtl'] & {
			padding-right: 0 !important;
		}
	}
	.css-1uccc91-singleValue {
		font-size: 14px;
	}
	.css-1okebmr-indicatorSeparator {
		display: none;
	}
}
.app-c-card--default {
	label.go3973839879 {
		margin-bottom: 0;
	}
}

.sort-filter-dropdown__btn {
	&.btn {
		border: 1px solid $generic-border-color;
		text-align: left;
		padding: 0;
		text-decoration: none;
		font-size: $text-lg;
		color: $text-color;
		padding: 0px 0px 0px 13px;
		@include border-radius(6px !important);
		&:active,
		&:focus,
		&:hover {
			box-shadow: none;
			color: $text-color;
			outline: none;
			text-decoration: none;
		}
		&::after {
			top: 13px;
			right: 17px;
		}
	}
}
.sort-by-filter {
	border: none;
	padding: 20px 0px !important;
	@include border-radius(6px);

	&.active {
		display: block;
	}
	.set-title {
		font-size: $text-lg;
		font-weight: $font-weight-normal;
		color: $text-light;
		margin-bottom: 7px;
		display: block;
		padding: 5px 0px 0px 32px;
	}
	.dropdown-item {
		position: relative;
		padding-left: 32px;
		font-size: $text-lg;
		color: $text-color;
		display: block;
		line-height: 22px;
		@include transition(all ease 0.2s);
		&:active,
		&:focus {
			color: $text-color;
			background: none;
		}
		&.active {
			color: $text-color;
			background: none;
			&::before {
				content: '\e902';
				position: absolute;
				top: 5px;
				left: 12.5px;
				font-size: 9px;
				font-family: 'qualify-tms';
			}
		}
		i {
			margin: 0px 6px;
			font-size: $text-sm-plus;
		}
	}
}
