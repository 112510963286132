.app-c-add-new-box {
	border: 1px dashed $app-brand-primary;
	background: #f8f9fb;
	text-align: center;
	@include border-radius(2px);
	padding: 25px;
	margin-top: 8px;
	.app-c-add-new-box__icon {
		background: $app-brand-primary;
		@include border-radius(50%);
		width: 32px;
		height: 32px;
		@include inline-flex();
		@include align-items(center);
		@include justify-content(center);
		font-size: 14px;
		border: none;
		margin-bottom: 10px;
		&:disabled {
			// background-color: rgba($color: $app-brand-primary, $alpha: 0.7);
			background-color: $app-brand-primary-disable;
		}
		&:focus,
		&:hover:not(:disabled),
		&:active {
			@include noShadow();
			// background-color: rgba($color: $app-brand-primary-dark, $alpha: 1);
			background-color: $app-brand-primary-dark;
		}
	}
}
.app-c-add-new-box__title {
	line-height: 28px;
	font-size: $text-xl;
	font-weight: $font-weight-semibold;
	color: $text-color;
}
