.app-c-client-account-detail {
	@media screen and (max-width: 767px) {
		.app-l-text {
			margin-bottom: 10px !important;
		}
	}
	@media screen and (max-width: 991px) {
		.app-c-role {
			margin-bottom: 16px;
			&:last-child,
			&:nth-child(5) {
				margin-bottom: 0px;
			}
		}
	}

	.app-l-text {
		line-height: 16px;
		&.pending{
			color: rgba($text-muted,0.6);
		}
	}
	.app-c-form-group {
		.form-label {
			font-size: 12px;
		}
	}
	.users-table {
		.app-c-card__footer {
			padding: 42px 0px 0px;
		}
	}
	.app-c-tab .nav-item + .nav-item a {
		padding-left: 0px;
		padding-right: 0px;
		margin-right: 23px;
		margin-left: 63px;
		font-weight: normal;
		&.active {
			font-weight: 500;
		}
	}
	.app-c-right-date {
		margin-left: 35px !important;
	}
}
.app-c-switch-client-detail {
	margin-right: 35px !important;
}
