.labeled-graphics-preview {
	border-radius: 10px;
	margin: auto;
	text-align: center;

	@media print {
		display: block;
		//page-break-inside: auto;
		// page-break-before: auto;
	}

	@media (max-width: $screen-lg) {
		padding: 10px 10px;
	}

	.map-image-wrapper {
		position: relative;
		@include border-radius(4px);
		display: inline-flex;

		@media print {
			display: inline-block;
		}

		img {
			max-width: 100%;
			max-height: 65vh;
			@include border-radius(4px);
			width: auto;
			object-fit: contain;
			background: transparent;

			@media print {
				max-height: 500px;
			}

			@media (max-width: $screen-lg) {
				max-width: 100%;
			}
		}
	}
}

.photo-marker {
	width: 30px;
	height: 30px;
	background-color: $white;
	border: 1px solid #382f2f;
	border-radius: 40px;
	font-weight: $font-weight-semibold;
	font-size: 22px;
	@include flexbox;
	@include flex-direction(column);
	@include align-items(center);
	@include justify-content(center);
	font-size: 14px;
	z-index: 1 !important;
	cursor: pointer;

	&.active {
		background-color: $app-brand-primary;
		color: $white;
		border-color: $app-brand-primary;
	}

	& + .hotspot-popover {
		position: absolute !important;
	}

	@media screen and (max-width: 640px) {
		width: 26px;
		height: 26px;
		font-size: 12px;
	}
}

.btn-edit-labeled-graphics {
	width: 30px;
	height: 30px;
	position: absolute;
	top: -10px;
	right: -10px;
	border: none;
	background-color: $newslide-hover;
	border-radius: 50px;
	font-size: 12px;
	color: $text-muted;
	z-index: 1;
	font-weight: normal;
	@include transition(all 0.2s ease);

	&:hover {
		background-color: $app-brand-primary;
		color: $white;
	}
}

.mobile-preview {
	.labeled-graphics-preview {
		@media (max-width: $screen-lg) {
			padding: 0px;
		}

		.map-image-wrapper {
			img {
				max-width: 100%;

				@media (max-width: $screen-lg) {
					max-width: 100%;
				}
			}
		}
	}
}
