// General button sizes
@import '../core/mixins/border-radius';
@import '../core/mixins/transform';
@import '../core/mixins/transitions';
@import '../core/mixins/button-size';
@import '../core/mixins/flexbox';
@import '../core/mixins/box-shadow';
@import '../core/mixins/truncate';

@import '../variables/variables';
@import '../theme/theme';

.app-c-btn {
	text-align: center;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
	border: 1px solid transparent;
	font-weight: 500;
	color: #4f3f43;
	padding: 16px 24px;
	font-size: $text-lg;
	line-height: 18px;
	outline: none;
	font-family: $body-font;
	max-width: 100%;
	min-width: 0;

	@include transition(
		background ease 0.3s,
		color ease 0.3s,
		border ease 0.3s,
		box-shadow ease 0.3s
	);
	@include inline-flex();
	@include align-items(center);
	@include border-radius(8px);
	@include truncate();

	+ .app-c-btn {
		margin-left: 8px;
		.rtl &,
		[dir='rtl'] & {
			margin-left: 0px;
			margin-right: 8px;
		}
	}
	&.link-btn {
		border: none;
		background: none;
		padding: 0 !important;
	}
	&.skip-btn {
		font-size: 16px;
		font-weight: $font-weight-normal;
		line-height: 24px;

		&.right {
			margin-left: auto;
			display: block !important;
		}
		i {
			font-size: 14px;
		}
	}
	&.primary {
		color: $app-brand-primary;
		&:hover {
			color: $app-brand-primary-light;
		}
	}
	&.sm {
		padding: 7px $text-sm-plus;
		font-size: $text-md;
		line-height: 22px;
		display: inline-block;
		@include border-radius(6px);
		@media screen and (max-width: 576px) {
			padding: 5px 7px;
			min-width: inherit;
		}
		.icon-times {
			font-size: 10px;
		}

		i {
			font-size: 10px;
			margin-right: $text-xxs;
			@media screen and (max-width: 576px) {
				margin-right: 6px;
				font-size: 10px !important;
			}
			&.icon-save,
			&.icon-send {
				font-size: 13px;
				position: relative;
				top: -2px;
			}
			&.align-icon {
				font-size: 13px;
				margin-right: 8px;
				position: relative;
				top: 0px;
			}
			.rtl &,
			[dir='rtl'] & {
				margin-left: auto;
				margin-right: $text-sm-plus;
			}
			&.icon-close {
				font-size: 11px;
			}
			&.icon-close-solid {
				font-size: 15px;
			}
		}
	}
	&.md {
		padding: 10px 33.5px;
	}

	&:hover {
		text-decoration: none;
		outline: none;
	}
	&.app-c-btn--secondary {
		&:hover {
			background: #e9e7e7;
			i,
			em {
				color: $black;
			}
		}
	}
	&:active,
	&.active {
		outline: 0;
		background-image: none; // @include boxShadow(inset 0 3px 5px rgba(0,0,0,.125));

		@include noShadow();
	}

	&:focus {
		@include noShadow();
	}

	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		cursor: $cursor-disabled;
		opacity: 0.6;
		pointer-events: none;

		@include boxShadow(none);

		&:hover,
		&.hover {
			cursor: $cursor-disabled;
			opacity: 0.6;
			pointer-events: none;
			@include boxShadow(none);
		}
	}
	&.app-c-go-btn {
		padding: 0px;
		width: 24px;
		min-width: 24px;
		height: 30px;
		@include inline-flex();
		@include flex-direction(column);
		@include justify-content(center);
		font-size: 9.33px;
		@include transform(rotate(-90deg));
		@include border-radius(6px);
		&.position-top-right {
			position: absolute;
			top: 12px;
			right: 12px;
		}
		&.position-bottom-right {
			position: absolute;
			bottom: 16px;
			right: 12px;
			.rtl &,
			[dir='rtl'] & {
				right: auto;
				left: 12px;
				i {
					transform: scale(-1);
				}
			}
		}
	}
}

.app-c-link-btn {
	all: unset;
}

.app-c-btn--primary {
	background: $app-brand-primary;
	border-color: $app-brand-primary;
	color: $app-brand-primary-text;
	min-width: 80px;

	@include justify-content(center);

	&:hover,
	&:active,
	&:focus {
		background: $app-brand-primary-dark;
		border-color: $app-brand-primary-dark;
		outline: none;
		box-shadow: 0px 8px 16px var(--theme-primary-color-160);
	}

	&[disabled] {
		background: $app-brand-primary-disable;
		border-width: 0;
		border-color: $app-brand-primary-disable;
		color: $app-brand-primary-text;
	}
}

.app-c-btn--secondary {
	background: $white;
	border: 1px solid #bec1d2;
	color: #61636f;
	min-width: 80px;

	@include justify-content(center);
	i {
		color: #7e838d;
	}
	&:hover,
	&:active,
	&:focus {
		outline: none;
		background: $app-brand-secondary-disable;
	}

	&[disabled] {
		color: #9d999a;
		background: $neutrals-color4;
		border-color: var(--text-light-normal);
	}
}

.app-c-btn--icon-text {
	border: 2px solid $app-brand-primary;
	background: #ffffff;
	padding-left: 14px;
	padding-right: 14px;
	outline: none;

	i {
		font-size: 13px;
		margin-right: 5px;

		@include flex-shrink(0);
	}
	&:hover,
	&:focus {
		outline: none;
	}
}
.app-c-btn--success {
	background: $dark-green;
	color: $white;
	border-color: $dark-green;

	&:hover {
		background: darken($dark-green, 10%);
		border-color: $dark-green;
		outline: none;
	}

	&:active,
	&:focus {
		@include noShadow();
		outline: none;
	}
}

.app-c-btn--danger {
	background: $dark-red;
	color: $white;
	border-color: $dark-red;

	&:hover {
		background: darken($dark-red, 10%);
		border-color: $dark-red;
		outline: none;
	}

	&:active,
	&:focus {
		outline: none;

		@include noShadow();
	}
}

.app-c-btn--warning {
	background: $dark-yellow;
	color: $white;
	border-color: $dark-yellow;

	&:hover {
		background: darken($dark-yellow, 10%);
		border-color: $dark-yellow;
		outline: none;
	}

	&:active,
	&:focus {
		outline: none;

		@include noShadow();
	}
}

.app-c-btn--info {
	background: $dark-blue;
	color: $white;
	border-color: $dark-blue;

	&:hover {
		background: darken($dark-blue, 10%);
		border-color: $dark-blue;
		outline: none;
	}

	&:active,
	&:focus {
		outline: none;

		@include noShadow();
	}
}

.app-c-btn--light {
	background: $light-grey;
	border-color: $light-grey;

	&:hover {
		background: darken($light-grey, 10%);
		border-color: $light-grey;
		outline: none;
	}

	&:active,
	&:focus {
		outline: none;

		@include noShadow();
	}
}

.app-c-btn--dark {
	background: $dark-grey;
	color: $white;
	border-color: $dark-grey;

	&:hover {
		background: darken($dark-grey, 10%);
		border-color: $dark-grey;
		outline: none;
	}

	&:active,
	&:focus {
		outline: none;

		@include noShadow();
	}
}

.app-c-btn--default {
	background: $pear;
	color: $app-brand-primary;

	&:hover {
		background: darken($pear, 10%);
		border-color: $pear;
		outline: none;
	}

	&:active,
	&:focus {
		outline: none;

		@include noShadow();
	}
}

.app-c-btn--link {
	background: transparent;
	border-color: transparent;
	color: $app-brand-primary;
	text-decoration: underline;
	font-size: 12px;

	&:hover {
		color: $app-brand-primary;
		text-decoration: underline;

		@include noShadow();
	}
}

.app-c-btn--addon {
	i {
		margin-right: 10px;
		.rtl &,
		[dir='rtl'] & {
			margin-right: 0;
			margin-left: 10px;
		}
	}
}

.app-c-btn--block {
	display: block;
	width: 100%;

	+ .app-c-btn--block {
		margin-left: 0px;
		margin-top: 10px;
	}
}

.app-c-btn--transparent {
	border: 1px solid #363a43;
	background-color: #1a1f30;
	color: #636a7e;

	@include border-radius(16px);

	&:hover {
		border: 1px solid #81889c;
		background-color: #3f4761;
		color: rgba(255, 255, 255, 0.9);
		outline: none;
	}

	&:active,
	&:focus {
		outline: none;

		@include noShadow();
	}
}

// Button Sizes
// --------------------------------------------------
.app-c-btn-lg {
	height: auto !important;
	padding: 15px 16px;
	font-size: 20px;
}

.app-c-btn-sm {
	height: auto !important;
	padding: 7px 10px;
	font-size: 12px;
}

.app-c-btn-xs {
	height: auto !important;
	padding: 4px 8px;
	font-size: 10px;
}

.app-c-btn-block {
	display: block;
	width: 100%;
	margin-left: 0px !important;

	+ .app-c-btn-block {
		margin-top: 10px;
	}
}

//Input type file styles
//----------------------------------------------------
.app-c-btn-file {
	position: relative;
	overflow: hidden;
	margin-bottom: 0px;
	padding: 4px 10px;

	label {
		@media only screen and (max-width: 1024px) and (min-width: 768px) {
			max-width: 100%;
			white-space: initial;
			overflow: initial;
			text-overflow: initial;
		}
	}
}

.app-c-btn-file input[type='file'] {
	display: none;
	position: absolute;
	top: 0;
	right: 0;
	min-width: 100%;
	min-height: 100%;
	font-size: 100px;
	text-align: right;
	filter: alpha(opacity=0);
	opacity: 0;
	outline: none;
	background: white;
	cursor: inherit;
	display: block;
}

.app-c-browse-btn {
	position: relative;
	border: 1px solid #bec1d2;
	@include border-radius(6px);
	padding: 1px 12px 1px 14.67px;
	cursor: pointer;
	height: 40px;
	transition: all ease 0.2s;
	overflow: hidden;
	&:hover {
		background: #e9e7e7;
	}

	i {
		margin-right: 10px;
		.rtl &,
		[dir='rtl'] & {
			margin-right: 0;
			margin-left: 10px;
		}
		&::before {
			font-size: 16px;
		}
	}
	&.inline {
		@include inline-flex();
		@include flex-direction(row);
		@include align-items(center);
		margin-right: 8px;
		vertical-align: middle;
		.rtl &,
		[dir='rtl'] & {
			margin-right: 0;
			margin-left: 8px;
		}
	}
	.app-c-browse-btn__file {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		@media screen and (max-width: 767px) {
			width: 27px;
			font-size: 0;
			height: 100%;
		}
	}
	.app-c-browse-btn__label {
		color: $text-color;
		font-size: $text-lg;
		font-weight: $font-weight-semibold;
		display: inline-block;
		line-height: 18.2px;
	}
}

//Button group
//----------------------------------------------------
.app-c-btn-group {
	@include inline-flex();
	position: relative;
	vertical-align: middle;

	> .app-c-btn {
		position: relative;
		@include flex(1, 1, auto);

		+ .app-c-btn {
			margin-left: 0px;
		}

		&:not(:first-child),
		&:not(.dropdown-toggle) {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}

		&:not(:last-child),
		&:not(.dropdown-toggle) {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}

		&:first-child {
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
		}

		&:last-child {
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
		}
	}
}

.app-c-btn--icon {
	width: 30px;
	height: 30px;
	background: $white;
	color: #61636f;
	padding: 0;
	font-size: 16px;
	outline: none;

	@include border-radius(3px);
	@include inline-flex();
	@include align-items(center);
	@include justify-content(center);

	&:hover {
		outline: none;
		background: $neutrals-color3;
	}
	&:focus,
	&:active {
		outline: none;
		background: $neutrals-color2;
		color: $white;
	}

	&.app-c-btn--icon-primary {
		background: $app-brand-primary;
		border-color: $app-brand-primary;
		color: $app-brand-primary-text;

		&:hover {
			background: var(--lighten4);
			border-color: var(--lighten4);
			outline: none;
		}

		&:active,
		&:focus {
			background: var(--lighten5);
			border-color: var(--lighten5);
		}
		&[disabled] {
			background: var(--lighten6);
			border-color: var(--lighten6);
		}
	}

	&.app-c-btn--icon-border {
		background: #eaeff7;
		border: $generic-border-color 1px solid;
		color: $text-color-light;

		@include border-radius(3px);

		&:hover {
			outline: none;
			border-color: $app-brand-primary;
			background: $app-brand-primary;
			color: $app-brand-primary-text;
		}
		&:focus,
		&:active {
			border-color: $app-brand-primary-dark;
			color: $white;
			background: $app-brand-primary-dark;
		}
	}

	&.overflow-visible {
		overflow: visible;
	}
}

.app-c-btn--icon-only {
	width: auto;
	height: auto;
	background: transparent;
	color: #61636f;
	padding: 0;
	font-size: 22px;
	outline: none;
	border: none;

	@include inline-flex();
	@include align-items(center);
	@include justify-content(center);

	&:hover {
		outline: none;
		background: transparent;
		color: $app-brand-primary;
	}
	&:focus,
	&:active {
		outline: none;
		background: transparent;
	}
}
.app-c-vertical-sorting,
.app-l-sort-button-holder {
	.app-c-btn--icon-only {
		padding: 0 !important;
		height: 14px !important;
		i {
			font-size: 8px;
		}
	}
	.btn-primary {
		&.disabled,
		&[disabled] {
			color: $text-disabled;
		}
		&:focus {
			color: $text-light;
		}
	}
}
.app-c-btn--icon-24px {
	width: 24px;
	height: 24px;
	font-size: 13px;
}
.app-c-btn--icon-20px {
	width: 20px;
	height: 20px;
	font-size: 12px;
}
.app-c-btn--icon-16px {
	width: 16px;
	height: 16px;
	font-size: 10px;
}

.app-c-btn--icon--small {
	background: none;
	border: none;
	outline: none;
	padding: 3px;
	font-size: 12px;
	color: $text-color;

	&:hover,
	&:focus {
		color: $app-brand-primary;
	}
}

//Input group
//----------------------------------------------------

.input-group {
	> .input-group-append {
		.app-c-btn {
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
		}
	}
}

.app-c-btn__round {
	width: 17px;
	height: 17px;
	background: $app-brand-primary;
	padding: 0;
	outline: none;
	border: none;
	color: $app-brand-primary-text;
	font-size: 7px;

	@include border-radius(50%);
	@include flexbox();
	@include align-items(center);
	@include justify-content(center);
}

.app-c-btn--curved {
	border-radius: 20px;
}

.btn.btn-primary {
	&.app-l-new-lesson-icon {
		position: fixed;
		bottom: 36px;
		background: $app-brand-primary;
		@include boxShadow(0px 2px 4px rgba(0, 0, 0, 0.25));
		width: 68px;
		height: 68px;
		right: 16px;
		z-index: 2;
		@include border-radius(50%);
		color: $app-brand-primary-text;
		@include flexbox();
		@include justify-content($value: center);
		@include align-items($value: center);
		border: 0;
		outline: none;
		font-size: $xl;
		&:focus,
		&:active {
			background: $app-brand-primary;
		}
		.rtl &,
		[dir='rtl'] & {
			right: auto;
			left: 16px;
		}
		@media screen and (max-width: 767px) {
			width: 40px;
			height: 40px;
			font-size: 14px;
		}
	}
}

.app-c-addition-wrapper {
	display: block;
	&.sequenced {
		padding-left: 30px;
	}
	&.preview {
		.app-c-addition-bar {
			&.type1,
			&.type2,
			&.type3 {
				border: none;
			}
		}
	}
	&.no-shadow {
		.app-c-accordion-main {
			.card {
				@include noShadow();
				overflow: visible !important;
				.app-c-addition-bar__slno {
					color: $text-color;
				}
			}
		}
	}
	.app-c-addition-bar {
		margin-bottom: 8px;
		@include flexbox();
		@include flex-direction(row);
		@include justify-content(space-between);
		@include align-items(center);
		border: 1px solid rgba(174, 181, 209, 0.8);
		@include border-radius(3px);
		padding: 8px 16px 8px 20px;
		background: rgba(244, 245, 249, 0.5);
		@include transition(all ease 0.2s);
		position: relative;
		min-height: 48px;
		@media screen and (max-width: 768px) {
			flex-wrap: wrap;
			padding-left: 40px;
		}
		&.expand {
			&:before {
				position: absolute;
				left: 13px;
				@include border-radius(3px);
				content: '\e90c';
				font-family: 'qualify-tms', sans-serif;
				display: block;
				color: rgba(97, 99, 111, 1);
				padding: 0 5px;
				@media screen and (max-width: 767px) {
					left: 13px;
					.rtl &,
					[dir='rtl'] & {
						left: auto;
						right: 13px;
					}
				}
				.rtl &,
				[dir='rtl'] & {
					left: auto;
					right: 13px;
				}
			}
		}
		&.active {
			&:before {
				content: '\e94a';
			}
		}
		&.type1 {
			background: rgba(245, 109, 55, 0.04);
			border: 1px solid rgba(245, 108, 54, 0.3);
		}
		&.type2 {
			background: rgba(227, 236, 255, 0.4);
			border: 1px solid rgba(161, 191, 254, 1);
		}
		&.type3 {
			background: rgba(222, 248, 240, 0.5);
			border: 1px solid rgba(147, 229, 201, 1);
		}
		.app-c-btn {
			&.app-c-btn--icon-only {
				flex-shrink: 0;
			}
		}
		.app-c-addition-bar__slno {
			position: absolute;
			left: -30px;
			top: 0;
			bottom: 0;
			margin: auto;
			height: 22px;
			width: 18px;
			font-size: 14px;
			line-height: 22px;
		}
		.app-c-addition-bar__btn {
			background: none;
			padding: 0;
			border: none;

			@include flexbox();
			@include flex-direction(column);
			@include justify-content(center);
			@include align-items(flex-start);
			position: relative;
			padding-left: 38px;
			background: none;
			@media screen and (max-width: 768px) {
				padding-left: 0px;
			}

			.rtl &,
			[dir='rtl'] & {
				padding-left: 0;
				padding-right: 38px;
			}
			&:focus {
				outline: none;
				@include noShadow();
			}
			&:hover {
				color: $app-brand-primary;
				span {
					color: $app-brand-primary;
				}
			}
			&:focus,
			&.active,
			&:active {
				background-color: transparent !important;
				@include boxShadow(none!important);
			}
			span {
				@include inline-flex();
				@include flex-direction(row);
				@include justify-content(center);
				@include align-items(center);
				width: 22px;
				height: 22px;
				line-height: normal;
				background: none;
				color: rgba(97, 99, 111, 1);
				font-weight: $font-weight-normal;
				@include border-radius(3px);
				font-size: 14px;
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				margin: auto;
			}
			.app-c-addition-bar__btn-label {
				font-size: 14px;
				color: $text-color;
				font-weight: $font-weight-semibold;
				display: block;
				line-height: normal;
				text-align: left;
				word-break: break-word;
				@media screen and (max-width: 575px) {
					padding-bottom: 5px;
				}
			}

			.app-c-addition-bar__category {
				font-size: 12px;
				color: rgba(97, 99, 111, 1);
				display: inline-block;
				line-height: 15.6px;
				margin-top: 6px;
			}
		}
		.app-l-action-btn {
			@media screen and (max-width: 575px) {
				padding-top: 5px;
				padding-left: 24px;
				flex-grow: 1;
				flex-basis: 100%;
			}
		}
	}
}

.more-option-dots {
	@include flexbox();
	@include flex-direction(column);
	@include justify-content(center);
	@include align-items(flex-start);
	span {
		width: 4px;
		height: 4px;
		@include border-radius(50%);
		background: rgba(97, 99, 111, 1);
		display: block;
		margin: 1.5px;
	}
}

.app-c-status-tag {
	line-height: 14.22px;
	font-weight: $font-weight-semibold;
	font-size: $text-md;
	padding: 3px 6px;
	@include border-radius(4px);
	&.completed {
		background: rgba(232, 250, 240, 1);
		border: 1px solid rgba(25, 187, 68, 0.36);
		color: rgba(32, 107, 66, 1);
	}
	&.in-progress {
		background: rgba(250, 232, 232, 1);
		border: 1px solid rgba(142, 44, 52, 0.36);
		color: rgba(142, 44, 52, 1);
	}
}

.app-c-back-btn {
	all: unset;
	font-size: $text-xl;
	color: $text-color;
	font-weight: $font-weight-semibold;
	// display: inline-block;
	line-height: 17px;
	@include flexbox;
	@include align-items(center);
	&__arrow {
		font-size: 14px;
		color: rgba(97, 99, 111, 1);
		margin-right: 13px;
	}

	&:hover {
		color: $app-brand-primary;
		.app-c-back-btn__arrow {
			color: $app-brand-primary;
		}
	}
}

.app-c-attach-btn {
	position: relative;
	font-size: 18px;
	margin-bottom: 0;
	cursor: pointer;
	&.dead {
		opacity: 0.3;
		pointer-events: none;
		cursor: not-allowed;
		.app-c-attach-btn__input {
			pointer-events: none;
		}
	}

	i {
		font-size: 18px;
	}
	.app-c-attach-btn__input {
		width: 100%;
		height: 100%;
		background: #ccc;
		position: absolute;
		display: none;
		opacity: 0;
	}
}
.app-c-tab {
	position: relative;
}
.app-c-link-to-page {
	border: none;
	color: $app-brand-primary;
	background: none;
	font-weight: $font-weight-semibold;
	@include inline-flex();
	@include flex-direction(row);
	@include align-items(center);
	@include justify-content(center);
	@include border-radius(4px);
	&:hover {
		color: $text-color;
		i {
			background: $text-color;
		}
	}
	&.right-mid {
		position: absolute;
		right: 16px;
		top: 15px;
		i {
			font-size: 9px;
		}
		@media screen and (max-width: 767px) {
			position: relative;
			margin-bottom: 10px;
			margin-left: auto;
			display: block;
			right: inherit;
			top: inherit;
		}
	}
	i {
		@include inline-flex();
		margin-left: 6px;
		font-size: 7px;
		background: $app-brand-primary;
		color: $app-brand-primary-text;
		width: 16px;
		height: 16px;
		@include flex-direction(column);
		@include align-items(center);
		@include justify-content(center);
		@include border-radius(4px);
		position: relative;
		.rtl &,
		[dir='rtl'] & {
			transform: scale(-1);
			margin-right: 12px;
		}
	}
}
.app-c-add-new.btn.btn-primary {
	&.btn-plain-action {
		&:focus,
		&:active,
		&:not(:disabled):not(.disabled):active {
			box-shadow: none;
		}
	}
}

.app-c-tiny-status {
	display: inline-block;
	font-size: $text-sm;
	padding: 3px 7px;
	line-height: 12px;
	@include border-radius(3px);
	font-size: $text-sm !important;
	font-weight: $font-weight-semibold !important;
	&.pending {
		color: $overDueColor;
		background: $overDueBg;
		border: 1px solid $overDueColor;
	}
	&.approved {
		color: $scheduledColor;
		background: $scheduledBg;
		border: 1px solid $scheduledColor;
	}
	&.inactive {
		color: #797979;
		background: $archievedBg;
		border: 1px solid $archievedColor;
	}
}
.app-c-btn-text {
	padding: 8px 0;
	font-size: 14px;
	@media (min-width: 992px) and (max-width: 1200px) {
		padding: 0;
	}
}
.auto-height {
	height: auto !important;
}
.btn.app-c-floating-button {
	position: fixed;
	right: 16px;
	bottom: 56px;
	width: 68px;
	height: 68px;
	background: $app-brand-primary;
	border-color: $faded-blue;
	border-radius: 50px;
	font-size: 30px !important;
	z-index: 1;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	&:hover {
		background: $app-brand-primary-dark;
	}
}

button[disabled] {
	cursor: not-allowed !important;
}
.arrowLink {
	color: $light-blue;
	text-decoration: none;
	display: inline-flex;
	@include inline-flex();
	@include justify-content(flex-start);
	@include align-items(center);
	font-size: $text-xl;
	font-weight: $font-weight-semibold;
	padding: 0;
	border: none;
	background: none;
	transition: all ease 0.2s;
	@media only screen and (max-width: 767px) {
		font-size: 14px;
	}
	i {
		color: $light-blue;
		margin: 0px 4px;
		display: inline-block;
	}
	&:hover {
		color: $app-brand-primary;
		text-decoration: none;
		i {
			color: $app-brand-primary;
		}
	}
	&.black {
		color: $text-color;
		i {
			color: $text-color;
		}
		&:hover {
			color: $app-brand-primary;
			text-decoration: none;
			i {
				color: $app-brand-primary;
			}
		}
	}
}
