.app-l-sidebar {
	position: fixed;
	top: 0;
	left: 0;
	background: $app-brand-primary;
	// border-right: 1px solid #e4e6ed;
	width: 80px;
	height: 100%;
	z-index: 9;

	@include flexbox();
	@include flex-direction(column);
	@include transition(all ease 0.2s);

	.rtl &,
	[dir='rtl'] & {
		left: auto;
		right: 0;
		border-right: 0;
		border-left: 1px solid #e4e6ed;
	}
	@media screen and (max-width: 767px) {
		width: 60px;
	}

	&.pull-menu {
		//width: 280px;
		&:hover {
			width: 280px;
			.app-l-nav__block {
				> ul {
					> li {
						> a {
							width: 100%;
							@include justify-content(flex-start);
							padding-left: 13.5px;
							.rtl &,
							[dir='rtl'] & {
								padding-right: 13.5px;
								padding-left: inherit;
								text-align: right;
							}
							.app-l-menu-text {
								left: 50px;
								transform: translate(0px, 0px);
								.rtl &,
								[dir='rtl'] & {
									right: 50px;
									left: inherit;
									text-align: right;
								}
							}
							&:hover,
							&.active {
								.app-l-menu-text {
									color: $app-brand-primary;
								}
								&::after {
									color: $app-brand-primary;
								}
							}
							.rtl &,
							[dir='rtl'] & {
								padding-right: 13.5px;
								padding-left: inherit;
								text-align: right;
							}
						}
						&.open {
							> a {
								background: $app-brand-primary;
								color: $white;
								em,
								.app-l-menu-text {
									color: $white;
								}
								&::after {
									color: $white;
								}
							}
							.sub-menu {
								height: auto;
								overflow: visible;
								margin-top: -6px;
								position: relative;
								padding-top: 6px;
								padding-bottom: 15px;
								&.level-2 {
									padding-bottom: 0px;
									height: auto;
									overflow: visible;
								}
							}
						}
						&.have-sub {
							> a {
								color: $text-muted;
								position: relative;
								font-size: 13px;

								&::after {
									position: absolute;
									right: 12px;
									top: 18px;
									content: '\e972';
									font-family: 'qualify-tms';
									font-size: 8px;
									color: rgba(184, 183, 187, 1);
									@include transform(rotate(-90deg));
									opacity: 1;
									.rtl &,
									[dir='rtl'] & {
										right: inherit;
										left: 12px;
										@include transform(rotate(90deg));
									}
								}
							}
							&.open {
								> a {
									&::after {
										@include transform(rotate(0deg) !important);
										color: $white;
									}
								}
							}
						}
						> ul {
							padding-right: 12px;
							.rtl &,
							[dir='rtl'] & {
								padding-left: 12px;
								padding-right: inherit;
							}
							li {
								&.have-sub {
									> a {
										&::after {
											position: absolute;
											right: 0px;
											top: 18px;
											content: '\e972';
											font-family: 'qualify-tms';
											font-size: 8px;
											color: $white;
											@include transform(rotate(-90deg));
											opacity: 1;
											.rtl &,
											[dir='rtl'] & {
												right: inherit;
												left: 0px;
												@include transform(rotate(90deg));
											}
										}
									}
								}
								&.open {
									> a {
										&::after {
											@include transform(rotate(0deg));
											.rtl &,
											[dir='rtl'] & {
												@include transform(rotate(0deg));
											}
										}
									}
								}
							}
						}
					}
				}
			}
			.app-l-hamberger {
				min-width: 224px;
				height: 59px;
				margin: 0 0 0 10px;
				background-size: contain;
				@media screen and (max-width: 767px) {
					margin: 0 0 0 5px;
					.rtl &,
					[dir='rtl'] & {
						margin: 0 5px 0 0;
					}
				}
			}
			.app-l-user {
				@include justify-content(flex-start);
				padding-left: 24px;
				@media screen and (max-width: 767px) {
					padding-left: 12px;
				}
				.rtl &,
				[dir='rtl'] & {
					//@include justify-content(flex-end);
					padding-left: inherit;
					padding-right: 24px;
				}
				&::after {
					position: absolute;
					right: 10px;
					top: 0px;
					bottom: 0;
					margin: auto;
					content: '\e972';
					font-family: 'qualify-tms';
					font-size: 8px;
					color: rgba(184, 183, 187, 1);
					@include transform(rotate(-90deg));
					opacity: 1;
					.rtl &,
					[dir='rtl'] & {
						left: 10px;
						right: inherit;
						@include transform(rotate(90deg));
					}
				}

				.app-l-user__avatar {
					margin: 0;
				}
				.app-l-user__texting {
					opacity: 1;
					transform: translate(0px, 0px);
					padding-left: 8px;
					width: inherit;
					overflow: visible;
					height: inherit;
					.rtl &,
					[dir='rtl'] & {
						padding-left: inherit;
						padding-right: 8px;
						text-align: right;
					}
				}
				&:hover {
					background: rgba($white, 0.1);
					.app-l-user__menu {
						opacity: 1;
						pointer-events: auto;
						@include transform(translate(170px, 0px));
						@media screen and (max-width: 767px) {
							@include transform(translate(-10px, -65px));
						}
						.rtl &,
						[dir='rtl'] & {
							@include transform(translate(-170px, 0px));
							@media screen and (max-width: 767px) {
								@include transform(translate(10px, -65px));
							}
						}
					}
				}
			}
		}
	}

	.app-l-menu__action {
		padding: 16px;
		position: relative;

		@include flex-shrink(0);
		@media screen and (max-width: 767px) {
			padding: 16px 8px;
		}

		&:before {
			content: '';
			width: 54px;
			height: 1px;
			// background: rgba($text-dark-60, 0.2);
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
		}

		.app-l-hamberger {
			background: none;
			border: none;
			padding: 0;
			outline: none;
			font-size: 21px;
			color: $white;
			width: 35px;
			height: 31px;
			overflow: hidden;
			margin: auto;
			transition: all ease 0.2s;
			@include flexbox();
			@include align-items(center);
			@include justify-content(center);
			@include transition(color ease 0.2s);
			position: relative;
			background: url(../../images/full-logo-white.svg) no-repeat left top;
			background-size: cover;
			img {
				min-width: 35px;
				position: absolute;
				left: 0;
				top: 0;
			}

			em {
				display: block;
			}

			&:hover {
				color: $app-brand-primary;
			}
		}
	}

	.app-l-nav {
		//height: 100%;
		overflow: hidden;
		overflow-y: auto;
		padding: 14px;
		margin-bottom: auto;
		@media screen and (max-width: 767px) {
			padding: 8px;
		}
	}
	.app-l-nav__bottom {
		.app-l-nav__block {
			position: relative;
			overflow: hidden;
			padding: 14px;
			> ul {
				list-style-type: none;
				padding: 0;
				margin: 0;
				> li {
					list-style-type: none;
				}
			}
		}
	}
	.app-l-nav__block {
		position: relative;
		overflow: hidden;
		+ .app-l-nav__block {
			padding-top: 16px;

			&:before {
				content: '';
				width: 54px;
				height: 1px;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				margin: auto;
			}
		}

		&.app-l-nav__no-border {
			padding-top: 10px;

			&:before {
				display: none;
			}
		}

		h6 {
			margin: 0 0 5px;
			padding: 0;
			text-transform: uppercase;
			font-weight: $font-weight-semibold;
			font-size: 8px;
			text-align: center;
		}
		// Menu First Level
		> ul {
			margin: 0;
			padding: 0;

			> li {
				+ li {
					margin-top: 3px;
				}

				> a {
					position: relative;
					cursor: pointer;
					width: 48px;
					height: 48px;
					border-radius: 8px;

					@include flexbox();
					@include align-items(center);
					@include justify-content(center);
					text-decoration: none;
					@media screen and (max-width: 767px) {
						width: 44px;
						height: 44px;
					}

					em {
						font-size: 21.2px;
						color: $white;
						@include transition(color ease 0.2s);
						&.icon-gear {
							font-size: 24px;
						}
					}
					.app-l-menu-text {
						position: absolute;
						left: 64px;
						color: $main-primary-light;
						transform: translate(10px, 0px);
						transition: all ease 0.2s;
						white-space: nowrap;
						font-size: 13px;
						font-weight: $font-weight-semibold;
					}

					&:before {
						content: '';
						width: 4px;
						height: 0;
						background: $app-brand-primary;
						position: absolute;
						top: 0;
						left: 0;
						bottom: 0;
						margin: auto;

						@include transition(height ease 0.2s);

						.rtl &,
						[dir='rtl'] & {
							left: auto;
							right: 0;
						}
					}

					&:hover,
					&.active {
						background: $white;

						em {
							color: $app-brand-primary;
						}
					}
					&.active {
						.app-l-menu-text {
							font-weight: $font-weight-bold;
						}
					}
				}
				// Menu second Level
				> ul {
					background: $app-brand-primary;

					@include border-radius(0px 0px 8px 8px);

					> li {
						a {
							position: relative;
							color: $white;
							line-height: 15.41px;
							padding: 8.5px 0px 8.5px 50px !important;
							display: block;
							height: auto;
							width: auto;
							font-size: 13px;
							text-decoration: none;
							.rtl &,
							[dir='rtl'] & {
								padding: 8.5px 50px 8.5px 0px !important;
								text-align: right;
							}
							&::before {
								position: absolute;
								margin: auto;
								left: 35px;
								top: 0;
								bottom: 0;
								background: $white;
								@include border-radius(50%);
								width: 6px;
								height: 6px;
								border: 1px solid $white;
								content: '';
								.rtl &,
								[dir='rtl'] & {
									left: inherit;
									right: 35px;
								}
							}
							&::after {
								top: 6px !important;
								opacity: 1;
							}
						}
						// Menu Third Level
						> ul {
							margin-left: 36px;
							margin-top: 0;
							padding-bottom: 0;
							display: none;

							.rtl &,
							[dir='rtl'] & {
								margin-left: inherit;
								margin-right: 36px;
							}

							> li {
								a {
									padding: 8px 8px 8px 32px !important;
									display: block;
									line-height: 15.4px;
									@include border-radius(4px);
									position: relative;
									.rtl &,
									[dir='rtl'] & {
										text-align: right;
										padding: 8px 32px 8px 8px !important;
									}

									&::before {
										background: none;
										left: 12px;
										.rtl &,
										[dir='rtl'] & {
											left: inherit;
											right: 12px;
										}
									}
									&.active,
									&:hover {
										background: rgba(32, 47, 147, 1);
									}
								}
							}
						}
						&.open {
							&::after {
								@include transform(rotate(0deg));
							}
							> ul {
								display: block;
							}
						}
					}
				}
			}
			.have-sub {
				> a {
					&::after {
						opacity: 0;
					}
				}
			}
			.sub-menu {
				height: 0;
				overflow: hidden;
			}
		}
	}
	.fag {
		padding: 0 14px;
		@media screen and (max-width: 767px) {
			padding: 0 8px;
			position: absolute;
			bottom: 73px;
			width: 100%;
			z-index: 999;
		}
		li {
			display: block;
		}
		&:focus,
		&:active {
			& + .app-l-nav__bottom {
				pointer-events: none;
			}
		}
	}
	.app-l-nav__bottom {
		// margin-top: auto;
		position: relative;
		padding: 0px 0px;
		z-index: 999;
		// @media screen and (max-width: 1200px) {
		// 	margin-top: inherit;
		// }
		&.user_info {
			overflow: hidden;
			pointer-events: none;
			.app-l-user__menu {
				display: none;
			}
		}
		.app-l-notification__btn {
			position: relative;
			@include flexbox();
			@include justify-content(center);
			@include align-items(center);

			button {
				background: none;
				border: none;
				outline: none;
				padding: 0;
				font-size: 20px;
				color: $white;

				@include transition(color ease 0.2s);

				em {
					display: block;
				}

				&:hover {
					color: $app-brand-primary;
				}
			}

			.app-l-nh__count {
				width: auto;
				height: 18px;
				min-width: 18px;
				// min-height: 18px;
				padding: 0px 3px;
				overflow: hidden;
				//   background: $red;
				color: $white;
				font-size: $text-sm-plus;
				font-weight: 300;
				position: absolute;
				top: -$text-sm-plus;
				right: 16px;

				@include flexbox();
				@include justify-content(center);
				@include align-items(center);
				@include flex-shrink(0);
				@include border-radius(100%);
			}
		}
	}

	.app-l-user {
		position: relative;
		margin-top: 0px;
		margin-bottom: 14px;
		padding-top: 6px;
		padding-bottom: 6px;
		@include flex-shrink(0);
		@include transition(all ease 0.2s);
		@include flexbox();
		@include align-items(center);
		@include justify-content(center);

		&::before {
			content: '';
			width: 4px;
			height: 0;
			//background: $app-brand-primary;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			margin: auto;
			@include transition(height ease 0.2s);
		}

		&:hover,
		&.active {
			cursor: pointer;

			&::before {
				height: 100%;
			}
		}

		.app-l-user__avatar {
			width: 34px;
			height: 34px;
			overflow: hidden;
			border: 1px solid $white;
			margin: 0 auto;
			cursor: pointer;

			@include border-radius(50%);
			@include flexbox();
			@include justify-content(center);
			@include align-items(center);
			object-fit: cover;
			img {
				display: block;
				width: 34px;
				height: 34px;
			}
		}
		.app-l-user__texting {
			opacity: 1;
			transform: translate(0px, 0px);
			padding-left: 0px;
			width: 0;
			overflow: hidden;
			height: 0;

			.app-l-user--small-text {
				display: block;
				line-height: 14.22px;
				font-size: 12px;
				margin-bottom: 3px;
				color: $main-primary-light;
			}
			.app-l-user--normal-text {
				display: block;
				line-height: 15.41px;
				font-size: 13px;
				color: $main-primary-light;
				max-width: 140px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		.app-l-user__menu {
			position: absolute;
			bottom: -5px;
			right: 0;
			width: 200px;
			height: 60vh;
			@include flexbox();
			@include justify-content(flex-end);
			@include transform(translate(185px, 0px));
			@include transition(all ease 0.2s);
			opacity: 0;
			pointer-events: none;
			@media screen and (max-width: 767px) {
				@include transform(translate(0px, -65px));
			}
			.rtl &,
			[dir='rtl'] & {
				left: 0;
				right: inherit;
				@include transform(translate(0, 0px));
			}
			ul {
				background: $app-brand-primary;
				@include border-radius(8px);
				width: calc(100% - 8px);
				margin: 0;
				padding: 16px 0px;
				position: absolute;
				bottom: 0;
				right: 0;
				transform: translate(30px, 0px);
				opacity: 1;
				@include transition(all ease 0.2s);
				&::before {
					position: absolute;
					bottom: 20px;
					left: -15px;
					display: block;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 8px;
					border-color: transparent $app-brand-primary transparent transparent;
					content: '';
					@media screen and (max-width: 767px) {
						left: inherit;
						right: 12px;
						bottom: -15px;
						border-color: $app-brand-primary transparent transparent transparent;
					}
					.rtl &,
					[dir='rtl'] & {
						left: 0;
						right: -15px;
						border-color: transparent transparent transparent $app-brand-primary;
						@media screen and (max-width: 767px) {
							left: inherit;
							right: 8px;
							border-color: $app-brand-primary transparent transparent transparent;
						}
					}
				}
				&.hide-main {
					opacity: 0;
					transform: translate(30px, 0px);
				}
			}
			li {
				display: block;
				a {
					display: block;
					color: $white;
					padding: 10px 24px;
					font-size: 14px;
					line-height: 16px;
					text-decoration: none;
					position: relative;
					@include transition(background ease 0.2s);
					cursor: pointer !important;
					font-weight: $font-weight-normal;
					.rtl &,
					[dir='rtl'] & {
						text-align: right;
					}
					&:hover {
						background: rgba($white, 0.1);
					}
					&.is-parent {
						&::before {
							content: '\e949';
							font-family: 'qualify-tms';
							right: 10.5px;
							top: 11px;
							font-size: 8px;
							color: rgba($white, 0.9);
							position: absolute;
						}
					}
				}
			}
			.inner-pop {
				background: $app-brand-primary;
				position: absolute;
				width: calc(100% - 8px);

				bottom: 0;
				right: 0;
				opacity: 0;
				@include transition(all ease 0.2s);
				@include border-radius(6px);
				font-size: $text-lg;
				pointer-events: none;
				transform: translate(30px, 0px);

				&.active {
					opacity: 1;
					pointer-events: auto;
					transform: translate(30px, 0px);
				}
				&::before {
					position: absolute;
					bottom: 20px;
					left: -15px;
					display: block;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 8px;
					border-color: transparent $app-brand-primary transparent transparent;
					content: '';
					@media screen and (max-width: 767px) {
						left: inherit;
						right: 12px;
						bottom: -15px;
						border-color: $app-brand-primary transparent transparent transparent;
					}
					.rtl &,
					[dir='rtl'] & {
						left: 0;
						right: -15px;
						border-color: transparent transparent transparent $app-brand-primary;
						@media screen and (max-width: 767px) {
							left: inherit;
							right: 8px;
							border-color: $app-brand-primary transparent transparent transparent;
						}
					}
				}
				&__head {
					border-bottom: 1px solid rgba($white, 0.6);
					padding: 9px 10px 4px 7px;
					display: block;
					.go-back-btn {
						color: $white;
						text-decoration: none;
						line-height: 16.4px;
						position: relative;
						padding-left: 15px;
						font-size: $text-lg;
						&:before {
							content: '\e91f';
							font-family: 'qualify-tms';
							position: absolute;
							left: 0;
							top: 6px;
							font-size: 8px;
						}
						&:hover {
							color: rgba($white, 0.8);
						}
					}
				}
				.nav-items {
					padding: 0;
					margin: 0;
					position: relative;
					width: 100%;
					background: transparent;
					padding: 5px 0px;
				}
				label {
					color: $white;
					line-height: 16.4px;
					padding: 10px 22px;
					display: block;
					position: relative;
					margin: 0;
					@include transition(background ease 0.2s);
					cursor: pointer;
					&:hover {
						background: rgba($white, 0.1);
					}
					input {
						position: absolute;
						left: 0;
						top: 0;
						pointer-events: none;
						opacity: 0;
						&:checked + i {
							opacity: 1;
							transform: translate(0px, 0px);
						}
					}
					i {
						right: 18px;
						top: 13px;
						font-size: 10px;
						color: rgba($white, 0.9);
						position: absolute;
						opacity: 0;
						@include transition(all ease 0.2s);
						transform: translate(10px, 0px);
					}
				}
			}
		}
	}
}
