.app-common-radio-btns {
	padding-bottom: 2px;

	label {
		position: relative;
		color: #1e1919;
		font-size: $text-lg;
		padding-left: 30px;
		padding-right: 10px;
		line-height: 24px !important;
		display: block;
		cursor: pointer;
		margin: 0 !important;
	}
	input[type='radio'] {
		appearance: none;
		position: absolute;
		display: none;
	}

	input[type='radio'] + splabelan {
		cursor: pointer;
		position: relative;
		padding-left: 30px;
		border: 1px solid #ccc;
	}

	input[type='radio'] + label::before {
		content: '';
		display: inline-block;
		width: 24px;
		height: 24px;
		@include border-radius(50%);
		background: #fff;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 1;
		transition: all 0.3s;
		border: 1px solid #bec1d2;
	}

	input[type='radio'] + label::after {
		content: '';
		display: inline-block;
		width: 14px;
		height: 14px;
		background: #fff;
		border-radius: 50%;
		position: absolute;
		left: 5px;
		bottom: 5px;
		opacity: 0;
		transition: all 0.3s;
	}
	input[type='radio']:checked + label::before {
		border-color: $app-brand-primary;
		background: $app-brand-primary;
	}
	input[type='radio']:checked + label::after {
		opacity: 1;
	}
}

.app-c-radio-group {
	background: $radio-group-bg;
	@include border-radius(6px);
	@include inline-flex();
	@include flex-direction(row);

	.app-c-radio-btns {
		position: relative;
	}
	.app-c-radio__label {
		padding: 6px 24px;
		@include border-radius(6px);
		margin: 0;
		line-height: 15.6px;
		display: block;
		font-size: $text-md;
		color: $text-muted;
		font-weight: $font-weight-semibold;
		@include transition(all ease 0.2s);
		cursor: pointer;
		pointer-events: none;
	}
	.app-c-radio__input:checked + label {
		background: $app-brand-primary;
		color: $white;
		@include boxShadow(0px 0px 0px 2px $app-brand-primary);
	}
	.app-c-radio__input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		width: 100%;
		height: 100%;
		display: block;
		z-index: 2;
		cursor: pointer;
	}
}
