.app-c-flex-card-wrapper {
	.app-c-flex-card-wrapper__card {
		&.one-third {
			&.app-c-inst-dashboard {
				@media screen and (max-width: 980px) {
					min-width: 100% !important;
					// flex: 1 1 100%;
					margin-left: 0px;
					margin: 3px 0;
				}
			}
		}

		&.app-c-pathway {
			@media screen and (max-width: 980px) {
				min-width: 100%;
				margin-left: 0px;
				width: 100px;
			}
		}

		&.app-c-course-progress {
			max-height: 100%;

			@media screen and (max-width: 767px) {
				height: auto;
			}

			.app-c-pathway__wrapper {
				h5 {
					margin-bottom: 0px !important;
				}

				@include flexbox();
				margin-bottom: 32px;

				.app-c-course-progress {
					margin-left: auto;
					font-weight: $font-weight-xbold;
					color: $text-muted;
					@include flexbox;
					@include align-items(center);

					.app-c-total-text {
						font-size: $text-md;
						font-weight: $font-weight-normal;
					}
				}
			}

			.course-progress-bar-wrapper {
				max-height: 425px;
				min-height: 205px;
				overflow-y: auto;
				overflow-x: hidden;
				margin: 0 -10px;
				padding: 0 10px;

				.app-c-progress-bar {
					padding: 8px 11px;
					margin: 0 -8px 8px;
					border-radius: 8px;
					// cursor: pointer;
					transition: 0.3s background ease-in-out;

					&:hover {
						background: $gray02;
						// .progress {
						// 	background-color: $white;
						// }
					}

					&:last-child {
						margin-bottom: 0px;
					}
				}
			}

			.insDash-progress-bar--wrapper {
				max-height: calc(100vh - 223px);
				overflow-y: auto;
				padding: 0 10px 0 0;
				visibility: hidden;
				margin-right: -10px;

				@media screen and (max-width: 1280px) {
					max-height: calc(100vh);
				}

				&::-webkit-scrollbar-thumb {
					background: $gray03;
				}

				&:hover {
					visibility: visible;
				}
			}

			.app-c-progress-bar {
				visibility: visible;
				padding-bottom: 40px;

				&:last-child {
					margin-bottom: 0px;
				}

				.app-c-progress-bar__file-inpercent {
					text-align: right;
					margin-top: 8px;
				}

				.app-c-progress-bar__file-loaded {
					.app-c-progress-bar__file-name {
						font-weight: $font-weight-semibold;
						max-width: 300px;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
						text-transform: capitalize;
					}
				}

				.app-c-progress-bar-wrapper {
					cursor: pointer;
					margin-bottom: 0;

					.progress {
						height: 12px;
					}
				}
			}
		}

		&.app-c-success-rate {
			text-align: center;
			position: relative;

			h5 {
				text-align: left;
			}

			.recharts-wrapper {
				margin: 0 auto;
				width: 100% !important;

				.recharts-surface {
					width: 100% !important;
				}
			}

			@media screen and (max-width: 980px) {
				margin-left: 0px;
			}

			.app-c-legend {
				@include flexbox();
				@include flex-direction(column);
				font-size: $text-md;

				.app-c-legend-contain {
					@include flexbox();
					@include flex-direction(row);
					margin-bottom: 18px;
					@include align-items(center);

					&:last-child {
						margin-bottom: 0px;
					}

					.app-c-legend-value {
						margin-left: auto;
						color: $text-muted;

						.rtl &,
						[dir='rtl'] & {
							margin-right: auto;
							margin-left: 0;
						}
					}

					.app-c-legend-dot {
						width: 12px;
						height: 12px;
						@include border-radius(50%);
						margin-right: 8px;

						&.blue {
							background-color: $app-brand-primary;
						}

						&.pink {
							background-color: $pink;
						}
					}
				}
			}
		}
	}

	&.app-c-instructor-dasboard {
		@media screen and (max-width: 980px) {
			flex-wrap: wrap;
			flex-direction: column;
		}
	}

	&.app-c-inst-wrap {
		@media screen and (max-width: 980px) {
			flex-wrap: wrap;
		}
	}
}

.app-c-course-details {
	.app-c-contact-hours {
		// max-height: calc(100% - 200px);
		// height: initial;
		@media screen and (max-width: 1280px) {
			flex: 1 1 100%;
			// height: auto;
		}

		@media screen and (max-width: 980px) {
			margin-left: 0px;
			width: 100%;
		}

		.app-c-contact-title {
			@include flexbox();

			@media screen and (max-width: 450px) {
				@include flex-direction(column);

				.app-c-contact-switch {
					margin-right: 0;
					margin-left: auto;
					min-height: 37px;
					margin-top: -10px;
					margin-bottom: 20px;

					.form-group {
						margin-top: 0 !important;
					}

					.rtl &,
					[dir='rtl'] & {
						margin-right: 0;
						margin-left: auto;
					}
				}
			}

			h5 {
				margin-bottom: 23px;
			}

			.app-c-contact-switch {
				margin-left: auto;

				.app-c-toggle-switch {
					width: 180px;

					input {
						opacity: 0;
					}

					.app-c-switch--bg {
						height: 28px;
					}

					.app-c-switch-label {
						line-height: 24px;
					}
				}

				.form-group {
					margin-bottom: 0px;
					margin-top: -5px;
				}
			}
		}

		.app-c-tab {
			.tab-content {
				padding: 0px;
			}

			.app-c-common-tabs__content {
				padding: 0px 10px 0px 0px;
				font-size: $text-md;
				max-height: calc(100vh - 354px);
				overflow: auto;
				visibility: hidden;
				margin-top: 24px;

				@media (max-width: 767px) {
					max-height: 100%;
				}

				&::-webkit-scrollbar-thumb {
					background: $gray03;
				}

				.app-c-common-tabs__content-inner {
					@include flexbox();
					padding-bottom: 15px;
					visibility: visible;

					&:last-child {
						padding-bottom: 0px;
					}

					.app-c-input-set-value {
						margin-left: auto;
						font-size: $text-md;
						font-weight: $font-weight-xbold;
						color: $text-muted;
					}

					.app-c-input-set {
						margin-bottom: 0px;
						max-width: 300px;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
				}

				&:hover {
					visibility: visible;
				}
			}
		}

		.app-c-tab {
			.nav-item + .nav-item a {
				padding-left: 0px !important;
				padding-right: 0px !important;
				margin-left: 40px;
			}

			.nav-link {
				padding-bottom: 8px;
				font-weight: $font-weight-normal;

				&.active {
					font-weight: $font-weight-semibold;
				}
			}
		}
	}
}

.app-c-status-wrapper {
	margin: 0px 0px 0px 0px;

	.app-c-status-tile {
		@media screen and (max-width: 980px) {
			&:last-child {
				margin-right: 0px;
			}

			&:first-child {
				margin-left: 0px;
			}
		}
	}
}

.app-c-subText {
	display: block;
	margin-bottom: 10px;
	overflow: hidden;
	width: 100%;

	.app-c-pathway__wrapper {
		position: relative;
		margin: 0px 0px;
		overflow: hidden;
		width: 100%;

		h5 {
			margin-bottom: 16px !important;
		}
	}
}

.app-c-rate-text {
	margin-right: 10px;
	font-size: $text-xxl;
	font-weight: $font-weight-normal;
	line-height: 24px;
	color: $black;
	position: relative;
	top: 4px;

	&.right {
		margin-right: 0;
		margin-left: 2px;
	}

	&.sm {
		font-size: $text-lg;
		font-weight: $font-weight-semibold;
		position: relative;
		top: 2px;
	}
}

.app-c-rate-img {
	margin-right: 6px;

	.rtl &,
	[dir='rtl'] & {
		margin-right: 0;
		margin-left: 6px;

		img {
			transform: scaleX(-1);
		}
	}
}

.tooltip-course {
	@include flexbox;
	@include flex-direction(column);

	&--row {
		@include flexbox;
		font-size: $text-sm-plus;
	}

	&--title {
		font-weight: $font-weight-semibold;
		margin-right: $text-sm-plus;
	}

	&--val {
		margin-left: auto;

		&__earned {
			font-weight: $font-weight-xbold;
		}

		&__total {
			font-weight: $font-weight-semibold;
		}
	}
}

.tile-ins-dash {
	@media (max-width: 767px) {
		flex-direction: column;

		&.app-c-status-wrapper .app-c-status-tile {
			margin: 5px 0;
		}
	}
}

.ins-dashstar-wrapper {
	@media (max-width: 767px) {
		display: flex;
		align-items: flex-start;

		.app-c-rate-text {
			top: 1px;
		}
	}
}

.skills-table {
	width: 100%;

	thead,
	tbody {
		tr {
			th,
			td {
				&:not(:first-child) {
					width: 61px;
					text-align: center;
				}
			}
		}
	}

	thead {
		position: sticky;
		top: 0;
		z-index: 1;
		background: $white;

		tr {
			th {
				line-height: 1;
				color: $text-muted;
				font-weight: $font-weight-semibold;
				font-size: $text-lg;
				text-transform: uppercase;
				padding-bottom: 26px;
				padding-top: 14px;
			}
		}
	}
}

.course-table {
	width: 100%;

	thead {
		position: sticky;
		top: 0;
		z-index: 1;
		background: $white;

		tr {
			th {
				color: $text-muted;
				font-weight: $font-weight-semibold;
				font-size: $text-lg;
				text-transform: uppercase;
				padding-bottom: 26px;
				padding-top: 14px;
			}
		}
	}

	tbody {
		tr {
			td {
				color: $black01;
				font-weight: $font-weight-semibold;
				font-size: $text-lg;
				padding-bottom: 24px;
				cursor: pointer;

				&:last-child {
					font-weight: $font-weight-xbold;
				}
			}
		}
	}

	thead,
	tbody {
		tr {
			&.-failed {
				td {
					color: $error-toast;
				}
			}

			&.-unattended {
				td {
					color: rgba($color: $error-toast, $alpha: 0.4);
				}
			}

			th,
			td {
				&:last-child {
					width: 100px;
					text-align: right;
				}
			}
		}
	}
}

.lifetime-gpa {
	.total {
		font-weight: $font-weight-semibold;
	}
}

.improvement-score {
	font-size: $text-xl;
	font-weight: $font-weight-light;
	margin: 5px 0;

	.is-score {
		font-weight: $font-weight-xbold;
	}
}

.students-dashboard {
	@media screen and (max-width: $screen-lg) {
		.app-c-flex-card-wrapper {
			&.student-quick-glance {
				@include flex-direction(column);
			}

			.app-c-profile-tag {
				max-width: 100%;
			}

			.app-c-status-wrapper {
				flex-flow: row wrap;
				width: 100%;

				.app-c-status-tile {
					flex: 1 0 48%;

					@media screen and (max-width: 400px) {
						flex: 1 0 100%;
						margin: 5px 0;
					}
				}
			}
		}
	}
}

.app-c-inst_wrap {
	.course-progress-bar-wrapper {
		max-height: calc(100vh - 370px);
		.app-c-progress-bar {
			cursor: default;
			pointer-events: none;
		}
	}
	.app-l-nodata-holder {
		height: calc(100vh - 377px);
	}
}
