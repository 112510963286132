.app-l-create-lesson__section {
	border: none !important;
	.app-c-tab-panel-wrap {
		.nav {
			padding: 14px 15px 0px;
			box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.135216);
			@include border-radius(8px);
			background: #fff;
		}
		@media screen and (max-width: 575px) {
			.px-4,
			.card-body,
			.card-header {
				padding: 8px 8px !important;
			}
		}
		.tab-content {
			margin-top: 7px;
			padding: 24px;
			box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.135216);
			@include border-radius(8px);
			background: none;
		}
	}
	.app-l-create-lesson__tab-section {
		// background-color: #fff;
	}
}

.no-shadow {
	@include noShadow();
}

.bg-transparent {
	background: none !important;
	background-color: transparent !important;
}

.app-c-form-group {
	.app-c-label {
		color: #61636f;
		font-weight: normal;
		font-size: 14px;
		text-align: left;
		margin-bottom: 6px;
		.rtl &,
		[dir='rtl'] & {
			text-align: right;
		}
	}
}

.info-link {
	background: none;
	border: none;
	outline: none;
	box-shadow: none !important;
	background-color: #fff !important;
	border-color: #fff !important;
	//color: var(--theme-primary-color) !important;
	color: rgba(97, 99, 111, 1) !important;
	padding: 3px !important;
	&:focus {
		outline: none;
	}
}

.app-l-link-competencies {
	.app-l-sub-heading {
		color: #1e1919;
		font-weight: 500;
		font-size: 14px;
		margin: 0 0 18px;
	}
}

.app-c-btn-link {
	background: none;
	border: none;
	outline: none;
	box-shadow: none !important;
	background-color: #fff !important;
	border-color: #fff !important;
	color: var(--theme-primary-color) !important;
	padding: 3px !important;
	&:focus {
		outline: none;
	}
	span {
		margin-right: 5px;
	}
}

.editor-padding {
	padding: 30px !important;
	height: 350px !important;
}

.content-disabled {
	pointer-events: none;
	opacity: 0.7;
}

.app-l-editor-holder {
	// border: none;
	overflow: hidden;
	.app-l-cards-buttons {
		z-index: 999; // to developer
		top: 6px !important;
		right: 5px !important;
	}
	.editor-padding {
		padding: 0 !important;
		height: auto !important;
		.tox-tinymce {
			border: none;
		}
	}
}

.app-l-common-tab-content {
	padding: 0 !important;
	background: #fff;
	margin-top: 8px;
}

.app-l-content-head {
	padding: 0px 8px 0px 20px;
	@include flexbox();
	@include justify-content(space-between);
	align-items: center;
	border-bottom: 1px solid rgba(190, 193, 210, 0.4);
	flex-wrap: wrap;

	.rtl &,
	[dir='rtl'] & {
		padding: 0px 20px 0px 8px;
	}
	.app-l-chapter-box {
		width: auto;
	}
	@media screen and (max-width: 767px) {
		padding: 0px 8px 0px 16px;
		flex-wrap: wrap;
		.rtl &,
		[dir='rtl'] & {
			padding: 0px 16px 0px 8px;
		}
	}
	&.set-right {
		@include justify-content(flex-end);
	}
	h5 {
		margin: 0;
		font-size: $text-xl;
		padding: 16px 0px;
		sup {
			color: #ff0000;
			font-size: 15px;
			top: 0px;
		}
	}
}

.app-l-chapter-box {
	padding: 10px 0px;
	width: 100%;
	sup {
		color: rgb(250, 16, 16);
		font-size: 15px;
		margin-left: 6px;
		display: inline-block;
		vertical-align: middle;
	}
}

.app-l-chapter-box__item {
	color: $text-color;
	font-size: $text-xl;
	line-height: 17px;
	display: inline-flex;
	align-items: center;

	font-weight: $font-weight-semibold;
	text-decoration: none;
	width: auto;
	display: inline-flex;
	border: none;
	position: relative;
	&.fullWidth {
		display: flex;
	}
	> span {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		background: rgba(233, 235, 244, 0.5);
		padding: 0.25em;
		@include border-radius(2px);
		cursor: default;
		&.app-l-chapter-box__suffix,
		&.app-l-chapter-box__value {
			background: none !important;
		}
	}
	button {
		background: rgba(233, 235, 244, 0.5);
		padding-right: 10px;
		min-height: 25px;
	}
	&:hover {
		// background: rgba(233, 235, 244, 0.6);
		text-decoration: none;
		color: $text-color;
	}
	&.title-view {
		background: none;
		padding-left: 0;
	}
	i {
		margin-left: 10px;
		font-size: 12px;
		color: rgba(97, 99, 111, 1);
		.rtl &,
		[dir='rtl'] & {
			margin-left: 0;
			margin-right: 10px;
		}
	}
}

.app-l-chapter__text {
	all: unset;
	color: transparent;
	font-size: $text-xl;
	line-height: 17px;
	align-items: center;
	font-weight: $font-weight-semibold;
	display: inline-block;
	min-width: 30px;
	width: auto;
	font: inherit;
	padding: 0.25em;
	margin: 0;
	resize: none;
	background: none;
	border: none;
	caret-color: $text-color;
	position: absolute;
	left: 0;
	&::-webkit-input-placeholder {
		/* Edge */
		color: $text-muted !important;
	}

	&:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: $text-muted !important;
	}

	&::placeholder {
		color: $text-muted !important;
	}
}

.app-l-chapter__error-msg {
	// flex: 1 0;
	// flex-basis: 100%;
	font-size: $text-md;
	color: $dark-red;
	font-weight: $font-weight-semibold;
	display: block;
	margin-right: auto;
}

.app-l-chapter__edit {
	border: none;
	padding: 0;
	margin: 0;
	background: none;
}

.app-l-chapter__actions {
	@include flexbox();
	@include justify-content(space-between);
	align-items: center;
	padding: 6px 10px;
	button {
		margin-left: 8px !important;
		font-size: 18px;
		display: inline-block;
		padding: 2px;
		.rtl &,
		[dir='rtl'] & {
			margin-left: 0 !important;
			margin-right: 8px !important;
		}
	}
	.app-c-btn {
		@include flexbox();
		line-height: 18.2px;
		//font-size: 14px !important;
		padding: 9px 12px;
	}
	.app-c-btn--icon-only {
		@include border-radius(4px);
		font-size: 18px;
		color: rgba(97, 99, 111, 1);
		padding: 10.5px 11.5px;
		width: 36px;
		height: 36px;
		@include flexbox();
		@include justify-content(center);
		@include justify-content(column);
		@include align-items(center);
		&:hover {
			background: #e9ebf4;
		}
		.icon-minus {
			position: relative;
			&::before {
				font-size: 3px;
				position: relative;
				top: -4px;
			}
			&.normal {
				&::before {
					font-size: 18px;
					top: 0;
				}
			}
		}
	}
}

.app-c-vertical-sorting {
	@include flexbox();
	@include flex-direction(column);
	button {
		height: 12px;
		width: 24px;
		text-align: center;
		background: none !important;
		@include border-radius(2px);
		position: relative;
		border: none;
		color: #61636f;
		&:hover {
			color: $app-brand-primary;
		}
		&:focus {
			outline: none;
			@include boxShadow(none);
			background: rgba(244, 245, 249, 1) !important;
		}
		&::before {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			text-align: center;
			line-height: 12px;
			font-size: 9px;
		}
	}
}

.app-l-content-wrapper {
	padding: 24px 20px;
}

.bg-white {
	background: #fff;
}

.app-l-add-link {
	display: inline-block;
	border: none;
	background: none;
	color: $app-brand-primary;
	font-weight: $font-weight-semibold;
	font-size: $text-lg;
	text-decoration: none;
	position: relative;
	padding-left: 22px;
	line-height: 18.2px;
	cursor: pointer;
	&::before {
		position: absolute;
		left: 0;
		top: 0;
		content: '\e90c';
		font-family: 'qualify-tms';
		display: block;
		@include border-radius(4px);
		background: $app-brand-primary;
		color: #fff;
		font-size: 8px;
		text-align: center;
		line-height: 16px;
		width: 16px;
		height: 16px;
	}
	&:hover {
		text-decoration: none;
	}
}

.app-l-link-competencies {
	.app-c-btn-link {
		font-size: $text-lg;
	}
}

.app-l-infolink {
	color: #61636f;
	text-decoration: none;
	position: relative;
	font-size: $text-lg;
	line-height: 20px;
	display: inline-block;
	margin-bottom: 12px;
	sup {
		color: #ff0000;
		font-size: 15px;
		top: 0px;
	}
	&:hover {
		text-decoration: none;
	}
	.icon-info {
		display: inline-block;
		margin-left: 5px;
		vertical-align: middle;
		position: relative;
		top: 0;
		color: $app-brand-primary;
	}
}

.app-l-nodata-holder {
	min-height: 300px;
	@media screen and (max-width: 767px) {
		padding-left: 60px;
	}
	&.auto-height {
		height: calc(100vh - 257px);
		// padding-top: 3%;
	}
	img {
		// max-height: 120px;
		max-height: 70%;
		max-width: 260px;
	}
	h1 {
		text-align: center;
		margin-top: 20px;
	}
}

.app-l-question-tab {
	&.no-shadow {
		&.tab-content {
			@include noShadow();
		}
	}
	&.bg-transparent {
		background: none !important;
		background-color: transparent !important;
	}
	&.tab-content {
		.tab-pane {
			// min-height: calc(100vh - 253px) !important;
			// padding-top: 10px;
			// padding-bottom: 10px;
			.card {
				// box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.135216);
				// margin: 20px;
			}
		}
	}
	&.auto-height {
		.tab-pane {
			min-height: inherit !important;
			// background: #fff;
		}
	}
	&.no-border {
		box-shadow: none !important;
		.card {
			border: none;
			box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.135216);
			&.app-l-editor-holder {
				box-shadow: none !important;
				border: 1px solid rgba(190, 193, 210, 1) !important;
			}
		}
	}
}

.put-shadow {
	@include boxShadow(0px 2px 8px rgba(0, 0, 0, 0.135216) !important);
	border: none !important;
}

.cm-radius {
	@include border-radius(6px);
}

.app-l-screen-id {
	font-size: $text-lg;
	font-weight: $font-weight-semibold;
	line-height: 26px;
	@media screen and (max-width: 767px) {
		padding: 0px 16px;
	}
}

.text-placeholder {
	color: $text-placeholder;
	opacity: 1;
	font-weight: $font-weight-normal;
}

.lesson-cr-wrapper {
	padding: 4px 20px 20px;
	@media (max-width: 767px) {
		padding: 4px 0 20px;
	}
	.p-3 {
		padding: 1rem 0.5rem !important;
	}
}

.app-c-tab-center-align {
	max-width: 1024px;
	margin: 0 auto;
}
