%btn-close {
	position: absolute;
	width: 30px;
	height: 30px;
	background: $newslide-hover;
	border-radius: 50px;
	top: -7px;
	right: -7px;
	font-size: 12px;
	color: inherit !important;
	z-index: 1;
	font-weight: normal;

	&:hover {
		background: $app-brand-primary !important;

		i {
			color: $white !important;
		}
	}

	.rtl &,
	[dir='rtl'] & {
		right: auto;
		left: 8px;
	}
}

@mixin media-mobile-view {
	@media (max-width: $screen-lg) {
		@content;
	}

	.mobile-preview & {
		@content;
	}
}

@mixin media-mobile-view-medium {
	@media (max-width: $screen-sm) {
		@content;
	}
}

@mixin media-mobile-view-small {
	@media (max-width: $screen-xs) {
		@content;
	}
}

.app-l-main-container {
	.app-c-back__btn {
		margin: 30px 0px;
	}

	.app-c-slider-container {
		transform: translateZ(0);
	}

	.app-c-slider-container,
	.app-c-slider-container-inner {
		.app-c-slider-wrapper {
			height: calc(100vh - 80px);
			max-width: 1100px;
			padding-top: 10px;
			margin: 70px auto 0;
			display: block;
			@include flex-direction(column);
			@include flexbox;

			&:not(.no-overflow) {
				overflow: hidden;
				overflow-y: auto;
			}

			@media (max-width: 1400px) {
				height: calc(100vh - 80px);
				max-width: 1100px;
				margin: 52px auto 0;
			}

			.app-l-screen-header {
				border: none;
				box-shadow: none;
				padding: 0;
			}

			.app-c-slider-content {
				height: auto;

				&.dark-bg {
					a {
						background: #fff;
						padding: 0 6px;
						@include border-radius(3px);
					}
				}

				&.qn-set {
					overflow: visible;
					max-height: fit-content;
				}

				.app-l-upload-editor-blk.media-only {
					height: 100%;
					width: 100%;

					@include media-mobile-view() {
						height: auto;

						&.media-center {
							height: 100%;
						}
					}

					.app-l-upload-editor-blk__left {
						height: 100%;

						.app-c-upload-media-blk {
							height: 100%;

							.app-c-upload-media-wrap {
								height: 100%;
								display: flex;
								flex-direction: column;
								margin: auto;
								align-items: center;
								justify-content: center;

								.media-wrapper {
									height: auto;

									@include media-mobile-view {
										margin-top: auto;
									}
								}

								.app-c-btn--addon {
									@media (max-width: 640px) {
										padding: 8px 24px;
									}
								}
							}
						}
					}
				}
			}

			@include media-mobile-view {
				overflow: hidden;
				overflow-y: auto;
				padding: 0 20px;

				.app-l-upload-editor-blk {
					&.text-only {
						width: 100%;
						margin: 0;

						.text-preview-mode {
							padding-left: 30px;

							.rtl &,
							[dir='rtl'] & {
								padding-left: 0;
								padding-right: 30px;
							}
						}
					}

					.app-l-upload-editor-blk__left,
					.app-l-upload-editor-blk__right {
						width: 100%;
						margin: 0;

						.text-preview-mode {
							margin-top: 20px;
						}

						.app-c-upload-media-wrap {
							height: auto;

							&.file-type {
								height: auto;
							}
						}

						.app-c-upload-items_blk.file-type em {
							font-size: 50px;
						}
					}
				}
			}

			// .mobile-preview & {
			// 	@media (min-width: $screen-lg) {
			// 		height: calc(100vh - 270px);
			// 	}
			// }
			@media (max-width: $screen-lg) {
				height: calc(100vh - 160px);
			}
		}

		.app-c-qn-attachment-file {
			padding-bottom: 20px;
			border-bottom: 1px solid #d1d1d1;
			margin-bottom: 20px;

			img {
				max-width: 100%;
				border-radius: 10px;
			}
		}

		.app-c-title {
			margin-bottom: 30px;
			font-size: 25px;
			line-height: 1.5;

			@include media-mobile-view {
				font-size: 18px !important;
				padding-right: 10px;
				padding-top: 5px;
			}
		}

		.app-l-add-caption {
			font-weight: $font-weight-light;
		}
	}

	.slide-control-group {
		z-index: 2;
		position: absolute;
		bottom: -34px;
		right: 80px;
		@include flexbox;
		@include align-items(center);

		&.single-slide {
			right: 80px;

			@media (max-width: $screen-lg) {
				right: 0;
			}

			.preview-plain-btn {
				right: 60px;

				@media (max-width: 640px) {
					right: 20px;
				}
			}
		}

		.rtl &,
		[dir='rtl'] & {
			right: auto;
			left: 60px;
		}

		@include media-mobile-view {
			background: $white;
			box-shadow: 0px -9px 21px rgba(0, 0, 0, 0.131638);
			width: 100%;
			right: 0;
			bottom: 0;
			padding: 18px 20px;
			@include justify-content(center);
			@include align-items(center);
			position: fixed;
			@include border-radius(0 0 15px 15px);
		}

		.slide-count {
			color: $dune;
			font-size: $text-xxl;
			margin-right: 20px;

			.rtl &,
			[dir='rtl'] & {
				margin-right: 0;
				margin-left: 20px;
			}

			@include media-mobile-view {
				font-size: $text-xl;
				margin: 0;
			}
		}

		.preview-plain-btn {
			background-color: $app-brand-primary;
			color: $white;
			display: block;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			@include flexbox;
			@include justify-content(center);
			@include align-items(center);
			font-size: 12px;
			text-decoration: none;

			@include media-mobile-view {
				position: absolute;
				top: -20px;
				right: 68px;
				width: 40px;
				height: 40px;

				.rtl &,
				[dir='rtl'] & {
					right: auto;
					left: 50px;
				}
			}

			&:focus {
				text-decoration: none;
			}

			&:hover {
				background-color: $app-brand-primary-dark;
			}
		}
	}

	.slick-prev:before,
	.slick-next:before {
		opacity: 1;
	}

	.vertical-slider-lesson {
		height: calc(100vh - 50px);

		@include media-mobile-view {
			height: 100vh;
		}

		@include media-mobile-view-small {
			height: calc(100vh - 114px);
		}

		> .slick-arrow {
			@include media-mobile-view {
				position: fixed;
			}

			&.slick-prev,
			&.slick-next {
				&::before {
					background-color: $app-brand-primary;
					color: $white;
					display: block;
					width: 40px;
					height: 40px;
					border-radius: 50%;
					@include flexbox;
					@include justify-content(center);
					@include align-items(center);
					font-size: 12px;
				}

				&:focus {
					text-decoration: none;
				}

				&:hover {
					&::before {
						background-color: $app-brand-primary-dark;
					}
				}
			}
		}

		> .slick-next {
			right: 0px;
			bottom: -54px;
			top: auto;
			width: 40px;
			height: 40px;

			@media screen and (max-width: 1200px) {
				display: block;
				//position: fixed;
				bottom: 20px;
				z-index: 5;
				right: 15px;
			}

			@media screen and (max-width: 640px) {
				right: 20px;
			}

			.rtl &,
			[dir='rtl'] & {
				right: auto;
				left: 20px;
			}

			&:before {
				content: '\e972';
				font-family: $font-qtms;
			}
		}

		> .slick-prev {
			top: auto;
			bottom: -4px;
			right: 0px;
			left: auto;
			z-index: 1;
			width: 40px;
			height: 40px;

			@media screen and (max-width: 1200px) {
				display: block;
				//position: fixed;
				z-index: 3;
				bottom: 65px;
				right: 15px;
			}

			@media screen and (max-width: 640px) {
				right: 20px;
			}

			.rtl &,
			[dir='rtl'] & {
				right: auto;
				left: 20px;
			}

			&:before {
				content: '\e923';
				font-family: $font-qtms;
			}
		}
	}

	.horizontal-slider-lesson {
		@include media-mobile-view {
			.slick-list {
				max-width: calc(100% - 113px);
				margin: auto;
			}
		}

		@include media-mobile-view-medium {
			.slick-list {
				max-width: calc(100% - 20px);
				// height: auto!important;
			}
		}

		> .slick-arrow {
			&.slick-prev:before {
				transform: rotate(180deg);
				display: block;
			}

			&.slick-prev:before,
			&.slick-next:before {
				color: var(--slideText, $app-brand-primary);
				content: '\e949';
				font-family: $font-qtms;

				@include media-mobile-view() {
					color: $app-brand-primary;
				}
			}
		}

		> .slick-next,
		> .slick-prev {
			z-index: 2;
			transform: translateY(-25px);
		}

		> .slick-next {
			right: -74px;
			top: 50%;

			.rtl &,
			[dir='rtl'] & {
				right: auto;
				left: -4px;
			}

			@include media-mobile-view {
				bottom: 50%;
				right: 24px;
				background: #f2f3f7;
				width: 40px;
				height: 40px;
				border-radius: 50%;

				//position: fixed;
				&:before {
					font-size: $text-sm-plus;
					position: relative;
					left: -3px;
				}

				.rtl &,
				[dir='rtl'] & {
					right: auto;
					left: 9px;
				}
			}

			@include media-mobile-view {
				right: -4px;
			}

			@include media-mobile-view-medium {
				width: 64px;
				height: 64px;

				&::before {
					width: 64px;
					height: 64px;
					font-size: 14px;
					left: 0;
					right: 0;
					display: flex !important;
					align-items: center;
					justify-content: center;
				}
			}

			s .rtl &,
			[dir='rtl'] & {
				right: auto;
				left: -74px;
			}
		}

		> .slick-prev {
			left: -74px;

			.rtl &,
			[dir='rtl'] & {
				right: -4px;
				left: auto;
			}

			@include media-mobile-view {
				bottom: 50%;
				left: 24px;
				background: #f2f3f7;
				width: 40px;
				height: 40px;
				border-radius: 50%;

				//position: fixed;
				&:before {
					font-size: $text-sm-plus;
					position: relative;
					right: -3px;
				}

				.rtl &,
				[dir='rtl'] & {
					right: 9px;
					left: auto;
				}
			}

			@include media-mobile-view {
				left: -4px;
			}

			@include media-mobile-view-medium {
				width: 36px;
				height: 36px;

				&::before {
					width: 36px;
					height: 36px;
					font-size: 14px;
					left: 0;
					right: 0;
					display: flex !important;
					align-items: center;
					justify-content: center;
				}
			}
		}
	}

	> .slick-slide {
		opacity: 1;
		transition: 0.3s ease;

		&:not(.slick-current.slick-active) {
			opacity: 0;
		}
	}
}

.app-c-upload-media-blk {
	.app-c-slider-container-inner {
		height: auto;

		img {
			width: 100%;
		}
	}
}

.slider-set-questions .app-c-slider-content .slider-student-qns {
	margin-top: 10px;
	display: block;
	margin-bottom: 20px;

	li {
		padding: 5px 10px;
		border-radius: 4px;
		border: 1px solid transparent;

		.slider-s-q-wrapper {
			.slider-sq-answered {
				+ .slider-s-q-indicator {
					&:before {
						font-size: 9px;
						font-family: 'qualify-tms';
						color: $white;
						position: absolute;
					}

					&.-correct {
						border: 0;
						border-radius: 3px;
						background: $default-green !important;

						&:before {
							content: '\e902';
						}
					}

					&.-wrong {
						border: 0;
						border-radius: 3px;
						background: $default-red !important;

						&:before {
							content: '\e916';
						}
					}
				}
			}

			.slider-s-q-indicator {
				&.-correct,
				&.-wrong {
					background-color: $app-brand-primary !important;
				}
			}
		}

		.slider-s-q-option {
			padding-bottom: 0;
		}

		&.-correct {
			background: $white;
			border-color: $default-green;
		}
	}
}

.row-question-fill-up {
	margin-bottom: 10px;
	// .form-control {
	// 	margin-left: 10px;
	// 	width: calc(100% - 10px);
	// }
}

.slider-set-questions {
	padding: 20px 20px 20px;

	@media print {
		background: #f2f3f7;
		border-radius: 4px;
	}

	.app-c-title {
		color: var(--slideText, $dune);
		font-size: 22px;
		margin-bottom: 39px;
		padding-right: 70px;

		.rtl &,
		[dir='rtl'] & {
			padding-left: 70px;
			padding-right: 0;
		}
	}

	.app-c-slider-content {
		ul {
			all: unset;

			li {
				all: unset;
				@include flexbox;

				+ li {
					margin-top: 18px;
				}

				.slider-s-q-wrapper {
					@include flexbox;
					@include align-items(flex-start);
					width: 100%;

					.slider-s-q-option {
						font-size: $text-xl;
						color: var(--slideText, $dune);
						margin-right: 32px;

						@media print {
							--slideText: #000;
						}

						@media only screen and (max-width: 640px) {
							margin-right: 10px;
						}

						&.media {
							margin: 0;
							width: 100%;

							.expand-button {
								top: 10px;
								right: 10px;
							}
						}

						img,
						video {
							max-width: 100%;
							border-radius: 10px;
							margin: 0 0 10px;
						}

						img {
							width: 100%;
							height: auto;
							object-fit: contain;
							margin: auto;
							max-height: 200px;
						}

						.rtl &,
						[dir='rtl'] & {
							margin-right: 0;
							margin-left: 32px;
						}
					}

					.slider-s-q-option--wrapper {
						width: 100%;
						margin-right: 10px;
					}

					.slide-radio-label {
						@include flexbox;
						cursor: pointer;
						margin-bottom: 0;
						position: relative;
						top: -2px;
					}

					.slide-radio,
					.slider-sq-answered {
						opacity: 0;
						position: absolute;
						top: 3px;
						left: 0;
						width: 100%;
						height: 100%;
						display: block;

						+ .slider-s-q-indicator {
							margin-left: auto;
							min-width: 20px;
							width: 20px;
							height: 20px;
							border-radius: 50%;
							border: 1px solid $newslide-1;
							@include flexbox;
							@include align-items(center);
							@include justify-content(center);
							transition: all 0.3s;
							pointer-events: none;
						}

						&:checked {
							+ .slider-s-q-indicator {
								border: 0;
								border-radius: 3px;
								background-color: $app-brand-primary;

								&:before {
									content: '\e902';
									font-size: 9px;
									font-family: 'qualify-tms';
									color: $white;
									position: absolute;
								}

								&.-correct {
									&:before {
										content: '\e902';
									}
								}

								&.-wrong {
									&:before {
										content: '\e916';
									}
								}
							}
						}
					}

					.slider-s-q-indicator {
						margin-left: auto;
						min-width: 20px;
						width: 20px;
						height: 20px;
						border-radius: 50%;
						border: 1px solid;
						border-color: var(--slideText, $dune);
						top: 4px;
						position: relative;
						flex-shrink: 0;

						&.-correct {
							border: 0;
							background-color: $default-green !important;
						}

						&.-wrong {
							border: 0;
							background-color: $default-red !important;
						}
					}
				}
			}
		}
	}
}

.slider-question-editor {
	border-radius: 10px;
	transition: 0.3s all;
	position: relative;
	@include flexbox;
	@include align-items(center);

	@include media-mobile-view {
		margin-bottom: 20px;
	}

	> .app-c-btn--icon-only {
		z-index: 1;
		margin-left: auto;
		font-size: 20px;
		font-weight: $font-weight-semibold;
		padding: 5px 5px;
		cursor: pointer;
		border-radius: 50px;
		transition: 0.3s ease-in-out;
		position: absolute;
		right: 68px;
		top: 4px;
		width: 30px;
		height: 30px;
		color: var(--slideText);

		@include media-mobile-view {
			right: 0;
		}

		.rtl &,
		[dir='rtl'] & {
			margin-left: 0;
			margin-right: auto;
			left: 18px;
			right: auto;
		}

		&:hover {
			background-color: $newslide-hover;
			color: $app-brand-primary;
		}
	}
}

.text-preview-mode {
	margin-left: 32px;
	@include flexbox;
	@include align-items(center);

	.rtl &,
	[dir='rtl'] & {
		margin-right: 32px;
		margin-left: 0;
	}
}

.ce-paragraph,
.ce-block {
	white-space: normal;
}

.slide-count {
	font-size: 17px;
	font-weight: 500;
}

.-questionSlider {
	.app-c-title {
		padding: 20px 20px 0;
		margin-bottom: 10px !important;
	}

	.slider-s-q-option.media {
		img {
			width: 98%;
		}
	}

	.slick-list {
		background: $aquaHaze;
		color: black !important;
		border-radius: 10px;
		height: 100% !important;

		// .slick-track,
		// .slick-slide {
		// 	max-width: 100% !important;
		// }
		div,
		span,
		h3,
		li {
			color: black !important;
		}

		.app-c-slider-content {
			padding: 10px 10px 0;
		}

		h3 {
			font-size: 16px;
			padding-right: 10px;
			padding-left: 10px;
		}
	}

	.submit-btn {
		width: 78%;
		margin: auto;

		@include media-mobile-view {
			width: 87%;
			margin: auto;
		}
	}

	.app-c-btn:not(.submit-btn) {
		@extend %btn-close;
	}

	.slick-arrow {
		width: 30px !important;
		height: 30px !important;
		background: #e9e7e7 !important;
		border-radius: 50px !important;
		display: flex !important;
		align-items: center !important;
		justify-content: center !important;

		&::before {
			color: #4f3f43 !important;
			font-size: 13px;
			left: 0 !important;
			margin-top: -2px;
		}

		@media (min-width: $screen-lg) {
			&:hover {
				background: $app-brand-primary;

				&::before {
					color: $white;
				}
			}
		}

		&.slick-prev {
			&::before {
				margin-left: -2px;
			}
		}

		&.slick-disabled {
			pointer-events: none;
		}
	}

	.slider-s-q-indicator {
		border-color: $app-brand-primary !important;

		&.-correct {
			background: $app-brand-primary !important;
		}
	}

	.slider-s-q-option {
		padding-bottom: 5px;
	}

	.app-c-qn-attachment-file {
		padding: 20px;

		img {
			width: 100%;
			max-height: 300px;
			object-fit: contain;
			margin: auto;
		}
	}

	.-questionSlider {
		padding-right: 0;
		overflow: auto;
		width: 100%;
		max-width: 100%;

		.app-l-upload-editor-blk__left {
			overflow: auto;

			.app-c-upload-media-blk {
				max-width: 900px;
			}
		}
	}
}

.page-content-preview {
	.app-c-slider-wrapper {
		@media (max-width: 1200px) {
			height: calc(100vh - 110px) !important;

			padding: 0 20px !important;

			.app-l-upload-editor-blk.media-text {
				.file-type {
					//height: 400px !important;
				}

				.app-l-upload-editor-blk__left {
					//height: 400px;
					margin-bottom: 20px !important;

					.app-c-upload-items_blk {
						max-width: 100%;
					}

					video {
						height: 225px;
					}
				}
			}

			.app-l-screen-header {
				background: transparent !important;
				min-height: auto;
			}

			.icon-document-new {
				font-size: 45px !important;
			}

			// .file-name {
			// 	padding: 10px 0 !important;
			// }

			.app-c-slider-content-holder {
				// height: 100% !important;
			}
		}

		@media (max-width: 640px) {
			height: calc(100vh - 110px) !important;

			padding: 0 20px !important;

			.app-l-upload-editor-blk.media-text {
				.file-type {
					//height: 200px !important;
				}

				.app-l-upload-editor-blk__left {
					//height: 200px;
					margin-bottom: 0px !important;

					.app-c-upload-items_blk {
						max-width: 100%;

						.app-c-btn--addon {
							padding: 8px 24px;
						}
					}

					video {
						height: 225px;
					}
				}
			}

			.app-l-screen-header {
				background: transparent !important;
				min-height: auto;
			}

			.icon-document-new {
				font-size: 45px !important;
			}

			.file-name {
				padding: 10px 0 !important;
			}

			.app-c-slider-content-holder {
				// height: 100% !important;
			}
		}
	}

	.slick-track .slick-slide {
		@media (max-width: 768px) {
			height: auto !important;
		}
	}

	.document-viewer-wrapper.modal-dialog-centered-type-1 .modal-dialog .modal-header {
		font-size: 20px;
	}
}

.mobile-preview {
	@include media-mobile-view {
		padding: 0 20px !important;

		.app-c-video-only {
			padding: 0 !important;
		}

		.app-l-upload-editor-blk.media-text {
			.file-type {
				height: auto !important;
			}

			.app-l-upload-editor-blk__left {
				max-height: 250px !important;
				margin-bottom: 20px !important;

				.app-c-upload-items_blk {
					max-width: 100%;
					gap: 5px;
				}

				video {
					height: 200px !important;
				}
			}
		}

		.file-name {
			padding: 0 !important;
		}
	}

	.page-content-preview
		.app-c-slider-wrapper
		.app-l-upload-editor-blk.media-text
		.app-l-upload-editor-blk__left {
		max-height: max-content !important;
	}

	.app-l-main-container .app-lesson-mobile-view {
		.app-c-slider-container .app-c-slider-wrapper {
			height: calc(100vh - 140px) !important;
			padding: 0 20px !important;
			transform: translateZ(0px);
		}
	}

	.slide-index {
		&.active {
			opacity: 1;
			visibility: visible;
			transform: translateY(0);
		}
	}

	// .slick-list {
	// 	height: 100% !important;
	// }
	.slick-slider,
	.app-c-slider-content-inner-holder,
	.slick-track,
	.slick-slide {
		// height: 100% !important;
		&.vertical-slider-lesson {
			// height: calc(100vh - 150px) !important;
			height: 640px !important;
		}
	}

	.app-lesson-mobile-view {
		.slick-list {
			.slick-slide {
				> div {
					height: 100%;

					> .app-c-slider-container {
						height: 100%;
					}
				}
			}
		}
	}

	.app-c-slider-content-holder {
		height: calc(100vh - 190px) !important;
	}

	// .app-l-main-container .app-c-slider-container .app-c-slider-wrapper {
	// 	height: 100%;
	// }
	.app-c-slider-content-holder .app-c-upload-media-wrap em {
		font-size: 45px !important;
	}

	.file-name {
		padding: 10px 10px !important;
	}

	.app-c-upload-media-wrap {
		.app-c-btn--addon {
			padding: 8px 24px;
		}
	}

	.app-l-view-lesson--preview .app-c-title {
		padding-left: 0 !important;
	}
}

.document-viewer-wrapper.modal-dialog-centered-type-1 .modal-dialog .modal-header {
	font-size: 22px;
}

.backdrop {
	@include media-mobile-view() {
		position: fixed;
		background: transparent;
		width: 100%;
		height: 100%;
		top: 0;
		z-index: 0;
		transition: 0.3s all;
	}
}

.icon-fill {
	fill: currentColor;
}

.desc-text {
	word-break: break-word;
	font-size: $text-xl;
	color: var(--slideText);

	a {
		text-decoration: underline;
		color: $strict-link;
	}
}

.app-l-view-lesson--preview {
	.vertical-slider-lesson {
		> .slick-list {
			max-height: 100% !important;
			height: 100% !important;
		}
	}
}

.form-label {
	word-break: normal;
}

.action-group-test-paper {
	@include flexbox;
	position: relative;
	top: -20px;
	right: 70px;
	min-width: 100px;
	justify-content: flex-end;

	@media (max-width: 1200px) {
		top: -33px;
	}

	.app-c-btn--addon {
		padding: 9px;
		min-width: 105px;

		+ .app-c-btn--addon {
			margin: 0;
			margin-left: 10px;
		}
	}
}

.app-c-card--row {
	@include flexbox;

	@media (max-width: 992px) {
		flex-direction: column;
	}

	+ .app-c-card--row {
		margin-top: 15px;
	}

	.app-c-card--default {
		width: 100%;

		&.evaluated-card-pane {
			@media (min-width: 992px) {
				max-width: 280px;
			}

			@media (min-width: 1300px) {
				max-width: 30%;
			}
		}

		+ .app-c-card--default {
			margin-left: 15px;

			@media (max-width: 992px) {
				margin-top: 15px;
				margin-left: 0;
			}
		}
	}
}

.app-delimiter-response {
	margin: 20px auto;
	background-color: #e9ebf4;
	width: 91%;
	display: block;
	@include border-radius(10px);
	height: 4px;
}

.correct-response {
	padding: 0 10px;
	@include flexbox;
	@include align-items(flex-start);
	margin-bottom: 10px;
	font-size: 16px;

	@media (min-width: 767px) {
		padding: 0 20px;
	}

	.slider-s-q-indicator-correct {
		margin-left: auto;

		&:before {
			font-size: 9px;
			font-family: 'qualify-tms';
			color: $white;
			content: '\e902';
			border: 0;
			border-radius: 3px;
			background: $default-green !important;
			width: 20px;
			position: relative;
			top: 2px;
			height: 20px;
			@include flexbox;
			@include justify-content(center);
			@include align-items(center);
		}
	}
}

.ncq-title {
	margin-bottom: 30px;
}

.hide-qn-element {
	display: none;
}

.fill-correct {
	border: 1px solid $default-green;
	padding: 10px;
	border-radius: 3px;
}

.qn-set-fill-up {
	padding: 0 10px;

	@media (min-width: 767px) {
		padding: 0 20px;
	}

	.failed-response {
		@include flexbox;
		@include align-items(flex-start);
		margin-bottom: 10px;
		font-size: 16px;
	}

	.response-text {
		font-size: 16px;
	}

	.slider-s-q-indicator-failed {
		margin-left: auto;

		&:before {
			font-size: 9px;
			font-family: 'qualify-tms';
			color: $white;
			content: '\e916';
			border: 0;
			border-radius: 3px;
			background: $default-red !important;
			width: 20px;
			position: relative;
			top: 2px;
			height: 20px;
			@include flexbox;
			@include justify-content(center);
			@include align-items(center);
		}
	}
}

.app-l-view-lesson--preview {
	.app-l-screen-header__left {
		overflow: visible;
	}

	.app-l-screen-header__left-group {
		overflow: visible;

		.app-c-custom-tooltip {
			top: 27px !important;
		}
	}
}
