@import '../core/mixins/border-radius';

.alert {
	font-size: 13px;
	font-weight: 500;

	@include border-radius(3px);

	&.alert-primary {
		background-color: var(--lighten3);
		border-color: $app-brand-primary;
	}
	&.alert-secondary {
		background-color: lighten($app-brand-secondary, 15%);
		border-color: $app-brand-secondary;
	}
	&.alert-success {
		background-color: $app-alert-success;
		border-color: darken($app-alert-success, 55%);
	}
	&.alert-danger {
		background-color: $app-alert-danger;
		border-color: darken($app-alert-danger, 20%);
	}
	&.alert-warning {
		background-color: $app-alert-warning;
		border-color: darken($app-alert-warning, 20%);
	}
	&.alert-info {
		background-color: $app-alert-info;
		border-color: darken($app-alert-info, 20%);
	}
	&.alert-light {
		background-color: $app-alert-light;
		border-color: darken($app-alert-light, 20%);
	}
	&.alert-dark {
		background-color: $app-alert-dark;
		border-color: darken($app-alert-dark, 20%);
	}
}
.app-c-alert-container {
	width: 100%;
	div {
		padding: 10px;
		border-radius: 5px;
		margin-bottom: 10px;
		word-break: break-word;
	}
	.primary {
		background-color: #ebf8ff;
		border: 1px solid #4299e1;
		color: #2b6cb0;
	}
	.secondary {
		background-color: #f7fafc;
		border: 1px solid #cbd5e0;
		color: #222731;
	}

	.info {
		background-color: #e6fdff;
		border: 1px solid #4cd4ce;
		color: #00727c;
	}
	.success {
		background-color: #f0fff4;
		border: 1px solid #68d391;
		color: #2f855a;
	}
	.warning {
		background-color: #fffaf0;
		border: 1px solid #ed8936;
		color: #c05621;
	}
	.danger {
		background-color: #fff5f5;
		border: 1px solid #fc8181;
		color: #c53030;
	}
	.light {
		background-color: #fff;
		border: 1px solid #edf2f7;
		color: #1a202c;
	}
	.dark {
		background-color: #2d3748;
		border: 1px solid #1a202c;
		color: #d3d3d3;
	}
}
.cdx-notifies {
	z-index: 999999 !important;
}
