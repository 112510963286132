.app-c-type-select {
	position: relative;
	ul {
		background: $white;
		@include boxShadow(0px 3px 6px $text-muted);
		list-style: none;
		max-height: 300px;
		overflow-y: scroll;
		width: 100px;
		padding: 0;
		&.fullWidth {
			width: 100%;
		}
		li {
			padding: 0.375rem 0.75rem;
			&:hover,
			.selected {
				background: $base-bg;
			}
		}
	}
	&.error {
		input {
			border-color: $dark-red;
		}
	}
	.errorText {
		font-size: $text-md;
		color: $dark-red;
	}
}
