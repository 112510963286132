body {
	margin: 0px !important;
	padding: 0px;
	font-family: 'Rubik', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
:root {
	--theme-primary-color: hsl(232, 66%, 30%);
	--theme-primary-color-ind: 232, 66%, 30%;
	--theme-primary-color-H: 232;
	--theme-primary-color-S: 66%;
	--theme-primary-color-L: 30%;
	--theme-text-invert-color: hsl(0, 0%, 100%);
	/* variants */

	--theme-primary-color-102: hsla(var(--theme-primary-color-ind), 0.102);
	--theme-primary-color-212: hsla(var(--theme-primary-color-ind), 0.212);
	--theme-primary-color-160: hsla(var(--theme-primary-color-ind), 0.16);
	--theme-primary-color-200: hsla(var(--theme-primary-color-ind), 0.2);
	--theme-primary-color-600: hsla(var(--theme-primary-color-ind), 0.6);

	--theme-primary-dark: hsl(
		var(--theme-primary-color-H),
		calc(var(--theme-primary-color-S) - (var(--theme-primary-color-S) * 0.371)),
		calc(var(--theme-primary-color-L) - (var(--theme-primary-color-L) * 0.31))
	);

	--theme-primary-light: hsl(
		var(--theme-primary-color-H),
		calc(var(--theme-primary-color-S) - (var(--theme-primary-color-S) * 0.5148)),
		calc(var(--theme-primary-color-L) + (var(--theme-primary-color-L) * 0.2275))
	);

	--theme-primary-disable: hsl(
		var(--theme-primary-color-H),
		calc(var(--theme-primary-color-S) - (var(--theme-primary-color-S) * 0.5433)),
		calc(var(--theme-primary-color-L) + (var(--theme-primary-color-L) * 0.6824))
	);

	--text-dark-medium: hsl(
		var(--theme-primary-color-H),
		calc(var(--theme-primary-color-S) - (var(--theme-primary-color-S) * 0.7781)),
		calc(var(--theme-primary-color-L) + (var(--theme-primary-color-L) * 0.1725))
	);

	--text-dark-normal: hsl(
		var(--theme-primary-color-H),
		calc(var(--theme-primary-color-S) - (var(--theme-primary-color-S) * 0.7865)),
		calc(var(--theme-primary-color-L) + (var(--theme-primary-color-L) * 0.3647))
	);

	--text-light-normal: hsl(
		var(--theme-primary-color-H),
		calc(var(--theme-primary-color-S) - (var(--theme-primary-color-S) * 0.6141)),
		calc(var(--theme-primary-color-L) + (var(--theme-primary-color-L) * 0.6549))
	);

	--text-light-medium: hsl(
		var(--theme-primary-color-H),
		calc(var(--theme-primary-color-S) - (var(--theme-primary-color-S) * 0.7231)),
		calc(var(--theme-primary-color-L) + (var(--theme-primary-color-L) * 0.7098))
	);

	--lighten1: hsl(
		var(--theme-primary-color-H),
		calc(var(--theme-primary-color-S)),
		calc(var(--theme-primary-color-L) + (var(--theme-primary-color-L) * 0.12))
	);

	--lighten2: hsl(
		var(--theme-primary-color-H),
		calc(var(--theme-primary-color-S) - (var(--theme-primary-color-S) * 0.7781)),
		calc(var(--theme-primary-color-L) + (var(--theme-primary-color-L) * 0.1725))
	);

	--tooltip1: hsl(
		var(--theme-primary-color-H),
		calc(var(--theme-primary-color-S) - (var(--theme-primary-color-S) * 0.611)),
		calc(var(--theme-primary-color-L) - (var(--theme-primary-color-L) * 0.725))
	);

	--sidebarLogoText: hsl(
		var(--theme-primary-color-H),
		calc(var(--theme-primary-color-S) - (var(--theme-primary-color-S) * 0.5433)),
		calc(var(--theme-primary-color-L) - (var(--theme-primary-color-L) * 0.6824))
	);

	--lighten3: hsl(
		var(--theme-primary-color-H),
		calc(var(--theme-primary-color-S)),
		calc(var(--theme-primary-color-L) - (var(--theme-primary-color-L) * 0.18))
	);

	--lighten4: hsl(
		var(--theme-primary-color-H),
		calc(var(--theme-primary-color-S)),
		calc(var(--theme-primary-color-L) - (var(--theme-primary-color-L) * 0.08))
	);

	--lighten5: hsl(
		var(--theme-primary-color-H),
		calc(var(--theme-primary-color-S)),
		calc(var(--theme-primary-color-L) - (var(--theme-primary-color-L) * 0.12))
	);

	--lighten6: hsl(
		var(--theme-primary-color-H),
		calc(var(--theme-primary-color-S)),
		calc(var(--theme-primary-color-L) - (var(--theme-primary-color-L) * 0.16))
	);
}
.app-c-preview-content.inner-scroll-hide {
	page-break-before: always;
	margin: 0;
	padding: 0;
}

.app-c-preview-content.inner-scroll-hide div {
	page-break-before: always;
}
