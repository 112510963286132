%btn-close {
	position: absolute;
	width: 30px;
	height: 30px;
	background: $newslide-hover;
	border-radius: 50px;
	top: -10px;
	right: -10px;
	font-size: 12px;
	color: #61636f !important;
	z-index: 1;
	font-weight: normal;

	&:hover {
		background: #cfcfcf;
	}

	.rtl &,
	[dir='rtl'] & {
		right: auto;
		left: 8px;
	}
}

%btn-close-modal {
	position: absolute;
	width: 24px;
	height: 24px;
	background: $white;
	border-radius: 50px;
	top: 8px;
	right: 8px;
	font-size: 12px;
	color: $text-muted;
	z-index: 1;
	font-weight: normal;
	box-shadow: 0px 0px 21px -8px rgb(136, 136, 136);

	&:hover {
		background: $dove-grey;
	}

	.rtl &,
	[dir='rtl'] & {
		right: auto;
		left: 8px;
	}
}

%slideControl {
	all: unset;
	font-size: $text-xl;
	font-weight: $font-weight-semibold;
	padding: 6px 10px;
	cursor: pointer;
	border-radius: 50px;
	transition: 0.3s ease-in-out;
	position: relative;

	input {
		opacity: 0;
		position: absolute;
	}

	&:hover {
		background-color: $newslide-hover;

		i {
			color: $app-brand-primary;
		}
	}

	&.active {
		i {
			color: $app-brand-primary;
		}
	}
}

//React Accordion

.app-c-accordion-main.lesson-authoring {
	&.set-question {
		[data-rbd-draggable-id] {
			left: auto !important;

			.rtl &,
			[dir='rtl'] & {
				right: auto !important;
			}
		}

		.aq-set-question-text {
			word-break: break-word;
		}

		.app-c-file-uploaded {
			margin: 10px 0;
			height: auto;
		}

		.card {
			.app-c-modal-list-stack__item {
				flex-shrink: 1;
			}

			.card-body {
				padding-bottom: 10px;
			}

			.accordion-custom-header {
				@include align-items(flex-start);

				.slide-control {
					&.-drag {
						margin-top: 11px;
					}
				}

				.accordion-toggle {
					margin-top: 11px;
				}
			}

			.app-l-chapter-box {
				.app-l-chapter-box__item {
					@include align-items(flex-start);
					@include flex-direction(column);

					.aq-set-question-text {
						font-size: $text-xl;
						color: $dune;
						margin: 10px 0 0;
						line-height: 1.5;
						word-break: break-word;
					}
				}
			}

			.aq-set-question-dropdown {
				max-width: 320px;
				margin: 7px 0;
			}

			.aq-set-question-details {
				@include flexbox;
				width: 100%;

				.aq-set-question-type-wrapper {
					width: 100%;

					textarea {
						width: 100%;
						min-height: 50px;
						margin-top: 10px;
					}
				}

				.aq-set-question-number {
					margin-right: 16px;
					font-size: $text-xxl;
					font-weight: $font-weight-semibold;
					line-height: 1;
				}
			}
		}
	}

	.aq-set-question-type {
		font-size: $text-sm-plus;
		color: $spunPearl;
		font-weight: $font-weight-normal;

		&.-correct-label {
			position: absolute;
			top: 0;
			margin-left: 44px;

			+ div {
				padding-top: 7px;
			}
		}
	}

	.card {
		border-bottom: 1px solid $accordionBorder-1;
		background-color: transparent;
		border-radius: 0 !important;
		box-shadow: none;
		margin-top: 0;
		padding-bottom: 10px;
		overflow: visible !important;

		.card-body {
			.lesson-content-body {
				padding-bottom: 20px;
				border-radius: 4px;
				padding: 15px;
				min-height: 130px;

				@media (max-width: 768px) {
					padding-left: 10px;
					padding-right: 10px;
				}

				&.dark-bg {
					a {
						background: #fff;
						padding: 0 6px;
						@include border-radius(3px);
					}
				}
			}

			.codex-editor__redactor {
				padding-bottom: 70px !important;

				@media (max-width: 640px) {
					padding-bottom: 40px !important;
				}
			}

			@media (max-width: 1300px) and (min-width: 1200px) {
				.csEditorjs .ce-toolbar .ce-toolbar__plus {
					left: -34px;

					.rtl &,
					[dir='rtl'] & {
						left: auto;
						right: -34px;
					}
				}
			}

			.ce-toolbar__actions {
				right: 5px;
				top: 14px;
				width: 100%;

				.rtl &,
				[dir='rtl'] & {
					right: auto;
					left: 5px;
				}

				.ce-toolbar__actions-buttons {
					position: absolute;
					right: 0;

					// top: -12px;
					@media (max-width: 640px) {
						top: -7px;
					}
				}

				.ce-toolbar__plus {
					top: -13px;
				}
			}
		}

		+ .card {
			border-bottom: 1px solid $accordionBorder-1;
		}

		.csEditorjs {
			margin: 0;
		}

		.app-l-chapter-box {
			.app-l-chapter-box__item {
				background-color: transparent;
				width: 100%;
				min-height: 40px;

				.text-placeholder {
					min-height: 33px;

					&.noBg {
						background: none;
					}
				}
			}

			input.app-l-chapter__text {
				margin-top: -3px;
			}

			.app-l-chapter__text,
			span {
				width: 100%;
				line-height: 1.5;
				font-size: 20px;
				word-break: break-word;
				font-weight: $font-weight-semibold;
				margin-left: -5px;
				height: 100%;

				.rtl &,
				[dir='rtl'] & {
					margin-right: -5px;
					margin-left: 0;
				}

				@media (max-width: 640px) {
					font-size: 18px;
				}
			}
		}

		&:hover {
			.accordion-custom-header {
				.slide-control {
					opacity: 1;
					visibility: visible;
					pointer-events: all;
				}
			}
		}

		.question-bank-header {
			.question-bank-slideControl {
				position: relative;
				top: 4px;

				.slide-control {
					margin: 8px 3px 0;

					&.-trash {
						@media screen and (max-width: 640px) {
							margin-right: -40px;
						}
					}
				}
			}

			&:hover {
				.slide-control {
					opacity: 1;
					visibility: visible;
					pointer-events: all;
				}
			}
		}
	}

	.accordion-toggle {
		width: 36px;
		min-width: 36px;
		height: 36px;
		@include flexbox;
		@include align-items(center);
		@include justify-content(center);
		margin-top: 0;
		@include border-radius(50px);

		&:hover {
			background-color: $box-gray-hover;
		}

		i {
			color: $app-brand-primary;
		}

		&.active {
			i {
				transform: rotate(180deg);
			}
		}
	}

	.accordion-custom-header {
		@include flexbox;
		@include align-items(center);

		.app-l-chapter-box__item > span {
			background: transparent !important;
		}
	}

	.app-c-modal-list-stack__row {
		width: 100%;
		background-color: transparent;
		box-shadow: none;
		padding-left: 0;
		min-height: 67px;

		.rtl &,
		[dir='rtl'] & {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.app-c-modal-list-stack__item {
		text-align: left;
		width: 100%;
		flex-shrink: 0;

		.rtl &,
		[dir='rtl'] & {
			text-align: right;
		}

		.form-label {
			font-size: 22px;
			margin: 0;
			color: $dune;
			font-weight: $font-weight-semibold;
		}
	}
}

.app-l-author-card {
	//padding: 50px 0 0;
	@media (max-width: 640px) {
		padding: 0;
	}
}

.popover-btn-content-type {
	@include flexbox();
	@include flex-direction(column);
	@include align-items(center);
	@include justify-content(flex-start);
	border: 0;
	box-shadow: none;
	background: transparent;
	transition: 0.3s ease-in-out;

	&.active {
		transform: rotate(45deg);
	}

	&:hover {
		.add-slide-icon {
			box-shadow: 0px 0px 2px 1px $app-brand-primary;
		}
	}

	.add-slide-icon {
		width: 30px;
		height: 30px;
		transition: 0.3s ease-in-out;
		background-color: $app-brand-primary;
		color: $white;
		border-radius: 50%;
		@include flexbox();
		@include align-items(center);
		@include justify-content(center);

		i {
			color: $white;
		}
	}
}

.app-c-add-ctm {
	@include flexbox();
	@include align-items(center);
	margin-bottom: 10px;
	margin-left: 40px;

	@media screen and (max-width: 991px) {
		@include flex-direction(column);
		@include align-items(flex-start);
	}

	@media (max-width: 640px) {
		margin-left: 0;
	}
}

.app-c-context-type-menu__outer-wrap {
	@include inline-flex();
	@include flex-direction(column);
	margin-left: 10px;
	background: $white;
	border-radius: 10px;
	box-shadow: 0px 9px 21px rgba(0, 0, 0, 0.13);
	z-index: 7;

	.rtl &,
	[dir='rtl'] & {
		margin-right: 10px;
		margin-left: 0;
	}

	@media screen and (max-width: 991px) {
		margin-left: 0px;
		margin-top: 10px;
		width: 46%;

		.rtl &,
		[dir='rtl'] & {
			margin-right: 0;
		}
	}

	@media (max-width: $screen-sm) {
		width: 50%;
	}

	.app-c-context-type-menu {
		@include flexbox();
		@include align-items(center);
		@include flex-wrap(wrap);
		padding: 8px;
		border-radius: 4px;
		list-style: none;
		margin: 0px -10px 0px;

		@media screen and (max-width: 991px) {
			@include flex-direction(column);
			@include align-items(flex-start);
			margin: 0;
		}

		.app-c-context-type-menu-item {
			padding: 8px 8px 8px 10px;
			border-radius: 4px;
			@include inline-flex();
			@include align-items(center);
			cursor: pointer;
			margin: 0px 10px;
			transition: all ease 0.3s;

			@media (max-width: $screen-md) {
				width: 100%;
				margin: 0;
			}

			&.active,
			&:hover {
				background: $app-brand-primary;

				i,
				span {
					color: $white;
				}
			}

			i {
				color: #61636f;
				font-size: 16px;
			}

			span {
				font-weight: 500;
				font-size: 16px;
				line-height: normal;
				color: #61636f;
				margin-left: 10px;

				.rtl &,
				[dir='rtl'] & {
					margin-left: 0;
					margin-right: 10px;
				}

				@media (max-width: $screen-sm) {
					font-size: 14px;
				}
			}
		}
	}
}

.app-l-upload-editor-blk {
	align-items: center;
	@include flexbox();
	padding: 10px;
	@media print {
		&.media-text {
			display: inline-block;
			width: 100%;
		}
	}

	@include media-mobile-view {
		@include flex-direction(column);
		@include align-items(flex-start);
	}

	video {
		height: 100%;
		background-color: $black;
		margin: -20px 0;
		border-radius: 10px;
	}

	&.media-only {
		.app-l-upload-editor-blk__left {
			width: 100%;

			@include media-mobile-view {
				width: 90%;
			}

			@media (max-width: 640px) {
				margin: 0;
				width: 100%;
			}

			.app-c-upload-items_blk {
				margin: auto;
				// max-width: 900px;
				max-width: 720px;

				&.file-type {
					+ .app-l-add-caption {
						max-width: 480px;
						margin: 10px auto;
					}
				}
			}

			.app-c-upload-media-blk {
				margin: auto;
				width: 100%;
				padding: 40px 0;
				padding-bottom: 30px;

				@media (max-width: 640px) {
					padding: 0;
				}

				&.app-c-upload-media-image {
					.app-c-btn--icon-only {
						//display: none;
					}

					.app-c-btn-__icon-only {
						position: absolute;
						width: 30px;
						height: 30px;
						top: -10px;
						right: -10px;
						font-size: 12px;
						z-index: 1;
						font-weight: normal;
						color: rgb(97, 99, 111) !important;
						background: rgb(233, 231, 231);
						border-radius: 50px;
						display: block !important;
					}
				}

				.app-c-upload-media-wrap {
					margin: auto;
					max-width: 900px;
					width: 100%;

					@media (max-width: 991px) {
						width: 98%;
					}

					//width: 100%;
					.app-c-slider-container & {
						max-width: 100%;

						@include media-mobile-view {
							max-width: 100%;
						}
					}

					img {
						width: 100%;
						height: 100%;

						@media (max-width: 1366px) {
							&:not(.hotspot-image) {
								max-height: 280px !important;
								object-fit: contain;
								background: whitesmoke;
							}
						}

						@media (max-width: 640px) {
							&:not(.hotspot-image) {
								height: auto !important;
							}
						}
					}

					.app-c-btn--addon {
						margin-bottom: 20px;

						@media (max-width: 640px) {
							margin-bottom: 15px;
							padding: 8px 24px;
						}
					}
				}
			}

			.app-c-upload-items_blk__img__item i {
				font-size: 62px !important;
			}
		}
	}

	&.text-only {
		@media (max-width: 1200px) {
			width: 100%;
			margin: 0 auto 10px;
		}

		.app-l-upload-editor-blk__left {
			//max-width: 900px;
			width: 100%;
			margin: auto;

			.ce-toolbar--opened {
				.ce-toolbar__plus {
					left: 0px !important;
				}

				.ce-toolbar__content {
					padding-left: 37px;
				}
			}

			.ce-block__content {
				border-left: 3px solid var(--slideText);
				padding: 1px 30px 8px 45px;
				margin-left: 40px;

				.cdx-alert-info {
					margin-bottom: 0;
				}

				@media (max-width: $screen-lg) {
					padding: 0 0px 10px 20px;
					margin-left: 40px;
				}

				@media (max-width: $screen-sm) {
					padding: 0 0px 10px 25px;
					margin-left: 15px;
				}

				.rtl &,
				[dir='rtl'] & {
					border-left: 0;
					border-right: 3px solid;
					padding-left: 0;
					padding-right: 80px;
				}
			}
		}

		.text-preview-mode {
			border-left: 3px solid;
			padding-left: 80px;
			padding-bottom: 10px;
			max-width: 100%;

			.rtl &,
			[dir='rtl'] & {
				border-left: 0;
				border-right: 3px solid;
				padding-left: 0;
				padding-right: 80px;
			}
		}
	}

	&.-questionSlider {
		max-width: 1100px;
		margin: auto;
		width: 100%;

		@media print {
			padding: 0 20px;
		}

		.horizontal-slider-lesson {
			width: 100%;
			padding: 0 0 50px;
		}

		@media (min-width: $screen-lg) {
			.horizontal-slider-lesson {
				max-width: calc(100% - 100px);
				margin: auto;
				// padding-bottom: 0;
			}
		}

		.slider-question-editor {
			position: relative;
			right: 75px;
			top: 0px;

			@media (max-width: 1200px) {
				position: relative;
				right: 55px;
				top: 21px;
			}

			@media (max-width: 768px) {
				right: 9px;
			}

			@media (max-width: 640px) {
				margin-top: -20px;
			}
		}
	}

	&.-question-bank-preview {
		.app-l-upload-editor-blk__left {
			max-width: calc(100% - 400px);

			@include media-mobile-view {
				max-width: 100%;
			}
		}

		.app-l-upload-editor-blk__right {
			align-items: center;
			justify-content: center;
			display: flex;
		}

		@include media-mobile-view {
			.app-l-upload-editor-blk__left {
				order: 2;
			}

			.app-l-upload-editor-blk__right {
				order: 1;
				padding-left: 0;
				padding-right: 0;
				width: 90%;
				margin: 0 auto !important;
			}
		}

		.score-card {
			background: $aquaHaze;
			border-radius: 10px;
			width: 100%;
			max-width: 268px;
			max-height: 281px;
			height: 100%;
			@include flexbox;
			@include align-items(center);
			@include flex-direction(column);
			@include justify-content(center);
			padding: 16px;

			@include media-mobile-view {
				max-width: 100%;
				margin: 0;
				margin-bottom: 24px;
			}

			.score-wrapper {
				@include flexbox;
				@include align-items(center);
				@include flex-direction(column);
				@include justify-content(center);

				@include media-mobile-view {
					@include align-items(flex-start);
					@include flex-direction(row);
					width: 100%;
					margin: 0;

					> .score-wrapper {
						@include flex-direction(column);
						margin-left: 30px;

						.rtl &,
						[dir='rtl'] & {
							margin-right: 30px;
							margin-left: 0;
						}
					}
				}
			}

			@include media-mobile-view {
				@include flex-direction(row);
			}

			.brain-icon {
				font-size: 80px;
				color: $spunPearl;

				@include media-mobile-view {
					font-size: 60px;
				}
			}

			.score-point {
				font-size: 20px;
				line-height: 1.5;
				margin-top: 30px;
				color: $app-brand-primary-dark;
				font-weight: $font-weight-semibold;

				@include media-mobile-view {
					margin-top: 0px;
					font-size: 22px;
				}
			}

			.score-slide {
				font-size: $text-lg;
				margin-top: 30px;
				color: $app-brand-primary-dark;

				@include media-mobile-view {
					margin-top: 6px;
					font-size: 16px;
				}
			}
		}

		.choice-selector-btn {
			width: 100%;
			margin: 20px 0;

			&.-correct {
				border-color: #8dd1b9;
				background-color: #8dd1b9;
			}

			&.-wrong {
				border-color: rgba($color: $validation-red, $alpha: 0.6);
				background-color: rgba($color: $validation-red, $alpha: 0.6);
			}
		}

		.app-c-title {
			font-size: 22px;
			line-height: 1.5;

			@include media-mobile-view {
				font-size: $text-xl;
			}
		}

		.slider-s-q-option {
			font-size: $text-xxl;
		}

		.text-preview-mode {
			margin-left: 100px;

			@media only screen and (max-width: 767px) {
				margin-left: 0px;
			}
		}

		.app-c-slider-content {
			overflow: auto;
			max-height: calc(100vh - 350px);
			margin: 0 -2px;
			padding: 0 20px;
			height: 100%;

			@include media-mobile-view {
				max-height: 100%;
			}
		}
	}

	.app-l-upload-editor-blk__right {
		.ce-block {
			// padding-right: 30px;
		}
	}

	.app-l-upload-editor-blk__left,
	.app-l-upload-editor-blk__right {
		width: 50%;

		@media print {
			display: block;
			vertical-align: middle;
			max-width: 100%;
			width: 100%;

			.desc-text {
				text-align: left;
			}
		}

		@include media-mobile-view {
			width: 100%;
			margin: 0 auto 10px;

			.csEditorjs .ce-toolbar .ce-toolbar__plus {
				left: -30px;

				@media (max-width: 640px) {
					left: 0;
				}

				.rtl &,
				[dir='rtl'] & {
					left: auto;
					right: -30px;
				}
			}
		}
	}

	.app-l-upload-editor-blk__left {
		position: relative;
		padding-block: 20px;

		@media (max-width: $screen-lg) {
			padding-block: 0px;
		}

		.app-c-btn--addon {
			@media (max-width: 768px) {
				padding: 8px 20px;
				margin-bottom: 15px;
			}
		}

		.app-c-upload-items_blk {
			width: 100%;
			//height: 240px;
			background: #f2f3f7 !important;
			border: 1px dashed $app-brand-primary-dark;
			border-radius: 10px;
			@include flexbox();
			@include flex-direction(column);
			@include align-items(center);
			@include justify-content(space-evenly);
			max-width: 482px;
			margin: auto 65px auto auto;
			padding: 20px 0px;

			@media print {
				background: transparent !important;

				img,
				video {
					background: transparent !important;
				}
			}

			&.not-empty {
				border: 0;
			}

			@include media-mobile-view {
				margin: 0 auto;
			}

			@media (max-width: 640px) {
				height: auto;
				gap: 5px;
			}

			&.file-type {
				+ .app-l-add-caption {
					max-width: 480px;
					margin: 10px 65px auto auto;

					.rtl &,
					[dir='rtl'] & {
						margin: 10px auto 65px auto;
					}

					@include media-mobile-view {
						margin: 10px auto 26px;
					}

					@media (max-width: 640px) {
						margin: 10px auto 0;
					}
				}

				.file-name {
					color: $newslide-1;
					font-size: $text-xl;
					padding: 20px;
					text-align: center;
					word-break: break-word;
				}

				em {
					font-size: 75px;
					color: $app-brand-primary;
					margin-top: 15px;

					@media (max-width: 640px) {
						font-size: 50px;
						margin-top: 15px;
					}
				}
			}

			.rtl &,
			[dir='rtl'] & {
				margin: auto auto auto 65px;
			}

			.app-c-upload-items_blk__img {
				@include flexbox();
				@include align-items(center);
				@include justify-content(space-around);
				width: 100%;
				padding-bottom: 30px;

				@media screen and (max-width: 991px) {
					@include justify-content(center);
				}

				.app-c-upload-items_blk__img__item {
					@include flexbox();
					@include align-items(center);
					@include justify-content(center);

					@media screen and (max-width: 991px) {
						width: 60px;
						height: 60px;
					}

					i {
						line-height: normal;
						color: $app-brand-primary;

						&.icon-video-camera,
						&.icon-media-solid {
							font-size: 52px;
						}

						&.icon-headphone,
						&.icon-document-new {
							font-size: 46px;
						}

						@media screen and (max-width: 991px) {
							font-size: 32px !important;
						}
					}
				}
			}

			.app-c-upload-items_blk__link {
				.app-c-btn--link {
					padding: 0px;
					font-weight: 700;
					font-size: 16px;
					text-decoration: none;
					color: $text-muted;
					margin-bottom: 15px;
					cursor: pointer;

					&:hover {
						color: $app-brand-primary;
					}

					i {
						margin-right: 12px;

						.rtl &,
						[dir='rtl'] & {
							margin-right: 0;
							margin-left: 12px;
						}
					}
				}

				.app-c-btn {
					position: relative;

					+ .app-c-btn {
						margin-left: 20px;

						.rtl &,
						[dir='rtl'] & {
							margin-right: 20px;
							margin-left: 0;
						}
					}

					input {
						position: absolute;
						top: 0;
						left: 0;
						opacity: 0;
						width: 100%;
						height: 100%;
					}
				}
			}

			.app-c-upload-items_blk__drag-blk {
				@include flexbox();
				@include flex-direction(column);
				@include align-items(center);
				@include justify-content(center);

				@media screen and (max-width: 991px) {
					padding: 0px 30px;
					text-align: center;
				}

				p {
					font-weight: normal;
					font-size: 16px;
					line-height: normal;
					color: #382f2f;
					margin-bottom: 4px;
				}

				span {
					font-weight: normal;
					font-size: 11px;
					line-height: normal;
					color: #382f2f;
				}
			}
		}

		.app-c-upload-media-blk {
			width: 100%;
			@include flexbox();
			@include flex-direction(column);

			@media print {
				background: transparent !important;

				img {
					background: transparent !important;
				}
			}

			@media screen and (max-width: 991px) {
				width: auto;
			}

			.app-c-upload-media-wrap {
				border-radius: 10px;
				position: relative;
				overflow: visible;
				//margin: auto 65px auto auto;
				background: transparent;

				.rtl &,
				[dir='rtl'] & {
					margin: auto auto auto 65px;
				}

				@include media-mobile-view {
					margin: 0 auto;
				}

				iframe {
					width: 100%;
					border-radius: 10px;
				}

				img {
					width: 100%;
					height: auto;
					display: block;
					max-height: 100%;
					border-radius: 4px;
					background: whitesmoke;
					object-fit: contain;
					max-height: calc(100vh - 330px);

					.app-c-slider-container & {
						@media screen and (max-width: 1500px) {
							width: 100%;
						}
					}

					@include media-mobile-view {
						width: 100%;
						margin: 0 auto;
					}
				}

				.app-c-btn--icon-only {
					@extend %btn-close;

					&.expand-button {
						color: $dune;
						background: rgba(255, 255, 255, 0.5);
						width: 30px;
						height: 30px;
						font-size: 17px;
						border-radius: 0;

						&:hover {
							background: rgba(255, 255, 255, 1);
						}
					}
				}
			}
		}
	}

	.app-l-upload-editor-blk__right {
		padding-left: 60px;
		padding-right: 30px;
		padding-top: 10px;

		@media print {
			&.app-l-upload-editor-blk__right.app-l-upload-editor-blk__right.app-l-upload-editor-blk__right {
				width: 100% !important;
				display: block !important;
				float: none !important;
			}
		}

		@media (max-width: 640px) {
			padding-left: 0px;
			padding-right: 0px;
		}

		.text-preview-mode-data {
			width: 100%;
		}
	}
}

.app-l-upload-editor-blk__left {
	.app-c-upload-media-image {
		.app-c-btn-__icon-only {
			display: none;
		}
	}
}

.app-l-add-caption {
	text-align: center;
	margin-top: 8px;
	font-weight: normal;
	font-size: 16px;
	line-height: normal;
	border: none;
	width: 100%;
	background-color: transparent;
	color: $spunPearl;
	resize: none;

	@media (max-width: 767px) {
		font-size: $text-lg;
	}
}

.accordion-slide-action-group {
	@include flexbox();
	@include align-items(center);
	@include justify-content(flex-end);
	position: sticky;
	top: 88px;
	padding: 10px 5px;
	z-index: 8;
	background: $app-card-grey;

	@media (max-width: $screen-md) {
		@include flex-direction(column);
		top: 84px;
		background: white;
		width: 100%;
	}

	// @media (max-width: 778px) {
	// 	top: 108px;
	// }
	@media (max-width: 767px) {
		top: 150px;
	}

	.slide-count {
		@media (max-width: $screen-md) {
			margin-top: 20px;
		}
	}

	.action-group {
		margin-right: 16px;

		.rtl &,
		[dir='rtl'] & {
			margin-right: 0;

			margin-left: 16px;
		}
	}

	.app-c-btn--addon {
		padding: 10px 16px;
	}
}

.slide-control {
	position: relative;
	margin: 10px 10px 0;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transition: 0.3s ease-in-out;

	@media (max-width: $screen-md) {
		opacity: 1;
		visibility: visible;
		pointer-events: all;
	}

	&.-drag {
		margin-left: 2px;

		.slide-controls {
			cursor: grab;
		}

		.rtl &,
		[dir='rtl'] & {
			margin-left: 0;
			margin-right: 2px;
		}
	}

	&.-trash {
		em {
			color: $pink;
		}
	}
}

.slide-controls {
	@extend %slideControl;
}

.app-c-modal-list-stack__row .slide-controls {
	@extend %slideControl;
}

.slide-controls-dp {
	z-index: 8;
	width: 100%;
	max-width: 197px;
	background: $white;
	box-shadow: 0px 9px 21px rgba(0, 0, 0, 0.131638);
	border-radius: 4px;
	transform: translateY(10px);
	@include flexbox();
	@include flex-direction(column);
	border: 0;
	padding-bottom: 9px;

	.slide-controls-main-title {
		padding: 8px;
		font-size: $text-sm-plus;
		color: $black;
		@include flexbox;
		@include justify-content(space-between);
		@include align-items(center);

		.app-c-btn--addon {
			padding: 3px;
			width: 30px;
			height: 30px;
			min-width: 30px;
			border: 0;
		}
	}

	.slide-controls-item {
		all: unset;
		cursor: pointer;
		transition: 0.3s ease-in-out;
		margin: 0;
		padding: 5px 10px;
		@include flexbox();
		@include align-items(center);
		color: $text-muted;

		.icon-paint,
		.icon-duplicate {
			color: $text-muted;
		}

		&.delete {
			.slide-controls-title {
				color: $pink;
			}

			em {
				color: $pink;
			}

			&:hover {
				background-color: $pink;
				color: $white;

				em,
				div {
					color: $white;
				}
			}
		}

		&:hover {
			background-color: $app-brand-primary;
			color: $white;

			em,
			div {
				color: $white;
			}
		}

		i {
			color: $newslide-1;
			font-size: 19px;
		}

		.slide-controls-title {
			color: $dune;
			margin-top: 3px;
			margin-left: 12.5px;
			font-weight: $font-weight-normal;
			font-size: $text-xl;

			.rtl &,
			[dir='rtl'] & {
				margin-left: 0;
				margin-right: 12.5px;
			}
		}

		+ .slide-controls-item {
			margin-top: 3px;
		}
	}
}

.header-dynamic-title.form-group {
	margin-bottom: 0;
	@include flexbox;

	.form-control {
		border: 0;
		padding: 0;
		height: auto;
		font-size: 20px !important;
		width: auto;
		font-weight: $font-weight-semibold;

		&:focus {
			outline: none;
			box-shadow: none;
		}
	}

	.help-block {
		white-space: nowrap;
		position: absolute;
		bottom: -11px;
		font-size: 10px;
		padding: 0 3px;
	}
}

.color-picker-wrapper {
	padding: 0 13px 13px;

	.form-group {
		position: relative;

		&.-custom-color-picker-prefix {
			.color-prefix {
				color: $newslide-1;
				position: absolute;
				left: 7px;
				top: 8px;

				.rtl &,
				[dir='rtl'] & {
					left: auto;
					right: 7px;
				}
			}

			.form-control {
				padding: 0 20px;
			}
		}

		&.-custom-color-picker {
			.color-prefix {
				color: $newslide-1;
				position: absolute;
				left: 7px;

				.rtl &,
				[dir='rtl'] & {
					left: auto;
					right: 7px;
				}
			}

			.form-control {
				color: $newslide-1;
				height: 24px;
				min-height: 24px;
				padding: 0 20px;
			}
		}
	}

	input {
		color: $newslide-1;
	}

	.color-picker-selected {
		min-width: 170px;
		height: 108px;
		position: relative;
		border-radius: 5px 5px 0px 0px;
		margin-bottom: 10px;
		color: $white;
		font-size: 22px;
		@include flexbox;
		@include justify-content(center);
		@include align-items(center);
		background-color: var(--color-selected);
		box-shadow: 0px 0px 21px -8px rgb(107, 106, 106);

		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: 50%;
			border-top: 12px solid var(--color-selected);
			border-right: 12px solid var(--color-selected);
			border-left: 12px solid transparent;
			border-bottom: 12px solid transparent;
			transform: rotate(-45deg) translateX(50%);

			.rtl &,
			[dir='rtl'] & {
				left: 50%;
				right: auto;
			}
		}

		.selected-color-text {
			color: var(--color-selected-text, $dune);
		}
	}

	.recent-colors {
		@include flexbox;
		@include flex-wrap(wrap);
		@include align-items(center);
		@include justify-content(space-between);
		gap: 5px;
		border-radius: 2px;
		margin-bottom: 10px;
	}

	.color-tile {
		position: relative;
		cursor: pointer;

		input {
			width: 30px;
			height: 30px;
			opacity: 0;
			position: absolute;
			cursor: pointer;
			width: 30px;
			height: 30px;

			&:checked {
				~ .color-tile-item {
					outline: 1px solid;
				}
			}

			~ .color-tile-item {
				outline: 3px solid transparent;
				transition: 0.3s ease-in-out;
				width: 30px;
				height: 30px;
				border-radius: 2px;
				border: 1px solid $aquaHaze;
				background-color: var(--avail-colors);
			}
		}
	}
}

.question-bank-creator {
	position: relative;
	margin: 10px auto 0;
	max-width: 80%;

	.question-bank-header {
		@include flexbox;
		@include align-items(baseline);
		padding-top: 10px;

		.question-bank-inputs {
			@include align-items(baseline);
			@include flexbox;
			width: 100%;
		}

		.question-bank-form {
			@include flexbox;
			@include align-items(flex-start);
			@include flex-direction(column);
			width: 100%;

			.form-group {
				width: 100%;
				margin-top: 8px;
				margin-bottom: 0;

				.form-control {
					height: 40px;
					resize: vertical;
					padding-top: 0.5rem;
				}
			}

			.app-c-checkbox {
				@include justify-content(center);
				@include flex-direction(column);
				min-width: 40px;

				.form-label {
					@include justify-content(center);
				}

				.app-c-checkbox-icon {
					margin: 0;
				}
			}
		}
	}

	.question-bank-item {
		position: relative;

		+ .question-bank-item {
			.-correct-label {
				display: none;
			}
		}
	}

	.question-bank-choice {
		margin-left: 94px;
		margin-top: 10px;

		.rtl &,
		[dir='rtl'] & {
			margin-left: 0;
			margin-right: 94px;
		}
	}
}

.lesson-authoring-header {
	&.app-l-base {
		z-index: auto;
	}

	.app-l-screen-header {
		z-index: 8;

		&__left {
			max-width: 600px;
			width: 100%;

			&-group {
				width: 100%;

				> div {
					width: 100%;

					.header-dynamic-title {
						width: 100%;
						@include flex-direction(column);
						@include align-items(flex-start);

						input {
							width: 100%;
						}
					}
				}
			}
		}

		&__right {
			flex-shrink: 0;
			flex-basis: auto;
		}
	}
}

.app-modal-btn-blk {
	@extend %btn-close-modal;
	position: absolute;
	top: -50px;
	right: 50%;
	width: 40px;
	height: 40px;
	margin-right: -20px;

	@media (max-width: $screen-lg) {
		// right: 6px;
		// top: -20px;
		.rtl &,
		[dir='rtl'] & {
			left: 6px;
			right: auto;
		}
	}

	&:hover {
		background: $newslide-hover;

		i {
			color: $app-brand-primary;
		}
	}

	.rtl &,
	[dir='rtl'] & {
		right: auto;
		left: -47px;
	}
}

.document-viewer-wrapper.modal-dialog-centered-type-1 {
	&.doc-type-__1 {
		.modal-dialog {
			width: 100%;
		}
	}

	.modal-dialog {
		max-width: 1000px;
		height: 86%;

		.app-c-pagination {
			.pagination {
				margin-bottom: 0;
			}
		}

		.react-pdf__Page {
			overflow: visible;
		}

		@include media-mobile-view {
			padding: 0;
			margin-top: auto !important;
		}

		.modal-header {
			word-break: break-word;
			font-weight: 600;
		}
	}
}

.label-heading-section {
	@include flexbox;
	@include align-items(center);

	+ .csEditorjs {
		margin-left: 110px;

		.rtl &,
		[dir='rtl'] & {
			margin-left: 0;
			margin-right: 110px;
		}

		@media (max-width: 1200px) {
			margin-left: 60px;

			.rtl &,
			[dir='rtl'] & {
				margin-left: 0;
				margin-right: 60px;
			}
		}
	}

	&-num {
		font-size: 22px;
		font-weight: $font-weight-semibold;
		color: $dune;
		margin-left: 40px;

		.rtl &,
		[dir='rtl'] & {
			margin-left: 0;
			margin-right: 40px;
		}

		@media (max-width: 1200px) {
			margin-left: 20px;

			.rtl &,
			[dir='rtl'] & {
				margin-left: 0;
				margin-right: 20px;
			}
		}
	}

	&-title {
		margin-left: 24px;
		width: 100%;

		.rtl &,
		[dir='rtl'] & {
			margin-left: 0;
			margin-right: 24px;
		}

		.form-control {
			font-size: 22px;
			font-weight: $font-weight-normal;
			color: $dune;
			background: transparent;
		}
	}
}

//preview page
.sideview-group {
	position: fixed;
	top: 44px;
	z-index: 2;
	left: 20px;
	width: 48px;

	&.right-side {
		right: 17px;
		left: auto;

		@media (min-width: 992px) {
			+ .page-content-preview {
				.header-bind {
					padding-right: 230px;
				}
			}
		}
	}

	@media (max-width: 640px) {
		display: none;
	}

	.icon-leftArrow {
		width: 48px;
		height: 48px;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: auto;
		margin-bottom: 10px;
		border-radius: 0.25rem;
		font-size: 18px;
	}

	.preview-plain-btn {
		font-size: 21.2px;
		width: 48px;
		height: 48px;
		@include flexbox;
		@include align-items(center);
		@include justify-content(center);
		margin-bottom: 3px;
		text-decoration: none;
		display: flex;
		align-items: center;
		justify-content: center;
		outline: none;
		box-shadow: none !important;

		.rtl &,
		[dir='rtl'] & {
			margin-left: 10px;
			margin-right: 0;
		}

		&:focus {
			text-decoration: none;
		}

		em {
			color: $text-muted;
		}

		&:hover,
		&.active {
			text-decoration: none;
			background-color: $app-brand-primary;

			em {
				color: $white;
			}
		}
	}
}

.slick-slide {
	&:not(.slick-active.slick-current) {
		opacity: 0;
		pointer-events: none;
	}
}

.score-float {
	position: fixed;
	bottom: 0;
	@include flex-direction(column);
	@include justify-content(center);
	@include align-items(center);
	@include flexbox;
	left: calc(50% - 330px);
	transform: translateY(200%);
	animation: slideup 5s 0.3s ease-in-out forwards;
	z-index: 2;
	opacity: 0;
	pointer-events: none;

	@include media-mobile-view {
		left: calc(50%);
		transform: translate(-50%, 200%);
		animation: slideupMob 5s 0.3s ease-in-out forwards;
	}

	.slick-current & {
		display: flex;
	}

	@keyframes slideup {
		20% {
			transform: translateY(-50%);
			opacity: 1;
		}

		90% {
			transform: translate(0);
			opacity: 1;
		}
	}

	@keyframes slideupMob {
		20% {
			transform: translate(-50%, -50%);
			opacity: 1;
		}

		90% {
			transform: translate(-50%, -50%);
			opacity: 1;
		}
	}

	.sf-stars-minis {
		@include flexbox;
		@include justify-content(space-between);
		color: $gold;

		em {
			transition: 2s all;
			font-size: 37px;
			animation: starSlide 3s 1s ease-in-out forwards;
			opacity: 0;

			&:first-child {
				transform: translate(100%, 60px);
			}

			&:last-child {
				transform: translate(-110%, 60px);
			}

			@keyframes starSlide {
				20%,
				70% {
					opacity: 1;
					transform: translate(0);
				}

				100% {
					&:first-child {
						transform: translate(100%, 60px);
					}

					&:last-child {
						transform: translate(-110%, 60px);
					}
				}
			}
		}
	}

	.sf-stars {
		text-align: center;
		min-width: 120px;
		display: flex;
		flex-direction: column;
	}

	.sf-stars-max {
		color: $gold;
		font-size: 60px;
	}

	.sf-text {
		font-size: 30px;
		line-height: 1.5;
		color: $dune;
		font-weight: $font-weight-bold;
	}

	.sf-mark {
		font-size: 60px;
		line-height: 1.5;
		color: $spunPearl;
		font-weight: $font-weight-bold;
		@include flexbox;
	}
}

.finish-btn {
	margin: 20px auto;
	display: block;

	@include media-mobile-view {
		max-width: 100%;
	}
}

.slide-index {
	background-color: $white;
	border-radius: 2px;
	max-width: 375px;
	position: fixed;
	bottom: 9%;
	right: 20px;
	width: 100%;
	padding: 15px 26px 30px;
	opacity: 0;
	visibility: hidden;
	transform: translateY(100%);
	transition: all 0.3s ease-in-out;
	z-index: 10;
	box-shadow: 0px 0px 4px 2px #74747463;
	border-radius: 10px;

	.rtl &,
	[dir='rtl'] & {
		left: 20px;
		right: auto;
	}

	@include media-mobile-view {
		// height: 79%;
		height: auto;
		right: 0;
		left: 0;
		margin: auto;
		top: auto;
		bottom: 0;
		max-width: calc(100% - 20px);
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;

		.rtl &,
		[dir='rtl'] & {
			left: 0;
			right: auto;
		}
	}

	&.active {
		opacity: 1;
		visibility: visible;
		transform: translateY(0);

		~ .backdrop {
			background: #0000006b;
		}
	}

	&-header {
		@include flexbox;
		@include align-items(center);
		@include justify-content(start);
		min-height: 40px;

		.-plain-btn {
			width: 30px;
			height: 30px;
			@include flexbox;
			padding: 0;
			@include align-items(center);
			@include justify-content(center);
			border: none;
			margin-top: 7px;
			background-color: transparent;
			color: $text-muted;
			display: block;
			position: absolute;
			right: 15px;
			top: 12px;
			text-decoration: none;
			font-size: 12px;
			box-shadow: none !important;

			.rtl &,
			[dir='rtl'] & {
				left: auto;
				right: 15px;
			}

			&:hover {
				background-color: $newslide-hover;
				text-decoration: none;
			}

			em {
				color: $app-brand-primary-dark;
				display: block;
			}

			&.active {
				background-color: $newslide-hover;
			}
		}
	}

	&-title {
		color: #1e1919;
		font-size: 18px;
		font-weight: 500;
	}

	.slide-index-content {
		margin-top: 18px;

		@include media-mobile-view {
			overflow: auto;
			// height: calc(100vh - 300px);
			max-height: calc(100vh - 300px);
		}

		ul {
			list-style: none;
			@include flexbox;
			@include flex-direction(column);

			li {
				&:before {
					display: none;
				}

				font-size: $text-lg;
				line-height: 1;
				color: $dune;
				cursor: pointer;

				&.slide-index-active {
					color: $black;
					font-weight: $font-weight-semibold;
				}

				&:hover {
					color: $black;
				}

				+ li {
					margin-top: 20px;
				}
			}
		}
	}

	.slide-index-content {
		overflow: auto;
		// height: calc(100vh - 300px);
		max-height: calc(100vh - 300px);
	}
}

.lesson-authoring-preview {
	&.app-l-base.app-l-full-height {
		padding-left: 50px;

		.rtl &,
		[dir='rtl'] & {
			padding-right: 50px;
			padding-left: 0;
		}

		@media (max-width: $screen-lg) {
			padding-left: 0;

			.rtl &,
			[dir='rtl'] & {
				padding-right: 0;
			}
		}
	}
}

.media-wrapper {
	position: relative;
	height: 100%;
	max-height: calc(100vh - 310px);

	img {
		object-fit: contain;
	}

	@include media-mobile-view {
		max-height: fit-content;
		//width: 100%;
		border-radius: 4px;
		background-color: whitesmoke;
	}
}

.page-content-preview {
	width: 100%;
	transition: 0.3s all;
	padding-left: 98px;
	padding-right: 30px;

	@media (max-width: 640px) {
		padding-left: 0px;
		padding-right: 0px;
	}

	@media (min-width: $screen-sm) {
		.mobile-preview & {
			max-width: 375px;
			margin: auto;
			transform: translateZ(0);
			background: $white;
			border: 5px solid $aquaHaze;
			border-radius: 20px;
			padding: 0;

			.app-c-slider-content {
				padding: 0;
			}

			.link-tool--content {
				img {
					width: 100%;
					margin: 10px 0;
				}

				a {
					word-break: break-all;
				}
			}
		}
	}

	.preview-text-only,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	li {
		color: var(--slideText, #382f2f);
	}

	.preview-text-only {
		border-left: 3px solid var(--slideText);
		padding: 10px 80px 0px 45px;
		margin-left: 0px;
		//max-width: 900px;
		width: 100%;

		@media (max-width: $screen-lg) {
			padding: 10px 40px 0px 40px;
			margin-left: 10px;
		}

		@media (max-width: $screen-sm) {
			padding: 10px 40px 0px 30px;
			margin-left: 15px;
		}
	}
}

.mobile-preview {
	.header-bind {
		padding-right: 0;
	}

	.app-c-slider-content-holder {
		display: block;

		&.media-only-preview {
			.app-c-slider-content-inner-holder {
				margin: auto;
			}
		}
	}

	.slide-control-group {
		z-index: 2;
		position: fixed;
		bottom: -34px;
		right: 60px;
		@include flexbox;
		@include align-items(center);

		.rtl &,
		[dir='rtl'] & {
			right: auto;
			left: 60px;
		}

		background: $white;
		box-shadow: 0px -9px 21px rgba(0, 0, 0, 0.131638);
		width: 100%;
		right: 0;
		bottom: 0;
		padding: 18px 20px;
		@include justify-content(center);
		@include align-items(center);

		.slide-count {
			color: $app-brand-primary;
			font-size: $text-xl;
			margin: 0;

			.rtl &,
			[dir='rtl'] & {
				margin-right: 0;
				margin-left: 20px;
			}

			@include media-mobile-view {
				font-size: $text-xl;
				margin: 0;
			}
		}

		.preview-plain-btn {
			background-color: var(--theme-primary-color);
			color: $white;
			display: block;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			@include flexbox;
			@include justify-content(center);
			@include align-items(center);
			font-size: 12px;
			text-decoration: none;
			position: absolute;
			top: -15px;
			right: 20px;

			.rtl &,
			[dir='rtl'] & {
				right: auto;
				left: 50px;
			}

			&:focus {
				text-decoration: none;
			}
		}
	}

	.page-content-preview .preview-text-only {
		padding-left: 15px;
		padding-right: 0;
	}

	.link-tool--content {
		background: #f4f5f9;
		padding: 20px 25px 25px;
		border-color: #dddfde;
		box-shadow: none !important;
		margin-bottom: 20px !important;

		p {
			display: none;
		}

		.link-tool__title {
			font-size: 14px;
			font-weight: 600;
		}

		a {
			color: #17226f !important;
		}
	}

	.page-content-preview .app-l-upload-editor-blk__right .link-tool--content {
		margin-bottom: 20px !important;
	}

	.app-l-view-lesson--preview .app-c-title {
		font-size: 22px !important;
		margin-bottom: 10px;
	}

	.app-l-screen-header .app-l-screen__heading {
		width: 100%;
		font-size: $text-md;

		@include media-mobile-view {
			font-size: 12px;
		}
	}

	.app-c-slider-content-holder .app-c-slider-content-inner-holder {
		padding: 20px 5%;
		// display: flex;
		//margin: auto;
	}

	.app-c-slider-content-holder .app-c-slider-content-inner-holder .preview-text-only {
		overflow: auto;
	}

	.app-l-upload-editor-blk.-questionSlider .horizontal-slider-lesson {
		max-width: calc(100% - 10px);
		margin: auto;
		padding-bottom: 0;
	}

	.app-l-main-container .horizontal-slider-lesson > .slick-next {
		transform: translateY(50%);
	}

	.app-l-main-container .horizontal-slider-lesson > .slick-prev {
		transform: translateY(50%);
	}

	// .app-l-base.app-l-full-height.lesson-authoring-preview .page-content-preview ul li:before {
	// 	content: none;
	// }
	.app-l-main-container .horizontal-slider-lesson .slick-list {
		max-width: calc(100% - 10px);
	}

	.app-l-base.app-l-full-height.lesson-authoring-preview .page-content-preview ul li {
		padding-right: 10px;
	}

	.link-tool__image {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		margin: 0 0 0 30px;
		width: 50px !important;
		height: 50px;
		border-radius: 3px;
		float: right;
	}

	.app-l-view-lesson--preview .app-l-upload-editor-blk__left {
		justify-content: flex-start;
	}

	.app-l-upload-editor-blk__left {
		height: auto !important;
		margin-bottom: 20px !important;
		min-height: auto !important;
	}

	.app-c-upload-items_blk {
		height: 100% !important;
	}

	.app-l-view-lesson--preview .text-preview-mode-data {
		padding-right: 0 !important;
	}

	.app-l-upload-editor-blk {
		justify-content: center;
	}

	.app-c-slider-content {
		padding: 0 !important;
		//align-items: center;
	}

	.app-c-slider-content-holder .slider-s-q-wrapper {
		padding-right: 0;
	}

	.app-l-view-lesson--preview
		.media-text
		.app-l-upload-editor-blk__left
		.app-c-upload-media-wrap
		.app-c-btn--icon-only.expand-button {
		border-radius: 0;
		top: 5px !important;
		right: 5px !important;
		font-size: 12px;
		color: #61636f !important;
		z-index: 1;
		font-weight: normal;
	}

	.app-l-base.app-l-full-height.lesson-authoring-preview {
		height: 100vh;
	}
}

.aq-set-question-text-wrapper {
	@include flexbox;
	@include align-items(baseline);

	.form-group {
		width: 100%;
	}
}

.hotspot-popover {
	background: $white;
	box-shadow: 0px 9px 21px rgba(0, 0, 0, 0.13);
	border-radius: 10px;
	color: $dune;
	padding: 20px;
	max-width: 400px;

	.hotspot-title {
		font-weight: $font-weight-semibold;
		font-size: 20px;
		margin-bottom: 16px;
	}

	.hotspot-details {
		font-size: $text-xl;
		margin-bottom: 16px;
	}

	@include media-mobile-view {
		position: fixed;
		bottom: 60px;
		z-index: 1;
		max-width: 80%;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
	}
}

.filepond--wrapper {
	width: 100%;

	.filepond--credits {
		display: none;
	}

	.filepond--file {
		background-color: $app-brand-primary;
	}

	.filepond--drop-label {
		background-color: $aquaHaze;

		div {
			font-weight: normal;
			font-size: 16px;
			line-height: normal;
			color: #382f2f;
			margin-bottom: 4px;
		}
	}
}

.app-l-upload-editor-blk {
	.cdx-block,
	.ce-block__content .ce-header {
		color: var(--slideText, $dune);
	}

	.ce-paragraph,
	.cdx-alert__message {
		font-size: 18px;
	}
}

.ce-paragraph[data-placeholder]:empty::before {
	color: rgba($color: var(--slideText, #707684), $alpha: 0.7) !important;
}

.app-l-labelled-graphic {
	margin: auto;

	.slider-question-editor {
		margin: 0 !important;
	}

	.app-c-btn--icon-only {
		@extend %btn-close;
	}

	.app-c-upload-media-blk {
		position: relative;
		margin: auto;
		max-width: 900px;
		width: 100%;
	}

	.app-c-upload-media-wrap {
		margin: auto;
		max-width: 900px;
		width: 100%;

		img {
			width: 100%;
			height: 100%;
			display: block;
			border-radius: 10px;
		}
	}

	.app-l-popup-container {
		margin-left: 40px;
	}
}

.app-l-add-caption {
	font-style: italic;
	color: var(--slideText, #382f2f);

	&::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: var(--slideText, #382f2f);
	}

	&::-moz-placeholder {
		/* Firefox 19+ */
		color: var(--slideText, #382f2f);
	}

	&:-ms-input-placeholder {
		/* IE 10+ */
		color: var(--slideText, #382f2f);
	}

	&:-moz-placeholder {
		/* Firefox 18- */
		color: var(--slideText, #382f2f);
	}
}

.app-l-preview-caption {
	text-align: center;
	font-weight: normal;
	font-size: 14px;
	line-height: normal;
	width: 100%;
	display: block;
	text-align: center;
	padding: 10px 0 0;
	background-color: transparent;
	color: var(--slideText, #382f2f);
	font-style: italic;
}

.link-tool__image {
	object-fit: contain;
}

.hotspot__popover {
	h3 {
		font-size: 20px;
	}

	ol,
	ul {
		padding: 0;

		li {
			ol {
				padding-left: 18px;
			}
		}
	}

	ul {
		padding-left: 18px;

		li {
			list-style-type: circle;
		}
	}

	ol {
		counter-reset: item;
		list-style: none;

		li {
			&::before {
				counter-increment: item;
				margin-right: 5px;
				white-space: nowrap;
				content: counters(item, '.') '. ';
			}

			&::marker {
				display: none;
			}
		}
	}
}

.slide-control.-drag {
	.slide-controls {
		cursor: grab;
		width: 36px;
		height: 36px;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.slide-controls {
	em {
		color: #61636f;
	}
}

.cdx-block {
	padding: 0.4em 0 !important;
}

audio {
	width: 90%;
	margin-top: 20px;
}

.mobile-preview {
	.slider-wrapper-content-holder {
		height: 100%;
		transform: translateZ(0);
		padding: 0 20px;

		.app-c-slider-content-holder {
			margin: 0 -20px;
			padding: 5px !important;

			.horizontal-slider-lesson > .slick-prev {
				left: -9px !important;
				transform: translateY(-20px);
			}

			.horizontal-slider-lesson > .slick-next {
				right: -9px !important;
				transform: translateY(-20px);
			}
		}
	}

	.app-c-slider-content-holder {
		.app-c-slider-content-inner-holder {
			&.align-content-top {
				display: flex;
				align-items: flex-start;
				margin: 0;
			}
		}
	}
}

.app-l-view-lesson--preview {
	.app-c-slider-wrapper {
		margin: 10px auto 0 !important;
		overflow: unset !important;
		// max-width: 1250px !important;
		max-width: 100% !important;
	}

	.app-c-title {
		font-size: 20px !important;
		margin-bottom: 15px !important;
		padding-left: 35px !important;

		@include media-mobile-view {
			font-size: 22px !important;
			font-weight: $font-weight-bold;
		}
	}

	.app-c-sub-title {
		font-size: 25px;
		padding: 0px 10px 0;
		word-break: break-word;

		@include media-mobile-view {
			font-size: 14px;
			margin-bottom: 0;
			padding: 0px 0 10px;
		}
	}

	.app-c-slider-content {
		border-radius: 4px;
		// height: 80% !important;
		position: relative;
		overflow: auto;

		// .app-l-upload-editor-blk {
		// 	overflow: auto;
		// }
		@media (max-width: 640px) {
			padding: 15px 0px 0px !important;
		}

		.text-preview-mode-data,
		.preview-text-only {
			ul {
				padding-left: 0px;

				li {
					list-style-type: none;
					padding-left: 10px;
					position: relative;
					padding-bottom: 3px;

					&:before {
						content: '•';
						left: 0;
						position: absolute;
					}

					ul {
						padding: 5px 0px 0px 16px;
					}
				}
			}

			ol {
				padding-left: 0px;
				counter-reset: item;

				li {
					position: relative;
					padding-bottom: 3px;
					list-style-type: none;
					font-size: $text-xl;

					&::before {
						content: counters(item, '.') '. ';
						counter-increment: item;
					}

					ol {
						padding: 5px 0px 0px 16px;
					}
				}
			}

			.app-c-alert-container {
				font-size: $text-xl;
			}
		}
	}

	.app-l-author-card {
		padding: 50px 0 0;
	}

	.app-l-upload-editor-blk__left {
		position: relative !important;
		// width: 55% !important;
		display: flex;
		//justify-content: center;
		padding: 20px 20px 20px 20px;

		@media (max-width: 640px) {
			padding: 0;
		}
	}

	.app-l-upload-editor-blk__right {
		overflow: auto;
		padding-left: 0px;
		width: 90%;
		height: 97%;
		align-items: flex-start;
		padding: 0px 20px 20px 20px;

		@media (max-width: 640px) {
			padding: 0;
		}
	}

	.app-c-upload-items_blk {
		width: 100%;
		background: #f2f3f7 !important;
		border: 1px dashed $app-brand-primary-dark;
		border-radius: 10px;
		@include flexbox();
		@include flex-direction(column);
		@include align-items(center);
		@include justify-content(space-evenly !important);
		max-width: 482px;
		margin: auto 65px auto auto;

		&.not-empty {
			border: 0;
		}

		@include media-mobile-view {
			margin: 0 auto;
		}

		@media (max-width: 640px) {
			height: auto;
			gap: 20px;
			padding: 20px 0;
		}

		&.file-type {
			+ .app-l-add-caption {
				max-width: 480px;
				margin: 10px 65px auto auto;

				.rtl &,
				[dir='rtl'] & {
					margin: 10px auto 65px auto;
				}

				@include media-mobile-view {
					margin: 10px auto 26px;
				}

				@media (max-width: 640px) {
					margin: 10px auto 0;
				}
			}

			.file-name {
				color: $newslide-1;
				font-size: $text-xl;
			}

			em {
				font-size: 100px;
				color: $app-brand-primary;
			}
		}

		.rtl &,
		[dir='rtl'] & {
			margin: auto auto auto 65px;
		}

		.app-c-upload-items_blk__img {
			@include flexbox();
			@include align-items(center);
			@include justify-content(space-around);
			width: 100%;

			@media screen and (max-width: 991px) {
				@include justify-content(center);
			}

			.app-c-upload-items_blk__img__item {
				@include flexbox();
				@include align-items(center);
				@include justify-content(center);

				@media screen and (max-width: 991px) {
					width: 60px;
					height: 60px;
				}

				i {
					line-height: normal;
					color: $app-brand-primary-dark;

					&.icon-video-camera,
					&.icon-media-solid {
						font-size: 52px;
					}

					&.icon-headphone,
					&.icon-document-new {
						font-size: 46px;
					}

					@media screen and (max-width: 991px) {
						font-size: 32px !important;
					}
				}
			}
		}

		.app-c-upload-items_blk__link {
			.app-c-btn--link {
				padding: 0px;
				font-weight: 700;
				font-size: 16px;
				text-decoration: none;
				color: $text-muted;
				cursor: pointer;

				&:hover {
					color: $app-brand-primary;
				}

				i {
					margin-right: 12px;

					.rtl &,
					[dir='rtl'] & {
						margin-right: 0;
						margin-left: 12px;
					}
				}
			}

			.app-c-btn {
				position: relative;

				+ .app-c-btn {
					margin-left: 20px;

					.rtl &,
					[dir='rtl'] & {
						margin-right: 20px;
						margin-left: 0;
					}
				}

				input {
					position: absolute;
					top: 0;
					left: 0;
					opacity: 0;
					width: 100%;
					height: 100%;
				}
			}
		}

		.app-c-upload-items_blk__drag-blk {
			@include flexbox();
			@include flex-direction(column);
			@include align-items(center);
			@include justify-content(center);

			@media screen and (max-width: 991px) {
				padding: 0px 30px;
				text-align: center;
			}

			p {
				font-weight: normal;
				font-size: 16px;
				line-height: normal;
				color: #382f2f;
				margin-bottom: 4px;
			}

			span {
				font-weight: normal;
				font-size: 11px;
				line-height: normal;
				color: #382f2f;
			}
		}
	}

	.app-c-upload-media-blk {
		width: 100%;
		@include flexbox();
		@include flex-direction(column);
		padding: 0 !important;

		@media screen and (max-width: 991px) {
			width: auto;
		}
	}

	.app-c-upload-media-wrap {
		border-radius: 10px;
		position: relative;
		overflow: visible;
		margin: auto auto;
		background: transparent;
		width: 100%;
		height: 100%;
		text-align: center;
		max-width: 900px;

		.rtl &,
		[dir='rtl'] & {
			margin: auto auto auto 65px;
		}

		@include media-mobile-view {
			width: 100%;
			margin: 0 auto;
		}

		iframe {
			width: 100%;
			border-radius: 10px;
		}

		.media-wrapper {
			height: 100% !important;
			display: flex;
			flex-direction: column;
			height: 100% !important;
			min-height: 0;
		}

		img {
			max-width: 100%;
			// max-height: 100%;
			display: block;
			border-radius: 10px;
			margin: auto !important;
			//height: 523px !important;
			max-height: 50vh;
			object-fit: contain;

			.app-c-slider-container & {
				@media screen and (max-width: 1500px) {
					width: 100%;
				}
			}

			@include media-mobile-view {
				width: 100%;
				margin: 0 auto;
			}
		}

		.app-c-btn--icon-only {
			@extend %btn-close;
			top: 7px !important;
			right: 7px !important;

			&.expand-button {
				color: $dune;
				background: rgba(255, 255, 255, 0.5);
				width: 30px;
				height: 30px;
				font-size: 17px;
				border-radius: 0;

				&:hover {
					background: rgba(255, 255, 255, 1);
				}
			}
		}
	}

	.text-preview-mode-data {
		padding-right: 10px;
		width: 100%;
		height: 100%;
		padding-top: 20px;
		padding-bottom: 20px;

		.link-tool--content {
			margin-bottom: 10px;

			img {
				max-width: 100%;
			}
		}
	}

	.media-only {
		.app-c-upload-media-wrap {
			max-width: 700px !important;
		}
	}

	.media-text {
		overflow: unset !important;
		height: 100%;

		@include media-mobile-view {
			height: 100%;
			padding-bottom: 30px;

			&.not-empty-set {
				height: auto;

				.app-l-upload-editor-blk__right {
					overflow: visible;
				}
			}
		}

		&.not-empty-set {
			justify-content: center;
			width: 100%;

			@media screen and (max-width: 640px) {
				height: 100%;
			}
		}

		.app-l-upload-editor-blk__left {
			display: flex;
			flex-direction: column;
			height: 100%;
			min-height: 0;

			@media screen and (max-width: 640px) {
				min-height: fit-content;
			}

			.app-c-upload-media-wrap {
				display: flex;
				flex-direction: column;
				height: 100%;
				min-height: 0;

				img {
					width: 100% !important;
					//height: 100% !important;
					max-height: calc(100% - 20px) !important;
					object-fit: contain;
					background: whitesmoke;

					@media screen and (max-width: 640px) {
						object-fit: contain;
					}
				}

				.app-c-btn--icon-only.expand-button {
					position: absolute;
					width: 30px;
					height: 30px;
					background: #e9e7e7;
					border-radius: 50px;
					top: -1px !important;
					right: -5px !important;
					font-size: 12px;
					color: #61636f !important;
					z-index: 1;
					font-weight: normal;

					@media screen and (max-width: 1200px) {
						top: -9px !important;
						right: -9px !important;
					}
				}
			}

			.app-c-upload-media-blk {
				height: 100%;
				padding: 0 !important;
				display: flex;
				flex-direction: column;
				min-height: 0;
			}
		}

		.app-l-upload-editor-blk__right {
			height: 100%;
			flex: 1;

			.text-preview-mode-data {
				padding-top: 20px;
				padding-bottom: 20px;
				padding-right: 30px;

				@media screen and (max-width: 1200px) {
					padding-right: 0;
					padding-bottom: 20px;
				}
			}
		}
	}
}

.page-content-preview {
	.app-l-screen-header .app-l-header-wrapper {
		padding-top: 0;
	}

	.app-l-screen-header__left {
		align-items: center;
	}

	.app-l-upload-editor-blk__left {
		@media (max-width: 1440px) {
			video {
				height: calc(100vh - 303px);
			}
		}

		@media (max-width: 1200px) {
			// max-height: 300px;
			max-width: 100%;
			margin-bottom: 0px !important;
			padding-bottom: 0 !important;

			video {
				height: 250px;
			}

			.app-c-upload-media-wrap {
				max-width: 100%;
			}

			.app-c-upload-media-blk {
				padding-bottom: 0 !important;
			}
		}

		@media (max-width: 640px) {
			// max-height: 168px;
		}
	}
}

.app-c-slider-content-holder {
	&.media-only-preview {
		align-items: center;
	}

	&.alignCentered {
		align-items: center;
	}

	height: calc(100vh - 175px) !important;
	@include flexbox;

	@include media-mobile-view {
		// height: calc(100vh - 205px) !important;
		height: 496px !important;
		display: flex;
	}

	@media (max-width: 992px) {
		padding: 30px 25px !important;
		// .slick-prev {
		// 	left: 0 !important;
		// }
		// .slick-next {
		// 	right: 0 !important;
		// }
	}

	@media (max-width: 768px) {
		padding: 15px 10px !important;
		height: calc(100vh - 195px) !important;

		.app-c-slider-content-inner-holder {
			//margin: auto;
		}
	}

	@media (max-width: 640px) {
		height: calc(100vh - 235px) !important;
	}

	.preview-labelled-graphics {
		margin: auto;
		position: relative;
		padding-bottom: 0px;
	}

	.app-c-slider-content-inner-holder {
		width: 100%;
		display: block;
		margin: auto;
		padding: 10px;

		&.align-content-top {
			margin: 0;
		}

		.app-c-upload-media-blk {
			height: auto !important;
		}
	}

	.link-tool--content {
		color: #000000;

		.link-tool__title {
			margin-bottom: 10px;
			font-weight: 600;
			font-size: $text-xl-plus;
		}

		.link-tool__link {
			font-size: $text-lg;
		}

		p,
		a {
			color: #000000;
		}

		img {
			width: 100%;
		}

		a {
			word-break: break-all;
		}
	}

	.app-c-upload-itapp-c-upload-items_blk .file-type .not-empty {
		height: auto;
	}

	.app-c-upload-media-wrap {
		height: auto;

		em {
			margin-top: 20px;
			font-size: 75px !important;

			@media (max-width: 640px) {
				font-size: 45px !important;
			}
		}

		button,
		audio {
			margin-bottom: 0px;
		}

		.file-name {
			padding: 20px 0;

			@media (max-width: 640px) {
				padding: 0px 0;
			}
		}
	}

	ol,
	ul {
		padding: 0;
		margin: 15px 0 10px 0;
		padding-left: 18px;
	}

	ol {
		ol {
			margin: 0;
		}
	}

	ul {
		ul {
			margin: 0;
		}
	}

	h2,
	h3 {
		&.heading-text {
			margin-bottom: 20px;

			@media (max-width: 640px) {
				font-size: 20px;
				margin-bottom: 12px;
			}
		}
	}

	.link-tool__image {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		margin: 0 0 0 30px;
		width: 65px !important;
		height: 65px;
		border-radius: 3px;
		float: right;
	}

	.app-c-slider-content-inner-holder {
		.preview-labelled-graphics & {
			overflow: auto;
		}
	}

	.app-l-upload-editor-blk .app-l-upload-editor-blk__left .app-c-upload-items_blk {
		height: auto;

		@media (max-width: 640px) {
		}
	}
}

.link-tool--content {
	margin: 10px 0;
}

.no-content-added {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	justify-content: center;
	height: 100%;
	flex: 1;
	padding-block: 30px;

	p {
		width: 100%;
	}

	img,
	svg {
		width: 175px;
		height: 175px;
		color: var(--slideText, #382f2f);
	}

	div {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

.app-l-main-container {
	.mobile-preview & {
		.app-l-screen-header__left button {
			display: none;
		}

		.page-content-preview {
			.vertical-slider-lesson {
				> .slick-arrow {
					display: none !important;
				}
			}

			.slide-control-group .preview-plain-btn {
				right: 28px;
				top: -15px;
			}
		}
	}
}

.app-l-backBtn-ui {
	display: none;
}

.app-c-question-img-expand-blk {
	display: none !important;
}

.app-c-qn-attachment-file,
.slider-s-q-option {
	position: relative;
}

.mobile-preview {
	.app-l-backBtn-ui {
		display: block;

		.icon-leftArrow {
			font-size: 21.2px;
			width: 48px;
			height: 48px;
			@include flexbox;
			@include align-items(center);
			@include justify-content(center);
			margin-bottom: 3px;
			text-decoration: none;
			display: flex;
			align-items: center;
			justify-content: center;
			outline: none;
			box-shadow: none !important;

			.rtl &,
			[dir='rtl'] & {
				margin-left: 10px;
				margin-right: 0;
			}

			&:focus {
				text-decoration: none;
			}

			em {
				color: $text-muted;
			}

			&:hover,
			&.active {
				text-decoration: none;
				background-color: $app-brand-primary !important;
				color: $white !important;
			}
		}
	}

	.-questionSlider {
		.app-c-sub-title {
			font-size: 16px;
		}

		.app-c-qn-attachment-file {
			padding: 0 0 10px;
			margin-bottom: 0;
			border: none;

			img {
				width: 100%;
				height: auto;
				margin: 0;
				object-fit: initial;
				border-radius: 0;
			}

			button {
				right: 10px;
				left: auto;
				top: 10px;
			}
		}

		ul {
			li {
				background: white;
				padding: 20px;
				border-radius: 5px;

				.slider-s-q-option {
					margin-right: 0 !important;
					line-height: normal;
					display: flex;
					order: 1;
					word-break: break-word;
				}

				.slider-s-q-indicator {
					top: 0 !important;
				}

				.slider-s-q-option .media {
					padding: 0 !important;
				}

				img {
					width: 100% !important;
					height: auto !important;
					margin: 0 !important;
					object-fit: initial !important;
					border-radius: 0 !important;
				}

				.slider-s-q-wrapper {
					align-items: center;

					.slider-s-q-option--wrapper {
						display: flex;
						flex-direction: column;
					}

					.slider-s-q-option {
						order: -1;
						position: relative;
					}
				}
			}
		}
	}

	.app-c-question-img-expand-blk {
		display: block;
	}

	.app-c-question-img-expand-blk {
		right: 5px;
		left: auto;
		bottom: auto;
		top: 5px;
		width: 20px;
		font-size: 10px;
		height: 20px;
		display: flex !important;
		align-items: center;
		justify-content: center;
		border-radius: 0 !important;
	}

	.app-l-upload-editor-blk__left,
	.app-l-upload-editor-blk__right {
		padding: 0;
	}

	.app-l-screen__heading {
		max-width: 325px;
	}

	.app-l-add-caption {
		@media (max-width: 767px) {
			font-size: $text-lg;
		}
	}

	.page-content-preview {
		.preview-text-only {
			margin-left: 0;
		}
	}

	.page-content-preview .app-l-upload-editor-blk__left video {
		height: 100% !important;
	}
}

.slick-list {
	max-height: max-content !important;
	// height: 100%!important;
}

.link-tool--content a {
	color: #17226f !important;
}

.app-c-alert-container,
.linkTool-wrapper {
	font-size: 16px;

	.link-tool__title {
		font-weight: 600;
	}
}

.list-nesting {
	font-size: 16px;

	ol,
	ul {
		padding: 0;

		li {
			ol {
				padding-left: 18px;
			}
		}
	}

	ul {
		padding-left: 18px;

		li {
			list-style-type: circle;
		}
	}

	ol {
		counter-reset: item;
		list-style: none;

		li {
			&::before {
				counter-increment: item;
				margin-right: 5px;
				white-space: nowrap;
				content: counters(item, '.') '. ';
			}

			&::marker {
				display: none;
			}
		}
	}
}

.media-content--default {
	display: flex;
	align-items: center;
	background: $aquaHaze;
	padding: 10px 20px;
	border-radius: 5px;
	margin: 20px 0;

	&.media-content--audio {
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
		padding: 10px 5px 10px 10px;

		audio {
			&::-webkit-media-controls-panel {
				width: 100%;
			}

			&::-webkit-media-controls-volume-slider,
			&::-webkit-media-controls-time-remaining-display {
				display: none;
			}
		}
	}

	em {
		font-size: 39px;
		color: $faded-blue-one;

		@media (max-width: 767px) {
			font-size: 17px;
		}
	}
}

.app-l-view-lesson--preview {
	.app-l-upload-editor-blk__right {
		&.text-preview-mode {
			margin-left: 0;
			width: 60%;
		}
	}
}

.sideview-group.right-side {
	& + .page-content-preview {
		padding-left: 30px;

		@media (max-width: 640px) {
			padding-left: 0;
		}
	}

	@media (max-width: 640px) {
		display: block;

		.action-group-test-paper {
			right: 56px;
		}

		button {
			min-width: auto;

			span {
				display: none;
			}

			i {
				margin-right: 0;
			}
		}
	}
}

.app-c-card--default .app-c-card__body h6 {
	word-break: break-word;

	line-height: normal;
}

.header-bind {
	padding-right: 195px;

	@media (max-width: 640px) {
		padding-right: 90px;
	}

	&.flexWrap {
		width: 100%;
		@include flexbox;
		@include justify-content(space-between);
		@include align-items(flex-start);
		padding-right: 0;

		.app-c-btn {
			display: inline-flex;

			&.sm {
				i {
					font-size: 14px;
				}
			}
		}
	}
}

.app-l-vertical-content-page {
	.app-c-checkbox-icon::before {
		content: '\e916';
		opacity: 1 !important;
	}

	.app-c-checkbox > label > input[type='checkbox']:checked + .app-c-checkbox-icon::before {
		content: '\e902';
		opacity: 1 !important;
	}
}

.app-l-view-lesson--preview {
	.app-c-video-only {
		max-width: 700px !important;

		video {
			max-height: 394px;
			height: auto !important;
		}

		@media (max-width: 1300px) {
			max-width: 530px !important;

			video {
				max-height: 300px;
			}
		}
	}
}

.link-tool__content--rendered {
	cursor: pointer;

	div,
	p,
	span {
		pointer-events: none;
	}
}

.slide__count {
	background: #fff;
	border-radius: 5px;
	box-shadow: 0px 9px 21px rgba(0, 0, 0, 0.08);
	border: 1px solid #ebebeb;
	padding: 15px;
	margin: 20px 0 20px 0;
}

.report-preview-slide-content .preview-text-only {
	@media print {
		margin-top: 40px;
		margin-bottom: 40px;
	}
}
