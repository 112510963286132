.csEditorjs {
	margin-left: 40px;
	.ce-toolbar {
		// left: 0;
		//53px
		// display: block;
		// @media (max-width: 1400px) {
		// 	left: 0;
		// }
		.ce-toolbar__plus {
			border-radius: 50%;
			// position: absolute;
			// top: 0;
			// left: 47%;
			position: absolute;
			left: -41px;
			color: $gray03;
			box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.14);
			background-color: $white;
			z-index: 1;
			// transform: translate3d(0px, calc(0px - 50%), 0px) !important;
			transition: 0.3s all;
			@media (max-width: 1500px) {
				left: -45px;
			}
			@media (max-width: 650px) {
				top: 0;
			}
			&:hover {
				box-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.14);
				color: $black01;
			}
			&__content {
				// display: flex;
				// align-items: center;
				// justify-content: center;
				// @media (max-width: 767px) {
				// 	height: 3px;
				// }
				// &:before {
				// 	content: '';
				// 	height: 1px;
				// 	width: 100%;
				// 	background: $gray03;
				// 	position: absolute;
				// 	z-index: 0;
				// }
			}
			&__plus {
			}
		}
		.ce-toolbox {
			&.ce-toolbox--opened {
				// position: absolute;
				// top: 26px;
				// transform: translate(0);
				.ce-toolbar__plus {
					@include transform(rotate(45deg));
				}
				margin-left: 20px;
				background: $white;
				box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.14);
				@media (max-width: 650px) {
					margin-left: 0;
					max-width: 300px;
					flex-wrap: wrap;
				}
			}
		}
		// .ce-toolbar__plus.ce-toolbar__plus--hidden {
		// 	display: flex;
		// }
	}
	.inline-image__embed-button {
		background-color: $app-brand-primary;
		border-radius: 5px;
	}
	.inline-image__tab.active {
		border-color: $app-brand-primary;
	}
	.ce-settings__button {
		svg {
			&.icon--arrow-up,
			&.icon--arrow-down {
				display: none;
			}
		}
		&.ce-tune-move-up:before {
			content: '\e923';
			font-family: 'qualify-tms';
		}
		&.ce-tune-move-down:before {
			content: '\e972';
			font-family: 'qualify-tms';
		}
	}
	.ce-block__content {
		// max-width: 1200px;
		max-width: 100%;
	}

	.ce-toolbar__content {
		// max-width: 1200px;
		max-width: 100%;
		@media (max-width: 640px) {
			width: 100%;
			max-width: 100%;
		}
	}
	.ce-toolbox__button--active,
	.ce-toolbox__button:hover {
		color: $app-brand-primary;
	}
	.ce-settings__button {
		&.ce-settings__button--confirm {
			background-color: #a71133 !important;
			&:before {
				content: '\e910';
				font-family: 'qualify-tms';
			}
			svg {
				display: none;
			}
			&:hover {
				background-color: #a71133 !important;
			}
		}
		&:hover {
			background-color: $app-brand-primary !important;
			color: $white;
		}
	}
	.ce-delimiter {
		position: relative;
		&.cdx-block::before {
			display: inline-block;
			content: '';
			font-size: 30px;
			line-height: 65px;
			height: 4px;
			width: 240px;
			letter-spacing: 0.2em;
			position: absolute;
			top: 0;
			@include border-radius(5px);
			background: $app-brand-primary;
			transform: translateX(-50%);
		}
	}
	.ce-block {
		> .ce-block__content {
			> .ce-delimiter {
				position: relative;
				&.cdx-block::before {
					background: #e8e9f2;
				}
			}
		}
	}
}
.codex-editor {
	.ce-block__content {
		// max-width: 1200px;
		max-width: 100%;
	}
	.ce-toolbar__content {
		// max-width: 1200px;
		max-width: 100%;
		@media (max-width: 650px) {
			max-width: 100%;
		}
	}
	.ce-delimiter {
		position: relative;
		&.cdx-block::before {
			display: inline-block;
			content: '';
			font-size: 30px;
			line-height: 65px;
			height: 4px;
			width: 240px;
			letter-spacing: 0.2em;
			position: absolute;
			top: 0;
			@include border-radius(5px);
			background: $app-brand-primary;
			transform: translateX(-50%);
		}
	}
	.ce-block {
		> .ce-block__content {
			> .ce-delimiter {
				position: relative;
				&.cdx-block::before {
					background: #e8e9f2;
				}
			}
		}
	}
}
.character-limiter {
	font-size: 12px;
	color: $gray03;
	text-align: right;
	max-width: 1200px;
	margin: 0 auto;
}
.cdx-notifies {
	left: 50% !important;
	transform: translateX(-50%);
	.cdx-notify {
		width: 300px;
		.cdx-notify__cross {
			right: 10px;
		}
	}
}
// .ce-settings {
// 	left: -1px;
// 	right: unset;
// 	top: 30px;
// }

.ce-paragraph[data-placeholder]:focus::before {
	opacity: 1 !important;
}
.cdx-loader {
	&::before {
		z-index: 9;
	}
}
