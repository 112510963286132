.ContextualNavHolder {
	display: flex;
	@include boxShadow(0px 2px 8px rgba(0, 0, 0, 0.135216));
	@include border-radius(8px);
	overflow: hidden;
	margin-top: 10px;
	.nav-pills {
		flex-basis: 200px;
		position: relative;
		transition: all 0.5s ease;
		@media (max-width: 767px) {
			flex-basis: 111px;
		}
		.nav-link {
			border-radius: 0px;
			padding: 20px 1rem;
			color: var(--theme-primary-color);
			cursor: pointer;
			margin-bottom: 1px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			max-width: 200px;
			font-size: 16px;
			@media (max-width: 767px) {
				font-size: 14px;
			}
			&[disabled],
			&.disabled {
				background: #f0f0f0;
				pointer-events: none;
				color: #a3a3a3;
			}
			div:not([data-tooltipped]) {
				position: absolute;
				right: 10px;
			}
			&.nav-item-wrapper {
				> div[data-tooltipped] {
					display: block !important;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
			&.active {
				background: var(--theme-primary-color);
				color: #fff;
				div {
					.icon-info {
						color: #ffffff;
					}
				}
			}
		}
		.app-c-nav-toggle-btn {
			position: absolute;
			width: 24px;
			height: 24px;
			background: white;
			color: var(--theme-primary-color);
			border-radius: 50px;
			border: none;
			display: flex;
			align-items: center;
			justify-content: center;
			box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.35);

			right: -12px;
			top: 19px;
			font-size: 10px;
			padding: 0;
			cursor: pointer;
			i {
				transform: rotate(180deg);
			}
		}
	}

	@media screen and (max-width: 768px) {
		&.app-c-expand {
			.nav-pills {
				flex-basis: 30px;
				.nav-link {
					opacity: 0;
					padding: 0;
					width: 10px;
					overflow: hidden;
					pointer-events: none;
				}
				.app-c-nav-toggle-btn {
					i {
						transform: rotate(0deg);
					}
				}
			}
		}
	}
	&__mini {
		.nav-pills {
			flex-basis: 30px;
			.nav-link {
				opacity: 0;
				padding: 0;
				width: 10px;
				overflow: hidden;
				pointer-events: none;
			}
			.app-c-nav-toggle-btn {
				i {
					transform: rotate(0deg);
				}
			}
			@media screen and (max-width: 768px) {
				flex: 0 0 13px;
				.nav-item {
					display: none;
				}
				.nav-link {
					opacity: 1;
					padding: 20px 1rem;
					width: 100%;
					pointer-events: all;
				}
				.app-c-nav-toggle-btn {
					i {
						transform: rotate(180deg);
					}
				}
			}
		}
	}
	.tab-content {
		flex: 1;
		border-left: 1px solid hsla(0, 0%, 66.7%, 0.2);
		padding: 20px;
		transition: all 0.5s ease;

		@media screen and (max-width: 640px) {
			padding: 20px;
			width: calc(100% - 180px);
		}
		.tab-content {
			padding: 0;
			border: none;
			margin-top: 20px;
			@media screen and (max-width: 640px) {
				width: 100%;
				.lesson-group-title {
					word-break: break-word;
				}
				.lesson-group-status {
					margin-right: 14px;
				}
			}
			.app-c-common-tabs__content {
				padding: 0;

				.lesson-group-item {
					padding: 24px;
					align-items: center;
					&[disabled] {
						background: #f0f0f0;
						pointer-events: none;
						.lesson-group-header,
						.lesson-group-action,
						.lesson-status-circle {
							opacity: 0.65;
						}
						.progress-circle {
							stroke: #dadadc;
						}
					}
					.progress-circle {
						stroke: #7e838d;
					}
					.lesson-status-circle {
						margin-top: 5;
					}
					.lesson-group-status {
						margin-top: -3px;
					}
					.lesson-group-action {
						margin-left: auto;
					}
					@media screen and (max-width: 640px) {
						flex-direction: column;
						padding: 15px;
						.lesson-group-action {
							margin-right: auto;
							padding-top: 5px;
						}
					}
				}
			}
		}
		.app-l-memory-check {
			width: 100% !important;
			@include boxShadow(0px 2px 8px rgba(0, 0, 0, 0.135216));
			@include border-radius(8px);
			background: #fff;
			margin: 0 0 12px;
			padding: 24px;
			@include flexbox;
			@include align-items(center);
			@media screen and (max-width: 980px) {
				margin-right: 0 !important;
			}
			@media screen and (max-width: 640px) {
				padding: 16px 12px;
				flex-wrap: wrap;
			}
			@media screen and (max-width: 480px) {
				img {
					margin-bottom: auto;
					width: 34px;
				}
			}
			.title {
				@media screen and (max-width: 480px) {
					margin-top: 8px;
				}
				div {
					margin-top: -3px;
				}
			}
			.app-l-description {
				padding-left: 20px;
				padding-right: 20px;
				@media screen and (max-width: 640px) {
					width: calc(100% - 38px);
					padding-right: 0;
				}
				@media screen and (max-width: 480px) {
					width: calc(100% - 38px);
					padding-right: 0;
					padding-left: 10px;
				}
				h5,
				p {
					margin: 0;
				}
				h5 {
					font-size: 20px;
					line-height: 16px;
					margin-bottom: 8px;
					display: block;
					color: var(--theme-primary-color);
					font-weight: 500;
					@media screen and (max-width: 480px) {
						font-size: 16px;
					}
				}
				p {
					color: #61636f;
					font-size: 12px;
					line-height: 13px;
					@media screen and (max-width: 480px) {
						margin-left: -43px;
						margin-top: 11px;
						line-height: normal;
					}
					span {
						background: white;
						word-break: break-word;
					}
				}
			}
			.app-c-btn--addon {
				margin-left: auto;
				padding: 10px 16px;
				@media screen and (max-width: 640px) {
					margin-right: auto;
					margin-top: 10px;
				}
			}
		}
	}
}
.app-l-locked-content-info {
	display: flex;
	align-items: center;
	padding-bottom: 24px;
	padding-top: 0;
	img {
		width: 18px;
	}
	span {
		padding-left: 12px;
		font-size: 16px;
	}
}

.student-course-showcase {
	@include flexbox();
	@media only screen and (max-width: 1200px) {
		display: block;
	}
	> div {
		@media only screen and (min-width: 1600) {
			flex-grow: 0;
			flex-shrink: 0;
		}
	}
	&__bannner {
		background: #ccc;
		@include border-radius(8px 0 0 8px);
		overflow: hidden;
		position: relative;
		max-height: 420px;
		width: 50%;
		@media only screen and (max-width: 1600px) {
			width: 42%;
		}
		@media only screen and (max-width: 1200px) {
			@include border-radius(8px 8px 0 0);
			width: 100%;
		}

		> img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	&__overlay {
		position: relative;
		z-index: 2;
		background: rgba($black, 0.3);
		padding: 16px 10px 30px 30px;
		font-size: $text-lg;
		@include flexbox();
		@include flex-direction(column);
		height: 100%;
		.course-descrp-box {
			@include flex-grow(1);
			overflow-y: auto;
		}

		* {
			color: $white;
		}
		h2 {
			margin-bottom: 0.4rem;
			font-size: 4rem;
			@include flex-shrink(0);
			@media only screen and (max-width: 1600px) {
				font-size: 2.5rem;
			}
			@media only screen and (max-width: 1200px) {
				font-size: 2rem;
			}
		}
	}
	&__datas {
		@include border-radius(0 8px 8px 0);
		@include flex-grow(0);
		padding: 10px;
		width: 50%;
		@media only screen and (max-width: 1600px) {
			width: 58%;
		}
		@media only screen and (max-width: 1200px) {
			@include border-radius(0 0 8px 8px);
			width: 100%;
		}

		.key-value-pair {
			table {
				width: 100%;

				tr {
					&.standout {
						border-top: 1px solid $generic-border-color;
						border-bottom: 1px solid $generic-border-color;

						td {
							padding: 8px 0;
							border: none !important;
						}
					}
					td {
						padding: 5px 0;
						font-size: $text-lg;
						@media only screen and (max-width: 1600px) {
							padding: 2px 0;
						}
						&:first-child {
							width: 150px;
							white-space: nowrap;
							padding-right: 30px;
						}
						.app-l-text {
							margin: 0 !important;
						}
						table {
							tr {
								td {
									padding: 2px 0;
								}
							}
						}
					}
					&.small-screen {
						td {
							@media only screen and (max-width: 768px) {
								display: block;
								padding-bottom: 0;
							}
						}
					}
				}
			}
		}
	}
	&__data-holder {
		padding: 6px 20px;
		overflow-y: auto;
		max-height: 400px;
	}
}
