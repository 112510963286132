//React Accordion
.app-c-accordion-main {
	.card {
		border: none;
		padding: 0;
		margin: 0;

		background: none;
		overflow: inherit;
		@include boxShadow(0px 0px 8px 0px rgba(0, 0, 0, 0.1352));
		margin-top: 8px;
		@include border-radius(8px !important);
		overflow: hidden !important;
	}
	.btn-link {
		padding: 0;
		margin: 0;
		@include boxShadow(none);
		text-decoration: none;
		.app-c-modal-list-stack__row {
			position: relative;
			padding-left: 64px;
			margin-top: 0;
			@include noShadow();
			.rtl &,
			[dir='rtl'] & {
				padding-left: 0;
				padding-right: 64px;
			}
			@media screen and (max-width: 767px) {
				padding-left: 60px;
				.rtl &,
				[dir='rtl'] & {
					padding-left: 0;
					padding-right: 60px;
				}
			}
			&:before {
				position: absolute;
				left: 13px;
				@include border-radius(3px);
				content: '\e90c';
				font-family: 'qualify-tms', sans-serif;
				display: block;
				color: rgba(97, 99, 111, 1);
				padding: 0 5px;
				@media screen and (max-width: 767px) {
					left: 13px;
					.rtl &,
					[dir='rtl'] & {
						left: auto;
						right: 13px;
					}
				}
				.rtl &,
				[dir='rtl'] & {
					left: auto;
					right: 13px;
				}
			}
		}
		&.active {
			.app-c-modal-list-stack__row {
				background: rgba(233, 235, 244, 0.5);
				@include boxShadow(0px -2px 4px 0px rgba(0, 0, 0, 0.1352));
				@include border-radius(8px 8px 0px 0px);
				&:before {
					content: '\e94a';
				}
			}
		}
		&:hover {
			text-decoration: none;
		}
		&:focus {
			outline: none;
			@include boxShadow(none);
			outline: none;
			text-decoration: none;
		}
		.app-c-modal-list-stack__item {
			.form-label {
				color: $app-brand-primary;
			}
		}
	}
	.card-body {
		padding: 0;
	}
	.app-c-tab {
		.app-c-common-tabs__nav {
			@include border-radius(0px);
			border: none !important;
			border-bottom: 1px solid #e9ebf4 !important;

			padding-left: 64px !important;
			.rtl &,
			[dir='rtl'] & {
				padding-left: 0 !important;
				padding-right: 64px !important;
			}
		}
	}

	.app-c-tab .tab-content {
		padding: 0;
	}
	.app-c-common-tabs {
		@include border-radius(0px 0px 8px 8px);
		margin-bottom: 0;
	}
	.app-c-common-tabs__content {
		padding: 10px 64px 5px;
	}
}
.app-l-labelled-graphics-accordion {
	@include flexbox;
	@include flex-direction(column);
	.card {
		@include flexbox;
		@include flex-direction(row);
		@include align-items(flex-start);
		padding-bottom: 15px;
		min-height: 60px;
		overflow: inherit !important;
		.app-l-author-card {
			width: 100% !important;
			padding: 0 !important;
			@include flex-direction(row);
		}
		.-drag {
			margin: 2px 5px -8px 0 !important;
			span {
				border-radius: 50px;
				width: 30px;
				height: 30px;
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		.-trash {
			button {
				width: 30px;
				height: 30px;
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 1px 0 -8px 0;
				border-radius: 4px;
			}
		}
		.slide-control {
			margin: 0;
		}

		.label-heading-section-num {
			margin-left: 0 !important;
			& + div {
				padding: 5px 0 0 10px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				max-width: 48vw;
				input {
					margin-top: -3px;
				}
			}
		}
		.label-heading-section-title {
			margin-left: 0 !important;
		}
		.app-l-upload-editor-blk__left {
			width: 100% !important;
			margin: -4px 0 0 0 !important;
		}
		.app-l-right-btn-block {
			display: flex;
			align-items: flex-start;
			button {
				border-radius: 50px;
				width: 30px;
				height: 30px;
				flex: 0 0 30px;
				max-width: 30px;
				min-width: 30px;
				margin-left: 12px;
			}
			.accordion-toggle i {
				margin-top: 3px;
			}
			.accordion-toggle.active {
				margin-top: 1px;
				i {
					margin-top: -3px;
				}
			}
		}

		&:hover {
			.slide-control {
				opacity: 1;
				visibility: visible;
				pointer-events: all;
			}
		}
		.ce-toolbar__actions {
			right: 0;
			width: 100%;
			.ce-toolbar__plus {
				@media (max-width: 640px) {
					top: -19px;
				}
			}
			.ce-toolbar__settings-btn {
				position: absolute;
				right: -41px;
				@media (max-width: 640px) {
					top: -18px;
				}
			}
		}
	}
	[data-rbd-draggable-id] {
		left: auto !important;
		& + [data-rbd-draggable-id] {
			margin-top: 10px;
		}
	}
}
.link-tool--content {
	background: #fff;
	border: 1px solid rgba(201, 201, 204, 0.48);
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
	border-radius: 6px;
	will-change: filter;
	display: block;
	padding: 25px;
	a {
		color: inherit;
	}
}
.accordion-header-main {
	.slide-control.-drag {
		margin-top: 5px;
	}
	.slide-controls {
		&:hover {
			background-color: #eaebef;
		}
	}
	.slide-control {
		button {
			margin-top: -5px;
			width: 36px;
			height: 36px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0;
		}
	}
	.accordion-toggle {
		margin-top: 2px !important;
		.icon-downArrow {
			margin-top: 3px;
		}
		&.active {
			margin-top: 4px !important;
			.icon-downArrow {
				margin-top: -3px;
			}
		}
	}
}
.app_l-question-set-accordion {
	.accordion-custom-header {
		.slide-control {
			&.-drag {
				margin-top: 7px !important;
				.slide-controls {
					&:hover {
						background-color: #eaebef !important;
					}
				}
			}
			&.-trash {
				margin-top: 9px !important;
				.slide-controls {
					&:hover {
						background-color: #eaebef !important;
					}
				}
			}
		}
		.accordion-toggle {
			margin-top: 8px !important;
			.slide-controls {
				&:hover {
					background-color: #eaebef !important;
				}
			}
			&.active {
				margin-top: 6px !important;
			}
		}
	}
}
