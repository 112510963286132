//General checkbox
//----------------------------------------------------

@import '../core/mixins/box-shadow';
@import '../core/mixins/flexbox';
@import '../core/mixins/transform';
@import '../core/mixins/transitions';

.app-c-checkbox {
	@include inline-flex();

	& + * {
		margin-left: 10px;
		.rtl &,
		[dir='rtl'] & {
			margin-left: 0;
			margin-right: 10px;
		}
	}
	&.app-c-checkbox--inline {
		display: inline-block;
		& + * {
			margin-left: 10px;
			.rtl &,
			[dir='rtl'] & {
				margin-left: 0;
				margin-right: 10px;
			}
		}
	}

	&.app-c-checkbox--align-in-form {
		@media (min-width: 480) {
			margin-top: 45px;
		}
	}
	&.disabled {
		> label {
			cursor: default;
		}
		.app-c-checkbox-icon {
			background: $app-checkbox-border;
		}
	}
	&.dead {
		pointer-events: none;
		.app-c-checkbox-icon {
			background: #bec1d2 !important;
			border-color: #bec1d2 !important;
		}
	}
	&.with-label {
		padding: 0 0 0 24px;
		.rtl &,
		[dir='rtl'] & {
			padding: 0 24px 0 0;
		}

		@media screen and (max-width: 767px) {
			padding: 0 0 0 15px;
			.rtl &,
			[dir='rtl'] & {
				padding: 0 15px 0 0;
			}
		}
	}
}

.app-c-checkbox > label {
	position: relative;
	margin: 0;
	cursor: pointer;
	line-height: 30px;

	@include inline-flex();
	@include align-items(center);

	> input {
		position: absolute;
		top: 0;
		left: 0;
		right: auto;
		bottom: auto;
		margin: 0;
		opacity: 0;
		z-index: 1;
		width: 1px;
		height: 1px;
		cursor: pointer;
		// display: none;

		&[type='checkbox']:checked + .app-c-checkbox-icon {
			background: var(--theme-primary-color);
			border-color: var(--theme-primary-color);
			color: #fff;
			&::before {
				opacity: 1;

				filter: alpha(opacity=100);
			}
		}
		&[type='checkbox']:indeterminate + .app-c-checkbox-icon {
			&:before {
				content: '';
				width: 8px;
				height: 8px;
				background: var(--theme-primary-color);
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				opacity: 1;

				@include border-radius(2px);
			}
		}
		&[disabled] + .app-c-checkbox-icon {
			cursor: not-allowed;
			border-color: transparent;
			background: $text-light-medium;
		}
		&[type='checkbox']:checked {
			&[disabled] + .app-c-checkbox-icon {
				background: $app-checkbox-bg;
				border-color: $app-checkbox-bg;
				opacity: 0.5;
			}
		}
		&[type='checkbox']:focus {
			+ .app-c-checkbox-icon {
				// @include boxShadow(0px 0px 0px 5px rgba(112, 12, 22, 0.15));
			}
		}
	}
}

.app-c-checkbox-icon {
	width: $checkbox-size;
	height: $checkbox-size;
	border: 1px solid $gray03;
	position: relative;
	background: $gray04;
	padding-top: 1px;
	margin-right: 12px;
	top: 2px;

	@include transition(background ease 0.2s, border ease 0.2s, box-shadow ease 0.2s);
	@include flexbox();
	@include align-items(center);
	@include justify-content(center);
	@include flex-shrink(0);
	@include border-radius(4px);
	@include align-self(flex-start);
	.rtl &,
	[dir='rtl'] & {
		margin-left: 12px;
		margin-right: 0;
	}
	&::before {
		content: '\e902';
		font-family: 'qualify-tms' !important;
		font-size: $text-sm-plus;
		color: $white;
		display: block;

		opacity: 0;
		filter: alpha(opacity=0);

		@include transition(opacity ease 0.2s, transform ease 0.2s);
	}
}
.app-c-checkbox-label {
	padding-left: 8px;
	font-weight: normal;
	font-size: 14px;
	line-height: normal;
	color: #61636f;
	margin-top: 5px;
	.rtl &,
	[dir='rtl'] & {
		padding-left: 0px;
		padding-right: 8px;
	}
	a {
		color: $app-brand-primary !important;
		font-weight: $font-weight-semibold;
	}
}

.app-c-checkbox > .app-c-checkbox-content {
	position: relative;
	margin: 0;
	cursor: pointer;
	line-height: 13px;

	@include inline-flex();
	@include align-items(flex-start);
}

.app-c-checkbox--input-right > label {
	display: block;
	padding-left: 0;
	padding-right: 24px;

	.app-c-checkbox-icon {
		left: auto;
		right: 0;

		&::before {
			right: 2px;
		}
	}
}

.app-c-checkbox--list {
	> .app-c-checkbox {
		margin: 0;
		padding: 12px;
	}

	> .app-c-checkbox:not(:last-child) {
		border-bottom: solid 1px $app-checkbox-border;
	}
}

.app-c-checkbox-button-group--inline {
	.app-c-checkbox-button {
		display: inline-block;

		& + & {
			margin-left: 10px;
		}
	}
}
.app-c-checkbox__vertical-list {
	.app-c-checkbox {
		@include flexbox();

		+ .app-c-checkbox {
			margin-top: 18px;
			margin-left: 0px;
			margin-right: 0px;
		}
	}
}
.app-c-checkbox__horizontal-list {
	.app-c-checkbox {
		display: inline-block;
		+ .app-c-checkbox {
			margin-top: 0px;
			margin-left: 10px;
			margin-right: 0px;
		}
	}
}

.app-c-check-flex-list {
	@include flexbox();
	@include justify-content(flex-start);
	flex-wrap: wrap;

	.app-c-checkbox {
		flex: 1;
		flex-basis: 25%;
		margin-top: 10px;
		@media screen and (max-width: 1365px) {
			flex-basis: 40%;
		}
		& + {
			* {
				margin-left: 0;
			}
		}
	}
}

//FILTER CHECKBOX
.app-c-filter-check {
	position: relative;
	.app-c-filter-check__icon {
		position: absolute;
		width: 10px;
		height: 10px;
		left: -20px;
		top: 4px;
		&::after {
			content: '\e902';
			position: absolute;
			top: 0;
			left: 0;
			font-size: 9px;
			font-family: 'qualify-tms';
			opacity: 0;
			@include transition(all ease 0.4s);
			@include transform(rotate(-20deg));
		}
	}
	.app-c-filter-check__input {
		position: absolute;
		top: 0;
		left: 0;
		display: none;

		&:checked + .app-c-filter-check__icon {
			&::after {
				opacity: 1;
				@include transform(rotate(0deg));
			}
		}
	}
	.app-c-filter-check__label {
		cursor: pointer;
		margin: 0 !important;
		display: block;
		line-height: 22px;
		&:hover {
			color: $app-brand-primary;
		}
		i {
			font-size: 10px;
			margin-right: 6px;
		}
	}
}
