.custom-calender-head {
	margin: 10px;
	display: flex;
	justify-content: space-between;

	button {
		border: none;
		color: rgb(49, 49, 49);
		@include transition(all ease 0.2s);

		&:hover {
			background-color: #fff;
		}
	}

	select {
		border: none;
	}
}

.app-c-input-validation {
	display: flex;
	position: relative;
	padding-bottom: 5px;
	margin-top: -10px;
	flex-wrap: wrap;

	@media screen and (max-width: 575px) {
		padding-bottom: 25px;
	}

	.app-c-text-limit {
		position: absolute;
		right: 0;
		top: 4px;
		font-size: $text-md;
		color: $text-color-light;
		padding-right: 3px;

		.rtl &,
		[dir='rtl'] & {
			right: auto;
			left: 0;
		}

		@media screen and (max-width: 575px) {
			position: relative;
			top: 0;
			margin-left: auto;
		}
	}
}

.card-error-indicator {
	border: red solid 1px !important;
}

.app-c-custom-tooltip {
	color: $white;
	background: #61636f;
	@include border-radius(4px);
	display: inline-block;
	position: absolute;
	padding: 8px 12px;
	font-size: $text-sm-plus;
	@include transition(all ease 0.2s);
	pointer-events: none;

	&.md {
		font-size: $text-md-plus;
	}

	&::before {
		position: absolute;
		display: block;
		width: 0;
		height: 0;
		left: 8px;
		bottom: -15px;
		border-style: solid;
		border-width: 8px;
		border-color: #61636f transparent transparent transparent;
		content: '';
	}

	&.bottom {
		&::before {
			border-color: transparent transparent #61636f transparent;
			bottom: inherit;
			top: -15px;
		}
	}
}

.Toastify__toast-container--bottom-right {
	bottom: 0 !important;
}

.unselectable {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	pointer-events: none;
}

@media only screen and (max-width: 480px) {
	.Toastify__toast {
		margin-bottom: 45px !important;
	}
}

.ce-toolbar__actions {
	right: 0;
}

.cdx-nested-list__item {
	padding: 5.5px 0 5.5px 3px;
}
.ce-block__content {
	font-size: 16px;
}

.cdx-attaches__file-icon {
	margin-right: 5px;
}

.cdx-list__item {
	padding: 5.5px 0 5.5px 3px;
	line-height: 1.6em;
}

.react-pdf__Page {
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: auto;
}

.app-c-pagination {
	position: relative;
	background: white;
	padding: 20px;
	width: initial;
	width: 960px;
	margin: 0 auto;

	@media only screen and (max-width: 1050px) {
		width: 100%;
	}
}

.react-pdf__Page {
	background: white;
	width: 100%;
	margin: 0 auto;
	display: grid;
	align-items: center;
	justify-content: center;

	@media only screen and (max-width: 1050px) {
		width: 100%;
	}
	.react-pdf__Page__canvas {
		margin: auto;
	}
	@include media-mobile-view {
		display: block;
	}
}

.app-c-modal-header-align {
	max-width: 1024px !important;
	margin: auto !important;
}

.app-c-attachment-button {
	position: relative;

	svg {
		position: absolute;
		margin-left: -18px !important;
		margin-top: 3px !important;
		flex: 0 0 12px;

		@media only screen and (max-width: 341px) {
			margin-left: -20px !important;
		}
	}
}

.cdx-attaches__button {
	align-items: baseline !important;
	display: block !important;

	span {
		color: $overDueColor !important;
		font-size: 14px;
	}
	em {
		&.fileIcon {
			@media (max-width: 575px) {
				display: block;
				height: 24px;
				text-align: center;
				svg {
					margin: 0 !important;
				}
			}
		}
	}
}

.app-l-terms-holder {
	.app-l-login__img-wrap {
		@media only screen and (min-width: 768px) and (orientation: landscape),
			only screen and (min-width: 720px) and (orientation: portrait) {
			padding: 24px;
			max-width: 1128px;
			// margin: auto;
		}

		@media screen and (max-width: 767px) {
			padding: 24px;
		}
		img {
			width: 200px;
		}
	}

	h1 {
		width: 100%;
		text-align: center;
		background: #f1ece6;
		height: 200px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin-bottom: 0;
		font-size: 3em !important;
		@media screen and (max-width: 767px) {
			font-size: 1.7em !important;
			height: 130px;
		}
		p {
			margin: 0;
		}
	}

	.app-l-terms-and-conditions {
		width: calc((676px * 0.66667) + 360px);
		margin: auto;
		padding: 50px 0 20px;
		@media screen and (max-width: 767px) {
			width: 100%;
			padding: 25px;
		}
	}
}

.app-l-terms-and-conditions {
	h1 {
		font-size: 3em !important;
		font-weight: bolder;
		margin: 0px !important;
		text-align: center;
		padding-bottom: 40px;

		& + p {
			margin-top: -15px;
		}
	}

	h2 {
		font-size: 1em !important;
	}

	p {
		margin-bottom: 1.5em !important;
		color: black;
		text-align: justify;
	}

	.p1 {
		text-align: center;
		padding-bottom: 30px;
	}

	ol {
		counter-reset: item;
		position: relative;
		margin-left: 40px;
		margin-top: 15px;

		li {
			display: block;
			font-size: 0.9rem;
			padding-left: 40px;
			padding-bottom: 10px;
			text-align: justify;

			strong {
				font-weight: $font-weight-semibold;
			}

			&::before {
				content: counters(item, '.') '.';
				counter-increment: item;
				width: 30px;
				position: absolute;
				left: 0;
				font-weight: normal;
			}
		}
	}

	ul {
		padding-left: 16px;

		li {
			font-size: 14px;
			padding: 5px 0;
		}
	}
}

.cdx-attaches__title {
	word-break: break-word;
}

.app-l-privacy-policy {
	h1 {
		font-size: 3em !important;
		font-weight: bolder;
		margin: 0px !important;
		text-align: center;
		padding-bottom: 60px;

		& + p {
			margin-top: -15px;
		}
	}

	h2 {
		font-size: 1em !important;
	}

	p {
		margin-bottom: 1.5em !important;
		color: black;
	}

	ol {
		counter-reset: item;
		position: relative;
		padding-top: 15px;
		margin-left: 0;

		li {
			display: block;
			font-size: 16px;
			padding-left: 40px;
			padding-bottom: 10px;
			color: black;
			text-align: justify;

			strong {
				font-weight: $font-weight-bold;
			}

			&::before {
				content: counters(item, '.') '.';
				counter-increment: item;
				width: 30px;
				position: absolute;
				left: 0;
				font-weight: normal;
			}
		}

		&.subList {
			margin-left: -40px;
			margin-top: 10px;
		}

		&.thirdLevelList {
			li {
				padding-left: 50px;
			}
		}

		&.fourthLevelList {
			margin-top: 10px;
			li {
				padding-left: 65px;
				&::before {
					width: 80px;
				}
			}
		}
	}
}

.ce-delimiter {
	margin: 35px 0;
}

.disable-button {
	opacity: 0.5;
}
.download-template {
	display: inline-block;
	cursor: pointer;
	padding: 10px 12px !important;
	text-decoration: none;
	&:hover {
		color: $black01 !important;
	}
}
.app-c-download-icon {
	color: $black !important;
	font-size: 16px;
	margin-right: 10px;
	float: left;
	margin-left: 6px;
	position: relative;
	top: 1px;
}
.download-template,
.app-c-browse-btn {
	div {
		float: left;
	}
	@media only screen and (max-width: 991px) {
		width: 30px;
		padding: 0 !important;
		min-width: auto;
		height: 30px;
		display: inline-flex;
		justify-content: center;
		i {
			margin: 0 !important;
			font-size: 14px;
		}
		div {
			display: none !important;
		}
	}
}
.spinner-text {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: 10px;
	@media screen and (max-width: 576px) {
		margin-left: -20%;
	}
	@media screen and (min-width: 767px) {
		margin-left: -12%;
	}
	@media screen and (min-width: 768px) {
		margin-left: -6%;
	}
}

.grid-two-col {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 10px;
}
.cdx-simple-image__picture {
	> img {
		width: 100%;
	}
}
.csEditorjs {
	&.instructions {
		margin: 15px 50px;
		.ce-toolbar__actions {
			width: 100%;
			right: 0;
			.ce-toolbar__actions-buttons {
				position: absolute;
				right: 0;
			}
		}
		.tc-editor {
			left: auto;
		}
		.cdx-alert-info {
			word-break: break-all;
		}
	}
	.ce-toolbar__settings-btn {
		position: absolute;
		right: 0;
		top: -12px;
		@media (max-width: 640px) {
			top: -7px;
		}
	}
}

.cdx-attaches--with-file {
	align-items: center;
}
.cdx-attaches__download-button {
	background: var(--theme-primary-color);
	box-shadow: none;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	padding: 4px 5px;
	border: none;
	margin-left: 10px;
	display: flex;
	height: 34px;
	width: 34px;
	align-items: center;
	justify-content: center;
	svg {
		width: 20px;
		path {
			fill: $white;
		}
	}
	&:hover {
		background: $app-brand-primary-light;
	}
}
.no-network {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	color: $app-brand-primary;
	padding: 0 20px;
	&--title {
		font-size: 28px;
		text-align: center;
		@media (max-width: 500px) {
			font-size: 20px;
		}
	}
	&--titleInfo {
		font-size: 28px;
		text-align: center;
		display: flex;
		height: 100vh;
		align-items: center;
		padding-top: 63px;
		@media (max-width: 500px) {
			font-size: 20px;
		}
	}
	&--image {
		margin-bottom: 20px;
		width: 70px;
	}
	&--explaination {
		font-size: 20px;
		text-align: center;
		max-width: 70%;
		@media (max-width: 500px) {
			font-size: 16px;
		}
	}
	&:before {
		background: $app-brand-primary;
		content: '';
		width: 100%;
		position: absolute;
		bottom: 0;
		z-index: 1;
		height: 30px;
		border-radius: 10px 10px 0 0;
	}
}
.hide-editor-toolbar {
	.ct {
		display: none;
	}
}
.app-l-two-line {
	display: flex !important;
	flex-direction: column;
}

.icon-info,
.tippy-popper {
	@media (pointer: coarse) {
		display: none !important;
	}
}
.select-track-for {
	span + div {
		border: 1px solid hsl(0, 0%, 80%) !important;

		box-shadow: none !important;

		div {
			border: none !important;
		}
	}
}
.clear-date {
	position: absolute !important;
	right: 36px !important;
	top: 10px !important;
	i {
		&:before {
			color: #61636f !important;
		}
	}
}
.html5-video-player:not(.ytp-transparent),
.html5-video-player.unstarted-mode,
.html5-video-player.ad-showing,
.html5-video-player.ended-mode,
.html5-video-player.ytp-fullscreen {
	background: transparent !important;
}
@media only screen and (max-width: 480px) {
	.Toastify__toast-container {
		width: calc(100vw - 40px) !important;
		margin: 0 20px !important;
	}
}
@media (max-width: 650px) and (max-width: 650px) {
	.ce-toolbar__plus {
		border-bottom-color: #e8e8eb !important;
	}
}
.csEditorjs.instructions .ce-toolbar__actions {
	width: 100%;
	right: 0;
}
