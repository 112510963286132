.app-c-file-upload {
	@include flexbox();

	@media screen and (max-width: 480px) {
		display: block;
	}

	.app-l-file-upload__btn {
		line-height: normal;

		@include flex-shrink(0);

		label {
			display: block;
			overflow: hidden;
			position: relative;

			input {
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
	.app-l-file__list {
		padding: 0 0 0 8px;
		line-height: normal;
		width: 100%;

		@include flexbox();
		@include flex-wrap(wrap);

		@media screen and (max-width: 480px) {
			padding-left: 0;
			padding-top: 8px;
		}
		.app-c-chips {
			margin: 0 5px 5px 0;
		}
	}
}

.app-l-onboard-form-group {
	&.generic-field {
		.form-control-wrap {
			overflow: visible;

			.form-control {
				height: 30px;
				padding: 6px 32px 6px 10px;
			}
			.app-l-field-visibility {
				height: 30px;
			}
		}
	}

	.form-control-wrap {
		.form-control {
			&.invalid-field {
				&.ng-dirty {
					&.ng-invalid {
						border-color: $form-error;
						background: $form-error-background;

						@include boxShadow(
							0 0.3px 0.9px rgba(221, 0, 0, 0.024),
							0 0.9px 2.4px rgba(221, 0, 0, 0.035),
							0 2.1px 5.7px rgba(221, 0, 0, 0.046),
							0 7px 19px rgba(221, 0, 0, 0.07)
						);

						~ .app-l-validation-wrap {
							display: block;
							opacity: 0;
							visibility: hidden;

							@include transition(opacity 0.3s ease, visibility 0.3s ease);
						}

						&:hover {
							~ .app-l-validation-wrap {
								opacity: 1;
								visibility: visible;
							}
						}
					}
				}
			}
		}

		.app-l-validation-wrap {
			top: -22px;
		}
	}
}

.cm-upload-file {
	border: 1px dashed $app-brand-primary;
	@include border-radius(5px);
	padding: 10px 14px 10px 21.63px;
	position: relative;
	@media screen and (max-width: 575px) {
		padding: 10px 10px 10px 10px;
		border: 1.5px dashed $app-brand-primary;
	}
}

.cm-upload-file__title {
	font-size: $text-lg;
	color: $text-color;
	line-height: 16.94px;
	margin-bottom: 8px;
	display: block;
}
.cm-upload-file__small {
	color: $text-color-light;
	font-size: $text-md;
	line-height: 16px;
}
.cm-upload-file__wrapper {
	@include flexbox();
	flex-direction: row;
	align-items: center;
	.icon-upload {
		font-size: 34px;
		color: rgba(190, 193, 210, 1);
		@media screen and (max-width: 575px) {
			font-size: 24px;
		}
	}
}
.cm-upload-file__texting {
	padding-left: 13.63px;
	position: relative;
	.rtl &,
	[dir='rtl'] & {
		padding-left: 0;
		padding-right: 13.63px;
	}
}

.cm-upload-file__input {
	width: 100%;
	height: 100%;
	position: absolute;
	opacity: 0;
	cursor: pointer;
	display: block;
	background: #ccc;
}
.cm-upload-file__browse {
	position: relative;
	display: inline-block;
	.cm-upload-file__browse-text {
		font-size: $text-lg;
		color: $app-brand-primary;
		cursor: pointer;
		font-weight: $font-weight-semibold;
	}
}

.cm-upload-file__preview {
	position: absolute;
	top: 0;
	left: 0;
	@include flexbox();
	@include flex-direction(row);
	@include align-items(center);
	@include justify-content(flex-start);
	width: 100%;
	height: 100%;
	background: #fff;
	padding: 3px 30px 3px 14px;
	flex-shrink: 0;
	flex-basis: 100%;
	@include border-radius(6px);
	.cm-upload-file__small {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
.cm-upload-file__preview--icon {
	position: relative;
	top: 2px;
	font-size: 34px;
	color: $app-brand-primary;
	margin-right: 12px;
	//color: #bec1d2;
	color: $app-brand-primary;
	font-weight: 700;
}
.app-c-remove-attached {
	all: unset;
	position: absolute;
	top: 0;
	right: 14px;
	bottom: 0;
	margin: auto;
	width: 16px;
	height: 16px;
	font-size: 8px;
	@include flexbox();
	@include flex-direction(column);
	@include align-items(center);
	@include justify-content(center);
	color: $text-muted;
	@include transition(all ease 0.2s);
	&:hover {
		color: $app-brand-primary;
	}
}
