@import '../variables/variables';
@import '../theme/theme';

// i {
// 	color: $text-muted;
// }
%get-full-height {
	@include flexbox();
	@include flex-dir(column);
	-webkit-flex: 1 1 1px;
	-moz-box-flex: 1 1px;
	flex: 1 1 1px;
	min-height: 0;
	height: 100%;
}

.app-c-btn--primary {
	i {
		color: $white;
	}
}

.app-c-btn {
	&.sm {
		i {
			font-size: $text-xl;
			display: inline-block;
			vertical-align: middle;
		}
	}
}

.report-filter-wrapper {
	// background-color: $gray02;
	// padding: $padding-base;
	@include border-radius(7px);
	@include flexbox();
	@include justify-content(space-between);
	@include align-items(flex-start);
	flex-wrap: wrap;
	background: $white;
	@include boxShadow(0px 2px 8px 0px rgba(0, 0, 0, 0.15));
	padding: $padding-lg;

	@media only screen and (max-width: 1200px) {
		flex-wrap: wrap;
	}

	> * + * {
		margin-left: 0.625rem;

		@media only screen and (max-width: 1200px) {
			margin-left: 0;
			margin-top: 0.625rem;
		}
	}

	@media only screen and (max-width: 600px) {
		.inner-tabs {
			margin-right: 10px;
		}

		.app-c-btn {
			flex: 0 0 34px;

			i {
				margin-right: 0;
			}

			span {
				display: none;
			}
		}
	}

	.report-filter-holder {
		width: 40%;

		@media only screen and (max-width: 1365px) {
			width: 35%;
		}

		@media only screen and (max-width: 1200px) {
			width: 100%;
		}
	}

	.selectWrapper {
		@include flexbox();
		@include justify-content(space-between);
		@include align-items(center);
		width: 100%;

		@media only screen and (max-width: 991px) {
			width: 100%;
			@include justify-content(space-between);
		}

		@media only screen and (max-width: 600px) {
			@include flex-dir(column);
		}

		> * + * {
			margin-left: 0.625rem;

			@media only screen and (max-width: 991px) {
				margin-left: 12px;
			}

			@media only screen and (max-width: 600px) {
				margin-left: 0;
				margin-top: 10px;
			}
		}

		.w-50 {
			position: relative;

			@media only screen and (max-width: 1200px) {
				min-width: 230px;
			}

			@media only screen and (max-width: 600px) {
				min-width: 100%;
				width: 100%;
			}

			.app-c-custom-tooltip {
				opacity: 0;
				top: -36px;
			}

			&:hover {
				.app-c-custom-tooltip {
					opacity: 1;
				}
			}
		}
	}

	.app-c-btn {
		@include flex-shrink(0);
	}

	.dateRangePickerField {
		width: 230px;
		z-index: 8;

		@media only screen and (max-width: 991px) {
			width: calc(50% - 6px);
		}

		@media only screen and (max-width: 640px) {
			width: 100%;
		}
	}
}

.filter-restriction-msg {
	@include flex-grow(1);
	@include flex-shrink(0);
	width: 100%;
	padding-top: 8px;

	p {
		margin: 0;
		font-size: $text-md-plus;
		color: $app-brand-primary;
		line-height: 14px;

		sup {
			font-size: $text-lg;
			position: relative;
			top: 1px;
			margin-right: 2px;
		}
	}
}

.dropdown-select {
	position: relative;

	&.bold {
		button {
			font-weight: $font-weight-semibold;
		}
	}

	button {
		width: 100%;
		background: none;
		border: none;
		padding: 0.37rem 0.75rem 0.37rem 0.75rem;
		color: $text-color;
		font-size: $text-lg;
		text-align: left;
		display: block;
		// font-weight: $font-weight-semibold;
		font-weight: $font-weight-normal;

		&[disabled] {
			background: $form-disabled;
			color: $text-muted;
		}
	}

	> button {
		position: relative;
		border: 1px solid $form-control-border;
		@include border-radius(4px);
		//font-weight: $font-weight-semibold;
		@include flexbox();
		@include align-items(center);
		height: 40px;
		color: $text-color !important;

		&::after {
			content: '\e972';
			pointer-events: none;
			position: absolute;
			top: 0;
			right: 0.5rem;
			bottom: 0;
			margin: auto;
			width: 15px;
			height: 15px;
			@include flexbox();
			@include align-items(center);
			font-size: 10px;
			color: rgba(97, 99, 111, 1);
			font-weight: $font-weight-semibold;
			font-family: 'qualify-tms';
		}

		i {
			width: 28px;
			height: 24px;
			font-size: 16px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.flag-image {
			margin-right: 0.6rem;
			font-size: 0;
			position: relative;
			top: -1px;

			img {
				font-size: 0;
				height: 14px;
				width: 22px;
				min-width: 22px;
				min-height: 14px;
			}
		}
	}

	.dropdown-list {
		position: absolute;
		top: 44px;
		left: 0;
		background: $white;
		@include boxShadow(0px 2px 8px 0px rgba(0, 0, 0, 0.15));
		border: 1px solid $form-control-border;
		@include border-radius(4px);
		max-height: 400px;
		overflow: auto;
		width: 100%;
		z-index: 10;
		opacity: 0;
		pointer-events: none;

		&.set-bottom {
			top: inherit;
			bottom: 44px;
		}

		button {
			@include transition(background ease 0.2s);

			&:hover,
			&.selected {
				background: $base-bg;
			}
		}
	}

	&.open {
		> button {
			&::after {
				content: '\e923';
			}
		}

		.dropdown-list {
			opacity: 1;
			pointer-events: auto;
		}
	}
}

.inner-tabs {
	@include flexbox();
	@include flex-grow(1);
	padding: 0 $padding-base;
	max-width: 100%;
	overflow-x: auto;

	@media only screen and (max-width: 1200px) {
		width: calc(100% - 120px);
	}

	// @media only screen and (max-width: 600px) {
	// 	width: 100%;
	// 	padding: 0;
	// }
	> * + * {
		margin-left: 20px;

		@media only screen and (max-width: 600px) {
			margin-left: 20px;
		}
	}

	.nav-link {
		color: $text-dark-medium;
		font-size: $text-lg;
		font-weight: $font-weight-semibold;
		position: relative;
		background: none;
		border: 0;
		border-radius: 0;
		padding: 10px 0;
		@include transition(width 0.3s ease, background 0.3s ease);
		@include flexbox();
		@include align-items(center);

		@media only screen and (max-width: 480px) {
			font-size: 13px;
		}

		&::before {
			content: '';
			width: 0;
			height: 0px;
			background: $app-brand-primary;
			position: absolute;
			bottom: 1px;
			left: 0px;
			right: 0px;
			margin: auto;
		}

		&.active,
		&:hover {
			color: $text-color;

			&::before {
				height: 2px;
				width: 100%;
				@include transition(width ease 0.3s);
			}
		}

		&:hover {
			&::before {
				width: 90%;
			}
		}
	}
}

.twoColFlex {
	@include border-radius(7px);
	@include flexbox();
	@include justify-content(flex-start);

	@media only screen and (max-width: 1200px) {
		display: block;
	}

	> * + * {
		margin-left: 15px;

		@media only screen and (max-width: 1200px) {
			margin-left: 0;
			margin-top: 15px;
		}
	}
}

.app-c-summary-card {
	background: #f2f3f7;
	padding: $padding-lg $padding-xl;
	@include border-radius(7px);
	width: 40%;

	@media only screen and (max-width: 1200px) {
		width: 100%;
		margin-bottom: 15px;
	}

	@media only screen and (max-width: 600px) {
		padding: $padding-lg $padding-lg;
	}

	h4 {
		margin-bottom: 18px;
		font-size: 22px;
		font-weight: $font-weight-semibold;
		@include flexbox();
		@include justify-content(flex-start);
		@include align-items(center);

		i {
			margin-right: 15px;

			.rtl &,
			[dir='rtl'] & {
				margin-right: 0;
				margin-left: 15px;
			}
		}

		@media only screen and (max-width: 600px) {
			font-size: 18px;
		}
	}

	table {
		tr {
			td {
				line-height: 24px;
				font-size: 15px;
				padding-block: 3px;

				@media only screen and (max-width: 1365px) {
					width: 48%;
				}

				@media only screen and (max-width: 991px) and (min-width: 640px) {
					width: inherit;
				}

				@media only screen and (max-width: 767px) {
					width: 48%;
					font-size: 14px;
				}

				&:first-child {
					font-weight: $font-weight-semibold;
					white-space: nowrap;
					min-width: 55%;

					@media only screen and (max-width: 767px) {
						width: 65%;
					}
				}

				&:nth-child(2) {
					@media only screen and (max-width: 767px) {
						padding-left: 10px;
					}
				}
			}
		}
	}

	.app-c-summary-item {
		display: flex;
		padding: 3px 0px;

		span {
			@include flex-grow(1);
			line-height: 24px;
			font-size: 15px;

			@media only screen and (max-width: 767px) {
				font-size: 13px;
			}

			&.key {
				@include flex-grow(0);
				font-weight: $font-weight-semibold;
				width: 55%;
				@include flex-shrink(0);
			}
		}
	}

	.level2 {
		.app-c-summary-item {
			span {
				&.key {
					width: 150px;
					min-width: 150px;
					max-width: 150px;

					@media only screen and (max-width: 480px) {
						width: 130px;
						min-width: 130px;
						max-width: 130px;
					}
				}
			}

			&.grp-feedback {
				.key {
					width: 180px;
					min-width: 180px;
					max-width: 180px;
				}
			}
		}
	}

	.level3 {
		.app-c-summary-item {
			span {
				&.key {
					width: 100px;
					min-width: 100px;
					max-width: 100px;
				}
			}
		}
	}

	.flex-box {
		display: block;
		width: 100%;

		> * {
			margin-right: 8px;

			@media only screen and (max-width: 1365px) {
				margin-left: 0;
				margin-right: 8px;
			}
		}

		.arrowLink {
			position: relative;
			top: 2px;
		}

		span {
			display: inline-block;
			vertical-align: middle;
		}

		ul {
			display: inline-flex;
			margin-top: 0;
			margin-bottom: 0;
			padding: 0;
			font-size: 0;
			position: relative;

			@media only screen and (max-width: 1365px) {
				margin: 0;
			}

			@media only screen and (max-width: 480px) {
				@include flex-wrap(wrap);
			}

			li {
				width: 100%;
				font-size: $text-lg;
				color: #382f2f;
				white-space: nowrap;
				position: relative;
				margin: 0px 6px;
				padding: 1px 0 0;
				border-bottom: none;

				@media only screen and (max-width: 480px) {
					white-space: normal;
				}

				&::before {
					position: absolute;
					left: -7px;
					top: 6px;
					background: #382f2f;
					height: 11px;
					width: 1px;
					content: '';
					display: block;
				}

				@media only screen and (max-width: 1365px) {
					&:first-child {
						//margin-left: 0;
						// &::before {
						// 	display: none;
						// }
					}
				}
			}
		}
	}
}

.app-c-summary-card-flex {
	@include flexbox();
	@include justify-content(flex-start);

	&.print-layout-summary-card {
		column-gap: 40px;
		max-width: 540px;
	}

	@media only screen and (max-width: 1365px) and (min-width: 1200px) {
		@include flex-dir(column);
	}

	@media only screen and (max-width: 640px) {
		@include flex-dir(column);
	}

	> * + * {
		margin-left: 15px;

		@media only screen and (max-width: 1365px) and (min-width: 1200px) {
			margin-left: 0;
		}

		@media only screen and (max-width: 640px) {
			margin-left: 0;
		}
	}

	.summary-card-col {
		flex: 1;
	}

	.tag-box {
		flex: 0 0 136px;
		@include flex-shrink(0);
		@include flexbox();
		@include flex-dir(column);
		@include align-items(center);
		//@include justify-content(center);
		text-align: center;

		> * + * {
			margin-top: 8px;
		}

		.avatar {
			@include border-radius(50%);
			width: 77px;
			height: 77px;
			background: #c8c8c8;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				@include border-radius(50%);
			}
		}

		.tag-info {
			@include flexbox();
			@include flex-dir(column);
			width: 100%;
		}

		.name {
			font-size: $text-xl;
			font-weight: $font-weight-normal;
			line-height: 24px;
			margin: 0;
			@include truncate();
		}

		.id {
			font-size: $text-sm-plus;
			color: $text-muted;
			line-height: 11px;
		}
	}
}

.app-c-diagrams {
	@include flexbox();
	@include align-items(center);
	@include flex-grow(1);
	background: $white;
	@include boxShadow(0px 2px 8px 0px rgba(0, 0, 0, 0.15));
	padding: $padding-xl;
	@include border-radius(7px);

	@media only screen and (max-width: 600px) {
		padding: $padding-lg $padding-lg;
	}

	&.level3 {
		@media only screen and (max-width: 991px) {
			@include flex-wrap(wrap);

			.progress-group {
				width: 100%;
				padding: 0 0 20px 0;
			}
		}
	}

	.app-c-legend-full-w {
		@include border-radius(7px);
		@include flexbox();
		@include justify-content(flex-start);
		@include align-items(center);

		@media only screen and (max-width: 767px) {
			flex-wrap: wrap;
			@include justify-content(center);
		}

		> * + * {
			margin-left: 1.875rem;

			@media only screen and (max-width: 1365px) {
				margin-left: 1rem;
			}

			@media only screen and (max-width: 767px) {
				margin-left: 0;
			}
		}
	}

	.temp-performance {
		position: relative;

		.center-donut-label {
			height: 160px;

			.donutchart-innertext-value {
				font-size: xx-large;
				font-weight: $font-weight-semibold;
			}
		}
	}

	.progress-group {
		width: 45%;
		padding: 0px 30px 0 38px;

		@media only screen and (max-width: 1600px) {
			padding: 0 78px 0 0;
		}

		@media only screen and (max-width: 1365px) {
			padding: 0 50px 0 0;
		}
	}

	.feedback-rating-wrapper {
		@include flex-grow(1);
		@include flex-dir(column);
		@include flexbox();
		padding-left: 40px;

		@media only screen and (max-width: 1600px) {
			padding-left: 0px;
		}

		> * + * {
			margin-top: 16px;
		}

		.feedback-rating-row {
			@include flexbox();
			@include align-items(flex-start);

			> * + * {
				margin-left: 10px;
			}

			.rating-value {
				flex: 0 0 110px;
				font-size: 40px;
				line-height: 40px;
				font-weight: $font-weight-semibold;
				@include flexbox();
				@include align-items(center);

				@media only screen and (max-width: 660px) {
					font-size: 28px;
					// flex: 0 0 80px;
				}

				> * + * {
					margin-left: 12px;
				}

				i {
					font-size: 28px;
					color: #ffba34;
					margin-right: 5px;

					@media only screen and (max-width: 660px) {
						font-size: 24px;
					}
				}
			}

			.texting {
				h5 {
					font-size: $text-xl;
					line-height: 24px;
					margin: 0;

					@media only screen and (max-width: 660px) {
						font-size: $text-lg;
					}
				}

				span {
					font-size: $text-sm-plus;
					color: $text-muted;
					line-height: 11px;
					@include flexbox();

					> * + * {
						margin-left: 8px;
					}
				}
			}
		}
	}
}

.chart-values-holder {
	@include flexbox();
	@include justify-content(flex-start);
	@include align-items(flex-start);
	//@include flex-wrap(wrap);
	@include flex-grow(1);
	padding-left: 50px;
	margin-top: 20px;
	margin-bottom: 20px;

	@media only screen and (max-width: 1365px) {
		padding-left: 30px;
		flex-wrap: wrap;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	@media only screen and (max-width: 1200px) {
		flex-wrap: nowrap;
	}

	@media only screen and (max-width: 767px) {
		width: 100%;
		margin: 0 !important;
		padding-left: 5px;
	}

	@media only screen and (max-width: 600px) {
		flex-wrap: wrap;
		padding-left: 0px;
		padding-top: 15px;
	}

	> * {
		margin-left: 1rem;
	}

	&.vertical {
		@include flex-dir(column);
		border-right: 1px solid #bbbbbb;
		@include flex-grow(0);
		padding-right: 60px;
		margin-left: 0;
		padding-left: 50px;

		@media only screen and (max-width: 1600px) {
			padding-right: 40px;
		}

		@media only screen and (max-width: 1365px) {
			padding-right: 30px;
		}

		@media only screen and (max-width: 767px) {
			@include flex-dir(row);
			border-right: none;
			padding-left: 0;
			padding-right: 0px;
		}

		li + li {
			padding-top: 25px;

			@media only screen and (max-width: 767px) {
				padding-top: 8px;
			}
		}
	}

	> li {
		@include flex-grow(1);
		border-bottom: none !important;
		position: relative;

		@media only screen and (max-width: 767px) {
			margin: 0 !important;
			text-align: center;
		}

		@media only screen and (max-width: 600px) {
			width: 33.333%;
		}

		&.info-values {
			padding-left: 0;

			.legends-value {
				padding-left: 0;

				&::before {
					display: none;
				}
			}

			@media only screen and (max-width: 767px) {
				padding-right: 0;
			}
		}

		&.info__values {
			display: flex;

			@media only screen and (max-width: 600px) {
				width: 100%;
				justify-content: center;

				.info-value-details {
					text-align: left;
				}
			}

			i {
				margin-top: 10px;
			}
		}

		&.star-rating {
			.legends-value {
				&::before {
					content: '\e971';
					font-family: 'qualify-tms';
					position: absolute;
					top: 8px;
					left: -30px;
					color: $dark-yellow;
					font-size: 24px;
					width: 24px;
					height: 24px;
					line-height: 100%;
					border-radius: 0;
					display: block !important;
				}
			}
		}

		&.completed,
		&.pass-rate {
			.legends-value {
				&::before {
					background-color: $main-primary;
				}
			}
		}

		&.inProgress,
		&.top-score {
			.legends-value {
				&::before {
					background-color: #ffbb28;
				}
			}
		}

		&.overdue,
		&.low-score {
			.legends-value {
				&::before {
					background-color: #e2475e;
				}
			}
		}

		&.notStarted,
		&.score {
			.legends-value {
				&::before {
					background-color: #c8c8c8;
				}
			}
		}

		&.engaged {
			.legends-value {
				&::before {
					background-color: $main-primary;
				}
			}
		}

		&.skipped {
			.legends-value {
				&::before {
					background-color: #c8c8c8;
				}
			}
		}

		.icon-performance-rating {
			font-size: 26px;
			margin-right: 8px;
		}
	}

	.legends-value {
		font-size: 40px;
		font-weight: $font-weight-semibold;
		position: relative;
		line-height: 40px;
		display: inline-block;

		@media only screen and (max-width: 100rem) {
			font-size: 1.875rem;
		}

		@media only screen and (max-width: 85.3125rem) {
			font-size: 1.5rem;
		}

		&::before {
			position: absolute;
			content: '';
			width: 12px;
			height: 12px;
			@include border-radius(50%);
			left: -20px;
			top: 15px;
		}

		.plus-or-minus {
			font-size: 24px;
			display: inline-block;
			vertical-align: middle;
			position: relative;
			top: -4px;
			font-weight: $font-weight-normal;
		}
	}

	.legends-unit {
		font-size: medium;
		position: absolute;
		top: -5px;
		right: -15px;
	}

	.legends-title {
		color: $text-muted;
		font-size: $text-md;
	}
}

.other-info {
	@include flex-grow(1);
	padding-left: 0;
	margin-left: 0 !important;

	@media only screen and (max-width: 768px) {
		padding-left: 0rem;
	}

	.chart-values-holder {
		padding-left: 64px;

		@media only screen and (max-width: 768px) {
			padding-left: 0;
		}

		li {
			margin-left: 0;
		}
	}
}

.progressBarHolder {
	.app-c-progress-bar-wrapper + .app-c-progress-bar-wrapper {
		margin-top: 10px;
	}
}

.resultSummaryWrapper {
	@include flexbox();
	width: 100%;

	.app-c-progress-bar-wrapper {
		margin-bottom: 0 !important;
	}

	& + .resultSummaryWrapper {
		margin-top: 10px;
	}

	@media only screen and (max-width: 767px) {
		display: block;
	}

	@media only screen and (max-width: 1600px) {
		> * + * {
			margin-left: 0 !important;
		}
	}

	> * + * {
		margin-left: 1.875rem;
	}

	.progressBarHolder {
		@include flexbox();
		@include flex-dir(column);
		@include justify-content(center);
		width: 160px;

		@media only screen and (max-width: 767px) {
			width: 100%;
		}

		&.dir-row {
			@include flex-dir(row);
			@include align-items(center);
			@include justify-content(flex-start);
			width: 100%;

			.app-c-progress-bar-wrapper {
				@include flex-grow(1);
			}
		}

		> * + * {
			margin-top: 0;
		}

		.progress-label {
			flex: 0 0 72px;
			text-align: right;
			font-size: $text-lg;
			font-weight: $font-weight-semibold;
			margin-right: 16px;
		}

		.progress {
			&.dark {
				.progress-bar {
					background-color: $main-primary;
				}
			}

			background-color: #e9e9e9;
			@include border-radius(50px);

			&.pass-rate {
				.progress-bar {
					background-color: $main-primary;
				}
			}

			&.top-score {
				.progress-bar {
					background-color: #ffbb28;
				}
			}

			&.low-score {
				.progress-bar {
					background-color: #e3475e;
				}
			}

			&.score {
				.progress-bar {
					background-color: #a7abc4;
				}
			}
		}

		.progress-bar {
			@include border-radius(50px);
		}
	}
}

.feedback-head {
	background: #f2f3f7;
	@include border-radius(5px);
	padding: 8px;

	h4 {
		color: #747891;
		text-transform: uppercase;
		font-size: 12px;
		margin: 0;
	}
}

.feedback-stack {
	.feedback-item-row {
		@include flexbox();
		border-top: 1px solid $generic-border-color;
		padding: 16px 0px;

		&:first-child {
			border-top: none;
		}

		@media only screen and (max-width: 600px) {
			display: block;
		}
	}

	.feedback-tag-wrapper {
		width: 183px;
		@include flex-shrink(0);
		@include flexbox();
		@include flex-dir(column);

		> * + * {
			margin-left: 0;
		}

		.tag-box {
			@include flexbox();
			@include align-items(center);

			> * + * {
				margin-left: 8px;
			}

			.avatar {
				width: 40px;
				height: 40px;
				flex: 0 0 40px;
				background: #ccc;
				@include border-radius(50%);

				img {
					@include border-radius(50%);
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}

			.tag-info {
				.name {
					font-size: $text-xl;
					font-weight: $font-weight-semibold;
					line-height: 24px;
				}

				.date {
					font-size: $text-sm-plus;
					color: $text-muted;
					line-height: 11px;
					@include flexbox();

					> * + * {
						margin-left: 8px;
					}
				}
			}
		}

		.rating-box {
			@include flexbox();
			@include align-items(flex-start);
			margin-top: 16px;

			> * + * {
				margin-left: 10px;
			}
		}

		.master-rating {
			width: 40px;
			text-align: center;
			font-size: 22px;
			font-weight: $font-weight-semibold;
			@include flex-shrink(0);
		}

		.breakdowns {
			@include flexbox();
			@include flex-dir(column);
			@include align-items(flex-start);
			margin-top: 8px;

			> * + * {
				margin-top: 8px;
			}

			.sub-rating {
				@include flexbox();
				font-size: $text-sm-plus;
				color: $text-muted;
				line-height: 11px;
				color: $text-color;

				.key {
					font-weight: $font-weight-semibold;
					display: block;
					width: 30px;
				}
			}
		}
	}

	.feedback-content {
		padding-top: 56px;
		font-size: $text-xl;

		@media only screen and (max-width: 600px) {
			padding-top: 15px;
		}
	}

	.no-feedback {
		color: #a7abc4;
		font-style: italic;
		font-size: 16px;
	}
}

.table-action-button {
	.tag-box {
		@include flexbox();
		@include align-items(center);

		> * + * {
			margin-left: 10px;
		}

		.avatar {
			width: 32px;
			height: 32px;
			background: #ccc;
			@include border-radius(50%);

			img {
				@include border-radius(50%);
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		.tag-info {
			.table-row-title {
				font-size: $text-lg;
				line-height: 20px;
			}

			.table-sub-text {
				font-size: $text-sm-plus;
				color: $text-muted;
				line-height: 11px;
			}
		}
	}
}

.app-c-scroll-table {
	.set-custom-width {
		max-width: 130px;
		min-width: 80px;

		.app-l-table-head {
			min-width: inherit;
		}
	}

	.set-custom-width2 {
		max-width: 130px;
		min-width: 120px;

		@media only screen and (max-width: 1600px) {
			max-width: 115px;
			min-width: 115px;
		}

		.app-l-table-head {
			min-width: inherit;
		}
	}
}

.app-c-confirm-modal {
	&.setMaxWidth {
		.modal-content {
			width: min(350px, 400px);
		}
	}
}

.option-btn {
	border: none;
	padding: 0;
	@include border-radius(4px !important);
}

.empty-column {
	width: auto !important;

	.table-action-button {
		.option-col-wrapper {
			width: 25px;
			@include flexbox();
			@include justify-content(flex-end);
			width: 25px;
			@include align-items(center);
		}
	}
}

.optionsDropdownMenu {
	position: relative;

	.option-btn {
		width: 24px !important;
		background: none;

		&:hover {
			background: $white;
		}
	}

	ul {
		position: absolute;
		top: 14px;
		right: 0;
		background: $white;
		@include boxShadow(0px 2px 8px 0px rgba(0, 0, 0, 0.15));
		border: 1px solid $form-control-border;
		@include border-radius(4px);
		width: 196px;
		z-index: 2;
		display: none;
		pointer-events: none;

		&.open {
			z-index: 4;
			display: block;
			pointer-events: auto;
		}

		li {
			display: block;
			border-bottom: none;
			width: 100%;

			button {
				width: 100% !important;
				background: none;
				border: none;
				font-size: $text-lg;
				color: $text-color;
				padding: 0.37rem 0.75rem;
				color: $text-muted;
				font-size: $text-lg;
				text-align: left;
				display: flex;
				@include flexbox();
				@include justify-content(flex-start);
				@include align-items(center);

				> * + * {
					margin-left: 8px;
				}

				i {
					margin-right: 8px;

					&.icon-times {
						font-size: 12px;
					}
				}
			}
		}
	}
}

.app-c-custom-textarea {
	border: 1px solid $form-control-border;
	@include border-radius(7px);
	padding: 8px 12px;
	resize: vertical;
	color: $text-muted;
	font-size: $text-lg;
	width: 100%;
}

.set-right {
	@include flexbox();
	@include justify-content(flex-end);
}

// PREVIEW PAGE START
.app-c-preview-page {
	@include flexbox();
	@include flex-dir(column);
	-webkit-flex: 1 1 1px;
	-moz-box-flex: 1;
	flex: 1 1 1px;
	overflow-y: auto;
	min-height: 0;
	height: 100%;
	background: $neutrals-color4;
}

.inner-scroll-hide {
	overflow: visible !important;
	min-height: auto !important;
	background: white;

	.app-c-preview-body,
	.app-c-preview-content,
	.app-c-preview-content .tab-content > .active,
	.report-assessment-preview {
		flex: auto !important;
	}

	.app-c-preview-body {
		overflow: visible !important;
		height: auto !important;
		min-height: auto !important;
		flex: 1 !important;
	}

	.app-c-preview-header {
		padding: 0;

		.app-c-preview-summary,
		.app-c-tab {
			padding: 0 40px;
		}

		.app-c-preview-title-bar {
			padding: 16px 40px;
			position: fixed;
			width: 100%;
			background: white;
			z-index: 9999;
		}

		.app-c-preview-summary {
			margin-top: 90px;
		}
	}

	.app-c-preview-content {
		overflow: visible !important;
		flex: auto !important;
		height: auto !important;
		min-height: auto !important;
		display: inline-block !important;
		padding-bottom: 0 !important;
		padding-left: 0;

		@media only screen and (max-width: 768px) {
			padding-left: 28px;
		}
	}

	.app-c-preview-summary {
		margin-top: 40px !important;
		padding-top: 10px !important;
		margin-top: 0 !important;
		margin-bottom: 12px !important;
	}
}

.app-c-preview-header {
	background: $white;
	padding: 0px 40px;
	@include boxShadow(0px 4px 8px 0px rgba(56, 47, 47, 0.15));
	position: relative;
	z-index: 2;

	@media only screen and (max-width: 768px) {
		padding: 0 16px;
	}

	.app-c-tab {
		height: 38px;

		> div {
			padding-bottom: 0px;
		}

		.nav {
			.nav-item {
				@media only screen and (max-width: 991px) {
					margin-right: 10px;
				}

				@media only screen and (max-width: 768px) {
					margin-right: 0px;
				}

				.nav-link {
					margin-bottom: 0;
					text-decoration: none;
				}
			}
		}
	}
}

.app-c-preview-title-bar {
	position: relative;
	text-align: center;
	padding: 16px 0px;
	@include flexbox();
	@include justify-content(space-between);
	@include align-items(center);
	border-bottom: 3px solid #f2f3f7;

	@media only screen and (max-width: 1365px) {
		padding: 6px 0;
	}

	.close-btn {
		font-weight: normal;
		font-size: 16px;
	}

	h3 {
		margin: 0;
		font-size: 22px;
	}

	.button-wrapper {
		@include flexbox();
		@include justify-content(flex-end);
		@include align-items(center);
	}
}

.app-c-preview-summary {
	margin-top: 16px;
	margin-bottom: 16px;
	@include border-radius(7px);
	@include flexbox();
	@include justify-content(flex-start);

	@media only screen and (max-width: 1365px) {
		margin-top: 5px;
		margin-bottom: 10px;
	}

	@media only screen and (max-width: 991px) {
		padding: 0px 0px;
		@include flex-wrap(wrap);
	}

	overflow: hidden;

	> * {
		@media only screen and (max-width: 991px) {
			margin-top: 2px;
			margin: 1px !important;
		}
	}

	> * + * {
		margin-left: 2px;

		@media only screen and (max-width: 991px) {
			margin: 1px !important;
		}
	}

	.preview-summary-col {
		background: $aquaHaze;
		padding: 20px 30px;
		@include flexbox();
		@include flex-dir(column);
		@include justify-content(center);

		&.grow {
			@include flex-grow(1);
		}

		@media only screen and (max-width: 1365px) {
			padding: 6px 15px;
		}

		@media only screen and (max-width: 991px) {
			padding: 2px 10px;
			flex: 1 1 48%;
			min-height: 52px;
		}

		&:nth-child(2) {
			max-width: 300px;
		}
	}
}

.app-c-preview-body {
	padding: 0 40px 20px;
	@extend %get-full-height;
	overflow-y: auto;

	@media only screen and (max-width: 768px) {
		padding: 0 0;
	}
}

.app-c-preview-content {
	padding: 16px 28px;
	@extend %get-full-height;
	overflow-y: auto;
	background: $white;

	.tab-content,
	// .tab-pane,
	.app-c-preview-comment-flex,
	.app-c-preview-comments {
		@include flexbox();
		@include flex-dir(column);
		-webkit-flex: 1 1;
		-moz-box-flex: 1;
		flex: 1 1;
		min-height: 0;
		height: 100%;
	}

	.tab-content > .active {
		@extend %get-full-height;
	}
}

.app-c-preview-comments-row {
	@include flexbox();
	@include flex-dir(column);
	padding: 16px 16px 0px 16px;
	border-bottom: 1px solid $aquaHaze;

	@media only screen and (max-width: 768px) {
		padding: 10px 0 0px 0;
	}

	.comment-text-block {
		padding: 12px 0px 0px 50px;

		@media only screen and (max-width: 768px) {
			padding: 12px 0px 0px 0px;
		}

		p {
			font-size: $text-xl-plus;

			@media only screen and (max-width: 1600px) {
				font-size: $text-xl;
			}

			@media only screen and (max-width: 1365px) {
				font-size: $text-lg;
			}
		}
	}
}

.app-c-preview-comments {
	overflow: auto;
}

.app-c-preview-write-comment {
	margin-top: 10px;
	position: relative;
	@include flex-shrink(0);

	.comment-text-wrapper {
		border: 1px solid $spunPearl;
		@include border-radius(7px);
		position: relative;

		textarea {
			border: none;
			min-height: 80px;
			resize: vertical;
			@include border-radius(7px);
			padding: 16px 72px 16px 20px;

			@media only screen and (max-width: 991px) {
				padding: 10px 72px 10px 16px;
				line-height: 20px;
				font-size: $text-lg !important;
			}
		}
	}
}

.btn-sent-msg {
	@include flexbox();
	@include justify-content(center);
	@include align-items(center);
	width: 48px;
	height: 48px;
	background: $app-brand-primary;
	position: absolute;
	margin: auto;
	top: 0;
	right: 12px;
	bottom: 0;
	@include transition(background ease 0.2s);
	color: $white;
	font-size: 18px;
	@include border-radius(50%);
	padding-left: 10px;
	border: none;

	i {
		color: $white;
	}

	&:hover {
		background: $app-brand-primary-dark;
	}
}

// Common styles do not edit Strat
.key-value-pair {
	table {
		tr {
			td {
				line-height: 24px;
				font-size: 16px;
				padding-block: 2px;

				@media only screen and (max-width: 1365px) {
					font-size: 14px;
				}

				@media only screen and (max-width: 991px) {
					line-height: 16px;
				}

				&:first-child {
					font-weight: $font-weight-semibold;
					padding-right: 16px;
				}
			}
		}
	}
}

// Common styles do not edit End
.app-c-user-tag {
	@include flexbox();
	@include justify-content(flex-start);
	@include align-items(center);

	> * + * {
		margin-left: 8px;
	}

	.user-avatar {
		@include border-radius(50%);
		background: #ccc;
		position: relative;
		@include flex-shrink(0);

		&.lg {
			width: 64px;
			height: 64px;

			@media only screen and (max-width: 1365px) {
				width: 40px;
				height: 40px;
			}
		}

		&.md {
			width: 40px;
			height: 40px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			@include border-radius(50%);
		}
	}

	.user-info-box {
		@include flexbox();
		@include flex-dir(column);
		@include justify-content(center);
		@include align-items(flex-start);

		.main-text {
			line-height: 24px;
			font-size: 16px;
			font-weight: $font-weight-semibold;

			@media only screen and (max-width: 1365px) {
				font-size: 14px;
				line-height: 18px;
			}
		}

		.sub-text-wrap {
			line-height: normal;
		}

		.sub-text {
			line-height: 12px;
			font-size: 11px;
			color: #747891;
			display: inline-block;
			vertical-align: middle;
			margin-right: 8px;
		}
	}
}

.app-c-flex-full {
	@include flexbox();
	flex: 1 1;
	@include flex-dir(column);
	min-height: auto;
	height: 100%;
	// .assessment-details {
	// 	height: 100%;
	// 	overflow-y: auto;
	// }
}

// PREVIEW PAGE START END
.share-report-modal {
	text-align: left;

	.modal-content {
		padding-top: 15px;
		text-align: left;

		.modal-body {
			overflow: visible;
		}
	}

	.right-align {
		text-align: right;
	}

	.app-common-radio-btns {
		padding: 10px 0px;

		&.flex {
			@include flexbox();
			@include align-items(center);
		}
	}

	.sub-label {
		font-size: $text-sm-plus;
		margin-left: 20px;
		position: relative;
		top: 2px;
		color: $text-light;
	}
}

.common-user-search {
	position: relative;

	&__wrapper {
		border: 1px solid $form-control-border;
		@include border-radius(6px);
		padding-left: 48px;
		position: relative;

		&::before {
			font-family: 'qualify-tms';
			content: '\e909';
			left: 17px;
			top: 8px;
			position: absolute;
			color: $text-light;
			font-size: 16px;
		}

		button {
			right: 12px;
			top: 0;
			bottom: 0;
			margin: auto;
			position: absolute;
			color: $text-light;
			font-size: 10px;
			border: none;
			background: none;
			padding: 0;
			line-height: 100%;
			width: 12px;
			height: 12px;
		}
	}

	.form-control {
		border: none;
		padding: 0.37rem 0.75rem 0.37rem 0;
		color: $text-light;
		font-size: $text-lg;
		background: none;

		&:focus {
			outline: none;
			box-shadow: none;
		}

		&::-webkit-input-placeholder {
			/* Edge */
			color: $text-light !important;
		}

		&:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			color: $text-light !important;
		}

		&::placeholder {
			color: $text-light !important;
		}
	}

	i {
		width: 28px;
		height: 24px;
		font-size: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.common-user-search-result {
		position: absolute;
		top: 44px;
		left: 0;
		background: $white;
		@include boxShadow(0px 9px 21px 0px rgba(0, 0, 0, 0.15));
		//border: 1px solid $form-control-border;
		@include border-radius(4px);
		overflow: auto;
		width: 100%;
		z-index: 10;
		opacity: 0;
		max-height: 207px;
		pointer-events: none;

		&.open {
			pointer-events: auto;
			opacity: 1;
		}

		ul {
			li {
				padding: 13px 16px;
				@include transition(background ease 0.2s);

				&:hover,
				&.selected {
					background: #f2f3f7;
				}
			}
		}

		.app-c-checkbox {
			display: block;

			.form-label {
				@include flexbox();
				@include justify-content(space-between);
				@include align-items(center);
				width: 100%;
			}

			.app-c-checkbox-icon {
				align-self: center;
				top: 0;
			}
		}

		.user-result-flex {
			width: 100%;
			@include flexbox();
			@include justify-content(space-between);
			@include align-items(center);
			padding-left: 6px;

			.id {
				font-size: $text-sm-plus;
				color: $text-color-light;
			}
		}
	}

	&.open {
		> button {
			&::after {
				content: '\e923';
			}
		}

		.dropdown-list {
			opacity: 1;
			pointer-events: auto;
		}
	}
}

.app-c-student-assessment-details {
	padding-bottom: 20px;
	height: auto;

	.tab-content {
		min-height: auto;
		height: auto;

		.active {
			min-height: auto !important;
			height: auto !important;
		}
	}
}

.app-c-report-table {
	table thead tr th:last-child {
		max-width: 150px;
	}

	.set-custom-width {
		button {
			white-space: nowrap;
		}
	}
}

.viewContentFeedback {
	margin-bottom: 21px;
	cursor: pointer;
}

.page-scroll {
	.app-l-screen-header {
		position: fixed;
		width: calc(100vw - 84px);
	}

	.app-l-vertical-content-page {
		padding: 70px 0 0 !important;
		flex-wrap: wrap;

		.app-c-table {
			padding: 30px 40px;
		}
	}
}

.share-report-holder {
	display: flex;
	flex-direction: column;

	.app-common-radio-btns {
		.form-label {
			float: left;

			&:after {
				width: 24px;
				height: 24px;
				left: 0;
				bottom: 0;
				border: 5px solid var(--theme-primary-color);
			}
		}
	}

	ul {
		.noUser {
			text-align: center;
			font-weight: 500;
			padding: 10px 7px;
			float: left;
			width: 100%;
		}

		.app-c-checkbox-icon::before {
			text-align: center;
		}
	}

	.right-align {
		text-align: center;
		margin-top: 24px;
	}

	.common-user-search .common-user-search-result {
		box-shadow: none;
		background: transparent;

		&.open {
			background: #fff;
			box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.16);
			border-radius: 6px;
			border: 1px solid rgba(0, 0, 0, 0.06);

			ul {
				margin-bottom: 0;
			}
		}
	}
}

.app-c-topic__data {
	button[disabled]:before {
		opacity: 0.6;
	}

	.icon-round_close {
		position: relative;

		.app-c-custom-tooltip {
			top: -32px;
			left: -4px;
			opacity: 0;
		}
	}

	&:hover {
		.icon-round_close {
			.app-c-custom-tooltip {
				opacity: 1;
			}
		}
	}
}

.app-c-common__tabs {
	.set-custom-width {
		max-width: max-content !important;
	}
}

.pagePDFView {
	.app-l-base {
		padding-left: 0;
	}

	.app-l-vertical-content-page {
		padding-top: 0 !important;
	}
}

.no-shadow {
	div {
		box-shadow: none;
	}

	.app-c-diagrams,
	.app-c-common-tabs {
		border: 1px solid rgba(0, 0, 0, 0.05);
	}
}

.app-c-summary__card {
	table tr td:first-child {
		min-width: 120px;
	}
}

.app-c-summary__card {
	table tr td:first-child {
		min-width: 120px;
	}
}

.inner-scroll-hide {
	display: block !important;

	.text-preview-mode {
		text-align: center;
		display: block !important;

		div {
			display: block !important;
			text-align: center;
			page-break-before: always;
		}
	}

	tbody {
		&.report-preview-slider-common {
			// display: unset !important;
			break-inside: avoid;

			.report-preview-header {
				break-inside: avoid;

				td {
					padding-top: 20px;
				}
			}
		}

		&.report-preview-slider-common .report-preview-slide-content {
			.app-l-upload-editor-blk {
				&.media-only:not(.-question-bank-preview),
				&.not-empty-set {
					.app-c-upload-media-blk {
						background-color: transparent !important;

						.app-c-upload-items_blk,
						.app-c-upload-media-blk {
							background-color: transparent !important;
							position: relative;

							.app-c-upload-items_blk {
								background-color: transparent !important;

								img {
									background-color: transparent !important;
								}
							}

							img {
								background-color: transparent;
							}
						}
					}
				}

				.app-l-upload-editor-blk__left {
					// padding-top: 0;
					// margin-bottom: 30px !important;

					.app-c-upload-media-blk {
						.app-c-slider-container-inner {
							background: #f2f3f7;
							border-radius: 4px;
						}
					}
				}
			}
		}

		&.slider-counter {
			td {
				break-inside: avoid;
				padding: 16px;
			}
		}

		.slide__count {
			box-shadow: none;
			border: 1px solid #050505;
			margin: 20px 0;
		}

		table {
			width: 100%;
		}
	}

	.report-preview-slider-common {
		// page-break-after: always;
		// page-break-before: always;
		// display: block !important;
	}

	.app-l-upload-editor-blk.-questionSlider {
		display: block !important;
	}

	.app-l-upload-editor-blk.media-only .app-l-upload-editor-blk__left {
		float: left;
		display: block;
		width: 60% !important;
		margin: 0 !important;
		padding: 0 !important;

		@media print {
			~ .app-l-upload-editor-blk__right.text-preview-mode {
				padding: 40px 0 !important;
			}
		}
	}

	.app-l-upload-editor-blk.media-only.media-center .app-l-upload-editor-blk__left {
		float: none;
		display: block;
		width: 100% !important;
		margin: 0 !important;
		padding: 0 !important;

		.app-c-upload-media-wrap {
			position: relative;

			.app-c-upload-media-blk {
				img {
					height: 300px !important;
				}
			}
		}
	}

	.app-l-upload-editor-blk .app-l-upload-editor-blk__right {
		float: right !important;
		display: block !important;
		width: 40% !important;
		margin: 0 !important;
		padding: 0 !important;

		@media print {
			padding: 10px !important;
		}

		.score-card {
			display: block;
			margin: auto !important;
		}
	}

	.app-l-upload-editor-blk .app-l-upload-editor-blk__left .app-c-upload-items_blk.not-empty,
	.app-l-upload-editor-blk .app-l-upload-editor-blk__left .app-c-upload-media-blk {
		max-width: 100%;

		img {
			height: 300px !important;
		}

		.app-c-video-only {
			&:after {
				display: none;
			}
		}
	}

	.videoDefault {
		text-align: center;
		background: whitesmoke;

		img {
			height: 300px !important;
		}
	}

	.score-card {
		display: block !important;
		float: right;
		width: 268px !important;
		margin-top: 100px !important;

		div {
			width: 100% !important;
			text-align: center !important;
			display: block !important;
		}

		.score-wrapper {
			display: flex !important;
			flex-direction: row !important;
			align-items: center !important;

			.score-wrapper {
				display: flex !important;
				flex-direction: column !important;
				justify-content: center !important;
			}
		}
	}

	#reportsTab-tabpane-fourth {
		page-break-inside: avoid;
		page-break-after: always;
		page-break-before: always;
		display: block !important;
		break-inside: avoid-page;

		.tab-content > .active {
			page-break-inside: avoid;
			page-break-after: always;
			page-break-before: always;
			display: block !important;
			break-inside: avoid-page;
		}
	}

	.app-video-icon {
		&:before {
			content: '';
			position: absolute;
			display: block;
			z-index: 1;
			width: 100%;
			height: 100%;
			background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='476' height='476' viewBox='0 0 476 476' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='238' cy='238' r='238' fill='black'/%3e%3cpath d='M348.635 216.165L227.772 128.269C219.385 122.169 208.29 121.297 199.052 126.011C189.817 130.724 184 140.227 184 150.607V326.398C184 336.779 189.816 346.279 199.052 350.99C203.004 353.006 207.292 354 211.57 354C217.285 354 222.973 352.223 227.772 348.731L348.635 260.836C355.777 255.645 360 247.341 360 238.5C360 229.662 355.777 221.355 348.635 216.165Z' fill='white'/%3e%3c/svg%3e ");
			background-position: center;
			background-repeat: no-repeat;
			top: 0;
			background-size: 50px;
			opacity: 0.8;
		}
	}
}

.pagePDFView {
	.twoColFlex {
		display: block !important;

		.app-c-summary-card {
			width: 100%;
			margin-bottom: 10px;
		}

		.app-c-diagrams {
			margin-left: 0 !important;
		}
	}
}

@media print {
	.page-break {
		page-break-before: always;
		position: relative;
		display: block;
		width: 100%;
	}
}
