.app-c-card__body {
	&.listing-search {
		padding: 16px;
		background: #fff;
		.app-c-search-wrapper__box {
			padding: 0;
		}
	}
}
.react-datepicker__day--outside-month {
	color: grey !important;
	pointer-events: none;
}

.app-c-filter-wrapper {
	@include flexbox();
	@include flex-direction(row);
	@include align-items(center);
	@include justify-content(space-between);
	@media screen and (max-width: 767px) {
		flex-wrap: wrap;
	}
	.app-c-input-set {
		margin-bottom: 0;
		@media screen and (max-width: 767px) {
			flex: 1 0;
			flex-basis: 100%;
		}
	}
}
.date-between {
	@include flexbox();
	@include flex-direction(row);
	@include align-items(center);
	&.inline {
		// max-width: 388px;
	}
	&.m-flex-col {
		@media (max-width: 600px) {
			@include flex-direction(column);
			.app-c-date-sep {
				display: none;
			}
			.react-datepicker-wrapper {
				width: 100%;
				margin-top: 15px;
			}
		}
	}
}
.app-c-date-sep {
	position: relative;
	background: rgba(0, 0, 0, 1);
	width: 12px;
	height: 1px;
	display: inline-block;
	margin: 0px 12px;
}

.app-c-input-set {
	&.width1 {
		max-width: 333px;
		width: 100%;
		@media screen and (max-width: 991px) {
			max-width: 100%;
		}
	}
	&.width2 {
		max-width: 233px;
		width: 100%;
	}
}
.app-c-form-checkbox {
	padding-top: 33px;
	margin-bottom: 25px;
}
.app-c-create-course {
	@include flexbox();
	@include flex-direction(row);
	@include justify-content(space-between);
	@media screen and (max-width: 991px) {
		flex-wrap: wrap;
	}
	.app-c-create-course__form {
		flex: 1;
		padding-left: 24px;
		&.course-creation {
			display: flex;
			flex-direction: column;
			height: inherit;
			@media (max-width: 767px) {
				display: block;
				height: auto;
				margin-bottom: 0;
			}
			.row.h-100 {
				@media (max-width: 767px) {
					height: auto !important;
				}
			}
			.app-c-input-set {
				@media (max-width: 767px) {
					display: flex;
					flex-direction: column;
					word-break: normal;
					.form-group {
						margin: 0;
					}
				}
				@media (max-width: 767px) {
					display: block;
				}
				&.adj-pb-9 {
					padding-bottom: 9px;
					height: 100%;
					@media (max-width: 767px) {
						height: auto;
						padding-bottom: 0;
					}
				}
				.form-group {
					height: 100%;
					@media (max-width: 767px) {
						display: block;
						height: auto;
					}
					.text-area-1 {
						resize: vertical;
						height: 100%;
						@media (max-width: 767px) {
							display: block;
							min-height: 120px;
						}
					}
				}
			}
		}
		@media screen and (max-width: 991px) {
			flex: 1 0;
			flex-basis: 100%;
			margin-bottom: 20px;
			padding-right: 0px;
			padding-left: 0;
			padding-top: 24px;
		}
		.rtl &,
		[dir='rtl'] & {
			text-align: right;
			padding-left: 24px;
			padding-right: 0;
			@media screen and (max-width: 991px) {
				padding-left: 0px;
			}
		}
	}
	.app-c-drag-drop {
		min-width: 330px;
		// @media screen and (max-width: 480px) {
		// 	min-width: 100%;
		// 	max-width: 260px;
		// }
		@media screen and (max-width: 575px) {
			min-width: 100%;
			// max-width: 230px;
		}
	}
	.app-c-drag-drop__box {
		height: 238px;
		@include flexbox();
		@include flex-direction(column);
		@include justify-content(center);

		@media screen and (max-width: 480px) {
			height: 180px;
		}
		@media screen and (max-width: 400px) {
			height: 160px;
		}

		.app-c-drag-drop__icon {
			margin: inherit;
			margin-bottom: 10px;
		}
	}
}
.app-c-add-new-trigger {
	@include flexbox();
	@include flex-direction(row);
	@include justify-content(flex-start);
	@include align-items(center);
	@media screen and (max-width: 767px) {
		flex-wrap: wrap;
	}
}
.app-c-add-new-trigger_btn {
	color: $text-color;
	font-size: 14px;
	line-height: 18.2px;
	padding: 9px 12px;
	background: none;
	border: 1px solid rgba(190, 193, 210, 1);
	@include border-radius(6px);
	font-weight: $font-weight-bold;
	margin-right: 16px;
	@include transition(all ease 0.2s);
	margin-bottom: 5px;
	.rtl &,
	[dir='rtl'] & {
		margin-right: 0px;
		margin-left: 16px;
	}
	i {
		color: rgba(126, 131, 141, 1);
	}
	&:hover {
		background: #e9e7e7;
	}
}
.app-c-add-new-trigger_close {
	color: #7e838d;
	font-size: 12px;
	line-height: 18.2px;
	padding: 9px 0px;
	background: none;
	border: none;
	@include border-radius(6px);
	font-weight: $font-weight-bold;
	@include transition(all ease 0.2s);
	&:hover {
		color: $app-brand-primary;
	}
}

.app-c-chapters-list {
	.app-c-chapters-list__item {
		display: block;
		line-height: 29px;
		font-size: $text-lg;
		font-weight: $font-weight-bold;
		color: $text-color;
		padding: 9px 0px;
		text-decoration: none;
	}
}
.app-c-option-drop {
	position: absolute;
	min-width: 262px;
	top: 36px;
	right: 12px;
	background: #fff;
	z-index: 3;
	padding: 8px 0px;
	@include border-radius(3px);
	@include boxShadow(0px 2px 8px 0px rgba(0, 0, 0, 0.1352));
	opacity: 0;
	@include transition(all ease 0.2s);
	pointer-events: none;
	&.active {
		opacity: 1;
		top: 30px;
		pointer-events: auto;
	}
	ul {
		padding: 0;
		margin: 0;
		border-top: 1px solid rgba(233, 235, 244, 1);
		margin-top: 4px;
		list-style: none;
		&:first-child {
			border-top: none;
			padding-top: 0;
		}
		li {
			padding: 8px 16px;
		}
	}
	.app-c-checkbox-icon {
		top: 1px;
	}
	.form-label {
		color: $text-color;
	}
}

.app-l-italic-text {
	line-height: 14.3px;
	font-size: $text-sm-plus;
	font-style: italic;
	color: $text-color-light;
}

.app-c-status-box {
	display: inline-block;
	position: relative;
	border-radius: 4px;
	padding: 5px 8px 5px 24px;
	line-height: 16px;
	font-weight: $font-weight-semibold;
	font-size: $text-md;
	margin-right: 8px;
	&::before {
		position: absolute;
		left: 8px;
		display: block;
		width: 8px;
		height: 8px;
		margin: auto;
		top: 0;
		bottom: 0;
		content: '';
		@include border-radius(50%);
	}
	.rtl &,
	[dir='rtl'] & {
		margin-right: 0px;
		margin-left: 8px;
	}
	&.in-progress {
		background: $inProgressBg;
		color: $inProgressColor;
		border: 1px solid $inProgressColor;
		&::before {
			background: $inProgressColor;
		}
	}
	&.scheduled {
		background: $scheduledBg;
		color: $scheduledColor;
		border: 1px solid $scheduledColor;
		&::before {
			background: $scheduledColor;
		}
	}
	&.draft {
		background: $draftBg;
		color: $draftColor;
		border: 1px solid $draftColor;
		&::before {
			background: $draftColor;
		}
	}
	&.archieved {
		background: $archievedBg;
		color: $archievedColor;
		border: 1px solid $archievedColor;
		&::before {
			background: $archievedColor;
		}
	}
	&.scheduled {
		background: $scheduledBg;
		color: $scheduledColor;
		border: 1px solid $scheduledColor;
		&::before {
			background: $scheduledColor;
		}
	}
	&.completed {
		background: $completedBg;
		color: $completedColor;
		border: 1px solid $completedColor;
		&::before {
			background: $completedColor;
		}
	}
}

.app-c-change-status {
	display: inline-block;
	position: relative;
	z-index: 2;

	.app-c-change-status__btn {
		all: unset;
		display: inline-block;
		position: relative;
		border-radius: 4px;
		padding: 5px 8px 5px 8px !important;
		height: auto !important;
		line-height: 16px;
		font-weight: $font-weight-semibold;
		font-size: $text-md;
		margin-right: 8px;
		text-decoration: none !important;
		&:active,
		&:focus,
		&:hover {
			outline: none;
			@include noShadow();
		}

		&.overdue {
			background: $overDueBg;
			color: $overDueColor;
			border: 1px solid $overDueColor;
		}
		&.in-progress {
			background: $inProgressBg;
			color: $inProgressColor;
			border: 1px solid $inProgressColor;
		}
		i {
			font-size: 8px;
			margin-left: 10px;
		}
	}
	.app-c-change-status__list {
		background: $white;
		@include border-radius(4px);
		position: absolute;
		top: 42px;
		left: 0;
		width: 178px;
		padding: 4px 0px;
		@include boxShadow(0px 9px 21px 0px #00000022);
		opacity: 0;
		@include transition(all ease 0.2s);
		pointer-events: none;
		&.active {
			opacity: 1;
			top: 32px;
			pointer-events: auto;
		}
		.app-c-change-status__item {
			text-decoration: none;
			padding: 8px 12px;
			line-height: 16px;
			color: $text-color-light;
			background: #e9ebf480;
			width: 100%;
			text-align: left;
			display: block;
			font-size: $text-lg;
			&:hover {
				color: $text-color;
			}
			&:active,
			&:focus,
			&:hover {
				outline: none;
				@include noShadow();
			}
		}
	}
}
.check-box-list {
	li {
		.form-label {
			&.wrap-no-mob {
				@media screen and (max-width: 767px) {
					flex-wrap: wrap;
				}
			}
		}
		&.inactive {
			padding-right: 50px;
			position: relative;
			.app-c-tiny-status {
				border: none;
				background: none;
				position: absolute;
				right: 0;
				top: 20px;
				height: 10px;
				padding: 0;
				@media screen and (max-width: 767px) {
					top: 16px;
				}
			}
			.check-box-list__name,
			.check-box-list__tags {
				color: $text-disabled;
			}
		}
	}
}
.check-box-list__name {
	flex-grow: 1;
	display: inline-block;
}
.check-box-list__tags {
	@media screen and (max-width: 767px) {
		flex-grow: 1;
		flex-basis: 100%;
		flex-shrink: 0;
		line-height: 16px;
	}
}
.app-c-search-field {
	&.have-filter {
		.app-c-search-btns {
			&.app-c-search__close-btn {
				right: 27px;
			}
		}
	}
	&.no-filter {
		.app-c-search-btns {
			&.app-c-search__close-btn {
				right: 16px;
			}
		}
	}
}
.app-c-input-topic {
	white-space: nowrap;
	.form-group {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-right: 0;
		label {
			margin-bottom: 0;
		}
		@media screen and (max-width: 575px) {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}
	}
}
.app-c-common-tabs__content {
	.form-group {
		.form-label {
			margin-bottom: 6px !important;
		}
	}
}
.app-c-input-topic-select-holder {
	display: grid;
	padding: 10px 30px 20px;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: 1fr;
	gap: 20px;
	@media screen and (max-width: 1200px) {
		grid-template-columns: repeat(2, 1fr);
		padding: 10px 2 0px 20px;
	}
	@media screen and (max-width: 767px) {
		grid-template-columns: repeat(1, 1fr);
	}
	.sub-sub {
		grid-column: 1/ 2;
		@media screen and (max-width: 767px) {
			grid-column: 1/ 1;
		}
	}
	&.view {
		grid-template-columns: repeat(3, 1fr);
		@media screen and (max-width: 1200px) {
			grid-template-columns: repeat(3, 1fr);
			padding: 10px 2 0px 20px;
		}
	}
}
.app-c-input-topic-select {
	width: 300px;
	margin-left: 10px;
	position: relative;
	@media screen and (max-width: 575px) {
		margin-left: 0;
		width: 100%;
	}

	&.full-width {
		margin-left: 0;
		width: 100%;
		.css-b8ldur-Input {
			padding-block: 0 !important;
		}
		.css-yk16xz-control {
			min-height: 40px;
		}
	}
	span + div {
		border: 1px solid hsl(0, 0%, 80%) !important;

		box-shadow: none !important;

		div {
			border: none !important;
			font-size: 14px;
		}
	}
	button {
		position: absolute;
		border: none;
		background: white;
		color: hsl(0, 0%, 70%);
		width: 30px;
		height: 30px;
		border-radius: 5px;
		font-size: 14px;
		right: 4px;
		top: 4px;
		&:hover {
			color: $app-brand-primary;
		}
	}
	.css-1wa3eu0-placeholder {
		color: #c5c5c5;
		@media screen and (max-width: 575px) {
			font-size: 12px;
		}
	}
	.reactSelectBox {
		position: relative;
	}
	label {
		color: $text-muted;
		margin-bottom: 6px;
		font-size: $text-lg;
		sup {
			color: red;
			font-size: 15px;
			top: 0;
		}
	}
}
.tab-btn {
	height: auto;
	padding: 6px 8px;
	margin: 0;
	position: absolute;
	right: 10px;
	top: 7px;
}
.app-c-topic-data {
	text-align: center;
	vertical-align: middle;
	touch-action: manipulation;
	border: 1px solid transparent;
	font-weight: 500;
	color: white;
	padding: 7px 6px;
	font-size: 16px;
	line-height: 18px;
	outline: none;
	font-family: $body-font;
	max-width: 100%;
	min-width: 0;
	background: $app-brand-primary;
	border-color: $app-brand-primary;
	color: $white;
	min-width: 80px;
	border-radius: 8px;
	display: flex;
	align-items: center;
	margin-left: 10px;
	@include justify-content(center);
	button {
		font-size: 22px;
		margin-left: 10px;
		background: none;
		border: none;
		min-width: auto;
		padding: 0;
	}
}
.session-tool-left-label {
	flex: 0 0 200px !important;
	max-width: 200px !important;
}
