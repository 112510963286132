.app-c-confirm-modal {
	&.feedback-modal {
		.modal-content {
			background: #fcfcfe;
			width: 623px;
			max-width: 623px;

			@media only screen and (max-width: 991px) {
				width: 600px;
				max-width: 600px;
			}

			@media only screen and (max-width: 767px) {
				width: 90%;
				max-width: 90%;
			}

			.modal-body {
				max-width: 380px;
				margin: auto;
				padding: 20px 0px 40px;

				@media only screen and (max-width: 767px) {
					padding: 0px 20px 20px;
				}
			}
		}
	}

	&.course-feedback-modal {
		.modal-content {
			background: #fcfcfe;
			width: 703px;
			max-width: 703px;

			@media only screen and (max-width: 991px) {
				width: 600px;
				max-width: 600px;
			}

			@media only screen and (max-width: 767px) {
				width: 90%;
				max-width: 90%;
			}

			.modal-body {
				padding: 20px 0px 40px;

				@media only screen and (max-width: 767px) {
					padding: 0px 0px 20px;
				}

				ul {
					margin: 30px 0px 0px;
					padding: 0;

					li {
						padding: 20px 0;
						border-top: 1px solid #e6e6e6;

						@media only screen and (max-width: 767px) {
							padding: 10px 0px;
						}

						.content-width {
							text-align: left;
							display: flex;

							label {
								font-weight: $font-weight-semibold;
								margin-bottom: 0;
								font-size: 14px;

								&.light {
									color: $text-muted;
									font-weight: $font-weight-normal;
								}
							}

							.form-control {
								min-height: 70px;
								padding-top: 12px;
							}

							.form-control::-webkit-input-placeholder {
								/* Edge */
								color: $black !important;
							}

							.form-control:-ms-input-placeholder {
								/* Internet Explorer 10-11 */
								color: $black !important;
							}

							.form-control::placeholder {
								color: $black !important;
							}
						}

						.left-box {
							flex-grow: 1;
							margin-right: 50px;

							@media only screen and (max-width: 767px) {
								margin-right: 20px;
							}
						}

						.feedback-emoji {
							width: 80px;
							flex-shrink: 0;
							text-align: center;

							img {
								width: 64px;
								height: 64px;

								@media only screen and (max-width: 767px) {
									width: 50px;
									height: 50px;
								}
							}

							.feedback-emoji-label {
								font-size: 14px;
								margin-top: 5px;
							}
						}
					}
				}

				.content-width {
					max-width: 490px;
					display: block;
					margin: auto;

					@media only screen and (max-width: 767px) {
						padding: 0px 20px;
					}

					textarea {
						background: #fff;
						resize: vertical;
					}
				}

				.right-align {
					text-align: right;
				}
			}
		}

		.scroll-container {
			max-height: calc(100vh - 400px);
			overflow: auto;
		}
	}
}

.add-feedback-wrapper {
	padding: 30px 15px 40px;

	@media only screen and (max-width: 767px) {
		padding: 20px 15px 20px;
	}

	.feedback-emoji-main {
		img {
			width: 126px;
			height: 126px;

			@media only screen and (max-width: 767px) {
				width: 80px;
				height: 80px;
			}
		}
	}
}

.feedback-emoji-label {
	font-weight: $font-weight-semibold;
	font-size: 16px;
	margin-top: 10px;
	display: block;
	line-height: 18px;
}

.custom-feedback-block {
	margin: 24px 0 0;
	padding: 0 10px;
	min-width: 360px;

	@media only screen and (max-width: 767px) {
		min-width: 320px;
	}

	.sliderTrack {
		height: 6px;
		cursor: pointer;
		background: rgba(170, 170, 170, 0.2);
		position: relative;
		@include border-radius(4px);
	}

	.sliderRange {
		background: $app-brand-primary;
	}

	.sliderHandle {
		width: 28.33px;
		height: 28.33px;
		background: $app-brand-primary;
		@include border-radius(50%);
		top: 3px !important;
		border: 4px solid #fff;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		cursor: pointer;
	}
}

.slider-points {
	display: flex;
	justify-content: space-between;
	margin-top: 23px;

	div {
		width: 1px;
		height: 6px;
		background: #bec1d2;
	}
}
