.flex-wrap {
	@include flexbox();
	@include flex-direction(row);
	@include justify-content(space-between);
	@include align-items(center);
}
.app-c-form-group {
	&.brdr-btm {
		border-bottom: 1px solid rgba(190, 193, 210, 0.4);
	}
	textarea {
		font-size: $text-lg;
		padding: 12px 13px;
		color: #1e1919;
	}
}

.flex-wrapper {
	@include flexbox();
	@include justify-content(space-between);
	@include flex-direction(row);
	@include align-items(center);

	.app-c-add-new-box {
		flex-basis: 100%;
		margin: 15px 0px;
	}
}
.app-c-or-label {
	display: inline-block;
	line-height: 22px;
	width: 14px;
	text-align: center;
	margin: 17px;
}
.app-c-skip-btn {
	margin-left: auto;
	display: block;
	background: none !important;
	color: $app-brand-primary;
	font-weight: $font-weight-semibold;
	border: none;
	padding: 0;
	&:hover {
		color: #61636f;
		i {
			margin-right: 5px;
		}
	}
	i {
		margin-right: 8px;
		font-size: 10px;
		@include transition(all ease 0.2s);
		&::before {
			color: $app-brand-primary;
		}
	}
}

.app-c-grading-setup {
	@include flexbox();
	@include flex-direction(row);
	flex-wrap: wrap;
	@include justify-content(flex-start);
	@include align-items(center);
	.app-common-radio-btns {
		margin-right: 48px;
		.rtl &,
		[dir='rtl'] & {
			margin-right: 0px;
			margin-left: 48px;
		}
	}
}

.app-c-modal-list-stack {
	padding: 0;
}
.app-c-modal-list-stack__head {
	@include flexbox();
	@include flex-direction(row);
	@include justify-content(space-between);
	@include align-items(center);
	padding: 24px 24px 6px 24px;
	@media screen and (max-width: 767px) {
		padding: 24px 16px 6px 16px;
	}
	&.light-bg {
		background: rgba(233, 235, 244, 0.5);
		@include border-radius(8px);
		margin-top: 12px;
		padding: 12px 24px;
	}
	.app-c-modal-list-stack__item {
		color: #61636f;
		text-transform: uppercase;
		font-size: $text-md;
		font-weight: $font-weight-semibold;
	}
}
.app-c-modal-list-stack__row {
	@include flexbox();
	@include flex-direction(row);
	@include justify-content(space-between);
	@include align-items(center);
	background: #fff;
	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.06);
	@include border-radius(8px);
	margin-top: 8px;
	padding: 9px 24px;
	@media screen and (max-width: 767px) {
		padding: 9px 16px;
	}
	@media screen and (max-width: 640px) {
		.slide-control {
			margin-right: -85px;
		}
		.question-bank-slideControl {
			.-trash {
				margin-right: -40px;
			}
		}
	}

	button {
		all: unset;
		font-size: $text-xl;
		font-weight: $font-weight-semibold;
		color: $app-brand-primary !important;
		line-height: 17px;
		padding: 7px 0px;
		&:hover {
			color: $text-color;
		}
	}
}
.app-c-modal-list-stack__item {
	text-align: left;
	flex-shrink: 0;
	&__text {
		font-size: $text-lg;
		color: $text-color-light;
	}
	label {
		margin: 0;
		color: #1e1919;
		font-weight: $font-weight-semibold;
		line-height: 28px;
		@media screen and (max-width: 480px) {
		}
	}

	&.width2 {
		width: 170px;
		@media screen and (max-width: 767px) {
			width: 90px;
			font-size: 14px;
		}
	}
	&.width3 {
		width: 270px;
	}
}

.app-c-selected-resources {
	padding: 16px 0px;
	min-height: 140px;
}
.app-c-prev-resource {
	.app-l-tags {
		&:hover {
			color: #1e1919;
		}
		&.selected {
			&:hover {
				color: #fff;
			}
		}
	}
}
.app-c-by-letter {
	text-transform: uppercase;
	background: rgba(233, 235, 244, 0.5);
	display: inline-block;
	line-height: 28px;
	padding: 0px 22px;
	@include border-radius(4px);
	color: #1e1919;
	font-weight: $font-weight-semibold;
	margin: 20px 0px 4px 0px;
	font-size: 14px;
}
.app-c-resources-not-found {
	@include border-radius(0px 0px 6px 6px);
	border: 1px solid #bec1d2;
	padding: 29px 20px;
	text-align: center;
	position: relative;
	margin-top: -1px;
	h4 {
		font-size: $text-lg;
		font-weight: $font-weight-normal !important;
		line-height: 22px;
		margin-bottom: 7px;
		display: block;
	}
	.app-c-resources-not-found__text {
		font-size: $text-lg;
		font-weight: $font-weight-normal;
		line-height: 22px;
		color: #7e838d;
		margin: 8px;
	}
}

.app-c-modal-search-box {
	position: relative;
	height: 40px;

	&::before {
		content: '\e92b';
		font-family: 'qualify-tms';
		left: 18.25px;
		top: 9.25px;
		position: absolute;
		font-size: 14px;
		color: #61636f;
		.rtl &,
		[dir='rtl'] & {
			left: inherit;
			right: 18.25px;
		}
	}
	.form-control {
		border: 1px solid #bec1d2;
		height: 40px;
		@include border-radius(6px);
		color: $text-color;

		padding-left: 52px;
		font-size: $text-lg;
		.rtl &,
		[dir='rtl'] & {
			padding-right: 52px;
			padding-left: inherit;
		}

		&:focus {
			@include boxShadow(0px 0px 5px 0px var(--theme-primary-color-600));
			border: 1px solid $app-brand-primary;
			outline: none;
		}
	}
}
.app-c-modal-search-box__close-btn {
	border: none;
	position: absolute;
	top: 0px;
	bottom: 0;
	margin: auto;
	right: 14px;
	font-size: 10px;
	opacity: 0;
	color: #61636f;
	background: none;
	width: 14px;
	height: 14px;
	padding: 0;
	line-height: 100%;
	.rtl &,
	[dir='rtl'] & {
		left: 14px;
		right: inherit;
	}
	@include transition(all ease 0.2s);
	&.active {
		opacity: 1;
	}
	&:hover {
		color: #000;
	}
}

.app-c-modal-search-result {
	display: block;
	position: absolute;
	top: 40px;
	left: 0;
	background: #fff;
	width: 100%;
	opacity: 0;
	z-index: 2;
	filter: alpha(opacity=0);
	pointer-events: none;
	@include boxShadow(0px 2px 8px 0px var(--theme-primary-color-200));
	@include border-radius(6px);
	max-height: 200px;
	overflow: auto;
	pointer-events: none;
	&.active {
		opacity: 1;
		filter: alpha(opacity=100);
		pointer-events: auto;
	}
	ul {
		padding: 4px 0px;
		margin: 0;
		li {
			color: rgba(30, 25, 25, 1);
			font-size: $text-lg;
			line-height: 16px;
			padding: 16px 24px;
			display: block;
			margin: 0;
			cursor: pointer;
			@include transition(all ease 0.2s);
			&.selected,
			&:hover {
				background: rgba(244, 245, 249, 1);
			}
		}
	}
}
.app-c-common-tabs__content,
.app-l-content-section {
	h5 {
		font-size: 16px;
	}
}
.brdr-btm {
	border-bottom: 1px solid rgba(190, 193, 210, 0.4);
}
.viewing-version-box {
	display: inline-block;
	vertical-align: middle;
	padding-right: 20px;
	text-align: right;
	position: relative;
	top: 2px;
	@media only screen and (max-width: 991px) {
		display: block;
		padding-right: 0;
		top: 0;
	}
}
.viewing-version-box__text {
	color: $text-color;
	font-weight: $font-weight-semibold;
	font-size: $text-md;
	line-height: 15.6px;
	display: block;
}
.viewing-version-box__name {
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
	color: $app-brand-primary;
}
.viewing-version-box__dateTime {
	font-size: $text-sm-plus;
	color: rgba(97, 99, 111, 1);
	line-height: 15px;
	display: inline-block;
}
.viewing-version-box__date {
	position: relative;
	padding-left: 20px;
	&::before {
		position: absolute;
		left: 8px;
		top: 4px;
		display: block;
		width: 4px;
		height: 4px;
		@include border-radius(50%);
		background: #d0d4f1;
		content: '';
	}
}
.app-l-preview-box {
	max-width: 960px;
	margin: auto;
}
.app-l-grading-setup-tab {
	margin-bottom: 20px;
	.row {
		margin: 0;
		padding: 0;
		@media only screen and (max-width: 768px) {
			flex-direction: column;
			.app-common-radio-btns + .app-common-radio-btns {
				margin-top: 10px;
			}
		}
	}
	.app-l-Weightage-block {
		.form-label {
			color: #1e1919;
			white-space: nowrap;
			word-break: break-word;
			@media (max-width: 330px) {
				white-space: initial;
			}
		}
		.app-l-infolink {
			margin-bottom: 6px;
		}
		.app-c-input-set,
		.form-group {
			margin-bottom: 0;
		}
		.d-flex {
			display: flex;
			@media only screen and (max-width: 480px) {
				flex-direction: column;
				.form-label {
					flex: 0 0 80px;
				}
				.app-c-input-set + .app-c-input-set {
					margin-top: 10px;
				}
			}
			.form-group {
				flex-direction: row;
				align-items: center;
				padding-right: 20px;
				.form-label {
					margin-bottom: 0;
					padding-right: 10px;
				}
				.app-l-chapter-box__item {
					padding: 7px 10px;
					min-width: 60px;
					display: flex;
					justify-content: center;
				}
			}
		}
	}

	.app-l-calculate-grade-block {
		@media only screen and (max-width: 768px) {
			margin-bottom: 20px;
		}
	}
}

.form-label {
	margin-bottom: 0.5rem;
}
.app-l-Weightage-details {
	padding-top: 2px;
	.col {
		width: auto;
		max-width: max-content;
		.form-label {
			margin-bottom: 5px;
		}
	}
}
.app-c-pre-test {
	div {
		width: 100%;
		padding: 0;
		height: 38px;
	}
	input {
		color: #1e1919;
		height: 100%;
		height: 38px;
		padding: 0;
		width: 100%;
	}
	.app-l-chapter-box__suffix {
		margin-left: auto;
	}
}
.tiny__heading {
	margin-bottom: 30px !important;
	button {
		margin-right: auto;
		left: 0px;
		top: 5px;
	}
}
