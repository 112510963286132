/* variants */

$app-brand-primary: var(--theme-primary-color);
$app-brand-primary-dark: var(--theme-primary-dark);
$app-brand-primary-light: var(--theme-primary-light);
$app-brand-primary-disable: var(--theme-primary-disable);
$app-brand-secondary: #eaeff7;
$app-brand-secondary-disable: #fafafb;
$app-brand-primary-selected: #e5e9ff;
// Text color
$text-color: #1a1e26;
$text-dark-extra: $text-color;
$text-dark-medium: var(--text-dark-medium);
$text-dark-normal: var(--text-dark-normal);
$text-light-normal: var(--text-light-normal);
$main-primary: hsl(232, 66%, 30%);
$main-primary-light: #d7e3f1;
$text-light-medium: var(--text-light-medium);
$text-disabled: #c1c1c1;
$text-placeholder: #c5c5c5;
$text-muted: #61636f;
$input-text-dark: #19191c;

$text-color-light: #586587;
$text-light: rgba(97, 99, 111, 1);

$white: #fff;
$black: #000;
$black01: #1e1919;
$cream: #faf7d2;
$mustard: #ffd64f;
$pear: #c8da3c;
$gray: #d1d5da;
$gray01: #7e838d;
$gray02: #f4f5f9;
$gray03: #bec1d2;
$gray04: #f8f9fb;
$gray05: #373a3f;
$pink: #e3475e;
$box-gray: rgba(244, 245, 249, 1);
$box-gray-hover: #eaebef;
$dune: #382f2f;
$aquaHaze: #f2f3f7;
$spunPearl: #a7abc4;
$dove-grey: #70696d;
$generic-border-color: rgba(233, 235, 244, 1);

$login-logo-seperator: #a9c2d9;
$login-form-bg: #f0f2f7;
$login-header: #342325;
$onboard-screen-bg: #480d13;
$onboard-form-label: #586587;
$primary-btn-active-focus: var(--lighten1);
$breadcrumb-active: #babfce;
$page-heading-color: #362f55;
$card-bordered-plain-color: #e8ecf6;
$darkGrey: #373737;
//Neutrals color palette

$neutrals-color1: #233b58;
$neutrals-color2: #586587;
$neutrals-color3: #e2e6ef;
$neutrals-color4: #f0f2f7;

$default-green: #44ab66;
$default-red: #eb5757;
$default-red-light: #fff6f6;
$avatar-border: #e4e4e4;

// themes color

$theme1: #7c3a87;
$theme2: #7dccf1;
$theme3: #e7ad56;
$theme4: #72b661;

//checkbox-theme
$app-checkbox-border: #c8cfd9;
$app-checkbox-bg: #5f9aff;
$app-checkbox-border-disable: #c8cfd9;

// Radio Button
$app-radio-border: #c8cfd9;
$app-radio-bg: #5f9aff;
$app-radio-border-disable: #c8cfd9;

$radio-group-bg: rgba(247, 247, 247, 1);

//form-control

$form-control-border: rgba(190, 193, 210, 1);
$form-control-active-border: $app-brand-primary;
$form-control-disable-border: $text-light-normal;
$form-control-disable-background: var(--text-light-medium);
$form-error: $default-red;
$form-error-background: $default-red-light;
$validation-red: #ff0000;
$form-disabled: #e9ecef;

// table
$table-border: $app-brand-secondary;
$grid-pagination-border: var(--text-light-normal);
$grid-pagination-border-active: var(--lighten2);
$grid-pagination-background-active: $text-light-normal;

//Tooltip

$tooltip-background: var(--tooltip1);
$tooltip-arrow-background: var(--tooltip1);

//Toggle Button

$app-toggle-bg: #e2e6ef;
$app-toggle-active-bg: #74cd47;
$app-toggle-inactive-border: #eae0e1;

// Card
$app-card-border: #e6e5e5;
$app-card-header: #f9fbff;
$app-card-grey: #fcfcfc;

//Alert
$app-alert-success: #f0fff4;
$app-alert-danger: #f8eced;
$app-alert-warning: #fffbf0;
$app-alert-info: #eff8ff;
$app-alert-light: #f6f6f6;
$app-alert-dark: #eaeaea;

//General Colors
$heading-color: #ff9200;
$menu-bg-color: #444444;
$light-blue: #4c71f0;
$dark-green: #5cb837;
$dark-red: #ff5630;
$dark-yellow: #ffc84c;
$dark-blue: #1facfe;
$light-grey: #eee;
$dark-grey: #a4a4a4;
$faded-blue: var(--theme-primary-color-102);
$faded-blue-one: var(--theme-primary-color);
$shade-grey: #e4e6ed;

// App Colors

$base-bg: #f0f2f7;
$grid-header-bg: $app-brand-secondary;
$grid-header-seperator: var(--text-light-medium);
$tab-header-bg: #fbfbfe;
$onboard-bg: #e0e0e0;
$onboard-form-header: #384155;
$grid-row-vertical-border: #f0f2f7;
$card-shadow: #e3e3e3;
$column-chooser-bg: $text-light-medium;
$select-dropdown-item-bg: $app-brand-secondary;
$select-dropdown-item-seperator: $text-light-normal;
$select-dropdown-item-text: $app-brand-primary;
$select-dropdown-item-select-bg: $app-brand-primary;
$select-dropdown-item-border: $text-light-normal;
$dx-checkbox-active-border: $app-brand-primary;
$dx-textfield-active-border: $app-brand-primary;
$filter-remove-btn-bg: $app-brand-secondary;
$filter-remove-btn-text: $app-brand-primary;
$filter-editable-condition-bg: $app-brand-primary-light;
$filter-condition-bg: $app-brand-secondary;
$filter-condition-text: $app-brand-primary;
$generic-dropdown-border: $text-dark-normal;
$field-focus-border: $app-brand-primary;
$unfocussed-field-border: $text-dark-normal;
$active-checkbox-bg: $app-brand-primary;
$sidebar-logo-text: var(sidebarLogoText);
$list-option-hover-bg: var(--text-light-medium);
$list-option-hover-text: $app-brand-primary;
$popover-border: #eae0e1;
$tag-chip-bg: #f2f2f2;
$tag-close-bg: $app-brand-primary;
$modal-close-color: #384155;
$success-toast: $default-green;
$success-toast-bg: #f3fff7;
$warning-toast: #fe951a;
$warning-toast-bg: #fff8ef;
$error-toast: #eb5757;
$error-toast-bg: #fff6f6;
$toast-close: #dbdfea;
$active-checkbox-label: $app-brand-primary;
$input-group-append-bg: $app-brand-secondary;
$notification-section-head-bg: $text-light-medium;
$data-card-border: var(--text-light-normal);
$app-no-pic: var(--theme-primary-color-212);

// Status colors
$inProgressBg: #ebedf9;
$inProgressColor: #036efa;

$scheduledBg: #e8faf0;
$scheduledColor: #169f6f;

$publishedBg: #e8faf0;
$publishedColor: #169f6f;

$overDueBg: #fcf3f3;
$overDueColor: #d20420;

$draftBg: #faf7ea;
$draftColor: #ffcc00;

$archievedBg: #f4f5f6;
$archievedColor: #cccccc;

$completedBg: #f8f3fd;
$completedColor: #a97ef8;

$whiteSmoke: #f5f5f5;

$accordionBorder-1: #d0d1d9;

$newslide-1: #747891;
$newslide-hover: #e9e7e7;

$gold: #ffba34;

$strict-link: #17226f;
$default-checked: #b7b5b5;
$green-checked: #8fd0b8;
$red-checked: #eb5757;

$app-brand-primary-text: $white !default;
$app-brand-primary-text: var(--theme-text-invert-color);
