@mixin filters($filters...) {
	filter: $filters;
	-webkit-filter: $filters;
	-ms-filter: $filters;
	-o-filter: $filters;
}

@mixin backdrop-filters($filters...) {
	backdrop-filter: $filters;
	-webkit-backdrop-filter: $filters;
	-ms-backdrop-filter: $filters;
	-o-backdrop-filter: $filters;
}
