.app-l-page-sub-heading-block {
	@include flexbox();
	@include align-items(center);
	.app-c-add-new,
	.app-l-add-link {
		margin-left: auto !important;

		.rtl &,
		[dir='rtl'] & {
			margin-left: 0 !important;
			margin-right: auto !important;
		}
	}
}
.app-l-page-sub-heading {
	font-weight: 500;
	font-size: 14px;
	color: #1e1919;
	margin-bottom: 0;
	sup {
		top: -3px;
		margin-left: 4px;
	}
}
.app-l-content-section {
	padding: 24px;
	@media screen and (max-width: 767px) {
		padding: 16px;
	}
	.app-l-add-point-input-block {
		font-size: $text-lg;
		color: #1e1919;
	}
}
.app-c-add-new {
	color: $app-brand-primary !important;
	background: transparent !important;
	border: none !important;
	padding: 0 !important;
	font-size: 14px !important;
	font-weight: $font-weight-bold !important;
	height: auto !important;
	//margin-left: 44px !important;
	&:focus {
		outline: none;
		border: none;
		box-shadow: none;
	}

	&:hover {
		opacity: 0.9;
	}

	span {
		background: $app-brand-primary;
		width: 16px;
		height: 16px;
		font-size: 7px;
		line-height: 9px;
		padding: 4px;
		border-radius: 4px;
		margin-right: 6px;
		margin-top: 2px;
		display: inline-block;
		position: relative;
		top: -2px;
		.rotate {
			@include transform(rotate(20deg));
		}
		&::before {
			color: $white;
		}
		.rtl &,
		[dir='rtl'] & {
			margin-left: 6px;
			margin-right: 0;
		}
	}
}
.app-l-add-criteria-top-section {
	margin-bottom: 17px;
	@include flexbox();
	@include align-items(center);
	.app-l-add-criteria-left-block {
		width: 320px;
		@include flexbox();
		@include align-items(center);
		@include flex-shrink(0);
		.app-c-add-new {
			margin-left: 0px !important;
		}
	}
	.app-l-add-criteria-right-block {
		width: 100%;
		.app-l-add-point__section {
			margin: 0px -8px;
			@include flexbox();
			@include align-items(center);
			@include flex-wrap(wrap);
		}
	}
}
.app-l-add-criteria-bottom-section {
	@include flexbox();
	@include align-items(flex-start);
	.app-l-add-description-right-block {
		margin: 4px -8px;
		width: 100%;
		overflow: auto;
		@include flexbox();
		@include flex-direction(column);
		@include align-items(center);
		.app-l-add-description-block-row {
			width: 100%;
			@include flexbox();
			@include align-items(center);
		}
		.app-l-add-description-block {
			margin: 4px 8px;
			width: calc((100% / 3) - 16px);

			.form-control {
				height: 194px;
				color: $text-color;
			}
		}
	}
}

// new

.app-l-content-section {
	.app-l-add-criteria-content-section {
		@include flexbox();
		@include align-items(flex-start);
		overflow: auto;

		.form-label {
			font-size: 12px;
			margin-bottom: 6px;
			line-height: 15.6px;
			display: block;
		}
		.app-l-add-criteria-content-left-section {
			min-width: 238px;
			width: calc((100% / 4) - 16px);
			@include flexbox();
			@include align-items(flex-start);
			@include flex-direction(column);
			@include flex-shrink(0);

			.app-l-add-criteria-left__header {
				margin: 31px 0px;
				.app-c-add-new {
					margin-left: auto !important;

					.rtl &,
					[dir='rtl'] & {
						margin-left: 0 !important;
						margin-right: auto !important;
					}
				}
			}
			.app-l-add-criteria-left__body {
				width: 100%;
				padding-right: 10px;
				&.add-top-padding {
					padding-top: 60px;
					.app-l-add-criteria {
						height: 202px;
					}
				}
				.app-l-add-criteria {
					margin-bottom: 17px;
					@include flexbox();
					@include align-items(flex-start);
					@include flex-shrink(0);

					@media screen and (max-width: 767px) {
						width: 100%;
						min-height: auto;
					}

					.app-l-sort-btn-block {
						height: 100%;
						@include flexbox();
						@include align-items(flex-end);

						.app-c-vertical-sort-btn {
							height: 40px;
							border: 1px solid $gray03;
							border-radius: 6px;
							width: 23px;
							@include flexbox();
							@include flex-direction(column);
							@include justify-content(space-between);
							margin-top: 35px;

							button {
								height: auto;
								flex: auto;
								max-width: 100%;
								font-size: 7px;
								margin: 0;
								padding: 3px 0 !important;

								&:hover {
									background: $gray02 !important;

									&::before {
										color: $black01 !important;
									}
								}
							}
							.info-link {
								&.disabled {
									opacity: 0.3;
									cursor: not-allowed;
									pointer-events: none;
								}
							}
						}
					}

					.app-l-add-criteria-details-block {
						margin: 0px 8px;
						width: 100%;
						.app-l-add-criteria-details-top-block {
							margin-bottom: 0;
							@include flexbox();
							@include align-items(center);
							.app-l-add-criteria-details-heading {
								h5 {
									color: #61636f;
									font-weight: 15.6px;
									font-size: 12px;
									margin-bottom: 6px;
								}
							}
						}
						.app-l-add-action-heading {
							margin-left: auto;
							@include flexbox();
							@include align-items(center);
							.rtl &,
							[dir='rtl'] & {
								margin-left: 0;
								margin-right: auto;
							}
							.app-c-icon-delete {
								background: none;
								border: none;
								outline: none;
								box-shadow: none !important;
								background-color: #fff !important;
								border-color: #fff !important;
								color: #61636f !important;
								padding: 3px !important;
								&:not(:disabled):hover {
									color: $app-brand-primary !important;
								}
								&:focus {
									outline: none;
								}
							}
							.app-c-horizontal-sort-btn {
								width: 44px;
								border: 1px solid $gray03;
								border-radius: 6px;
								height: 23px;
								@include flexbox();
								@include justify-content(space-between);
								margin-left: 12px;
								.rtl &,
								[dir='rtl'] & {
									margin-left: 0;
									margin-right: 12px;
								}

								button {
									height: auto;
									flex: auto;
									max-width: 100%;
									font-size: 7px;
									margin: 0;
									padding: 3px 0 !important;
									transform: rotate(-90deg);
									.rtl &,
									[dir='rtl'] & {
										transform: rotate(90deg);
									}
									&:hover {
										background: $gray02 !important;

										&::before {
											color: $black01 !important;
										}
									}
								}
								.info-link {
									&.disabled {
										opacity: 0.3;
										cursor: not-allowed;
										pointer-events: none;
									}
								}
							}
						}
						.app-l-add-point-input-block {
							width: 100%;
							@include flexbox();
							@include align-items(center);
							font-size: $text-lg;
							.form-control {
								&.percentage-value {
									width: 64px;
									margin-left: 8px;
								}
							}
						}
					}
					+ .app-l-add-criteria {
						margin-top: 17px;
					}
				}
			}
		}
		.app-l-add-criteria-content-right-section {
			width: 100%;
			// overflow: auto;
			@include flexbox();
			@include align-items(center);
			.app-l-add-criteria-content-right-inner-block {
				margin: 0px -8px;
				width: 100%;
				min-width: 660px;

				@include flexbox();
				padding-bottom: 50px;
				&.block {
					display: block;
					overflow: visible;
				}
			}
			.app-l-add-point-content__row {
				margin: 0px 0px;
				width: 100%;
				min-width: 660px;
				overflow: visible;
				@include flexbox();
				flex-grow: 1;
			}
			.app-l-add-point-content__column {
				margin: 0px 8px;
				//min-width: 258px;

				min-width: calc((100% / 3) - 16px);
				width: calc((100% / 3) - 16px);
			}
			.app-l-add-point-content__column-header {
				margin-bottom: 10px;
				.app-l-add-point-block {
					min-height: 50px;
					.app-l-add-point-top-block {
						margin-bottom: 6px;
						@include flexbox();
						@include align-items(center);
						.app-l-add-point-heading {
							h5 {
								color: #61636f;
								font-weight: normal;
								font-size: 12px;
								margin-bottom: 0;
								sup {
									color: #ff0000;
									font-size: 15px;
									top: 0px;
								}
							}
						}
					}
					.app-l-add-action-heading {
						margin-left: auto;
						@include flexbox();
						@include align-items(center);
						.rtl &,
						[dir='rtl'] & {
							margin-left: 0;
							margin-right: auto;
						}
						.app-c-icon-delete {
							background: none;
							border: none;
							outline: none;
							box-shadow: none !important;
							background-color: #fff !important;
							border-color: #fff !important;
							color: #61636f !important;
							padding: 3px !important;
							&:not(:disabled):hover {
								color: $app-brand-primary !important;
							}
							&:focus {
								outline: none;
							}
						}
						.app-c-horizontal-sort-btn {
							width: 44px;
							border: 1px solid $gray03;
							border-radius: 6px;
							height: 23px;
							@include flexbox();
							@include justify-content(space-between);
							margin-left: 12px;
							.rtl &,
							[dir='rtl'] & {
								margin-left: 0;
								margin-right: 12px;
							}
							button {
								height: auto;
								flex: auto;
								max-width: 100%;
								font-size: 7px;
								margin: 0;
								padding: 3px 0 !important;
								transform: rotate(-90deg);
								.rtl &,
								[dir='rtl'] & {
									transform: rotate(90deg);
								}
								&:hover {
									background: $gray02 !important;

									&::before {
										color: $black01 !important;
									}
								}
							}
							.info-link {
								&.disabled {
									opacity: 0.3;
									cursor: not-allowed;
									pointer-events: none;
								}
							}
						}
					}
					.app-l-add-point-input-block {
						width: 100%;
						font-size: $text-lg;
					}
				}
			}
			.app-l-add-point-content__column-body {
				@include flexbox();
				@include flex-direction(column);
				margin-bottom: 17px;
				height: 202px;
				.app-l-add-description-block {
					.form-control {
						height: 194px;
						color: $text-color;
					}
					+ .app-l-add-description-block {
						margin-top: 17px;
					}
				}
				.app-l-add-point-input-block {
					font-size: $text-lg;
					height: 174px;
					overflow: auto;
					line-height: 22px;
				}
			}
		}
	}
}

.app-l-add-criteria-table {
	position: relative;
	.app-l-add-criteria-table__scroll {
		width: 100%;
		overflow: auto;
		display: -moz-flex;
		display: flex;
		scrollbar-color: $app-brand-primary #ced0dd;
		scrollbar-width: thin;

		.table-responsive-md {
			width: 100%;
		}
	}
	.app-c-add-new {
		margin-left: 0 !important;
	}
	table {
		thead {
			tr {
				th {
					min-width: 268px;
					//min-width: calc(100% / 4);
					width: calc(100% / 4);
					padding: 0px 4px;
					border: none;
					vertical-align: middle;
				}
			}
		}
		tbody {
			tr {
				td {
					padding: 0px 8px 0 0;
					border: none;
					vertical-align: top;
				}
			}
		}
	}
	.app-l-add-point-content__column-header {
		margin-bottom: 17px;
		.app-l-add-point-block {
			.app-l-add-point-top-block {
				margin-bottom: 6px;
				height: 26px;
				@include flexbox();
				@include align-items(center);
				.app-l-add-point-heading {
					h5 {
						color: #61636f;
						font-weight: normal;
						font-size: 14px;
						margin-bottom: 0;
						sup {
							color: #ff0000;
							font-size: 15px;
							top: 0px;
						}
					}
				}
			}
			.app-l-add-action-heading {
				margin-left: auto;
				@include flexbox();
				@include align-items(center);
				.rtl &,
				[dir='rtl'] & {
					margin-left: 0;
					margin-right: auto;
				}
				.app-c-icon-delete {
					background: none;
					border: none;
					outline: none;
					box-shadow: none !important;
					background-color: #fff !important;
					border-color: #fff !important;
					color: #61636f !important;
					padding: 3px !important;
					&:not(:disabled):hover {
						color: $app-brand-primary !important;
					}
					&:focus {
						outline: none;
					}
				}
				.app-c-horizontal-sort-btn {
					width: 44px;
					border: 1px solid $gray03;
					border-radius: 6px;
					height: 23px;
					@include flexbox();
					@include justify-content(space-between);
					margin-left: 12px;
					.rtl &,
					[dir='rtl'] & {
						margin-left: 0;
						margin-right: 12px;
					}
					button {
						height: auto;
						flex: auto;
						max-width: 100%;
						font-size: 7px;
						margin: 0;
						padding: 3px 0 !important;
						transform: rotate(-90deg);
						.rtl &,
						[dir='rtl'] & {
							transform: rotate(90deg);
						}
						&:hover {
							background: $gray02 !important;

							&::before {
								color: $black01 !important;
							}
						}
					}
					.info-link {
						&.disabled {
							opacity: 0.3;
							cursor: not-allowed;
							pointer-events: none;
						}
					}
				}
			}
			.app-l-add-point-input-block {
				width: 100%;
				font-size: $text-lg;
				.form-control {
					font-size: $text-lg;
					height: 40px;
					color: $text-color;
					&::-webkit-input-placeholder {
						color: $text-placeholder;
					}

					&:-ms-input-placeholder {
						color: $text-placeholder;
					}

					&::placeholder {
						color: $text-placeholder;
					}
				}
			}
		}
	}
	.app-l-add-criteria {
		min-height: 228px;
		margin-bottom: 17px;
		@include flexbox();
		@include align-items(flex-start);
		@include flex-shrink(0);

		@media screen and (max-width: 767px) {
			width: 100%;
		}

		.app-l-sort-btn-block {
			height: 100%;
			@include flexbox();
			@include align-items(flex-end);

			.app-c-vertical-sort-btn {
				height: 40px;
				border: 1px solid $gray03;
				border-radius: 6px;
				width: 23px;
				@include flexbox();
				@include flex-direction(column);
				@include justify-content(space-between);
				margin-top: 34px;

				button {
					height: auto;
					flex: auto;
					max-width: 100%;
					font-size: 7px;
					margin: 0;
					padding: 3px 0 !important;
					// transform: rotate(-90deg);

					&:hover {
						background: $gray02 !important;

						&::before {
							color: $black01 !important;
						}
					}
				}
				.info-link {
					&.disabled {
						opacity: 0.3;
						cursor: not-allowed;
						pointer-events: none;
					}
				}
			}
		}

		.app-l-add-criteria-details-block {
			margin: 0px 0px 0px 8px;
			width: 100%;
			.rtl &,
			[dir='rtl'] & {
				margin-left: 0;
				margin-right: 8px;
			}

			.app-l-add-criteria-details-top-block {
				margin-bottom: 4px;
				@include flexbox();
				@include align-items(center);
				.app-l-add-criteria-details-heading {
					h5 {
						color: #61636f;
						font-weight: normal;
						font-size: 14px;
						margin-bottom: 0;
						sup {
							color: #ff0000;
							font-size: 15px;
							top: 0px;
						}
						.icon-info {
							position: relative;
							top: -1px;
						}
					}
				}
			}
			.app-l-add-action-heading {
				margin-left: auto;
				@include flexbox();
				@include align-items(center);
				.rtl &,
				[dir='rtl'] & {
					margin-left: 0;
					margin-right: auto;
				}
				.app-c-icon-delete {
					background: none;
					border: none;
					outline: none;
					box-shadow: none !important;
					background-color: #fff !important;
					border-color: #fff !important;
					color: #61636f !important;
					padding: 3px !important;
					&:not(:disabled):hover {
						color: $app-brand-primary !important;
					}
					&:focus {
						outline: none;
					}
				}
				.app-c-horizontal-sort-btn {
					width: 44px;
					border: 1px solid $gray03;
					border-radius: 6px;
					height: 23px;
					@include flexbox();
					// @include flex-direction(column);
					@include justify-content(space-between);
					margin-left: 12px;
					.rtl &,
					[dir='rtl'] & {
						margin-left: 0;
						margin-right: 12px;
					}

					button {
						height: auto;
						flex: auto;
						max-width: 100%;
						font-size: 7px;
						margin: 0;
						padding: 3px 0 !important;
						transform: rotate(-90deg);
						.rtl &,
						[dir='rtl'] & {
							transform: rotate(90deg);
						}
						&:hover {
							background: $gray02 !important;

							&::before {
								color: $black01 !important;
							}
						}
					}
					.info-link {
						&.disabled {
							opacity: 0.3;
							cursor: not-allowed;
							pointer-events: none;
						}
					}
				}
			}
			.app-l-add-point-input-block {
				width: 100%;
				@include flexbox();
				@include align-items(flex-start);
				font-size: $text-lg;
				line-height: 22px;
				&.suffix {
					position: relative;
					&::after {
						position: absolute;
						content: '%';
						right: 12px;
						top: 10px;
						color: #61636f;
					}
					.percentage-value {
						padding-right: 26px;
						text-align: right;
					}
				}
				.form-control {
					font-size: $text-lg;
					line-height: 22px;
					height: 40px;
					&::-webkit-input-placeholder {
						color: $text-placeholder;
					}

					&:-ms-input-placeholder {
						color: $text-placeholder;
					}

					&::placeholder {
						color: $text-placeholder;
					}
					&.percentage-value {
						width: 78px;
						margin-left: 8px;
						position: relative;
						text-align: center;
						.rtl &,
						[dir='rtl'] & {
							margin-left: 0;
							margin-right: 8px;
						}
					}
				}
			}
		}
		+ .app-l-add-criteria {
			margin-top: 17px;
		}
		.app-l-add-criteria-details-top-block {
			height: 30px;
		}
	}

	.app-l-add-description-block {
		padding-top: 3px;
		margin-bottom: 8px;
		.form-control {
			padding-top: 10px;
			max-height: 194px;
			height: 194px;
			font-size: $text-lg;
			line-height: 22px;
			color: $text-color;
		}
	}
}

.app-c-input-set {
	margin-bottom: 24px;
	word-break: break-word;
	.help-block {
		@extend .help-block-formatter;
	}
	.form-label {
		line-height: 22px;
		margin-bottom: 6px;
		display: block;
		i {
			color: $app-brand-primary;
			font-size: 13px;
			position: relative;
			margin-left: 6px;
			top: 1px;
		}
		.app-c-btn--icon-only {
			position: relative;
			top: -2px;
		}
	}
	.app-c-btn--icon-only {
		position: relative;
		top: -2px;
	}
	.react-datepicker-wrapper {
		display: block;
	}
	textarea {
		resize: auto;
	}
	.form-control {
		font-size: $text-lg;
		// color: #1e1919;
		color: $text-color;
		height: 40px;
		line-height: 26px;

		&:focus {
			outline: none;
			box-shadow: none;
		}
	}
	.date-fiels,
	.time-fiels {
		position: relative;
		&::after {
			position: absolute;
			content: '\e91c';
			font-family: 'qualify-tms';
			right: 14px;
			top: 10px;
			color: #61636f;
			pointer-events: none;
			.rtl &,
			[dir='rtl'] & {
				right: inherit;
				left: 14px;
			}
		}
		.form-control {
			padding-right: 40px;
			.rtl &,
			[dir='rtl'] & {
				padding-right: 12px;
				padding-left: 40px;
			}
		}
		input {
			&[readonly] {
				background: $white;
				border-color: #d2d2d6;
				color: $text-color;
			}
		}
	}
	.time-fiels {
		&::after {
			content: '\e946';
		}
	}
}
.outer-border {
	border: 1px solid #ced4da;
	@include border-radius(4px);
	padding: 1px;
	position: relative;
	&.absolute {
		.form-control {
			padding-left: 45px;
			background: none;
		}
		.flag-image {
			position: absolute;
		}
	}
	.app-c-normal-select {
		width: 100%;
	}
	.flag-image {
		margin-left: 0.75rem;
		font-size: 0;
		img {
			font-size: 0;
			height: 14px;
			width: 22px;
			min-width: 22px;
			min-height: 14px;
		}
	}
	input {
		border: none;
		height: 38px;
	}
	.app-l-chapter-box__item {
		padding: 7px 0px 7px 12px !important;
		background: none;
		@include justify-content(flex-start !important);
		.rtl &,
		[dir='rtl'] & {
			padding: 12px 12px 11px 0;
		}
		&.custom-width-overlay-box {
			position: relative;
			.app-l-chapter-box__suffix {
				padding-left: 9px;
			}
		}
		input {
			left: 12px;
			font-size: $text-lg;
			font-weight: $font-weight-normal;
			// color: #1a1e26 !important;
			&[type='number'] {
				-moz-appearance: textfield;
			}

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
			}
		}
		.app-l-chapter-box__value {
			padding: 6px 0 !important;
			min-height: 29px;
		}
		.app-l-chapter__text {
			padding: 0 !important;
		}
	}
	span {
		font-size: $text-lg;
		font-weight: $font-weight-normal;
		&.app-l-chapter-box__value {
			&.max-char-5 {
				max-width: 37px;
				overflow: hidden;
			}
		}
		&.app-l-chapter-box__suffix {
			padding-left: 3px;
		}
	}
	.app-c-normal-select {
		&::after {
			top: 12px;
		}
	}
	.form-control {
		border: none;
		height: 38px;
		min-height: 38px;

		&:focus {
			@include noShadow();
			outline: none;
		}
	}
}
.app-c-auto-input {
	input[type='text'] {
		width: 60px;
		&:focus {
			+ .app-c-auto-input__sufix {
				color: $text-color;
			}
		}
	}
	&.percent {
		position: relative;
		display: inline-flex;
		width: 60px;
		.app-c-auto-input__sufix {
			position: absolute;
			right: 9px;
			top: 9px;
			color: $text-color;
		}
	}
}

.app-c-input-set__wrap-it {
	border: 1px solid $generic-border-color;
	@include border-radius(4px);
	@include flexbox();
	padding: 2px;
	.app-c-code {
		min-width: 68px;
		position: relative;
		&::after {
			content: '\e972';
			font-size: 10px;
			font-family: 'qualify-tms';
			color: $text-muted;
			display: block;
			height: 20px;
			width: 20px;
			right: -3px;
			top: 0;
			bottom: 0;
			margin: auto;
			border-right: 1px solid $generic-border-color;
			position: absolute;
			line-height: 20px;
			pointer-events: none;
		}
		.form-control {
			padding: 0px 0.7rem;
			//padding-right: 5px;
			appearance: none;
			line-height: 34px;
		}
	}
	.form-control {
		border: none;
		min-height: 34px !important;
		height: 34px !important;
		line-height: 34px;
		@include border-radius(0px);
		background: none;
	}
}
.app-c-qa-required {
	@include flexbox();
	@include align-items(center);
	padding-top: 4px;
	.app-c-switch {
		display: inline-block;
		margin: 0px 48px 0px 0px;
		label {
			width: 60px;
			.app-c-switch--active,
			.app-c-switch--inactive {
				border: none;
			}
		}
	}
}
.radion-wrapper {
	.app-common-radio-btns {
		display: inline-block;
		margin-right: 48px;
	}
	&.flex {
		@include flexbox();
		@include flex-direction(row);
		@include justify-content(space-between);
		@include align-items(center);
		@media screen and (max-width: 575px) {
			flex-wrap: wrap;
		}
		.app-common-radio-btns {
			margin-right: 0px;
			flex: 1;
			@media screen and (max-width: 575px) {
				padding: 5px 0px;
			}
		}
	}
}
.app-l-add-criteria {
	position: relative;
	.app-c-add__new {
		position: absolute;
		bottom: 0;
	}
}
