.app-c-tab {
	.nav {
		border: none;
		overflow: hidden;
		overflow-x: auto;
		white-space: nowrap;
		@include flex-wrap(nowrap);

		@media (max-width: 767px) {
			&::-webkit-scrollbar {
				height: 4px;
			}
		}

		.nav-item {
			margin-right: 23px;

			.nav-link {
				color: rgba(97, 99, 111, 1);
				font-size: $text-lg;
				font-weight: $font-weight-semibold;
				//   font-family: $app-font-regular;
				position: relative;
				background: none;
				border: 0;
				border-radius: 0;
				padding: 0 0px 17px 0px;
				text-decoration: none;
				@include transition(width 0.3s ease, background 0.3s ease);
				@include flexbox();
				@include align-items(center);

				@media screen and (max-width: 767px) {
					margin: 0px 5px 4px 5px;
					font-size: 12px;
					// font-family: $app-font-regular;
				}

				i {
					font-size: 18px;
					margin-right: 10px;

					.rtl &,
					[dir='rtl'] & {
						margin-right: 0;
						margin-left: 10px;
					}
				}

				.nav-number {
					width: 24px;
					height: 24px;
					margin-right: 10px;
					border: 1px solid #6f6a6a;
					@include border-radius(100%);
					@include flexbox();
					@include align-items(center);
					@include justify-content(center);

					.rtl &,
					[dir='rtl'] & {
						margin-right: 0 !important;
						margin-left: 10px;
					}

					span {
						color: #6f6a6a;
					}

					.icon-check {
						font-size: 10px;
					}
				}

				&::before {
					content: '';
					width: 0;
					height: 0px;
					//   background: $app-brand-primary;
					background: var(--theme-primary-color);
					position: absolute;
					bottom: 10px;
					left: 0px;
					right: 0px;
					margin: auto;
					// @include transition(height 0.3s ease);
				}

				&.active,
				&:hover {
					border: none;
					background: none;
					color: #000;

					&::before {
						height: 2px;
						width: 100%;
						@include transition(width ease 0.3s);
					}

					.nav-number {
						width: 24px;
						height: 24px;
						margin-right: 10px;
						border: 1px solid var(--theme-primary-color);
						background-color: var(--theme-primary-color);
						border: 1px solid var(--theme-primary-color-200);
						position: relative;

						span {
							color: #fff;
						}

						&:after {
							content: '';
							width: 28px;
							height: 28px;
							background: var(--theme-primary-color-200);
							@include border-radius(100%);
							position: absolute;
							left: -3px;
							right: 0;

							.rtl &,
							[dir='rtl'] & {
								left: 0;
								right: -3px;
							}
						}
					}
				}

				&:hover {
					&::before {
						width: 90%;
					}
				}

				// &:hover {
				//   outline: none;

				//   &:before {
				//     @media screen and (min-width: 1024px) {
				//       width: 100%;
				//     }
				//   }
				// }
			}

			+ .nav-item {
				margin-left: 0;
				margin-right: 0;

				a {
					margin: 0px 23px;

					@media screen and (max-width: 767px) {
						margin: 0px 10px;
					}
				}
			}
		}
	}

	&.--tab-pane-nav {
		&.card {
			padding: 15px 24px 0 24px;
			border: none;
		}

		.nav-item {
			margin: 0;

			.nav-link {
				padding: 0 0 15px 0;
			}

			+ .nav-item {
				margin-left: 42px;

				a {
					padding: 0 0 15px 0;
					margin: 0;
				}
			}
		}
	}

	.tab-content {
		padding-top: 8px;
		padding-bottom: 24px;

		.rtl &,
		[dir='rtl'] & {
			text-align: right;
		}
	}
}

// Common tabs style
.app-c-common-tabs__nav {
	background: #fff;
	@include border-radius(8px);
	@include boxShadow(0px 2px 8px 0px rgba(0, 0, 0, 0.1352));
	padding: 15px 24px 0px 24px !important;
	// box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1352);
}

.app-c-common-tabs {
	background: #fff;

	@include boxShadow(0px 2px 8px 0px rgba(0, 0, 0, 0.1352));
	@include border-radius(8px);
	margin-bottom: 8px;
}

.app-c-common-tabs__head {
	padding: 8px 24px;
	min-height: 49px;
	border-bottom: 1px solid rgba(190, 193, 210, 0.4);
	@include flexbox();
	@include flex-direction(row);
	@include justify-content(space-between);
	@include align-items(center);

	@media screen and (max-width: 767px) {
		padding: 8px 16px;
	}

	h5 {
		margin: 0;
		font-size: 16px;
		line-height: 17px;
	}

	.app-l-chapter__actions {
		button {
			&.app-c-btn {
				margin-left: 0 !important;

				i {
					font-size: 18px;
					// &.icon-Copy {
					// 	font-size: 22px;
					// 	font-weight: 600;
					// 	position: relative;
					// 	top: -2px;
					// }
				}
			}
		}
	}
}

.app-c-common-tabs__content {
	padding: 24px;

	@media screen and (max-width: 991px) {
		padding: 16px !important;
	}

	.rtl &,
	[dir='rtl'] & {
		text-align: right;
	}
}

.app-l-checklist-holder {
	padding-top: 16px;

	h1 {
		color: $black;
		font-size: 16px !important;
		font-weight: normal;
		padding-bottom: 10px;
		margin-bottom: 0;
	}

	button {
		margin: 0;
		padding: 0;
		color: $black;
		font-size: 16px !important;
		outline: none !important;
		box-shadow: none !important;

		i {
			font-size: 13px;
			margin-right: 5px;
		}
	}

	.row {
		button {
			margin-right: 15px;
		}
	}

	.list-group {
		padding-top: 20px;

		.list-group-item {
			border-left: none !important;
			border-right: none !important;
			padding: 16px 0;
			background: transparent;
			font-size: 16px;
			color: $dune;
			border-color: $aquaHaze !important;

			&:first-child {
				border-top: none !important;
				padding-top: 0 !important;
			}

			&:last-child {
				border-bottom: none !important;
				padding-bottom: 0 !important;
			}
		}
	}
}

.app-l-rubric-button-block {
	.app-l-skip-block {
		@include flexbox();
		@include align-items(center);
		@include justify-content(space-between);
		padding-bottom: 16px;
		padding-top: 15px;

		small {
			font-size: 11px;
			color: $dove-grey;
			font-style: italic;
		}

		button {
			padding: 0;
			color: $app-brand-primary;
			font-weight: $font-weight-semibold;
			font-size: 14px;
		}

		.skip-btn {
			text-decoration: none !important;
			box-shadow: none !important;
			display: flex;
			align-items: center;

			i {
				margin-left: 5px;
			}
		}
	}

	.app-l-rubric-add-holder {
		@include flexbox();
		@include align-items(center);
		margin: 0;
		padding: 0;

		.col {
			&.app-l-or {
				flex: 0;
				font-size: 16px;
				font-weight: $font-weight-semibold;
				color: $black01;
			}
		}

		.app-l-create-rubric {
			flex: 1;
			min-height: 140px;
			@include flexbox();
			@include align-items(center);
			@include justify-content(center);
			border: 1px dashed $app-brand-primary;
			background: transparent;
			color: $app-brand-primary;

			span {
				@include flexbox();
				@include flex-direction(Column);
				font-weight: $font-weight-semibold;
				font-size: 16px;
				color: $black;
			}

			i {
				width: 50px;
				height: 50px;
				margin: auto;
				@include flexbox();
				@include align-items(center);
				@include justify-content(center);
				background: $app-brand-primary;
				border-radius: 50px;
				color: $white;
				box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
				margin-bottom: 11px;

				&::before {
					font-size: 24px;
				}
			}

			&:hover,
			&:active {
				background: $app-brand-primary !important;
				background-color: $app-brand-primary !important;
				border-color: $app-brand-primary !important;

				span {
					color: $white;

					i {
						background: $white;
						color: $app-brand-primary;
					}
				}
			}

			.tooltipLibrary {
				font-size: 11px !important;
				bottom: -15px;
				color: #ffffff !important;
				display: none !important;

				&:before {
					left: calc(50% - 8px);
				}
			}

			.tooltipRubric {
				font-size: 11px !important;
				bottom: -15px;
				color: #ffffff !important;
				display: none !important;

				&:before {
					left: calc(50% - 8px);
				}
			}

			&:hover {
				.tooltipRubric {
					display: block !important;
				}
			}

			&:hover {
				.tooltipLibrary {
					display: block !important;
				}
			}
		}
		.rubric-btn {
			box-shadow: none !important;
		}
	}
}
