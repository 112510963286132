// Switch
.app-c-toggle {
	display: inline-block;

	label {
		margin: 0;
		padding: 0;
		position: relative;
		display: block;
		line-height: normal;
		cursor: pointer;

		.app-c-toggle--icon {
			width: 38px;
			height: 18px;
			margin: auto;
			background: $app-toggle-bg;
			position: relative;
			display: block;

			@include border-radius(9px);
			@include transition(
				background ease 0.2s,
				transform ease 0.2s,
				box-shadow ease 0.2s,
				left ease 0.2s
			);

			&:before {
				content: '';
				width: 12px;
				height: 12px;
				background: $white;
				display: block;
				position: absolute;
				top: 0;
				left: 4px;
				bottom: 0;
				margin: auto;

				@include boxShadow(1px 1px 4px rgba(57, 60, 59, 0.15696));
				@include transition(left ease 0.2s);
				@include border-radius(50%);
			}
		}

		input {
			display: none;

			&[type='checkbox']:checked {
				+ .app-c-toggle--icon {
					background: $app-toggle-active-bg;

					&:before {
						left: calc(100% - 16px);
					}
				}
			}
		}
	}
}

.app-c-switch {
	display: inline-block;

	label {
		margin: 0;
		padding: 0;
		position: relative;
		display: block;
		line-height: normal;
		cursor: pointer;

		.app-c-switch--icon {
			position: absolute;
			top: 2px;
			left: 2px;
			width: 20px;
			height: 20px;
			background: $white;

			@include border-radius(50%);
			@include transition(
				background ease 0.2s,
				transform ease 0.2s,
				box-shadow ease 0.2s,
				left ease 0.2s
			);
			@include boxShadow(0px 1px 4px rgba(17, 25, 45, 0.1));
			&:before {
				content: '';
				width: 8px;
				height: 8px;
				background: #7e838d;
				position: absolute;
				top: 6px;
				right: 6px;
				@include border-radius(100%);
			}
		}
		.app-c-switch--active,
		.app-c-switch--inactive {
			color: $app-brand-secondary;
			font-size: 12px;
			font-weight: 400;
			line-height: 16px;
			//line-height: 13px;
			display: block;
			height: 24px;
			padding: 0px 10px 0px 22px;
			background: #7e838d;
			border: 1px solid #7e838d;

			@include border-radius($text-sm-plus);
			@include transition(
				background ease 0.2s,
				padding ease 0.2s,
				color ease 0.2s,
				border ease 0.2s,
				opacity ease 0.2s
			);
			@include flexbox();
			@include align-items(center);
			@include justify-content(center);
		}
		.app-c-switch--inactive {
			padding-left: 24px !important;
			color: #fff;
			border-color: #7e838d;
			padding-left: 28px;
		}
		.app-c-switch--active {
			display: none;
			color: $text-dark-medium;
		}
		input {
			// display: none;
			opacity: 0;
			z-index: 1;
			width: 1px;
			height: 1px;
			position: absolute;
			top: 0;
			left: 0;
			right: auto;
			bottom: auto;
			margin: 0;

			&[type='checkbox']:checked {
				+ .app-c-switch--icon {
					left: calc(100% - 22px);

					// @include boxShadow(0px 1px 4px rgba(17, 25, 45, 0.1));
					&:before {
						content: '';
						width: 8px;
						height: 8px;
						background: var(--theme-primary-color);
						position: absolute;
						top: 6px;
						right: 6px;
						@include border-radius(100%);
					}
				}
				~ .app-c-switch--active {
					// background: $app-toggle-active-bg;
					background: $app-brand-primary;
					color: $white;
					border-color: $app-brand-primary;
				}
				~ .app-c-switch--inactive {
					display: none;
				}
				~ .app-c-switch--active {
					padding-right: 24px;
					padding-left: 9px;

					@include flexbox();
				}
				// &:focus {
				// 	+ .app-c-switch--icon {
				// 		@include boxShadow(
				// 			0px 1px 4px rgba(17, 25, 45, 0.1),
				// 			0px 0px 0px 8px rgba($app-toggle-active-bg, 0.35)
				// 		);
				// 	}
				// }
			}
			// &[type='checkbox']:focus {
			// 	+ .app-c-switch--icon {
			// 		@include boxShadow(
			// 			0px 1px 4px rgba(17, 25, 45, 0.1),
			// 			0px 0px 0px 8px rgba($app-brand-primary, 0.15)
			// 		);
			// 	}
			// }
		}
	}
}

.form-group {
	.app-c-switch {
		margin-top: 5px;
		width: 75px;
	}
}

// custom toggle
.app-c-custom-toggle {
	@include flexbox();
	@include flex-direction(row);
	@include align-items(center);
	padding: 16px 22px;
	@media screen and (max-width: 767px) {
		padding: 16px 16px;
	}
	&.set-right {
		margin-left: auto;

		@include justify-content(flex-end);
	}
	&.no-wrapper {
		padding: 2px 0;
	}
	.app-c-custom-toggle__btn {
		padding: 4px 14px;
		margin: 0px 1px;
		background: rgba(247, 247, 247, 1);
		@include border-radius(6px);
		line-height: 15.6px;
		font-size: $text-md;
		border: none;
		&.active {
			background: $app-brand-primary;
			font-weight: $font-weight-bold;
			color: #fff;
			padding: 4px 18px;
			@include boxShadow(0px 0px 0px 2px $app-brand-primary);
		}
	}
}

.app-c-toggle-switch {
	input {
		opacity: 0;
	}
	position: relative;
	label {
		display: block;
		width: 100%;
		position: relative;
		background: rgba(247, 247, 247, 1);
		@include border-radius(6px);
	}
	.app-c-switch-label {
		width: 50%;
		display: inline-block;
		position: relative;
		z-index: 2;
		text-align: center;
		line-height: 32px;
		font-size: $text-md;
		cursor: pointer;
	}
	.app-c-switch--on {
		color: $white;
	}
	.app-c-switch--bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 50%;
		height: 32px;
		background: var(--theme-primary-color);
		@include boxShadow(0px 0px 0px 2px var(--theme-primary-color));
		@include transition(all ease 0.2s);
		@include border-radius(6px);
	}

	input {
		z-index: 1;
		width: 10px;
		height: 10px;
		position: absolute;
		top: -20px;
		left: -20px;
		right: auto;
		bottom: auto;
		margin: 0;

		&[type='checkbox']:checked {
			+ .app-c-switch--bg {
				left: 50%;
			}
			~ .app-c-switch--on {
				color: #61636f;
			}
			~ .app-c-switch--off {
				color: $white;
			}

			// &:focus {
			// 	+ .app-c-switch--icon {
			// 		@include boxShadow(
			// 			0px 1px 4px rgba(17, 25, 45, 0.1),
			// 			0px 0px 0px 8px rgba($app-toggle-active-bg, 0.35)
			// 		);
			// 	}
			// }
		}
		// &[type='checkbox']:focus {
		// 	+ .app-c-switch--icon {
		// 		@include boxShadow(
		// 			0px 1px 4px rgba(17, 25, 45, 0.1),
		// 			0px 0px 0px 8px rgba($app-brand-primary, 0.15)
		// 		);
		// 	}
		// }
	}
}
