.selfServeSignUpPage {
	display: grid;
	height: 100%;
	grid-template-columns: 1fr 1fr auto;
	@media only screen and (max-width: 991px) {
		display: block;
	}
	.logoBox {
		position: absolute;
		top: 40px;
		left: 40px;
		display: block;
		@media only screen and (max-width: 991px) {
			position: relative;
			top: inherit;
			left: inherit;
			padding: 20px 20px;
		}
		img {
			height: 80px;
		}
	}
	.signUpGraphics {
		max-width: 715px;
		max-height: 646px;
		right: -111px;
		position: absolute;
		margin: auto;
		top: 0;
		bottom: 0;

		@media only screen and (max-width: 1600px) {
			max-height: calc(100% - 35%);
			max-width: 100%;
			right: -11%;
		}
		@media only screen and (max-width: 991px) {
			display: none;
		}
	}
}
.selfServeSignUpPage--blocks {
	@include flexbox();
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	@media only screen and (max-width: 991px) {
		height: auto;
	}
	&.leftWrapper {
		background: $base-bg;
	}
	&.rightWrapper {
		padding: 40px;
		@media only screen and (max-width: 991px) {
			padding: 40px 16px;
		}
	}
	h3 {
		font-size: 28px;
		line-height: 28px;
		margin-bottom: 16px;
		font-weight: $font-weight-normal;
		position: relative;
		padding: 0px 20px;
		@include flexbox();
		@include align-items(center);
		@include justify-content(center);
		min-width: 380px;

		.app-c-btn {
			position: absolute;
			left: 0;
			top: 1px;
		}

		&.capitalize {
			text-transform: capitalize;
		}
	}
	.formWrapper {
		max-width: 380px;
		width: 100%;
		.formHead {
			text-align: center;
			position: relative;
			padding-bottom: 16px;

			p {
				font-size: $text-xl;
				line-height: 24px;
				margin-bottom: 16px;
				&:last-child {
					margin-bottom: 0;
				}
				strong {
					font-style: italic;
				}
			}
		}
		.app-c-btn {
			padding: 10px 24px;
			min-height: 40px;
			@include border-radius(4px);
		}
	}
}

.signUpPricing {
	font-size: 40px;
	line-height: 40px;
	margin-bottom: 8px;
	font-weight: $font-weight-normal;
}
.business-type-selector {
	display: block;
}
.type-selector-btn {
	display: block;
	width: 100%;
	line-height: 20px;
	padding: 18px 24px;
	background: $aquaHaze;
	border: 3px solid $aquaHaze;
	@include transition(all ease 0.2s);
	font-size: 20px;
	margin-bottom: 24px;
	@include border-radius(8px);
	&:hover {
		border-color: $newslide-1;
		background: $white;
	}
	&.selected {
		border-color: $app-brand-primary;
		background: $app-brand-primary-selected;
		color: $app-brand-primary;
	}
	&.inline {
		display: inline-block;
		width: auto;
	}
}
.subscription-note {
	padding: 8px;
	@include border-radius(4px);
	background: $aquaHaze;
	margin-bottom: 16px;
	p {
		line-height: 13.75px;
		font-size: $text-sm-plus;
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.brandingSetup {
	display: block;
}
.app-c-pic-color {
	@include flexbox();
	position: relative;
	flex-wrap: nowrap;
	align-items: center;
	margin-top: 4px;
	margin-bottom: 48px;
	button {
		border: 2px solid $newslide-1;
		background: $aquaHaze;
		width: 80px;
		height: 80px;
		flex-shrink: (0);
		margin-right: 24px;
		@include border-radius(8px);
	}
	span {
		font-size: 22px;
		line-height: 22px;
	}
	.slide-controls-dp {
		bottom: 102px;
		max-width: 100%;
		position: absolute;
	}
}
.inputHolder {
	position: relative;
	.app-c-btn {
		position: absolute;
		margin: auto;
		right: 10px;
		top: 0;
		bottom: 0;
		padding: 0 !important;
		width: auto;
		min-height: auto;
		line-height: unset;
	}
}
.largeIcon {
	@include flexbox();
	width: 100px;
	height: 100px;
	@include border-radius(50%);
	align-self: center;
	margin: auto;
	@include justify-content(center);
	@include align-items(center);
	border: 2px solid $default-green;
	font-size: 40px;
	color: $default-green;
	margin-bottom: 30px;
}
