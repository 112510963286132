@import '../core/mixins/border-radius';
.app-c-badge {
	padding: 7px 8px;
	font-weight: normal !important;
	font-size: 12px;
	line-height: normal;
	line-height: 15px;
	border: solid 1px transparent;

	@include border-radius(5px);

	+ .app-c-badge {
		margin-left: 6px;
	}

	&.app-c-grid-badge {
		padding: 7px 8px;
		font-size: 12px;
		line-height: normal;

		background: #e9ebf4;
		color: #1e1919;
		@include border-radius(5px);
	}
	&.app-c-badge--small {
		padding: 2px 15px 0px 15px !important;
		font-size: $text-sm-plus;
		text-transform: capitalize;
	}
	&.app-c-badge--round {
		width: 8px;
		height: 8px;
		@include border-radius(50%);
		background: #ccc;
		padding: 0px;
		font-size: 0;
		display: inline-block;
		margin-right: 10px;
		.rtl &,
		[dir='rtl'] & {
			margin-right: 0px;
			margin-left: 10px;
		}
	}
	
	&.app-c-badge--published,&.app-c-badge--active {
		background: $publishedColor;
	}
	&.app-c-badge--draft,&.app-c-badge--pending {
		background: $draftColor;
	}
	&.app-c-badge--archieve,&.app-c-badge--inactive {
		background: $archievedColor;
	}
	&.app-c-badge--review {
		background: $inProgressColor;
	}
	&.app-c-badge--in-progress {
		background: $inProgressColor;
	}
	&.app-c-badge--overdue {
		background: $overDueColor;
	}
	&.app-c-badge--completed {
		background: $completedColor;
	}
	
}
