.app-l-vertical-content-page {
	&.app-l-personal-information,
	&.app-l-personal-details {
		.app-c-card--default {
			.app-l-card-header {
				.app-l-card-title {
					border-bottom: 1px solid $app-card-border;
					font-size: $text-xl !important;
					padding: 16px 24px;
					line-height: 17px !important;
				}
			}
			.app-l-card-body {
				padding: 15px 24px 24px 24px;
				.app-c-pro-pic {
					width: 60px;
					height: 60px;
					@include border-radius(50%);
					@include flexbox();
					@include align-items(center);
					@include justify-content(center);
					background-color: $app-no-pic;
					margin-right: $lgs;
					button {
						background: none;
						border: none;
						padding: 0;
						@include flexbox();
					}
					.icon-camera {
						font-size: 22px;
						color: $app-brand-primary;
					}
				}
				.app-c-pro-pic-updated {
					width: 60px;
					height: 60px;
					@include border-radius(50%);
					// @include flexbox();
					@include align-items(center);
					@include justify-content(center);
					margin-right: $lgs;
					position: relative;
					transition: 0.3s all;
					&:hover {
						background: rgba(0, 33, 251, 0.4);
						.app-c-pro-overlay {
							background: rgba(0, 33, 251, 0.4);
						}
					}
					img {
						width: 60px;
						height: 60px;
						@include border-radius(50%);
						object-fit: cover;
					}
					button.camera {
						position: absolute;
						top: 0;
						left: 0;
						bottom: 0;
						right: 0;
						margin: auto;
						width: 60px;
						height: 60px;
						padding: 0;
						display: flex;
						align-items: center;
						justify-content: center;
						background: none;
						text-align: center;
						@include noShadow();
						border: none;
						color: #e1e1e1;
						opacity: 0;
						transition: all 0.5s;
						.icon-camera {
							font-size: 22px;
						}
						&:hover {
							color: $white;
						}
					}
					.app-c-pro-overlay {
						width: 100%;
						transition: 0.3s all;
						height: 100%;
						position: absolute;
						@include border-radius(50%);
					}
					button.delete {
						position: absolute;
						content: '';
						background: $white;
						@include border-radius(50%);
						width: 20px;
						height: 20px;
						@include flexbox();
						@include align-items(center);
						@include justify-content(center);
						bottom: 0px;
						z-index: 3;
						right: 0px;
						box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
						cursor: pointer;
						font-size: $text-md;
						border: none;
						transition: all 0.5s;
						&:hover {
							background-color: $app-brand-primary;
							color: $white;
						}
					}
					&:hover {
						button.camera {
							opacity: 1;
						}
					}
				}
				.app-c-pro-details {
					.app-c-pro-name {
						font-weight: $font-weight-semibold;
						font-size: $lgs;
						line-height: 16px;
						margin-top: 9px;
						@media screen and (max-width: 767px) {
							font-size: $text-xl;
						}
					}
					.app-c-pro-role {
						font-weight: $font-weight-normal;
						font-size: $text-md;
						line-height: 16px;
					}
				}
				.app-c-personal-form {
					padding-top: $lgs;
					.date-between {
						.date-fiels {
							width: 100%;
						}
					}
					.app-c-custom-select {
						.app-c-flag {
							position: absolute;
							left: 10px;
							top: $text-sm-plus;
						}
						.form-control {
							padding-left: 35px;
						}
						.app-c-client-select {
							padding-left: 12px;
						}
					}
					.app-c-phone-flag {
						@include flexbox();
						background-color: $gray04;
						width: 50px;
						height: 38px;
						@include align-items(center);
						@include justify-content(center);
						@include border-radius(4px 0px 0px 4px);
						position: absolute;
						top: 28px;
						left: 1px;
						img {
							margin-right: $sm;
						}
						.icon-downArrow {
							font-size: 9px;
						}
					}
					.app-c-form-group {
						margin-bottom: 23px;
						&.app-c-form-phone {
							position: relative;
							input {
								padding-left: 55px;
							}
						}
					}
				}
				.app-c-toggle-switch {
					input {
						opacity: 0;
					}
					.app-c-switch--bg {
						height: 28px;
					}
					.app-c-switch-label {
						line-height: 22px;
						&.app-c-switch--off {
							line-height: 24px;
							color: #61636f;
						}
					}
				}
				&.app-l-language-container {
					padding: 35px 24px 35px 24px;
					@media screen and (max-width: 767px) {
						padding: 20px 27px 20px;
					}
				}
			}
		}
		.app-c-toggle-switch input[type='checkbox']:checked ~ .app-c-switch--off {
			color: #fff !important;
		}
		&.app-l-client-account {
			.app-l-card-body {
				padding: 24px;
			}
		}
	}
	.app-l-content-main {
		padding-left: 8px;
		@media screen and (max-width: 991px) {
			padding-left: 15px;
		}
	}
	.app-l-vertical-content-custom {
		padding-right: 0px;
		@media screen and (max-width: 991px) {
			padding-right: 15px;
		}
	}
}

// 28/12/21
.students-dashboard-holder {
	.app-c-flex-card-wrapper {
		margin: 0;
	}
	.courses-passed-card {
		@media screen and (max-width: 980px) {
			&:last-child {
				margin-right: 4px !important;
			}
			&:first-child {
				margin-left: 4px !important;
			}
		}
		@media screen and (max-width: 640px) {
			margin-right: 4px !important;
			margin-bottom: 6px !important;
			margin-top: 0 !important;
			margin-left: 4px !important;
			flex: 1 0 48% !important;
		}
	}
	.app-c-status-tile {
		position: relative;
		.app-c-course-more {
			background: transparent !important;
			border: none !important;
			padding: 0;
			box-shadow: none !important;
			position: absolute;
			bottom: 26px;
			right: 24px;
			color: $app-brand-primary !important;
			text-transform: capitalize;
			@media screen and (max-width: 1200px) {
				bottom: 20px;
			}
			@media screen and (max-width: 768px) {
				bottom: 21px;
			}
			&:hover {
				opacity: 0.8;
			}
		}
	}
	.app-l-memory-check {
		width: calc(100% - 8px);
		@include boxShadow(0px 2px 8px rgba(0, 0, 0, 0.135216));
		@include border-radius(8px);
		background: #fff;
		margin: 0 4px 8px;
		padding: 24px;
		@include flexbox;
		@include align-items(center);
		@media screen and (max-width: 980px) {
			margin-right: 4px !important;
		}
		@media screen and (max-width: 768px) {
			flex-wrap: wrap;
		}
		.title {
			div {
				margin-top: -3px;
			}
		}
		.app-l-description {
			padding-left: 20px;
			@media screen and (max-width: 480px) {
				width: calc(100% - 38px);
			}
			h5,
			p {
				margin: 0;
			}
			h5 {
				font-size: 20px;
				line-height: 16px;
				margin-bottom: 8px;
				display: block;
				color: var(--theme-primary-color);
				font-weight: 500;
				@media screen and (max-width: 480px) {
					font-size: 16px;
				}
			}
			p {
				color: #61636f;
				font-size: 12px;
				line-height: 13px;
			}
		}
		.app-c-btn--addon {
			margin-left: auto;
			padding: 10px 16px;
			@media screen and (max-width: 480px) {
				margin-left: 58px;
				margin-top: 10px;
			}
		}
	}
}

.app-l-student-course-progress {
	overflow: auto;
	@media screen and (max-width: 1200px) {
		flex-basis: 100% !important;
	}
	@media screen and (max-width: 640px) {
		padding: 12px !important;
		margin: 0 4px 10px !important;
	}
	.app-c-pathway__wrapper {
		margin-bottom: 10px !important;
	}
	.app-c-pathway__wrapper {
		margin: 0;
	}
	.course-progress-bar-wrapper {
		margin: 0 !important;
		padding-top: 5px !important;
		.app-c-progress-bar {
			@include boxShadow(0px 2px 8px rgba(0, 0, 0, 0.135216));
			@include border-radius(8px);
			background: #fff !important;
			display: flex;
			padding: 0 !important;
			width: 100% !important;
			border-radius: 4px !important;
			cursor: pointer;
			& + .app-c-progress-bar {
				margin-top: 10px !important;
			}
			&:last-child {
				margin-bottom: 8px !important;
			}
			@media screen and (max-width: 640px) {
				flex-direction: column;
				justify-content: center;
				width: calc(100% - -8px) !important;
			}
			.app-l-course-image {
				flex: 0 0 80px;
				overflow: hidden;
				margin-right: 13px;
				margin-left: 13px;
				img {
					object-fit: contain;
					width: 80px;
					height: 100%;
				}
				@media screen and (max-width: 640px) {
					margin: auto;
					img {
						height: 80px;
					}
				}
			}
			.app-l-course-details {
				width: 100% !important;
				padding: 10px 10px 10px 0px;
				overflow: auto;
				@media screen and (max-width: 640px) {
					padding: 10px;
				}
				.app-c-subText {
					margin-bottom: 5px;
				}
				.app-c-progress-bar__file-loaded {
					margin-bottom: 5px;
					align-items: flex-end;

					.app-c-progress-bar__file-name {
					}
				}
				.app-c-progress-bar-wrapper {
					margin-bottom: 10px !important;
				}
				.app-l-course-schedule-details {
					display: flex;
					flex-direction: column;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
					max-width: 90%;
					.app-c-progress-bar__file-name {
						max-width: 100% !important;
						@media screen and (max-width: 1200px) {
							max-width: 500px !important;
						}
						@media screen and (max-width: 860px) {
							max-width: 400px !important;
						}
						@media screen and (max-width: 730px) {
							max-width: 300px !important;
						}
						@media screen and (max-width: 500px) {
							max-width: 250px !important;
						}
						@media screen and (max-width: 440px) {
							max-width: 200px !important;
						}
						@media screen and (max-width: 380px) {
							max-width: 150px !important;
						}
					}
				}
				.app-c-progress-bar__file-inpercent {
					.app-c-progress-bar__percent {
						flex-direction: column;
						display: FLEX;
						font-size: 12px !important;

						span {
							margin-top: 6px;
							display: block;
							font-size: 14px !important;
							line-height: normal !important;
							color: var(--theme-primary-color);
							font-weight: 700;
						}
					}
				}
				.app-l-progress__details {
					display: flex;
					.app-c-progress-bar__percent,
					.tooltip-course--row {
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						margin-right: 30px;
						@media screen and (max-width: 640px) {
							margin-right: 10px;
						}
						div {
							text-align: left;
							margin: 0;
						}
						.tooltip-course--val {
							font-size: 14px !important;
							line-height: normal !important;
							color: var(--theme-primary-color);
							font-weight: 700;
							span {
								font-weight: 700;
							}
						}
						.tooltip-course--title {
							color: #61636f;
							font-size: 12px;
							line-height: 13px;
							font-weight: normal;
						}
					}
					.app-c-progress-bar__percent {
						color: #61636f;
						font-size: 12px;
						line-height: 13px;
						span {
							font-size: 17px !important;
							line-height: normal !important;
							color: var(--theme-primary-color);
							font-weight: 500;
						}
					}
					.tooltip-course {
						display: flex;
						flex-direction: row;
					}
				}
				.app-c-progress-bar__file-inpercent {
					margin-top: -2px !important;
				}
			}
		}
	}
}
.app-c-skill-profile-holder {
	@media screen and (max-width: 640px) {
		margin: 0 4px !important;
		padding: 12px !important;
	}
	.app-c-skill-indicators {
		margin-left: 2px;
	}
	.app-l-my-skills-header {
		width: 100%;
		padding: 0 2px;
		@media screen and (max-width: 1200px) {
			padding-bottom: 10px !important;
		}
		div {
			margin-top: -2px;
		}
		button {
			margin-left: auto;
			margin-bottom: 0 !important;
		}
	}
	.course-progress-bar-wrapper {
		margin: 0 !important;
		padding: 0 15px 0 0 !important;
		.app-c-progress-bar {
			pointer-events: none;
		}
	}
}

.skills__table {
	width: 100%;

	thead,
	tbody {
		tr {
			th,
			td {
				&:not(:first-child) {
					width: 61px;
					text-align: center;
				}
			}
			th {
				background: rgba(233, 235, 244, 0.5) !important;
				border: none !important;
				font-weight: 600 !important;
				font-size: 13px !important;
				line-height: normal !important;
				text-transform: capitalize !important;
				color: #61636f !important;
				padding: 12px 16px 10px !important;
				position: relative !important;
				vertical-align: middle !important;
				white-space: nowrap;
			}
			td {
				font-size: 14px !important;
				line-height: normal !important;
				color: #1e1919 !important;
				padding: 12px 16px 5px !important;
				position: relative !important;
				vertical-align: middle !important;
			}
		}
	}

	thead {
		position: sticky;
		top: 0;
		z-index: 1;
		background: $white;
		tr {
			th {
				line-height: 1;
				color: $text-muted;
				font-weight: $font-weight-semibold;
				font-size: $text-lg;
				text-transform: uppercase;
				padding-bottom: 26px;
				padding-top: 14px;
			}
		}
	}
}
.app-l-my-skills-img-block {
	padding-left: 5px;
	em {
		font-size: 15px;
		position: relative;
		img {
			position: absolute;
			width: 11px;
			top: -5px;
			right: -5px;
			background: #fff;
			border-radius: 50%;
		}
	}
}
.noCourseData {
	display: flex;
	flex-direction: column;
	min-height: 350px;
	justify-content: center;
	align-items: center;
	div {
		display: flex;
		flex-direction: column;
	}
	img {
		height: 80px;
	}
	span {
		font-weight: 500;
		font-size: 15px;
		padding-top: 10px;
	}
}
.app-c-modal-profile-section {
	display: flex;
	align-items: center;
	flex-direction: column;
	margin: auto;
}
