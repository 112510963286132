.nav-item-wrapper {
	width: 100%;
	@include flexbox;
}

.app-c-student-assessment-details {
	.description,
	.title {
		font-size: $text-xl;
	}

	video,
	img {
		border-radius: 5px;
		width: 100%;
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
		display: block;
	}

	.link-more {
		font-weight: $font-weight-semibold;
		margin-left: 10px;
		cursor: pointer;
		text-decoration: none;
		color: $faded-blue-one;
	}

	.app-c-assessment-btn-group {
		@include flex-direction(column);
		@include flexbox();
		@include flex-direction(row);
		flex-wrap: wrap;

		.app-c-btn--link {
			background: $white;
			border: 1px solid $shade-grey;
			box-shadow: 0px 1px 0px $shade-grey;
			text-decoration: none;
			margin-bottom: 16px;
			// flex: 1 0 40%;
			margin: 10px;
			width: calc(100% / 2 - 20px);

			@media screen and (max-width: 991px) {
				flex: 1 1 100%;
				margin-left: 0;
			}

			.icon-rightArrow {
				margin-left: auto;
				margin-right: 0;
			}

			&:hover {
				box-shadow: 0px 0px 5px 0px var(--theme-primary-color-600);
				-webkit-box-shadow: 0px 0px 5px 0px var(--theme-primary-color-600);
				border: 1px solid $app-brand-primary;
			}

			.app-c-text {
				font-size: $text-xl;
				font-weight: $font-weight-normal;
				color: $black;
			}
		}
	}

	.ContextualNavHolder.assessment-details {
		@include border-radius(8px);
		@include flexbox();
		overflow: unset;
		margin-top: 0px;

		&.app-c-expand {
			.nav-pills {
				flex-basis: 200px;

				@media screen and (max-width: 991px) {
					flex-basis: 15px;
					overflow: hidden;
				}

				.nav-link {
					max-width: 200px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					padding: 25px 1rem !important;
					padding-right: 35px;
					margin: 0px;
					font-size: 16px;
					text-decoration: none;

					&.nav-item-wrapper {
						> div[data-tooltipped] {
							display: block !important;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
					}

					> div {
						font-size: 16px;
						font-weight: $font-weight-normal;
					}

					&::before {
						content: none !important;
					}

					@media screen and (max-width: 991px) {
						max-width: 125px;
						font-size: $text-lg;
						white-space: nowrap;
						padding: 15px 20px;
						padding-right: 38px;
					}

					.app-c-info {
						position: relative;
						top: -1px;
						right: 5px;
					}

					&.active {
						background: $faded-blue-one !important;
						color: $white !important;

						div {
							.icon-info {
								color: $white;
							}
						}
					}
				}
			}

			.nav-item {
				transition: all 0.5s ease;
				margin-right: 0px !important;

				@media screen and (max-width: 991px) {
					display: none;
				}
			}

			.tab-content {
				.app-c-nav-toggle-btn {
					i {
						transform: rotate(180deg);
						transition: all 0.5s ease;

						@media screen and (max-width: 991px) {
							transform: rotate(0deg);
						}
					}
				}
			}
		}

		&.app-c-collapse {
			.nav-pills {
				flex-basis: 15px;
				transition: all 0.5s ease;
				overflow: hidden;

				@media screen and (max-width: 991px) {
					flex-basis: 200px;
					overflow: hidden;

					.nav-link {
						max-width: 200px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;

						&.active {
							background: $faded-blue-one;
							color: $white;
						}

						@media screen and (max-width: 991px) {
							max-width: 160px;
							font-size: $text-lg;
							white-space: nowrap;
							padding: 15px 10px;
							opacity: 1;
							width: 100%;
							margin: 0;
						}

						.app-c-info {
							position: relative;
							top: -1px;
							right: 5px;
						}
					}
				}

				.nav-item {
					display: none;
					width: 15px;
					transition: all 0.5s ease;

					@media screen and (max-width: 991px) {
						display: block;
						width: 100%;
					}
				}
			}

			.app-c-nav-toggle-btn {
				i {
					transform: rotate(180deg);
					transition: all 0.5s ease;

					@media screen and (max-width: 991px) {
						transform: rotate(180deg) !important;
					}
				}
			}

			.tab-content {
				.app-c-nav-toggle-btn {
					i {
						transform: rotate(0deg);
						transition: all 0.5s ease;
					}
				}
			}
		}

		.tab-content {
			position: relative;

			@media screen and (max-width: 991px) {
				padding: 15px 15px 15px 15px;
				flex-basis: 100%;
			}

			.app-c-nav-toggle-btn {
				position: absolute;
				width: 24px;
				height: 24px;
				background: $white;
				color: $faded-blue-one;
				@include border-radius(50%);
				border: 1px solid $white;
				@include flexbox;
				@include align-items(center);
				@include justify-content(center);
				box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.35);
				left: -12px;
				top: 19px;
				font-size: $text-xsm;
				padding: 0;
				cursor: pointer;

				@media screen and (max-width: 991px) {
					top: 15px;
				}
			}

			.app-c-tab-content-header {
				h6 {
					font-size: $text-xl;

					@media screen and (max-width: 991px) {
						font-size: $text-lg;
					}
				}

				.app-c-sub-title {
					font-size: $text-xl;
					margin: 20px 0px;
				}

				&.rubrics-header {
					.main-header {
						color: #595858;
						font-size: 20px;

						.percent-header {
							color: #909090;
						}
					}

					.sub-header {
						color: #909090;
						font-size: $text-xl;
						margin-top: 15px;
					}
				}
			}

			&.rubrics {
				padding-left: 40px;
			}

			.app-c-tab-content-body.rubrics-body {
				border-top: 1px solid #ccc;
				padding-top: 20px;
				margin-top: 20px;

				.secondary-header {
					font-size: $text-xl;
					margin-bottom: 10px;
					display: block;
					font-weight: $font-weight-semibold;
				}

				.app-c-descrptn {
					font-size: $text-lg;
				}
			}
		}
	}

	.app-c-upload-media-default {
		margin-bottom: 20px;

		.app-c-youtube-video {
			@include flexbox();
			@include align-items(center);
			text-align: center;
			@include justify-content(center);

			iframe {
				max-width: 400px;
				width: 100%;
				border-radius: 5px;
			}
		}
	}

	.app-c-file-details {
		text-align: center;
		font-weight: normal;
		font-size: 14px;
		line-height: normal;
		width: 100%;
		display: block;
		padding: 10px 0 0;
		background-color: transparent;
		font-style: italic;
	}

	.app-c-image-container {
		@include flexbox();
		margin-bottom: 20px;
		@include align-items(center);
		@include justify-content(center);

		.image-ins-wrapper {
			@include flexbox();
			@include flex-direction(column);

			.expand-button {
				position: absolute;
				width: 30px;
				height: 30px;
				background: $newslide-hover;
				border-radius: 50px;
				top: 10px;
				right: 10px;
				font-size: 12px;
				color: #61636f !important;
				z-index: 1;
				font-weight: normal;
				border-radius: 0;

				&:hover {
					background: $app-brand-primary;
					color: $white !important;
				}

				.rtl &,
				[dir='rtl'] & {
					right: auto;
					left: 8px;
				}
			}
		}

		.app-c-profile-default__media {
			width: 400px;
			position: relative;

			@media (max-width: 992px) {
				width: 100%;
			}

			img,
			video {
				width: 100%;
			}
		}

		@media screen and (max-width: 502px) {
			@include flex-direction(column);
			@include align-items(flex-start);
			margin-bottom: 10px;
		}

		&.app-c-evidence-container {
			margin-top: 20px;
			justify-content: flex-start;
			flex-wrap: wrap;

			@media screen and (max-width: 991px) {
				flex-direction: row;
			}
		}
	}

	.app-c-profile-default__avatar {
		min-width: 60px;
		width: 70px;
		height: 70px;
		min-height: 60px;
		background: $tag-chip-bg;
		@include flexbox();
		@include flex-direction(column);
		@include justify-content(center);
		@include align-items(center);
		margin-right: 10px;
		position: relative;

		@media screen and (max-width: 991px) {
			margin-right: 0px;
			margin-bottom: 10px;
		}

		.rtl &,
		[dir='rtl'] & {
			margin-right: 0;
			margin-left: 20px;
			text-align: left;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: scale-down;
		}

		.expand-button {
			cursor: pointer;
			position: absolute;
			top: 5px;
			right: 5px;
			font-size: $text-lg;
			@include border-radius(0);
		}
	}

	.app-c-evidence-default__avatar {
		min-width: 80px;
		width: 100px;
		height: 100px;
		min-height: 80px;
		background: $tag-chip-bg;
		@include flexbox();
		@include flex-direction(column);
		@include justify-content(center);
		@include align-items(center);
		margin-right: 10px;
		margin-bottom: 10px;
		position: relative;

		.app-c-evidence-default__thumbnail {
			@include flexbox();
			@include flex-direction(column);
			@include justify-content(center);
			@include align-items(center);
			min-width: 80px;
			width: 100px;
			height: 100px;
		}

		&.default--evidence {
			border-radius: 4px;

			.playbtn {
				background-color: transparent;
				border: 0;
				box-shadow: none;
				position: absolute;
				z-index: 1;
				opacity: 0.7;
				transition: 0.4s all;

				img {
					width: 50px;
					border-radius: 50%;
				}

				&:hover {
					opacity: 1;
				}
			}

			&.evidence-play {
				.app-c-evidence-default__thumbnail {
					&:before {
						content: '';
						position: absolute;
						width: 100%;
						height: 100%;
						background: rgba($color: $tag-chip-bg, $alpha: 0.4);
						@include flexbox();
						@include justify-content(center);
						@include align-items(center);
						z-index: 1;
						left: 0;
					}
				}
			}
		}

		.rtl &,
		[dir='rtl'] & {
			margin-right: 0;
			margin-left: 20px;
			text-align: left;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.expand-button {
			cursor: pointer;
			position: absolute;
			top: 5px;
			right: 5px;
			font-size: $text-lg;
			@include border-radius(0);
			color: $text-muted;
			background: rgba(255, 255, 255, 0.5);
			width: 20px;
			height: 20px;
			z-index: 1;

			&:hover {
				background-color: $app-brand-primary;
				color: $white;
			}
		}
	}

	.app-c-tab-content-body {
		max-height: calc(100vh - 280px);
		overflow-y: auto;
		padding-right: 30px;

		@media screen and (max-width: 991px) {
			padding-right: 10px;
			max-height: calc(100vh - 210px);
		}

		.app-c-description {
			font-size: $text-lg;
		}

		ul {
			li {
				font-size: $text-lg;
				margin-bottom: 10px;
			}
		}

		&.app-c-tab-content-body-checklist {
			padding: 10px 10px 10px 0px;

			.app-c-checklist-container {
				.app-c-checklist-item {
					box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1352);
					-webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1352);
					-moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1352);
					padding: 10px 10px 10px 10px;
					margin-bottom: 20px;
					border-radius: 5px;

					@media print {
						border-top: 1px solid rgba(0, 0, 0, 0.1352);
						border-right: 1px solid rgba(0, 0, 0, 0.1352);
						border-bottom: 1px solid rgba(0, 0, 0, 0.1352);
					}

					.app-c-item-text {
						margin-bottom: 0px;
					}

					margin-left: 3px;
					border-left: 9px solid #e8e7e7;

					&.checked {
						border-left: 9px solid $green-checked;
					}

					&.unchecked {
						border-left: 9px solid #f399a7;
					}
				}
			}
		}
	}

	.app-c-box-youtube {
		background: rgba(233, 235, 244, 0.8);
		padding: 20px;
		margin-bottom: 20px;
		width: 60%;

		@media screen and (max-width: 991px) {
			padding: 10px;
			width: 100%;
		}

		.media {
			@media screen and (max-width: 991px) {
				@include flex-direction(column);
			}
		}

		.media-img {
			width: 110px;
			height: 90px;
			@include border-radius(2px);
			margin-right: 15px;
			background: rgb(216, 219, 230);
			@include flexbox();
			@include align-items(center);
			@include justify-content(center);

			@media screen and (max-width: 991px) {
				width: 100%;
				height: 50px;
			}
		}

		.media-body {
			.media-title {
				margin-top: 0px;

				@media screen and (max-width: 991px) {
					font-size: $text-lg;
					margin-top: 10px;
				}
			}
		}

		.app-c-link-youtube {
			font-weight: $font-weight-semibold;
			margin-left: 0px;
			cursor: pointer;
			text-decoration: none;
			color: $faded-blue-one;
			margin-top: auto;
			font-size: $text-lg;
			margin-bottom: 20px;
			cursor: pointer;

			@media screen and (max-width: 991px) {
				word-break: break-word;
				font-size: $text-md;
			}
		}
	}

	.app-c-box-attachment {
		background: rgba(233, 235, 244, 0.8);
		padding: 10px 15px;
		margin-bottom: 20px;
		border-radius: 5px;
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
		display: block;
		word-break: break-word;

		@media screen and (max-width: 991px) {
			width: 100%;
			padding: 10px 10px;
		}

		.media {
			@include flexbox();
			@include align-items(center);

			@media screen and (max-width: 550px) {
				@include flex-direction(column);
			}

			.app-c-attachment-icon {
			}

			.app-c-attachment-details {
				@include flexbox();
				@include flex-direction(column);
				margin-left: 10px;

				@media screen and (max-width: 550px) {
					margin-left: 0px;
				}

				.title {
					font-size: $text-lg;
					text-decoration: underline;
					cursor: pointer;
					color: $faded-blue-one;
				}

				.date {
					font-size: $text-md;
					color: #848484;
				}
			}
		}
	}
}

.app-c-common-tabs__conten.assessmentt {
	@media screen and (max-width: 991px) {
		padding: 0px !important;
	}
}

.app-c-tab-assessmement {
	.nav {
		border: none;
		overflow: hidden;
		overflow-x: auto;
		white-space: nowrap;
		@include flex-wrap(nowrap);

		@media (max-width: 767px) {
			&::-webkit-scrollbar {
				height: 4px;
			}
		}

		.nav-item {
			margin-right: 5px;

			.nav-link {
				color: rgba(97, 99, 111, 1);
				font-size: $text-lg;
				font-weight: $font-weight-semibold;
				position: relative;
				background: none;
				border: 0;
				@include border-radius(0);
				padding: 0 0px 17px 0px;
				@include transition(width 0.3s ease, background 0.3s ease);
				@include flexbox();
				@include align-items(center);
				max-width: 160px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				display: block;

				> div[data-tooltipped] {
					font-size: 14px;
				}

				@media screen and (max-width: 767px) {
					margin: 0px 5px 4px 5px;
					font-size: $text-md;
					max-width: 80px;
				}

				.nav-number {
					width: 24px;
					height: 24px;
					margin-right: 10px;
					border: 1px solid $text-muted;
					@include border-radius(100%);
					@include flexbox();
					@include align-items(center);
					@include justify-content(center);

					.rtl &,
					[dir='rtl'] & {
						margin-right: 0 !important;
						margin-left: 10px;
					}

					span {
						color: $text-muted;
					}

					.icon-check {
						font-size: $text-xsm;
					}
				}

				&::before {
					content: '';
					width: 0;
					height: 0px;
					background: $faded-blue-one;
					position: absolute;
					bottom: 8px;
					left: 0px;
					right: 0px;
					margin: auto;
				}

				&.active,
				&:hover {
					border: none;
					background: none;
					color: $black;

					&::before {
						height: 2px;
						width: 100%;
						@include transition(width ease 0.3s);
					}

					.nav-number {
						width: 24px;
						height: 24px;
						margin-right: 10px;
						border: 1px solid $faded-blue-one;
						background-color: $faded-blue-one;
						border: 1px solid var(--theme-primary-color-200);
						position: relative;

						span {
							color: $white;
						}

						&:after {
							content: '';
							width: 28px;
							height: 28px;
							background: var(--theme-primary-color-200);
							@include border-radius(100%);
							position: absolute;
							left: -3px;
							right: 0;

							.rtl &,
							[dir='rtl'] & {
								left: 0;
								right: -3px;
							}
						}
					}
				}

				&:hover {
					&::before {
						width: 90%;
					}
				}

				// &:hover {
				//   outline: none;
				//   &:before {
				//     @media screen and (min-width: 1024px) {
				//       width: 100%;
				//     }
				//   }
				// }
			}

			+ .nav-item {
				margin-left: 0;
				margin-right: 0;

				a {
					margin: 0px 23px;

					@media screen and (max-width: 767px) {
						margin: 0px 10px;
					}
				}
			}
		}
	}

	.tab-content {
		padding-top: 8px;
		padding-bottom: 24px;

		.rtl &,
		[dir='rtl'] & {
			text-align: right;
		}

		@media screen and (max-width: 991px) {
			padding-top: 0px;
			padding-bottom: 24px;
		}
	}
}

.app-c-tab-assessmement {
	padding: 30px;

	@media screen and (max-width: 767px) {
		padding: 20px;
	}

	.app-l-vertical-content-page {
		padding: 30px 0px;
	}

	.tab-top {
		@include flexbox();
		@include align-items(center);
		position: relative;

		.app-c-nav-toggle-btn {
			width: 34px;
			height: 34px;
			background: $faded-blue-one;
			color: $white;
			@include border-radius(50%);
			border: 1px solid $white;
			@include flexbox();
			@include align-items(center);
			@include justify-content(center);
			font-size: $text-xsm;
			padding: 0;
			cursor: pointer;

			&.left {
				transform: rotate(180deg);
				position: relative;
				left: 0px;
			}

			&.disabled {
				background: $text-disabled;
				pointer-events: none;
			}

			@media screen and (max-width: 991px) {
				width: 24px;
				height: 24px;
			}
		}

		.left-nav {
			@media screen and (max-width: 767px) {
				position: absolute;
				right: -5px;
				top: 17px;
				z-index: 9;
			}
		}

		.right-nav {
			@media screen and (max-width: 767px) {
				position: relative;
				right: -5px;
				top: 0px;
				z-index: 9;
			}
		}
	}

	.app-c-common-tabs__nav.assessment {
		@media screen and (max-width: 767px) {
			margin-bottom: 20px;
		}

		&::-webkit-scrollbar {
			width: 4px;
			height: 5px;
		}

		&.nav {
			flex: 1 1 100%;
			margin: 0;

			.nav-item {
				.nav-link {
					&.nav-item-wrapper {
						> div[data-tooltipped] {
							display: block !important;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
					}
				}
			}

			@media screen and (max-width: 991px) {
				margin: 0px 0px 2px;
				padding: 15px 15px 0px 15px !important;
			}
		}
	}
}

.app-c-common-tabs__content {
	&.assessments {
		@media screen and (max-width: 767px) {
			padding: 0px !important;
		}
	}

	.summary-container {
		@include flexbox();
		@include align-items(center);
		padding: 34px;

		@media screen and (max-width: 992px) {
			padding: 20px;
		}

		@media screen and (max-width: 992px) {
			@include flex-direction(column);
		}

		.summary-even {
			flex: 1 1 20%;
			margin-right: 30px;

			@media screen and (max-width: 992px) {
				margin-right: 0px;
			}
		}

		.summary-odd {
			flex: 1 1 80%;

			@media screen and (max-width: 992px) {
				flex: 1 1 100%;
				width: 100%;
			}

			.summary-odd-top {
				margin-bottom: 20px;

				@media screen and (max-width: 992px) {
					margin-top: 20px;
				}

				.app-c-value-title {
					color: $default-checked;
					font-size: 28px;
					margin-bottom: 10px;

					&.-passed {
						color: $green-checked;
					}

					&.-failed {
						color: $red-checked;
					}

					@media screen and (max-width: 992px) {
						text-align: center;
					}
				}

				.app-c-value-sub {
					color: #1a1e26;
					font-size: $text-xl;
					font-weight: $font-weight-semibold;

					@media screen and (max-width: 992px) {
						text-align: center;
					}
				}
			}
		}

		.summary-bottom-box {
			background: #e9ebf4;
			padding: 18px;
			width: 100%;
			@include flexbox();

			@media screen and (max-width: 767px) {
				max-width: 100%;
				flex-direction: column;

				.bottom-box-right {
					margin-bottom: 10px;
				}

				.bottom-box-left,
				.bottom-box-right {
					margin-bottom: 10px;

					&::after {
						display: none;
					}
				}
			}

			.bottom-box-left,
			.bottom-box-right {
				&.dividor-box {
					position: relative;

					@media (max-width: 767px) {
						flex-direction: row;
						padding-right: 0;
						margin-right: 0;

						.app-c-box-middle {
							margin-left: auto;
						}
					}

					&:not(:last-child) {
						&::after {
							position: absolute;
							content: '';
							border-left: 1px solid #ccc;
							height: 100%;
							width: 1px;
							right: 0;
						}
					}
				}
			}

			.bottom-box-left {
				@include flexbox();
				@include flex-direction(column);
				flex: 1 1 50%;
				position: relative;
				margin-right: 10px;
				padding-right: 20px;

				@media screen and (max-width: 992px) {
					flex-basis: auto;
				}
			}

			.bottom-box-right {
				@include flexbox();
				@include flex-direction(column);
				flex: 1 1 50%;
				margin-right: 10px;
				padding-right: 20px;

				@media screen and (max-width: 992px) {
					flex-basis: auto;
				}

				&:last-child {
					flex: 1 1 100%;
					margin-right: 0;
					padding-right: 0;

					@media (max-width: 767px) {
						margin-bottom: 0;
					}
				}
			}

			.app-c-box-top {
				font-weight: $font-weight-normal;
				font-size: $text-lg;
				margin-bottom: 8px;
				white-space: nowrap;
			}

			.app-c-box-middle {
				font-weight: $font-weight-semibold;
			}
		}
	}

	.app-c-evaluation {
		height: 100%;
		padding: 10px;

		@media screen and (max-width: 991px) {
			padding: 20px;
		}

		.app-c-evaluation-title {
			font-size: $text-xl;
			color: #1a1e26;
			font-weight: $font-weight-semibold;
			margin-bottom: 10px;
		}

		.app-c-evaluation-item {
			ul {
				list-style: none;
				margin-bottom: 0;

				li {
					font-weight: $font-weight-normal;
					font-size: $text-lg;
					margin-bottom: 12px;

					&:last-child {
						margin-bottom: 0px;
					}
				}
			}
		}
	}

	.app-c-evaluation-comments {
		padding-left: 40px;

		@media screen and (max-width: 991px) {
			padding: 20px;
		}

		.app-c-evaluation-title {
			font-size: $text-xl;
			color: #1a1e26;
			font-weight: $font-weight-semibold;
			margin-bottom: 20px;
		}

		.app-c-comments-container {
			@include flexbox();

			.app-c-comments-left {
				margin-right: 10px;
				align-self: flex-start;

				img {
					min-width: 54px;
					min-height: 54px;
					object-fit: fill;
					background: #ccc;
					border-radius: 50%;
					max-width: 54px;
					max-height: 54px;
				}
			}

			.app-c-comments-right {
				@include flexbox();
				@include flex-direction(column);

				.app-c-title-name {
					font-weight: $font-weight-normal;
					font-size: $text-xl;
					margin-bottom: 8px;
				}

				.app-c-title-date {
					color: #999999;
					font-weight: $font-weight-normal;
					font-size: $text-lg;
					margin-bottom: 10px;
				}

				.app-c-title-decrptn {
					font-size: $text-lg;
				}
			}
		}

		.app-c-showmore {
			padding-left: 60px;
			color: var(--theme-primary-color);
			cursor: pointer;
			text-decoration: none;
			font-weight: $font-weight-semibold;
			font-size: 17px;
		}
	}
}

.linkTool-wrapper {
	.link-tool--content {
		@include flexbox;
		@include align-items(flex-start);

		@media (max-width: 767px) {
			flex-direction: column;

			.link-content-wrapper {
				order: 2;
				padding-right: 10px;

				a {
					word-break: break-all;
				}
			}

			.link-tool__image {
				order: 1;
			}
		}

		.link-tool__image {
			max-width: 100px;
			margin-left: 10px;
			margin-left: auto;
			margin-right: 0 !important;

			@media (max-width: 767px) {
				max-width: 100%;
				margin: 0 0 10px;
			}
		}
	}
}

.codex-editor--narrow .link-tool__image {
	display: block !important;
}

.link-tool {
	.link-tool__anchor {
		color: #17226f !important;
		font-size: 14px;
	}
}

.app-c-tab-assessmement .nav .nav-item .nav-link {
	&.nav-is-active {
		position: relative;
		padding-left: 26px;

		&:after {
			content: '\e902';
			font-size: 7px;
			font-family: 'qualify-tms';
			color: $white;
			position: absolute;
			background-color: $app-brand-primary;
			border-radius: 50%;
			@include flexbox;
			@include align-items(center);
			@include justify-content(center);
			width: 15px;
			height: 15px;
			top: 2px;
			left: 0px;

			@media (max-width: 992px) {
				top: 0;
			}
		}
	}
}

.ATTACHES_modal {
	height: 100vh;
	display: FLEX;
	margin: 0 auto !important;
}

.ATTACHES_modal-content {
	border: none !important;
	margin: 0 auto !important;
	// height: 100vh;
	display: FLEX;
	align-items: center;

	.app-l-preview-box {
		min-width: 350px;

		.app-c-file-attached {
			padding: 10px;
		}
	}
}

.codex-editor {
	z-index: auto;
}

.attachmentWrapper {
	padding-block: 10px;
}
