.spinner-center {
	color: white;
	margin: 30px auto;
	-ms-transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.spinner-container {
	z-index: 9999;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	position: fixed;
	background: rgba(255, 255, 255, 0.7);
}

.spinner {
	margin: 100px auto 0;
	width: 70px;
	text-align: center;
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-right: -50%;
	transform: translate(-50%, -50%);
}
.spinner-container.set-top .spinner {
	top: 10%;
}

.spinner > div {
	width: 18px;
	height: 18px;
	background-color: #536dfe;

	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
