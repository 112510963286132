//Card
//----------------------------------------------------

@import '../core/mixins/border-radius';
@import '../core/mixins/box-shadow';
@import '../core/mixins/flexbox';
@import '../core/mixins/transitions';
@import '../theme/theme';

.card {
	background: $white;
	margin: 15px 0px;
	border-color: $app-card-border;

	@include border-radius(3px);
	@include boxShadow(0px 2px 8px rgba(0, 0, 0, 0.135216));
	@include border-radius(8px);

	.card-header {
		border-color: $app-card-border;
		background-color: $app-card-header;
	}

	// .card-body {
	//     padding: 15px;
	// }

	.card-footer {
		background: transparent;
		border-color: $app-card-border;

		.app-c-btn {
			@media screen and (max-width: 600px) {
				display: block;
				width: 100%;
				+ .app-c-btn {
					margin-left: 0px;
					margin-right: 0px;
					margin-top: 10px;
				}
			}
		}
	}
}

.card-title {
	margin-bottom: 10px;
	color: $black;
	h5 {
		font-size: 18px;
		margin-bottom: 0px;
		margin-top: 0;
	}
}
.card-sub-title {
	margin-bottom: 8px;
	h6 {
		display: inline-block;
		font-size: 14px;
		margin-bottom: 0px;
		margin-top: 0;
	}
}

.app-c-card--default {
	background: $white;
	position: relative;

	@include boxShadow(0px 2px 8px rgba(0, 0, 0, 0.135216));
	@include border-radius(8px);

	&.app-c-card--no-shadow {
		@include noShadow();
	}
	&.app-c-card--grey {
		background: $app-card-grey;
	}
	.app-c-card__header {
		padding: 8px 16px;

		@include flexbox();
		@include align-items(center);

		@media screen and (max-width: 767px) {
			padding-left: 14px;
			padding-right: 14px;
		}

		.app-c-card__heading {
			color: $text-color;
			font-weight: 500;
			font-size: 15px;
			margin: 0;
		}
		.app-c-btn {
			margin-left: 8px;
			font-size: $text-sm-plus;
		}

		.app-l-card-head-left {
			@include flexbox();
			@include align-items(center);
		}
		.app-l-card-head-right {
			@include flexbox();
			@include align-items(center);

			margin-left: auto;

			&.app-l-high-res-controls {
				@media screen and (max-width: 991px) {
					display: none;
				}
			}
		}

		+ .app-c-card__body {
			border-top: solid 1px $neutrals-color3;
		}
	}
	.app-c-card__body {
		padding: 24px;
		@media screen and (max-width: 767px) {
			padding: 16px 16px 16px 16px;
		}
		&.less-padding {
			padding: 10px 24px 24px 24px;
			@media screen and (max-width: 767px) {
				padding: 16px 16px 16px 16px;
			}
		}

		h6 {
			line-height: 17px;
			font-size: $text-xl;
			margin-bottom: 16px;
			color: #1e1919;
			font-weight: $font-weight-semibold;
		}

		.rtl &,
		[dir='rtl'] & {
			text-align: right;
		}
	}

	.app-l-card-form {
		width: 100%;
		margin: 0;
	}
	&.app-c-card--full-width {
		@include border-radius(0px);
		@include boxShadow(0px 3px 6px #e3e3e3);
	}

	form {
		.app-c-form--inline-label {
			.app-l-form__inline-right {
				.app-l-form__inline-value {
					max-width: 60px;
					min-width: 0px;

					&.full-width {
						max-width: 100%;
					}
					&.max-180 {
						max-width: 180px;

						@media screen and (max-width: 480px) {
							max-width: 100%;
						}
					}
				}
			}
		}
	}

	&.app-l-sticky-page-bg {
		background: $app-card-grey;
	}
}

.app-l-card-group-holder {
	max-width: 1400px;
	margin: -8px;

	@include flexbox();
	@include flex-wrap(wrap);

	.form-group {
		margin: 8px;
	}
}

.app-c-card--secondary {
	border: var(--text-light-normal) 1px solid;
	overflow: hidden;
	height: 100%;

	@include border-radius(4px);
	@include flexbox();
	@include flex-direction(column);

	.app-c-card--header {
		padding: 8px 8px 8px 16px;
		border-bottom: var(--text-light-normal) 1px solid;
		background: var(--text-light-medium);

		@include flex-shrink(0);
		@include flexbox();
		@include align-items(center);

		.app-c-card--header-left {
			padding-right: 10px;

			@include flex-shrink(0);
		}
		.app-c-card--header-right {
			width: 100%;
			max-width: 184px;
			margin-left: auto;
		}
	}

	.app-c-card--body {
		height: 100%;
		overflow: auto;
		padding: 17px 8px 17px 0;

		@include flexbox();
		@include flex-direction(column);

		.app-c-checkbox__vertical-list {
			height: 100%;
			overflow: auto;
			padding: 5px 0px 5px 16px;
		}
	}
}

.app-l-min-width-486 {
	min-width: 486px;

	@media screen and (max-width: 1200px) {
		min-width: 150px;
	}
	@media screen and (max-width: 480px) {
		width: 100%;
		max-width: 100%;
	}
}

.app-c-card--bordered-plain {
	background: $white;
	position: relative;
	padding: 24px 32px;

	@include border-radius(4px);
	border: 1px solid $card-bordered-plain-color;

	.app-c-card__header {
		@include flexbox();
		@include align-items(center);
		margin-bottom: 24px;
		.app-c-card__heading {
			color: $text-color;
			font-weight: 600;
			font-size: 18px;
			margin: 0;
			text-transform: capitalize;
		}
		.app-c-btn {
			margin-left: 8px;
			font-size: $text-sm-plus;
		}

		.app-l-card-head-left {
			@include flexbox();
			@include align-items(center);
			i {
				font-size: 28px;
				color: $app-brand-primary;
				margin-right: 12px;
			}
			img,
			svg {
				margin-right: 12px;
			}
		}
		.app-l-card-head-right {
			@include flexbox();
			@include align-items(center);

			margin-left: auto;

			&.app-l-high-res-controls {
				@media screen and (max-width: 991px) {
					display: none;
				}
			}
		}
	}

	.app-c-card__footer {
		@include flexbox();
		@include align-items(center);

		@media screen and (max-width: 480px) {
			@include flex-direction(column);
		}
		.app-c-card__footer__left {
			margin-right: auto;
		}
		.app-c-card__footer__right {
			margin-left: auto;
		}
	}
	.app-l-card-form {
		width: 100%;
		margin: 0;
	}
	&.app-c-card--full-width {
		@include border-radius(0px);
		@include boxShadow(0px 3px 6px #e3e3e3);
	}

	form {
		width: 100%;
	}
	.app-c-card__sub-heading {
		color: $black;
		font-weight: 500;
		font-size: 15px;
		margin-top: 0;
		margin-bottom: 14px;
	}
}
.app-c-card__footer {
	border-top: 1px solid rgba(170, 170, 170, 0.2);
	padding: 42px 42px 16px 16px;
	.rtl &,
	[dir='rtl'] & {
		padding: 42px 16px 16px 42px;
	}
	@include flexbox();
	@include align-items(center);
	@include justify-content(space-between);
	@media screen and (max-width: 767px) {
		flex-wrap: wrap;
		@include justify-content(center);
		padding: 16px 0px;
	}
	&.no-brdr {
		border-top: none;
	}

	@media screen and (max-width: 480px) {
		@include flex-direction(column);
	}

	> * {
		@media screen and (max-width: 480px) {
			width: 100%;
		}
		+ * {
			@media screen and (max-width: 480px) {
				margin-top: 5px;
				margin-left: 0;
				width: 100%;
			}
		}
	}
	&.low-res-footer {
		display: none;

		@media screen and (max-width: 991px) {
			@include flexbox();
		}
	}
}
.app-c-pagination__left {
	@include flexbox();
	@include align-items(center);
	@media screen and (max-width: 767px) {
		flex-basis: 100%;
		flex-shrink: 0;
		@include flex-direction(row);
		@include justify-content(center);
		padding-bottom: 10px;
	}
	.app-c-custom-select {
		&::after {
			top: 15px;
			font-size: 8px;
		}
	}
	.form-control {
		height: auto !important;
		padding: 0px 20px 0px 9px;
		border: 1px solid #edf0f4;
		border-radius: 6px;
		font-size: 12px;
		height: 30px !important;
		line-height: 16px;
		appearance: none;
		&:focus {
			outline: none;
			@include boxShadow(none);
		}
	}
	p {
		margin-left: 20px;
		font-weight: normal;
		font-size: 12px;
		color: #1e1919;
		margin-top: 0px;
		margin-bottom: 0px;
		@include flex-shrink(0);
		@include flex-grow(1);
		.rtl &,
		[dir='rtl'] & {
			margin-left: 0 !important;
			margin-right: 20px !important;
		}
		@media screen and (max-width: 767px) {
			@include flex-shrink(1);
			@include flex-grow(0);
			margin-left: 8px;
			.rtl &,
			[dir='rtl'] & {
				margin-left: 0;
				margin-right: 8px;
			}
		}
		span {
			font-weight: 500;
		}
	}
}
.grid-pagination {
	overflow: auto;
}
.app-c-pagination__right {
	.pagination {
		@media screen and (max-width: 991px) {
			@include justify-content(center);
			// overflow: auto;
			.page-item {
				text-align: center;
				&:first-child,
				&:last-child {
					width: auto;
				}
				padding: 0;
			}
		}
		margin-bottom: 0px;
		margin-top: 0px;
		.page-item {
			@media (max-width: 768px) {
				padding: 0;
				width: auto;
			}
			.page-link {
				border: none;
				border-radius: 6px;
				font-weight: normal;
				font-size: 12px;
				width: 30px;
				height: 30px;
				padding: 0px;
				margin: 0 1px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				color: #1e1919;
				@media (max-width: 992px) {
					width: 28px;
				}
				&:focus {
					box-shadow: none;
					text-decoration: none;
				}
			}
			&.active {
				.page-link {
					background: var(--theme-primary-color);
					color: $white;
				}
			}
			&.disabled {
				.page-link {
					color: $text-muted;
				}
			}
			&:first-child,
			&:last-child {
				span {
					color: var(--theme-primary-color);
					font-size: 22px;
				}
			}
		}
	}
}
.pagination {
	li {
		border: none !important;
	}
}
