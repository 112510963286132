.react-multi-carousel-list {
	.react-multiple-carousel__arrow {
		width: 16px;
		height: 16px;
		min-width: 16px;
		min-height: 16px;
		@include border-radius(0);
		background: rgba(245, 247, 251, 1);
		line-height: 16px;
		top: -36px;
		z-index: 8;
		&::before {
			font-size: 12px;
			color: rgba(97, 99, 111, 1);
		}
		&:hover {
			background: #ebedf1;
			&::before {
				color: rgba(97, 99, 111, 1);
			}
		}
	}
	.react-multiple-carousel__arrow--left {
		left: unset;
		right: 22px;
	}
	.react-multiple-carousel__arrow--right {
		right: 4px;
	}
}
