.app-c-steps-nav {
	background: #fff;
	@include border-radius(8px);
	@include boxShadow(0px 2px 8px 0px rgba(0, 0, 0, 0.1352));
	padding: 15px 24px 0px 0px !important;
	ul {
		@include flexbox();
		padding: 0;
		padding-left: 4px;
		margin: 0;
		position: relative;
		list-style: none;
		&::before {
			position: absolute;
			bottom: 0;
			content: '';
			width: 23px;
			height: 100%;

			background: #fff;
			left: 0;
			display: block;
			z-index: 2;
			@include border-radius(0px 0px 0px 8px);
		}
	}
	.nav-item {
	}
	.nav-link {
		color: rgba(97, 99, 111, 1);
		font-size: $text-lg;
		font-weight: $font-weight-semibold;
		//   font-family: $app-font-regular;
		position: relative;
		background: none;
		border: 0;
		border-radius: 0;
		padding: 0 0px 17px 25.84px;
		@include transition(width 0.3s ease, background 0.3s ease);
		@include flexbox();
		@include align-items(center);

		@media screen and (max-width: 520px) {
			margin: 0px 10px 4px 10px;
			font-size: 14px;
			// font-family: $app-font-regular;
		}
		.nav-number {
			width: 24px;
			height: 24px;
			margin-right: 10px;
			border: 1px solid #6f6a6a;
			@include border-radius(100%);
			@include flexbox();
			@include align-items(center);
			@include justify-content(center);
			.rtl &,
			[dir='rtl'] & {
				margin-right: 0;
				margin-left: 10px;
			}

			span {
				color: #6f6a6a;
			}
			.icon-check {
				font-size: 10px;
			}
		}
		&::before {
			content: '';
			width: 0;
			height: 0px;
			//   background: $app-brand-primary;
			background: var(--theme-primary-color);
			position: absolute;
			bottom: 1px;
			left: 0px;
			right: 0px;
			margin: auto;
			// @include transition(height 0.3s ease);
		}

		&.active {
			border: none;
			background: none;
			color: #000;

			&::before {
				height: 2px;
				width: 100%;
				@include transition(width ease 0.3s);
			}

			.nav-number {
				width: 24px;
				height: 24px;
				margin-right: 10px;
				border: 1px solid var(--theme-primary-color);
				background-color: var(--theme-primary-color);
				border: 1px solid var(--theme-primary-color-200);
				position: relative;

				span {
					color: #fff;
				}

				&:after {
					content: '';
					width: 28px;
					height: 28px;
					background: var(--theme-primary-color-200);
					@include border-radius(100%);
					position: absolute;
					left: -3px;
					right: 0;
					.rtl &,
					[dir='rtl'] & {
						left: 0;
						right: -3px;
					}
				}
			}
		}
	}
}
