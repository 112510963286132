.app-l-vertical-content-page {
	position: relative;
}

.app-c-footer {
	text-align: center;
	position: relative;
	padding: 24px 10px 19px 80px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	justify-content: center;
	flex-wrap: wrap;
	.rtl &,
	[dir='rtl'] & {
		padding: 24px 80px 19px 10px;
	}

	@media (max-width: 767px) {
		padding: 24px 10px 19px 60px;

		.rtl &,
		[dir='rtl'] & {
			padding: 24px 60px 19px 10px;
		}
	}

	.app-c-footer__content {
		line-height: 14.3px;
		font-size: $text-sm-plus;
		color: rgba(111, 106, 106, 1);
		display: block;
		border-right: 1px solid $gray;
		margin-right: 10px;
		padding-right: 10px;
	}

	a {
		color: $gray01;
		font-size: $text-sm-plus;
		line-height: 14.3px;
		position: relative;
		margin-right: 10px;
		padding-right: 14px;

		&:hover {
			color: $app-brand-primary;
		}

		&:before {
			content: '';
			width: 4px;
			height: 4px;
			border-radius: 50px;
			background: $neutrals-color2;
			position: absolute;
			right: 0;
			top: 5px;
			opacity: 0.8;
		}

		&:last-child {
			&:before {
				display: none;
			}
		}
	}
}
