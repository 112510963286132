@import '../variables/variables';
@import '../core/mixins/flexbox';
@import '../core/mixins/border-radius';
@import '../core/mixins/transitions';

html {
	height: 100%;

	@media screen and (max-width: 767px) {
		overflow: hidden;
		width: 100%;
		height: 100%;
	}
}

body {
	height: 100%;
	background: #fcfcfe !important;

	@media print {
		background-color: #fff !important;
	}

	@media screen and (max-width: 767px) {
		height: 100%;
		width: 100%;
		// position: fixed;
		/* prevent overscroll bounce*/
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}
}

#root {
	height: 100%;
}

.app-l-main-container {
	//height: 100vh;
	min-height: calc(100% - 58px);
	// overflow: auto;
	@include flexbox();

	@media screen and (max-width: 767px) {
		padding-left: 0px;
	}
}

.app-l-base {
	padding-left: 80px;
	position: relative;
	z-index: 1;

	.editor-modal-open & {
		z-index: 11;
	}

	.rtl &,
	[dir='rtl'] & {
		padding-left: 0px;
		padding-right: 80px;
	}

	@media screen and (max-width: 767px) {
		padding-left: 60px;

		.rtl &,
		[dir='rtl'] & {
			padding-left: 0px;
			padding-right: 60px;
		}
	}

	&.app-l-full-height {
		// height: calc(100%);
		height: 100%;
		width: 100%;
		min-width: 0;

		@include flexbox();
		@include flex-direction(column);

		@media screen and (max-width: 767px) {
			height: auto;
			min-height: 100%;
		}
	}
}

.app-l-vertical-content-page {
	background: #fcfcfe;
	//height: 100%;
	// min-height: 0px;
	padding: 30px 40px;
	@include flexbox();
	@include flex-direction(column);

	@media screen and (max-width: 991px) {
		padding: 24px 24px;
	}

	@media screen and (max-width: 767px) {
		padding: 15px 15px;
	}

	&.m-padding {
		padding: 30px 40px;

		@media screen and (max-width: 1365px) {
			padding: 30px 40px;
		}

		@media screen and (max-width: 991px) {
			padding: 24px 24px;
		}

		@media screen and (max-width: 767px) {
			padding: 15px 15px;
		}
	}
}

.app-l-screen-head-controls {
	max-width: 100%;

	@include flexbox();
	@include flex-direction(row);
	@include flex-wrap(wrap);
	@include justify-content(flex-end);
	@include align-items(center);

	.app-l-round-icon-btn {
		background: $white;
		border: solid 1px $app-brand-secondary;
		width: 24px;
		height: 24px;
		margin: 4px 0px 4px 8px;
		outline: none;

		@include inline-flex();
		@include align-items(center);
		@include justify-content(center);
		@include flex-shrink();
		@include border-radius(50%);

		> i {
			font-size: 12px;
			color: $app-brand-secondary;
			margin: auto;

			@include transition(color 0.3s ease-in-out);
		}

		&:hover {
			background-color: $app-brand-secondary;

			i {
				color: $white;
			}
		}
	}
}

.app-l-screen-holder {
	max-width: 100%;
	height: 100%;
	min-height: 0;

	@include flexbox();
	@include flex-direction(column);
}

.app-l-screen-contents {
	max-width: 100%;
	height: 100%;
	min-height: 0;
	overflow: auto;
	padding: 0;

	@include flexbox();
	@include flex-direction(column);
}

.app-l-common-layout {
	background: $white;
	height: 100%;

	@include border-radius(6px);
	@include flexbox();
	@include flex-direction(column);

	.app-l-common-layout__body {
		padding: 16px 24px;
		overflow: auto;

		@media screen and (max-width: 767px) {
			padding: 12px 15px;
		}
	}

	.app-l-common-layout__footer {
		border-top: $generic-border-color 1px solid;
		padding: 12px 24px;
		margin-top: auto;

		@include flexbox();
		@include flex-shrink(0);
		@include justify-content(flex-end);
	}
}

.app-l-heading--h3 {
	color: $text-color;
	font-weight: 500;
	font-size: 20px;
	margin: 0 0 15px;
	padding: 0;

	@media screen and (max-width: 767px) {
		font-size: 16px;
	}
}

.app-l-heading--h4 {
	color: $text-color;
	font-weight: 500;
	font-size: 15px;
	margin: 0 0 15px;
	padding: 0;

	@media screen and (max-width: 767px) {
		font-size: 13px;
	}
}

.flex-align-justify-center {
	@include flexbox();
	@include align-items(center);
	@include justify-content(center);
}

.app-l-view-check {
	@include flexbox();
	@include align-items(center);

	.app-l-view__icon {
		width: 18px;
		height: 18px;
		border: 1px solid $app-checkbox-border;
		position: relative;
		background: $app-checkbox-bg;
		padding-top: 1px;
		position: relative;
		z-index: 0;

		@include transition(background ease 0.2s, border ease 0.2s, box-shadow ease 0.2s);
		@include flexbox();
		@include align-items(center);
		@include justify-content(center);
		@include flex-shrink(0);
		@include border-radius(2px);
		@include align-self(flex-start);

		> input {
			position: absolute;
			top: 0;
			left: 0;
			right: auto;
			bottom: auto;
			margin: 0;
			opacity: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
		}
	}

	.app-l-view__label {
		padding-left: 6px;
		cursor: pointer;

		&.app-l-selected-label {
			.app-c-checkbox-label {
				color: $active-checkbox-label;
				text-shadow: 0px 1px rgba(0, 0, 0, 0.5);
			}
		}

		span {
			padding-top: 2px;
			color: rgba($text-dark-extra, 0.8);
			font-size: 13px;
			line-height: 14px;
			font-weight: 400;
		}
	}

	&.app-l-view-check--checked {
		.app-l-view__icon {
			background: $app-brand-primary;
			border-color: $app-brand-primary-dark;

			&:before {
				content: '\e90f';
				font-family: $font-qtms;
				font-size: $text-sm-plus;
				color: $white;
				display: block;
			}
		}
	}

	&.app-l-view-check--indeterminate {
		.app-l-view__icon {
			&:before {
				content: '';
				width: 8px;
				height: 8px;
				background: $app-brand-primary;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				opacity: 1;

				@include border-radius(2px);
			}
		}
	}
}

.app-l-progress {
	background: $white;
	height: 100%;

	@include border-radius(4px);
	@include flexbox();
	@include flex-direction(column);

	.app-l-progress-head {
		padding: 16px 40px;
		border-bottom: $generic-border-color 1px solid;

		@include flexbox();
		@include align-items(center);
		@include justify-content(center);

		.app-l-progress__h-block {
			width: 100%;
			position: relative;
			max-width: 350px;

			@include flexbox();
			@include flex-direction(column);
			@include align-items(flex-end);

			&:first-child {
				width: 20px;

				.app-l-ph__path {
					display: none;
				}
			}

			.app-l-ph__path {
				position: absolute;
				top: 15px;
				left: 0;
				width: 100%;
				height: 2px;
				background: var(--text-light-normal);
				overflow: hidden;

				&:before {
					content: '';
					width: 0%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					background: $app-brand-primary;

					@include transition(width ease 0.2s);
				}
			}

			.app-l-ph__box {
				width: 30px;
				height: 30px;
				border: 1px solid var(--text-light-normal);
				font-size: 16px;
				color: $text-dark-medium;
				cursor: pointer;
				background: $white;
				position: relative;
				z-index: 1;

				@include border-radius(3px);
				@include flexbox();
				@include align-items(center);
				@include justify-content(center);
				@include transition(border ease 0.2s, background ease 0.2s, color ease 0.2s);

				i {
					display: block;
					line-height: normal;
				}
			}

			.app-l-ph__label {
				font-weight: 500;
				font-size: 14px;
				line-height: normal;
				color: $text-dark-medium;
				width: 140px;
				margin-right: -53px;
				text-align: center;
				margin-top: 2px;

				@include transition(color ease 0.2s);

				@media screen and (max-width: 480px) {
					display: none;
				}
			}

			&.select {
				.app-l-ph__box {
					color: $white;
					background: $app-brand-primary;
					border-color: $app-brand-primary;
				}

				.app-l-ph__path {
					&:before {
						width: 100%;
					}
				}

				.app-l-ph__label {
					color: $app-brand-primary;
				}
			}
		}
	}
}

.app-l-progress-body {
	padding: 16px;
	max-height: 100%;
	min-height: 0;
	overflow: auto;

	.app-l-progress__block {
		display: none;

		&.active {
			display: block;
		}
	}
}

.app-l-progress__general {
	max-width: 1300px;

	@include flexbox();

	@media screen and (max-width: 991px) {
		display: block;
	}

	.app-l-progress__left {
		width: 100%;
		padding-right: 15px;

		@media screen and (max-width: 991px) {
			padding-right: 0;
		}

		.app-l-gn__tp {
			@include flexbox();
			@include flex-wrap(wrap);

			.form-group {
				margin-right: 16px;

				@media screen and (max-width: 480px) {
					margin-right: 0;
				}
			}
		}

		.app-l-gn__bt {
			padding-top: 16px;
			max-width: 760px;
			border-top: var(--text-light-normal) 1px solid;

			@include flexbox();
			@include flex-wrap(wrap);

			.form-group {
				margin-right: 16px;

				@media screen and (max-width: 480px) {
					margin-right: 0;
				}
			}
		}
	}

	.app-l-progress__right {
		width: 370px;
		padding-left: 32px;
		border-left: var(--text-light-normal) 1px solid;

		@include flex-shrink(0);

		@media screen and (max-width: 991px) {
			border-top: var(--text-light-normal) 1px solid;
			border-left: 0;
			padding-top: 32px;
			padding-left: 0;
			max-width: 100%;
		}
	}
}

.app-l-color__theme {
	@include flexbox();
	@include align-items(center);

	.app-l-color__select {
		margin-right: 12px;

		@include flex-shrink(0);
		@include align-self(flex-start);
	}

	.app-l-color__pre {
		width: 100%;

		@include flexbox();
		@include align-items(center);
		@include flex-wrap(wrap);

		.app-l-color__sample {
			width: 18px;
			height: 18px;
			display: block;
			cursor: pointer;
			margin-right: 12px;
			position: relative;

			@include border-radius(50%);
			@include transition(background ease 0.2s);

			&.yellow {
				background: #f9ae19;
			}

			&.orange {
				background: #f26e26;
			}

			&.blue {
				background: #2b5a9b;
			}

			&.blue {
				background: #7f15a5;
			}

			&.purple {
				background: #7f15a5;
			}

			&.default {
				background: $app-brand-primary;
			}

			&.green {
				background: #44ab66;
			}

			&.active {
				&:before {
					content: '';
					width: 24px;
					height: 24px;
					border: 1px solid var(--tooltip1);
					position: absolute;
					top: -3px;
					left: -3px;
				}
			}
		}
	}
}

.app-l-company__logo {
	margin-bottom: 16px;

	.app-c-file-upload {
		margin-bottom: 10px;
	}

	.app-l-company-logo__holder {
		display: inline-block;
		padding: 10px;
		border: 1px solid var(--text-light-normal);
	}
}

.app-l-short-logo {
	margin-bottom: 16px;

	.app-c-file-upload {
		margin-bottom: 10px;
	}

	.app-l-short-logo__holder {
		width: 60px;
		height: 60px;
		padding: 10px;
		border: 1px solid var(--text-light-normal);

		@include border-radius(50%);
		@include flexbox();
		@include align-items(center);
		@include justify-content(center);

		img {
			max-width: 100%;
			max-height: 100%;
		}
	}
}

.app-l-local__form-block {
	.app-l-local__form {
		@include flexbox();
		@include flex-wrap(wrap);

		.form-group {
			margin-right: 16px;

			@media screen and (max-width: 480px) {
				margin-right: 0;
			}
		}
	}
}

.app-l-progress-email {
	max-width: 500px;

	.app-l-progress-email__form {
		@include flexbox();
		@include flex-wrap(wrap);

		.form-group {
			margin-right: 16px;

			@media screen and (max-width: 480px) {
				margin-right: 0;
			}
		}
	}
}

.app-l-pr__check {
	.app-c-checkbox {
		margin-top: 20px;

		@media screen and (max-width: 480px) {
			margin-top: 0;
		}
	}
}

.app-l-no-data-area {
	@include inline-flex();
	@include justify-content(center);
	@include align-items(center);
	@include flex-direction(column);

	min-height: 78vh;
	height: 100%;
	width: 100%;

	.app-l-no-data-img-wrap {
		max-width: 100%;
		min-width: 0;

		@include flexbox();
		@include justify-content(center);
		@include align-items(center);

		img {
			max-width: 150px;
			width: 150px;
			max-height: 150px;
			height: 150px;
			min-height: 0;

			@media screen and (max-width: 767px) {
				max-width: 100px;
				width: 100px;
				max-height: 100px;
				height: 100px;
			}
		}
	}

	.app-l-no-data-text {
		max-width: 100%;
		min-width: 0;
		margin-top: 24px;
		margin-bottom: 20px;

		@include flexbox();
		@include justify-content(center);
		@include align-items(center);
		@include flex-direction(column);

		&.no-pad-top {
			padding-top: 0;
		}

		span {
			font-weight: 600;
			font-size: 22px;
			line-height: normal;
			text-align: center;
			color: #caccd3;
			text-align: center;

			@media screen and (max-width: 767px) {
				font-size: 18px;
			}
		}
	}

	&.app-l-dashboard-charts {
		.app-l-no-data-text {
			span {
				font-size: 20px;
				font-weight: 400;

				@media screen and (max-width: 767px) {
					font-size: 16px;
				}
			}
		}
	}
}

.app-l-card-stats {
	color: #3b4458;
	font-size: 13px;
	line-height: 14px;
	font-weight: 400;
}

// Page style

.app-l-page__header {
	@include flexbox();
	@include align-items(center);
	margin-bottom: 30px;

	.app-l-page__header__left {
		margin-right: auto;
	}

	.app-l-page__header__right {
		margin-left: auto;
		text-align: right;

		.app-l-page__header__right__info-box {
			@include flexbox();
			@include align-items(center);
			position: relative;
			top: 5px;
		}
	}
}

.app-l-page__header-text {
	font-weight: 600;
	font-size: 20px;
	line-height: normal;
	color: #362f55;
	margin-top: 0px;
	margin-bottom: 0px;
	@include inline-flex();
	@include align-items(center);
}

.app-l-page__sub-header-text {
	font-weight: 600;
	font-size: 14px;
	line-height: normal;
	color: #7a8092;
	margin-top: 8px;
	margin-bottom: 0px;
}

.app-l-page__action-btn-block {
	@include flexbox();
	@include align-items(center);
	@include justify-content(flex-end);
	margin-bottom: 16px;
}

.app-l-approve-driver {
	.tab-content {
		height: 100%;

		.tab-pane {
			height: 100%;

			.app-l-form-page__grid {
				height: 100%;
			}
		}
	}
}

.app-c-create-content {
	position: relative;
}

.app-c-create-content-dropdown {
	position: absolute;
	right: 0;
	top: 44px;
	min-width: 158px;
	background: #fff;
	@include border-radius(6px);
	@include boxShadow(0px 9px 21px 0px rgba(0, 0, 0, 0.1316));
	overflow: hidden;
	z-index: -1;
	opacity: 0;
	visibility: hidden;
	@include transition(all ease 0.2s);
	@include transform(translate(0px, 10px));

	.rtl &,
	[dir='rtl'] & {
		right: auto;
		left: 0;
	}

	&.active {
		z-index: 1;
		opacity: 1;
		visibility: visible;
		@include transform(translate(0px, 0px));
	}

	ul {
		margin: 0;
		padding: 0;
	}

	li {
		display: block;
		@include transition(all ease 0.2s);

		&:hover {
			background: $app-brand-secondary;
		}
	}

	button {
		width: 100%;
	}

	.app-c-link-btn {
		font-size: 14px;
		line-height: 16px;
		padding: 11.5px 24px;
		display: block;
		text-decoration: none;
		//width: 100%;
		text-align: left;
		color: $text-color;
		@include transition(all ease 0.2s);
		cursor: pointer;

		&:hover,
		&:active,
		&:focus {
			background: #eaeff7;
			color: $text-color;
		}

		.rtl &,
		[dir='rtl'] & {
			text-align: right;
		}
	}
}

// scroll bar style

::-webkit-scrollbar-track {
	// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	// background-color: transparent;
	background: #ced0dd;
}

::-webkit-scrollbar {
	width: 4px;
	height: 9px;
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	// background: rgba(190, 193, 210, 1);
	background: $app-brand-primary;
}

// Scrollup Button
.scrollTop {
	position: fixed;
	width: 100%;
	bottom: 20px;
	align-items: center;
	height: 20px;
	justify-content: center;
	z-index: 1000;
	cursor: pointer;
	animation: fadeIn 0.3s;
	transition: opacity 0.4s;
	opacity: 0.5;
}

.scrollTop:hover {
	opacity: 1;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 0.5;
	}
}

.app-c-pagination {
	li {
		list-style-type: none !important;
	}

	.app-c-pagination__center {
		.pagination {
			@include justify-content(center);
		}
	}

	.pagination {
		@include align-items(center);
	}

	.app-c-pagination-num {
		margin: 0px 8px;
		display: inline-block;
	}

	.page-link {
		font-size: 22px;
		padding: 0px;
		width: 30px;
		height: 30px;
		text-align: center;
		line-height: 27px;
		color: $text-color;
		@include border-radius(6px !important);
		text-decoration: none;
		border: none;
		background: transparent !important;
		outline: none !important;
		box-shadow: none !important;

		a {
			outline: none !important;
			box-shadow: none !important;

			&:hover {
				color: $app-brand-primary;
			}
		}

		&:hover {
			color: $white !important;
			background: $app-brand-primary !important;
		}

		span {
			pointer-events: none;
		}
	}

	border-radius: 0 0 6px 6px;
}

.app-l-full-page {
	@include flexbox();
	@include flex-direction(column);
	@include justify-content(center);
	@include align-items(center);
	height: 100%;
	background: #f2f9ff;
}

.app-l-pop-box {
	position: relative;
	background: $white;
	@include border-radius(8px);
	//@include boxShadow(0px 2px 8px 0px rgba(0, 0, 0, 0.1352));
	width: 100%;
	max-width: 360px;
	text-align: center;
	padding: 40px 24px 24px;
	@include flexbox();
	@include flex-direction(column);
	@include justify-content(center);
	@include align-items(center);

	.app-l-escape {
		position: absolute;
		top: 10px;
		right: 10px;
		font-size: 16px;
	}

	h5 {
		font-size: 18px;
	}

	.instruction-box {
		text-align: left;
		padding: 15px 0px;

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
		}

		li {
			font-size: $text-md;
			display: block;
			line-height: 18px;
			color: $text-light;
			position: relative;
			padding-left: 20px;
			margin-top: 8px;

			&::before {
				position: absolute;
				left: 2px;
				top: 5px;
				@include border-radius(50%);
				width: 7px;
				height: 7px;
				content: '';
				background: $white;
				border: 1.5px solid $app-brand-primary;
				display: block;
				padding: 0;
			}
		}
	}
}

.app-l-student-login {
	text-align: left;
	@include border-radius(6px);
	margin-top: 50px;
	padding: 14px 10px 16px 56px;
	background: #f2f9ff;
	position: relative;
	border: 1px solid #ced4da;

	.rtl &,
	[dir='rtl'] & {
		padding: 14px 56px 16px 10px;
		text-align: left;
	}

	&::before {
		content: '\e90b';
		font-family: 'qualify-tms';
		font-size: 30px;
		width: 30px;
		height: 30px;
		top: 0px;
		bottom: 0;
		margin: auto;
		line-height: 100%;
		left: 16px;
		display: block;
		color: $app-brand-primary;
		position: absolute;
		opacity: 0.5;

		.rtl &,
		[dir='rtl'] & {
			left: auto;
			right: 16px;
		}
	}

	p {
		font-size: 13px;
		line-height: 18px;
		font-weight: $font-weight-semibold;
		color: $app-brand-primary-light;

		&:last-child {
			margin-bottom: 0;
		}

		span {
			font-size: 14px;
			font-weight: $font-weight-bold;
			color: $app-brand-primary;
		}
	}
}

.app-downloads {
	@include flexbox();
	@include flex-direction(row);
	@include justify-content(center);
	@include align-items(center);

	a {
		display: inline-block;
		text-decoration: none;
		margin: 4px;
		//width: 49%;
	}

	img {
		@include border-radius(8px);
		@include transition(all ease 0.2s);

		&:hover {
			@include boxShadow(0px 2px 5px 2px rgba(0, 0, 0, 0.1352));
		}
	}
}

.button-bar {
	padding-bottom: 80px;
	display: block;

	.rtl &,
	[dir='rtl'] & {
		text-align: right;
	}

	@media screen and (max-width: 1200px) {
		padding-bottom: 20px;
	}
}

.app-l-back-btn {
	all: unset;
	font-size: $text-md;
	color: $text-color-light;
	@include transition(color ease 0.2s);

	&:hover {
		color: $app-brand-primary;
	}

	i {
		margin-right: 10px;
		font-size: 10px;
	}
}

.auto-text-area {
	resize: both;
	overflow: visible;
	display: block;
}

.sep-border {
	display: block;
	width: 100%;
	border-top: 1px solid $generic-border-color;
}

.disabled-box {
	position: relative;

	&::after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: '';
		display: block;
		background: $white;
		opacity: 0.7;
		z-index: 1;
	}
}

.dot-sep {
	width: 4px;
	height: 4px;
	border-radius: 50%;
	display: inline-block;
	background: #d0d4f1;
	position: relative;
	margin-left: 8px;
	margin-right: 8px;
	vertical-align: middle;
}

@media (max-width: 767px) {
	.tox-toolbar.tox-toolbar--scrolling {
		&::-webkit-scrollbar {
			height: 0;
		}
	}
}

.tippy-popper {
	@media (max-width: 767px) {
		max-width: 220px !important;
	}

	.tippy-tooltip-content {
		@media (max-width: 767px) {
			font-size: 10px;
		}
	}

	&[x-placement^='bottom'] {
		.right-move-theme {
			&[data-animation='perspective'].enter {
				transform: translate(-50px, 10px) rotateX(0);
			}

			&[data-animation='perspective'].leave {
				transform: translate(-50px, 0) rotateX(90deg);
			}
		}
	}

	&[x-placement^='top'] {
		.right-move-theme {
			&[data-animation='perspective'].enter {
				transform: translate(-50px, -10px) rotateX(0);
			}

			&[data-animation='perspective'].leave {
				transform: translate(-50px, 0) rotateX(90deg);
			}
		}
	}

	&[x-placement^='left'] [x-arrow] {
		margin: 4px 0 !important;
	}
}

p {
	margin-top: 0;
	margin-bottom: 1rem;
}

.visually-hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	border: 0;
	clip: rect(0 0 0 0);
}

.editor-modal-open {
	overflow: hidden;

	.ATTACHES_modal-content {
		max-width: 800px;
		width: 100%;

		.app-c-content-pic {
			display: flex;
		}

		.app-c-pagination {
			width: 100%;
		}

		.pagination {
			margin: 0;
		}

		.app-l-preview-box {
			width: 100%;
		}

		.react-pdf__Page,
		iframe {
			border-radius: 6px 6px 0 0;
			max-height: 80vh;

			.react-pdf__Page__canvas,
			.react-pdf__Page__textContent {
				width: 100% !important;
				overflow: hidden;

				@media screen and (max-width: 640px) {
					width: 100% !important;
					height: 100% !important;
				}
			}
		}

		img,
		video {
			border-radius: 6px;
			max-height: 80vh;
			margin: auto;
			display: flex;
		}

		@media screen and (max-width: 640px) {
			width: 98%;
		}
	}
}

.react-pdf__Document {
	.app-c-pagination {
		border-top: 1px solid #eeebeb;
	}
}

.mobile-preview {
	/* ::first-line is a pseudo element selector and not a simple selector */
	.app-c-youtube-video {
		iframe {
			height: 315px;
		}
	}
}

.app-c-youtube-video {
	iframe {
		height: 440px;

		@media screen and (max-height: 1024px) {
			height: 381px;
		}

		@media screen and (max-height: 860px) {
			height: 331px;
		}

		@media screen and (max-height: 800px) {
			height: 281px;
		}

		@media screen and (max-width: 640px) {
			height: 371px;
		}
	}
}
