.billingInfoPage {
	@include flexbox();
	@media (max-width: 992px) {
		flex-direction: column;
		.billingDetailsCard {
			margin-right: 0;
			margin-bottom: 30px;
			width: 100%;
			~ .app-c-table {
				max-width: 100%;
			}
		}
	}
	.app-c-table {
		@include flex-grow(1);
	}
}
.billingDetailsCard {
	background: $white;
	@include boxShadow(0px 2px 8px 0px rgba(0, 0, 0, 0.15));
	@include border-radius(8px);
	margin-bottom: 8px;
	margin-right: 16px;
	width: 350px;
	button.arrowLink {
		font-size: 12px;
	}
	~ .app-c-table {
		max-width: calc(100% - 350px);
		th,
		td {
			max-width: 115px;
			@media (max-width: 992px) {
				max-width: 100%;
			}
			&:nth-child(3) {
				img {
					width: 30px;
					height: 24px;
					object-fit: contain;
					margin-right: 5px;
				}
				.table-action-button {
					div {
						display: flex;
						align-items: center;
					}
				}
			}
			&:nth-child(4) {
				max-width: 160px;
				@media (max-width: 992px) {
					max-width: 100%;
				}
			}
			&:last-child {
				max-width: 160px;
				@media (max-width: 992px) {
					max-width: 100%;
				}
			}
		}
	}
	.card-header {
		background: $gray02;
		border-bottom: none;
		h5 {
			margin: 0;
			font-size: 16px;
		}
	}
	.itemRow {
		display: block;
		margin-bottom: 20px;
		.app-l-title-small {
			// width: 120px;
		}
		.app-l-text {
			margin: 0 0 0 0 !important;
			padding: 0 !important;
		}
		.app-l-text {
			& + * {
				margin-top: 0px;
			}
		}
	}
	.payment-method-box {
		@include flexbox();
		align-items: flex-start;
		justify-content: space-between;
		flex-direction: column;
		.arrowLink {
			margin-top: 10px;
		}
		.app-l-text {
			margin: 0 !important;
		}
		.cardImg {
			margin-right: 16px;
			img {
				width: 30px;
				height: 24px;
				object-fit: contain;
			}
		}
	}
}
.flex-centered {
	@include flexbox();
	align-items: center;
	&.space-between {
		justify-content: space-between;
	}
	.app-l-text {
		margin: 0 !important;
		padding: 0;
	}
}
