.app-l-main-container {
	&.--cm-dashboard {
		.app-c-profile-tag-holder {
			@media screen and (max-width: 767px) {
				@include flex-direction(column);
				text-align: center;
				width: 100%;
			}
			.app-c-subText {
				margin: 0;
			}
		}
		.app-c-flex-card-wrapper {
			@media screen and (max-width: 992px) {
				@include flex-direction(column);
			}
			&.app-c-status-wrapper {
				@include flex-direction(row);
				&.app-c-status--tile-wrapper {
					.app-c-status-tile {
						.app-c-status__count {
							line-height: 33px;
						}
					}
					@media screen and (max-width: 767px) {
						@include flex-direction(column);
						.app-c-status-tile {
							margin: 0 0 8px;
						}
					}
				}
			}
			&.app-c-course-ratings {
				@include flex-direction(row);
				@media screen and (max-width: 767px) {
					@include flex-direction(column);
				}
				.app-c-course-ratings--pane {
					@media screen and (max-width: 992px) {
						margin-left: 0;
					}
					.app-c-pathway__wrapper {
						&--rating {
							&--label {
								color: $text-muted;
								font-size: $text-md;
								margin-top: 6px;
							}
							&--value {
								@include flex-direction(row);
								@include flexbox;
								@include align-items(flex-end);
								&__number {
									font-size: 40px;
									line-height: 33px;
									margin: 8px 22px 0 0;
									color: $black;
									.rtl &,
									[dir='rtl'] & {
										margin: 8px 0 0 22px;
									}
								}
							}
						}
						&--rated-bar {
							@include flexbox;
							@include flex-direction(column);
							&--scroll {
								max-height: 100px;
								overflow-y: auto;
								padding: 0px 6px 0px 0px;
								margin-right: -6px;
								visibility: hidden;
								&:hover {
									visibility: visible;
								}
								@media (max-width: 500px) {
									max-height: 210px;
								}
							}
							& + .--row {
								margin-top: 8px;
							}
							&.-wrapper {
								& + .-wrapper {
									margin-top: 5px;
								}
								&--heading {
									color: $black01;
									font-size: $text-lg;
									margin: 47px 0 12px;
									font-weight: $font-weight-semibold;
								}
								&--ins-heading {
									margin: 22px 0 14px;
									font-size: $text-lg;
									font-weight: $font-weight-semibold;
									.rtl &,
									[dir='rtl'] & {
										text-align: right;
									}
								}
								&--ins-star {
									visibility: visible;
									@include flexbox;
									@include align-items(center);
									@include flex-direction(row);
									@media (max-width: 500px) {
										@include align-items(flex-start);
										@include flex-direction(column);
									}
									& + .-wrapper--ins-star {
										margin-top: 12px;
									}
									.ins {
										&--name {
											color: $black01;
											font-size: $text-md;
											text-overflow: ellipsis;
											overflow: hidden;
											white-space: nowrap;
											max-width: 140px;
											margin-top: 6px;
										}
										&--rating {
											margin-left: auto;
											@include flexbox;
											@include align-items(center);
											.rtl &,
											[dir='rtl'] & {
												margin-left: 0;
												margin-right: auto;
											}
											@media (max-width: 500px) {
												width: 100%;
											}
											&--star {
												white-space: nowrap;
											}
											&--val {
												margin-top: 6px;
												font-weight: $font-weight-normal;
												color: $text-muted;
												font-size: $text-md;
												margin-left: 33px;
												min-width: 17px;
												.rtl &,
												[dir='rtl'] & {
													margin-left: 0;
													margin-right: 33px;
												}
												@media (max-width: 767px) {
													width: 100%;
													text-align: right;
												}
											}
										}
									}
								}
							}
							&.progress-cut {
								@include flexbox;
								@include flex-direction(row);
								&--label {
									color: $black01;
									font-size: $text-md;
									.ins--name {
										text-overflow: ellipsis;
										overflow: hidden;
										white-space: nowrap;
										max-width: 300px;
									}
								}
								&--rate {
									font-weight: $font-weight-normal;
									color: $text-muted;
									font-size: $text-md;
									margin-left: auto;
									.rtl &,
									[dir='rtl'] & {
										margin-left: 0;
										margin-right: auto;
									}
								}
							}
							&--value {
								@include flexbox;
								@include flex-direction(row);
								@include flex-wrap(nowrap);
								margin-top: 8px;
								&--filler {
									background: $app-brand-primary;
									width: 0;
									height: 13px;
									@include transform(translateX(-100%));
									@include animation(progressFiller 0.5s linear both);
									.rtl &,
									[dir='rtl'] & {
										@include transform(translateX(100%));
										@include animation(progressFillerRtl 0.5s linear both);
									}
									&.-full {
										width: 100%;
									}
									&.-half {
										width: 50%;
									}
								}
								&--bar {
									height: 12px;
									width: 100%;
									overflow: hidden;
									@include border-radius(0);
									@include flexbox;
									border: 0.5px solid $app-brand-primary;
									background: rgba(226, 230, 255, 0.5);
									& + .app-c-pathway__wrapper--rated-bar--value--bar {
										margin-left: 4px;
										.rtl &,
										[dir='rtl'] & {
											margin-left: 0;
											margin-right: 4px;
										}
									}
									&:nth-child(1) {
										@include border-radius(14px 0px 0px 14px);
										.rtl &,
										[dir='rtl'] & {
											@include border-radius(0px 14px 14px 0px);
										}
										.app-c-pathway__wrapper--rated-bar--value--filler {
											animation-delay: 0.5s;
										}
									}
									&:nth-child(2) {
										.app-c-pathway__wrapper--rated-bar--value--filler {
											animation-delay: 1s;
										}
									}
									&:nth-child(3) {
										.app-c-pathway__wrapper--rated-bar--value--filler {
											animation-delay: 1.5s;
										}
									}
									&:nth-child(4) {
										.app-c-pathway__wrapper--rated-bar--value--filler {
											animation-delay: 2s;
										}
									}
									&:nth-child(5) {
										.app-c-pathway__wrapper--rated-bar--value--filler {
											animation-delay: 2.5s;
										}
									}
									&:nth-child(1) {
										@include border-radius(14px 0px 0px 14px);
										.rtl &,
										[dir='rtl'] & {
											@include border-radius(0px 14px 14px 0px);
										}
									}
									&:nth-child(5) {
										@include border-radius(0 14px 14px 0);
										.rtl &,
										[dir='rtl'] & {
											@include border-radius(14px 0px 0px 14px);
										}
									}
								}
							}
						}
					}
				}
			}
			&__card {
				@media screen and (max-width: 992px) {
					max-width: 100%;
				}
				.donutchart {
					// margin: 0 auto;
					// display: block;
					@media screen and (max-width: 575px) {
						width: 100%;
					}
				}
				&.--app-c-group-donut {
					position: relative;
					text-align: center;
					.recharts-wrapper {
						margin: 0 auto;
						width: 100% !important;

						.recharts-surface {
							width: 100% !important;
						}
					}

					h5 {
						text-align: left;
						.rtl &,
						[dir='rtl'] & {
							text-align: right;
						}
					}
					@media screen and (max-width: 980px) {
						margin-left: 0px;
					}
					.app-c-legend {
						@include flexbox();
						@include flex-direction(column);
						font-size: $text-md;
						.app-c-legend-contain {
							@include flexbox();
							@include flex-direction(row);
							margin-bottom: 18px;
							@include align-items(center);
							&:last-child {
								margin-bottom: 0px;
							}
							.app-c-legend-value {
								margin-left: auto;
								color: $text-muted;
								.rtl &,
								[dir='rtl'] & {
									margin-right: auto;
									margin-left: 0;
								}
							}
							.app-c-legend-dot {
								width: 12px;
								height: 12px;
								@include border-radius(50%);
								margin-right: 8px;
								.rtl &,
								[dir='rtl'] & {
									margin-right: 0;
									margin-left: 8px;
								}
								&.blue {
									background-color: $app-brand-primary;
								}
								&.pink {
									background-color: $pink;
								}
							}
						}
					}
				}
				&.app-c-profile-tag {
					@media (max-width: 992px) {
						max-width: 100%;
					}
					@media (max-width: 767px) {
						margin: 0 0 8px;
					}
					&-holder {
						&__avatar {
							img {
								width: 60px;
								height: 60px;
								object-fit: cover;
							}
						}
						.app-c-subText {
							margin-bottom: 0;
						}
					}
				}
				em {
					&.app-c-status__icon {
						font-size: 30px;
						color: $text-muted;
					}
				}
			}
		}
	}
}
@keyframes progressFiller {
	0% {
		@include transform(translateX(-100%));
	}
	100% {
		@include transform(translateX(0));
	}
}
@keyframes progressFillerRtl {
	0% {
		@include transform(translateX(100%));
	}
	100% {
		@include transform(translateX(0));
	}
}

//Common styles

.user-name {
	margin-bottom: 10px;

	@include flexbox;
	@media (max-width: 767px) {
		@include flex-direction(column);
		padding-top: 5px;
	}
	span {
		line-height: normal;
	}
	.user-name--cm {
		max-width: 220px;
		text-overflow: ellipsis;
		white-space: nowrap;
		// overflow: hidden;
		display: inline-block;
		margin-left: 4px;
		@media (max-width: 767px) {
			text-overflow: unset;
			white-space: unset;
			overflow: unset;
			text-align: left;
			margin-left: 0;
			text-align: center;
			.rtl &,
			[dir='rtl'] & {
				text-align: right;
			}
		}
		.rtl &,
		[dir='rtl'] & {
			margin-left: 0;
			margin-right: 4px;
			text-align: right;
		}
	}
}
.center-donut-label {
	// max-width: 400px;
	height: 340px;
	margin: 0 auto;
	position: absolute;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	right: 0;
	left: 0;
}

.donutchart {
	.donutchart-innertext-label {
		font-size: $text-md;
		color: $text-muted;
		@media (max-width: 767px) {
			font-size: $text-sm-plus;
		}
	}
	.donutchart-innertext-value {
		font-size: 20px;
		font-weight: $font-weight-semibold;
		@media (max-width: 767px) {
			font-size: $text-lg;
		}
	}
}
.manager-designation {
	font-size: 13px;
	margin-left: 15px;
	position: relative;
	top: 1px;
	color: $app-brand-primary;
	@media (max-width: 767px) {
		margin: 5px 0;
	}
	.designation {
		font-weight: $font-weight-normal;
		margin-left: 5px;
	}
}
