// Ngx bootstrap Accordion

.app-l-accordion {
	.panel-group {
		width: 100%;
		outline: none;

		.panel {
			width: 100%;
			outline: none;

			.card {
				width: 100%;
				outline: none;
				background: none;
				border: none;

				.card-header {
					background: none;
					padding: 0;
					border: none;
					position: relative;
					outline: none;

					&:before {
						content: '\e912';
						font-family: 'qualify-tms';
						font-size: 10px;
						color: $text-color;
						position: absolute;
						top: calc(50% - 7px);
						right: 12px;
						bottom: auto;
						left: auto;

						@include transition(transform 0.3s ease-in-out);
						@include transform(rotateZ(0deg));
					}
				}

				.panel-collapse {
					&.show {
						.card-body {
							padding: 0;
						}
					}
				}
			}

			&.panel-open {
				.card {
					.card-header {
						&:before {
							@include transform(rotateZ(180deg));
						}
					}
				}
			}
		}
	}
}
