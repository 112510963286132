.app-c-table {
	table {
		thead {
			tr {
				th {
					background-color: rgba(233, 235, 244, 0.5);
					border: none;
					font-weight: 600;
					font-size: 12px;
					line-height: normal;
					text-transform: uppercase;
					color: #61636f;
					padding: 12px 16px 10px;
					position: relative;
					vertical-align: middle;

					@media print {
						background-color: rgba(233, 235, 244, 0.5) !important;
					}

					&:hover {
						background: rgba(233, 235, 244, 0.9);
						transition: 0.5s;
						cursor: pointer;
					}

					&.cl-date-width {
						max-width: 178px;
						min-width: 178px;
						//width: 178px;
					}

					&.cl-date-width1 {
						max-width: 150px;
						min-width: 150px;

						//width: 150px;
						.app-l-table-head {
							min-width: 120px;
						}
					}

					&.cl-date-width2 {
						max-width: 140px;
						min-width: 140px;
						width: 140px;

						.app-l-table-head {
							min-width: 120px;
						}
					}

					&.cl-date-width2 {
						max-width: 180px;
					}

					// &:nth-child(3) {
					// 	max-width: 150px;
					// }
					// &:nth-child(2) {
					// 	min-width: 190px;
					// 	max-width: 190px;
					// }
					// &:nth-child(1) {
					// 	min-width: 150px;
					// }

					&:first-child {
						border-radius: 6px 0px 0px 0px;
						// min-width: 150px;
						// max-width: 150px;
					}

					&:last-child {
						border-radius: 0px 6px 0px 0px;
						width: 150px;
						max-width: 150px;

						.app-l-table-head {
							min-width: auto;
						}
					}

					&.empty-column {
						width: 30px !important;
						min-width: 30px !important;
						max-width: 30px !important;
					}

					.tag-head {
						min-width: 280px;
						max-width: 100%;
					}

					.dept {
						min-width: 160px;
					}

					.rtl &,
					[dir='rtl'] & {
						text-align: right;

						&:first-child {
							border-radius: 0px 6px 0px 0px;
						}

						&:last-child {
							border-radius: 6px 0px 0px 0px;
						}
					}

					button {
						position: relative;
						z-index: 2;
						float: right;
						color: rgba(97, 99, 111, 1);

						&.table-action-btn {
							z-index: 1;
						}

						&:hover {
							color: $app-brand-primary;
						}

						i {
							font-size: 12px;

							&.icon-times {
								font-size: 10px;
							}
						}

						&.app-c-table__label {
							float: left;
							z-index: 1;
							border: none;
							font-weight: 600;
							font-size: 12px;
							line-height: normal;
							text-transform: uppercase;
							color: #61636f;
							background: none;
							padding: 0;
							white-space: nowrap;
							@include transition(all ease 0.2s);

							&:hover {
								color: $app-brand-primary;
							}
						}

						&.react-datepicker__navigation {
							position: absolute !important;
							min-height: inherit;
							float: none;
						}

						.rtl &,
						[dir='rtl'] & {
							float: left;
						}
					}

					// .app-c-table-search{
					// 	height: 24px;
					// 	position: absolute;
					// 	top: 0;
					// 	bottom: 0;
					// 	right: 31px;
					// 	margin: auto;
					// 	width: calc(100% - 44px);
					// 	z-index: 1;
					// 	font-size: 12px;
					// 	&:focus{
					// 		outline: none;
					// 	}
					// }
				}
			}
		}

		tbody {
			tr {
				&:not(.no-data-row):hover td {
					background: #f3f3f3;
				}

				td {
					font-weight: normal;
					font-size: 12px;
					line-height: normal;
					color: #1e1919;
					padding: $text-sm-plus 16px;
					border-color: rgba(170, 170, 170, 0.2);
					vertical-align: middle;
					transition: 0.2s background;

					&:hover {
						cursor: pointer;
					}

					&:last-child {
						.table-action-button {
							@include flexbox();
						}
					}

					.app-c-multiselect {
						.dropdown-container {
							.dropdown-heading {
								//border: 1px solid #c2c2c2;
								border-radius: 5px;
							}
						}
					}

					.app-c-btn--icon-only {
						width: 24px;
						height: 24px;
						font-size: 14px;

						&.inactive {
							opacity: 0.2;
							pointer-events: none;
						}

						i {
							position: relative;

							&.icon-re-sent {
								font-size: 18px;
								top: -2px;
							}
						}
					}

					.rtl &,
					[dir='rtl'] & {
						text-align: right;
					}

					.table-row-title {
						@include truncate();
						max-width: 250px;
						display: inline-block;

						&.min-scroll {
							max-width: 150px;
						}
					}
				}

				&:first-child {
					td {
						border: none;
					}
				}

				.app-c-switch {
					display: inline-block;
					vertical-align: middle;
					margin-right: 13px;
					width: 82px;

					.rtl &,
					[dir='rtl'] & {
						margin-right: 0px;
						margin-left: 15px;
					}
				}
			}
		}

		thead,
		tbody {
			td,
			th {
				// max-width: 150px;
				// &:nth-child(2) {
				// 	min-width: 220px;
				// 	max-width: 220px;
				// }
				&:first-child {
					border-radius: 6px 0px 0px 0px;
					min-width: 100px;
					// max-width: 150px;
				}

				&:last-child {
					border-radius: 0px 6px 0px 0px;
					width: 150px;
				}

				.tag-head {
					min-width: 280px;
					max-width: 100%;
				}
			}
		}

		thead {
			tr {
				th {
					.app-c-multiselect {
						position: absolute;
						width: 100%;
						min-width: 280px;
						top: 42px;
						border: none;
						box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);
						border-radius: 6px;
						background: $white;
						padding: 10px;
						right: 0;
						z-index: 1;

						.gray {
							background: $white !important;
						}

						.clear-selected-button {
							margin-right: 15px !important;
						}

						label {
							padding: 10px;
						}

						.panel-content {
							background: #fff;
						}

						ul {
							li {
								label {
									padding: 10px;
								}
							}
						}
					}

					.first-col {
						right: inherit;
						left: -70px;
						min-width: 220px !important;
					}

					.status-col {
						min-width: 280px !important;
					}

					.app-c-multiselect-tags {
						position: absolute;
						width: 100%;
						min-width: 450px;
						top: 42px;
						border: none;
						box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);
						border-radius: 6px;
						background: $white;
						padding: 10px;
						right: 0;
						z-index: 1;

						.gray {
							background: $white !important;
						}

						.clear-selected-button {
							margin-right: 15px !important;
						}
					}
				}
			}
		}
	}

	&.auto-width {
		table {
			thead {
				tr {
					th {
						max-width: unset !important;
						min-width: unset !important;

						// padding: 12px 16px 10px;
						&:nth-child(2) {
							max-width: unset !important;
							min-width: unset !important;
						}

						.app-l-table-head {
							min-width: unset;
							padding-right: 0px;
							white-space: nowrap;
							width: 100%;

							&.search-only {
								// .app-c-search-form {
								// 	right: 0px;
								// }
							}

							&.min-width1 {
								min-width: 110px !important;
							}
						}

						button {
							&.app-c-table__label {
								margin-right: 26px;

								.rtl &,
								[dir='rtl'] & {
									margin-right: 0;
									margin-left: 26px;
								}
							}
						}
					}

					td {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
			}
		}
	}

	&.users-table {
		table {
			margin-bottom: 0;

			thead {
				tr {
					th {
						&:first-child {
							width: 100px;
						}

						&:last-child {
							width: 210px;
						}

						.app-c-search-form {
							right: 32px;
							top: 13px;
						}
					}
				}
			}
		}
	}

	&.table-width-reset {
		table {
			table-layout: fixed;

			thead {
				tr {
					th {
						width: auto !important;

						padding-right: 10px;
					}

					td {
						width: auto !important;
					}
				}
			}
		}
	}
}

.table-sub-text {
	display: block;
	font-size: 10px;
	color: $text-muted;
	line-height: 16px;
}

.table-action-button {
	&:hover {
		span,
		div {
			// color: $app-brand-primary;
		}
	}
}

.app-c-search-form {
	display: inline-block;
	position: absolute;
	width: 100%;
	right: 4px;

	// width: 95%;
	// right: 10px;
	// &.push-rt{
	// 	right: 10px;
	// }
	.rtl &,
	[dir='rtl'] & {
		right: auto;
		left: 4px;
	}

	.search-field {
		height: 28px;
		top: -5px;
		border-radius: 3px;
		bottom: 0;
		position: absolute;
		right: -5px;
		@include transition(all 0.5s ease-in-out);
		cursor: pointer;
		background-color: transparent;
		border: none;
		border: 1px solid hsla(0, 0%, 80%, 0.5) !important;
		box-sizing: border-box;
		color: #333;
		font-weight: 300;
		opacity: 0;
		width: 28px;
		background: #fff;
		padding-left: 6px;
		z-index: 2;

		@media (max-width: 500px) {
			font-size: 16px;
		}

		.rtl &,
		[dir='rtl'] & {
			right: 0;
			left: -5px;
		}

		&.active {
			cursor: text;
			opacity: 1;
			outline: none;
			width: 100%;
			// z-index: 2;
			padding-right: 20px;

			.rtl &,
			[dir='rtl'] & {
				padding-right: 10px;
				padding-left: 20px;
			}

			&:hover {
				~ {
					.search-submit {
						.fa-search {
							color: #ffffff;
						}
					}
				}
			}
		}

		&:hover {
			~ {
				.search-submit {
					.fa-search {
						color: white;
					}
				}
			}
		}
	}
}

.app-c-btn--filter {
	background: transparent !important;
	padding: 0 !important;
	border: none !important;
	width: auto;
	min-width: auto;
	color: $text-color !important;
	font-size: 12px !important;
	outline: none !important;
	box-shadow: none !important;

	&::after {
		display: none !important;
	}
}

.app-c-filter-dropdown {
	background: $white;
	box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);
	border-radius: 6px;
	border: none;
	padding: 16px 32px !important;

	h1 {
		color: $text-muted;
		font-size: 12px;
		line-height: 130%;
		margin: 0 0 2px 0;
	}

	.btn-group {
		@include flexbox();
		@include align-items(flex-start);
		@include flex-direction(column);

		label {
			background: transparent !important;
			padding: 0 !important;
			border: none !important;
			width: auto;
			min-width: auto;
			color: $text-color !important;
			font-size: 14px !important;
			line-height: 22px !important;
			margin-top: 10px !important;
			outline: none !important;
			box-shadow: none !important;
			text-align: left;

			&.active {
				&:after {
					content: '\e902';
					font-family: 'qualify-tms';
					position: absolute;
					left: -20px;
					font-size: 12px;
					top: -1px;
				}
			}
		}
	}
}

.app-l-lessonTable {
	thead {
		tr {
			th {
				&:nth-child(3) {
					.app-c-search-form {
						right: 10px;
						width: 95%;
					}
				}
			}
		}
	}

	@media screen and (max-width: 1200px) {
		width: 100vw !important;
	}
}

.app-l-table-head {
	@include flexbox();
	@include justify-content(space-between);
	min-width: 160px;

	.multiselect-filter {
		position: absolute;
		top: 0;
		right: 0;
	}

	button {
		min-height: 20px;

		&.daterefresh {
			font-size: 13px;
			color: $app-brand-primary;

			&:hover {
				color: $text-muted;
			}
		}
	}
}

.rs-picker-menu {
	margin: 16px 21px 0 0;
}

.app-c-scroll-table {
	min-height: 360px;
	overflow-x: auto;
	@include flexbox();
	@include flex-direction(column);
	@include justify-content(space-between);
	@include align-items(flex-start);

	.app-c-card__footer {
		width: 100%;
		flex-shrink: 0;
	}

	&.noScroll {
		@media screen and (min-width: 992px) {
			overflow-x: visible;
		}

		@media screen and (max-width: 991px) {
			overflow-y: visible;
		}

		overflow-y: visible;
	}
}

.app-l-action-btn {
	position: relative;

	button {
		font-size: 16px !important;
		width: 30px !important;
		height: 30px !important;
		border-radius: 6px !important;

		&:hover {
			background: $gray02;
		}

		& + button {
			margin-left: 5px !important;
		}
	}

	.app-c-vertical-sorting {
		justify-content: center;

		button {
			height: auto !important;
			margin: 0 !important;

			&:focus,
			&:active,
			&.btn-primary:not(:disabled):not(.disabled):active:focus {
				color: inherit;
				@include noShadow();
				outline: none;
			}
		}
	}
}

.tippy-tooltip {
	font-size: 0.85rem !important;
	padding: 0.2rem 0.6em !important;
}

.app-inner-page-table {
	margin-top: 24px;

	table {
		thead {
			tr {
				th {
					background: rgba(233, 235, 244, 0.5);
					text-transform: uppercase;
					font-size: $text-md;
					color: #61636f;
					border-bottom: none;
					border-top: none;
					padding: 12px 16px;
					line-height: 130%;

					&:first-child {
						@include border-radius(6px 0px 0px 0px);
					}

					&:last-child {
						@include border-radius(0px 6px 0px 0px);
					}

					&.field-colunm {
						width: 120px;
					}

					&.table-actions {
						width: 100px;
					}
				}
			}
		}

		tbody {
			tr {
				td {
					padding: 10px 16px;
					font-size: $text-md;
					vertical-align: middle;
					background: #fff;
					border-top: none;
					border-bottom: 1px solid #eaeaea;

					.form-group {
						margin: 0;
					}

					.table-tiny-field {
						max-width: 44px;
						@include border-radius(6px);
						height: 30px;
						font-size: $text-md;
						text-align: center;
					}
				}
			}
		}
	}
}

.table-actions {
	.app-c-btn {
		font-size: 18px;
	}
}

.top-radius-6 {
	@include border-radius(6px 6px 0px 0px);
}

.radius-6 {
	@include border-radius(6px);
}

.app-c-delimiter-container {
	span {
		width: 30%;
		display: block;
		@include border-radius(10px);
		height: 4px;
		background: #e9ebf4;
		margin: 30px auto;

		@media screen and (max-width: 991px) {
			width: 100%;
		}
	}
}

/* Table structure with ul and li (responsive) */

.app-c-table,
.pagination {
	li {
		border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
		list-style: none;
		padding: 5px;
		margin-right: -5px;
		// width: 32%;
		display: inline-block;
		text-align: left;

		&.disabled {
			opacity: 0.2;
			cursor: not-allowed;
		}
	}
}

.app-c-table ul {
	margin-top: 20px;
}

// .app-c-table li:nth-child(3n+1) {
//         font-weight: bold;
//     }

@media only screen and (max-width: 767px) {
	.app-c-table li {
		border-bottom: 0.5px solid rgba(0, 0, 0, 0.05);
		list-style: disc;
		padding: 8px;
		margin-right: 0;
		width: 100%;
		text-align: left;
	}

	.app-c-table li:nth-child(3n) {
		border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
	}
}

.app-c-table--new {
	margin: 20px 0;
	max-width: 100%;
	overflow-x: auto;

	table {
		width: 100%;
	}

	td {
		border: 1px solid rgba(0, 0, 0, 0.1);
		padding: 10px;
		text-align: left;
	}
}

.app-c-table.custom-topic-table table thead tr {
	th,
	td {
		&:nth-child(2),
		&:nth-child(3) {
			width: 150px;
			min-width: 150px;
			max-width: 150px;
		}

		&:first-child {
			width: 200px;
			max-width: 200px;
		}

		&:last-child {
			width: auto;
			min-width: 150px;
		}
	}
}

.survey-responses,
.app-c-report-table {
	.app-l-action-btn {
		cursor: auto !important;
		pointer-events: none;
	}
}
