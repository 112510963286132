.app-c-breadcrumb {
	@include flexbox();
	@include flex-wrap(wrap);
	@include align-items(center);
	

	.app-c-breadcrumb__prev {
		color: $text-dark-extra;
		font-size: $text-sm-plus;
		font-weight: 400;
		position: relative;
		padding-right: 13px;
		margin-right: 10px;
		cursor: default;

		@include transition(color ease 0.2s);

		&:after {
			content: '\e914';
			font-family: 'qualify-tms' !important;
			font-size: 8px;
			color: #8690a9;
			position: absolute;
			top: 0;
			right: 0;
			height: 100%;

			@include flexbox();
			@include align-items(center);
		}

		&.single-page {
			&:after {
				display: none;
			}
		}

		// &:hover{
		//     color: $text-dark-medium;
		// }
	}
	.app-c-breadcrumb__active {
		color: $breadcrumb-active;
		cursor: default;
		position: relative;
		font-size: $text-sm-plus;
		padding-right: 13px;
		margin-right: 10px;
		&:after {
			content: '\e914';
			font-family: 'qualify-tms' !important;
			font-size: 8px;
			color: #8690a9;
			position: absolute;
			top: 0;
			right: 0;
			height: 100%;

			@include flexbox();
			@include align-items(center);
		}
		&:last-child {
			&:after {
				display: none;
			}
		}
	}
}
