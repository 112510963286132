.app-l-question-tab {
	background: transparent !important;
	// box-shadow: none !important;
	padding: 0 !important;

	.tab-pane {
		padding: 24px !important;
		box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.135216) !important;
		@include border-radius(8px);
		background: #fff;
		@media screen and (max-width: 767px) {
			padding: 16px 10px !important;
		}
	}

	.card {
		@include border-radius(8px);
		overflow: hidden;
		// box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.135216);
		@include noShadow();

		.card-header {
			background: transparent;
		}

		.app-l-type-holder {
			position: relative;
			@include align-items(center);
			@include justify-content(space-between);

			.btn-outline-secondary {
				height: 40px;
				padding: 0 16px !important;
				border-color: $gray03;
				font-size: 14px;
				font-weight: $font-weight-semibold;
				color: $black01;
				margin-right: 38px !important;

				.rtl &,
				[dir='rtl'] & {
					margin-left: 38px !important;
				}

				span {
					margin-right: 10px;

					.rtl &,
					[dir='rtl'] & {
						margin-right: 0;
						margin-left: 10px;
					}

					&:before {
						color: $gray01;
					}
				}

				&:hover {
					color: $white;

					span {
						&:before {
							color: $white;
						}
					}
				}
			}

			form {
				min-width: 330px;

				label {
					font-size: 14px;
					line-height: 22px;
					margin-bottom: 6px;
					color: $text-muted;
				}

				.form-control {
					border: 1px solid $gray03;
				}
			}

			.app-l-cards-buttons {
				position: relative;
				top: auto;
				right: auto;

				.rtl &,
				[dir='rtl'] & {
					left: 0;
				}
			}
		}

		&.app-l-question-img-holder {
			p {
				color: $gray01;
				font-size: 14px;
				line-height: 22px;
			}
		}

		.app-l-cards-buttons {
			position: absolute;
			top: 10px;
			right: 15px;
			@include flexbox();
			align-items: center;

			.rtl &,
			[dir='rtl'] & {
				right: auto;
				left: 15px;
			}

			button {
				padding: 1px !important;
				margin: 0 12px;
				font-size: 18px;

				&:hover {
					&::before {
						color: $black01 !important;
					}
				}
			}

			.app-l-sort-button-holder {
				@include flexbox();
				@include flex-direction(column);
				//height: 24px;
				margin-left: 8px;

				.rtl &,
				[dir='rtl'] & {
					margin-left: 0;
					margin-right: 8px;
				}

				button {
					font-size: 7px;
					width: 24px;
					height: 12px;
					@include flexbox();
					@include align-items(center);
					@include justify-content(center);
					margin: 0;

					&:hover {
						background: $gray02 !important;

						&::before {
							color: $black01 !important;
						}
					}
				}
			}
		}

		&.app-l-question-content-holder {
			h6,
			p {
				color: $black01;
			}

			h6 {
				font-size: 14px;
				line-height: 29px;
				font-weight: $font-weight-semibold;

				.rtl &,
				[dir='rtl'] & {
					text-align: right;
				}
			}

			p {
				font-size: 14px;
				line-height: 22px;
				font-weight: $font-weight-normal;
				margin-bottom: 0;
				margin-top: 4px;

				.rtl &,
				[dir='rtl'] & {
					text-align: right;
				}

				strong {
					font-weight: $font-weight-semibold;
					float: left;
					direction: ltr;

					.rtl &,
					[dir='rtl'] & {
						float: right;
						direction: rtl;
					}
				}
			}
		}
	}

	.app-c-input-group {
		input {
			border-right: 0;

			.rtl &,
			[dir='rtl'] & {
				border-right: 1px solid $gray03;
				border-left: 0;
				border-top-right-radius: 0.25rem !important;
				border-bottom-right-radius: 0.25rem !important;
				border-top-left-radius: 0 !important;
				border-bottom-left-radius: 0 !important;
			}
		}

		button {
			border-color: $gray03;
			border-left: 0;

			.rtl &,
			[dir='rtl'] & {
				border-right: 0 !important;
				border-left: 1px solid $gray03;
				border-top-left-radius: 0.25rem !important;
				border-bottom-left-radius: 0.25rem !important;
				border-top-right-radius: 0 !important;
				border-bottom-right-radius: 0 !important;
			}

			&:hover {
				span {
					&:before {
						color: $white;
					}
				}
			}
		}
	}
}

.app-l-answer-list {
	h6 {
		color: $black01;
		font-weight: $font-weight-semibold;
		font-size: 15px;
		line-height: 17px;
		padding-left: 44px;

		.rtl &,
		[dir='rtl'] & {
			padding-left: 0;
			padding-right: 44px;
			text-align: right;
		}
	}

	.app-l-answer-list {
		padding-left: 44px !important;

		.rtl &,
		[dir='rtl'] & {
			padding-left: 0 !important;
			padding-right: 44px !important;
		}
	}

	.app-c-add-new {
		color: $app-brand-primary !important;
		background: transparent !important;
		border: none !important;
		padding: 0 !important;
		font-size: 14px !important;
		font-weight: $font-weight-bold !important;
		height: auto !important;
		margin-left: 44px !important;
		@include flexbox();
		@include align-items(center);

		&:hover {
			opacity: 0.9;
		}

		.rtl &,
		[dir='rtl'] & {
			margin-left: 0 !important;
			margin-right: 44px !important;
			float: right;
		}

		span {
			background: $app-brand-primary;
			width: 16px;
			height: 16px;
			font-size: 7px;
			padding: 4px;
			border-radius: 4px;
			margin-right: 6px;
			margin-top: 2px;
			display: inline-block;
			float: left;

			&::before {
				color: $white;
			}
		}
	}

	.app-c-checkbox > label {
		@include align-items(flex-start);
	}

	.app-c-checkbox-label {
		font-weight: $font-weight-semibold;
		font-size: 14px;
		color: $black01;
		padding-left: 16px;
	}

	button,
	input {
		height: 40px;
	}

	.btn-outline-secondary {
		margin-left: 12px;
		border: 1px solid $gray03;
		flex: 0 0 36px;
		max-width: 36px;
		padding: 0;

		&:hover {
			&::before {
				color: $white !important;
			}
		}
	}

	.app-c-sort-btn {
		height: 40px;
		border: 1px solid $gray03;
		border-radius: 6px;
		width: 23px;
		@include flexbox();
		@include flex-direction(column);
		@include justify-content(space-between);
		margin-left: 12px;

		button {
			height: auto;
			flex: auto;
			max-width: 100%;
			font-size: 7px;
			margin: 0;
			padding: 3px 0 !important;

			&:hover {
				background: $gray02 !important;

				&::before {
					color: $black01 !important;
				}
			}
		}
	}
}

.app-l-card-title {
	font-size: 14px !important;
	line-height: 29px !important;
	color: $black01 !important;
	font-weight: $font-weight-semibold !important;
}

.form-label {
	font-size: 14px;
	color: $text-muted;

	sup {
		color: $validation-red;
		//font-weight: $font-weight-bold !important;
		font-size: 15px;
		top: 0px;
	}
}

.app-c-custom-select {
	&.moreWidth {
		select {
			width: 108%;
		}
		&::after {
			right: -0.67px;
		}
	}
}
