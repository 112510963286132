@import '../core/mixins/flexbox';
@import '../core/mixins/border-radius';
@import '../core/mixins/box-shadow';
@import '../core/mixins/filters';

$text-dark-10: #1e1919;
$gray-shade-100: #f4f5f9;

.modal-backdrop {
	filter: alpha(opacity=0);

	@include transition(opacity 0.1s linear, background-color 0.1s ease, background 0.1s ease);

	&.show {
		opacity: 0.5;
		filter: alpha(opacity=50);

		@include backdrop-filters(blur(1.5px) grayscale(0%));
	}

	+ .modal-backdrop {
		display: none;
	}

	~ bs-dropdown-container {
		z-index: 1060 !important;
	}
}

body {
	> .modal {
		opacity: 0;
		filter: alpha(opacity=0);
		visibility: hidden;

		@include transition(
			opacity 0.1s linear,
			background-color 0.1s ease,
			background 0.1s ease,
			transform 0.1s ease
		);

		&.show {
			background-color: rgba(0, 0, 0, 0.25);
			background: rgba(0, 0, 0, 0.25);

			display: -webkit-box !important;
			display: -webkit-flex !important;
			display: -moz-flex !important;
			display: -ms-flexbox !important;
			display: flex !important;

			opacity: 1;
			filter: alpha(opacity=100);
			visibility: visible;

			@include align-items(center);
			@include justify-content(center);
			@include transform(scale(1));
			@include backdrop-filters(blur(1.5px) grayscale(0%));
			z-index: 9998;

			.modal-dialog {
				opacity: 1;
				filter: alpha(opacity=100);
				visibility: visible;

				@include transform(scale(1));
			}
		}

		.modal-dialog {
			opacity: 0;
			filter: alpha(opacity=0);
			visibility: hidden;

			@include transform(scale(0));
			@include transition(transform 0.1s ease, width 0.1s linear, opacity 0.1s linear);

			@media screen and (min-width: 576px) {
				max-width: 80%;
				min-width: 296px;
				margin: 0;
			}

			&.app-l-profile-modal {
				@media screen and (max-width: 576px) {
					width: 100%;
					max-width: 100%;
				}

				@media screen and (min-width: 991px) {
					max-width: 60%;
					min-width: 296px;
				}

				@media screen and (min-width: 1366px) {
					max-width: 40%;
					min-width: 296px;
				}
			}
		}

		~ .modal-backdrop {
			&.show {
				opacity: 0.25;
				filter: alpha(opacity=25);
			}
		}
	}
}

.app-c-modal {
	&.app-l-modal__wol {
		.modal-dialog {
			max-width: 800px;

			.modal-body {
				@include flexbox();
				@include flex-direction(column);
			}

			.app-c-grid {
				margin-left: -24px;
				margin-right: -24px;
				height: 100%;
				min-height: 0;

				> .dx-widget {
					height: 100%;

					@include flexbox();
					@include flex-direction(column);
				}

				.app-l-grid-header {
					padding-top: 0;
				}

				.app-c-grid--footer {
					padding-bottom: 0;
				}
			}
		}
	}

	&.app-l-modal__copy-form {
		.modal-dialog {
			max-width: 500px;

			.app-l-form__copy {
				.app-l-tc__row {
					@include flexbox();
					@include align-items(center);

					.app-l-tc__left {
						.app-c-group--input {
							.app-l-group__input-form {
								max-width: 124px;
							}
						}
					}

					.app-l-tc__right {
						padding-left: 8px;
						font-size: 12px;
						color: $text-color;
					}
				}
			}

			.app-l-cf__row {
				margin-left: -12px;
				margin-right: -12px;

				> div {
					padding-left: 12px;
					padding-right: 12px;
				}
			}
		}
	}

	&.app-l-modal__ais {
		.modal-dialog {
			max-width: 800px;

			.app-l-form__ais {
				.app-c-group--input {
					.app-l-group__input-form {
						max-width: 120px;
					}

					.app-l-group__icon {
						margin-left: 8px;

						@include align-self(center);

						.app-l-ais__info {
							font-size: 12px;
							font-weight: 400;
							color: $text-color;
							line-height: normal;
						}
					}
				}
			}

			.app-l-ais__row {
				margin-bottom: 25px;

				@media screen and (max-width: 576px) {
					margin-bottom: 5px;
				}
			}

			.modal-body {
				@include flexbox();
				@include flex-direction(column);
			}

			.app-c-grid {
				margin-left: -24px;
				margin-right: -24px;
				height: 100%;
				min-height: 0;

				> .dx-widget {
					height: 100%;

					@include flexbox();
					@include flex-direction(column);
				}

				.app-c-grid--footer {
					padding-bottom: 0;
				}
			}
		}
	}

	&.app-l-modal__aws {
		.modal-dialog {
			max-width: 800px;

			.app-l-aws__row {
				margin-bottom: 25px;

				@media screen and (max-width: 576px) {
					margin-bottom: 5px;
				}
			}

			.modal-body {
				@include flexbox();
				@include flex-direction(column);
			}

			.app-c-grid {
				margin-left: -24px;
				margin-right: -24px;
				height: 100%;
				min-height: 0;

				> .dx-widget {
					height: 100%;

					@include flexbox();
					@include flex-direction(column);
				}

				.app-c-grid--footer {
					padding-bottom: 0;
				}
			}

			.app-l-form__generic {
				.app-c-group--input {
					.app-l-group__input-form {
						max-width: 120px;
					}

					.app-l-group__icon {
						margin-left: 8px;

						@include align-self(center);

						.app-l-ais__info {
							font-size: 12px;
							font-weight: 400;
							color: $text-color;
							line-height: normal;
						}
					}
				}
			}
		}
	}

	&.app-l-modal__copyfrom {
		.modal-dialog {
			max-width: 800px;

			.app-l-aws__row {
				padding-bottom: 8px;

				@include flexbox();
				@include flex-wrap();

				.form-group {
					margin-right: 16px;
				}
			}

			.modal-body {
				@include flexbox();
				@include flex-direction(column);
			}

			.app-c-grid {
				margin-left: -16px;
				margin-right: -16px;
				height: 100%;
				min-height: 0;

				> .dx-widget {
					height: 100%;

					@include flexbox();
					@include flex-direction(column);
				}

				.app-c-grid--footer {
					padding-bottom: 0;
				}
			}

			.app-l-form__generic {
				.app-c-group--input {
					.app-l-group__input-form {
						max-width: 120px;
					}

					.app-l-group__icon {
						margin-left: 8px;

						@include align-self(center);

						.app-l-ais__info {
							font-size: 12px;
							font-weight: 400;
							color: $text-color;
							line-height: normal;
						}
					}
				}
			}
		}
	}

	&.app-l-modal__item-inquiry {
		.modal-dialog {
			max-width: 500px;

			.app-l-item-inquiry__field {
				.app-c-group--input {
					@media screen and (max-width: 576px) {
						display: block;
					}

					.app-l-group__input-form {
						max-width: 120px;
					}

					.app-l-group__icon {
						margin-left: 8px;

						@include align-self(center);

						@media screen and (max-width: 576px) {
							margin-left: 0;
							margin-top: 8px;
						}

						.app-l-ais__info {
							font-size: 12px;
							font-weight: 400;
							color: $text-color;
							line-height: normal;
						}
					}
				}
			}

			.app-l-item-inquiry__list {
				border: #dbdfea 1px solid;
				margin-top: 20px;

				.app-l-item-inquity__block {
					color: $text-color;
					font-size: 12px;
					line-height: 18px;
					font-weight: 400;
					padding: 10px 16px;
					min-height: 38px;

					+ .app-l-item-inquity__block {
						border-top: #dbdfea 1px solid;
					}
				}
			}
		}
	}

	&.app-l-modal__item-status {
		.modal-dialog {
			max-width: 500px;
		}

		.app-l-item-status__row {
			margin-left: -12px;
			margin-right: -12px;

			> div {
				padding-left: 12px;
				padding-right: 12px;
			}
		}

		.app-l-item__form-group {
			@media screen and (max-width: 576px) {
				display: block;
			}

			.app-l-group__input-form {
				max-width: 120px;
			}

			.app-l-group__icon {
				margin-left: 8px;

				@include align-self(center);

				@media screen and (max-width: 576px) {
					margin-left: 0;
					margin-top: 8px;
				}

				.app-l-ais__info {
					font-size: 12px;
					font-weight: 400;
					color: $text-color;
					line-height: normal;
				}
			}
		}

		.app-l-r-first {
			margin-bottom: 15px;
		}
	}

	&.app-l-lookup-modal {
		max-width: 800px;
		padding-top: 16px;
		padding-bottom: 16px;
		height: auto;
		max-height: 100%;
		min-height: 0;

		@include flexbox();
		@include flex-direction(column);

		> .modal-content {
			height: 100%;
			min-height: 0;

			@include flexbox();
			@include flex-direction(column);
		}

		.modal-body {
			min-height: 400px;
			padding-bottom: 8px;

			@include flexbox();
			@include flex-direction(column);
		}

		.app-l-lookup-modal-data {
			overflow: hidden;
			height: 100%;

			@include border-radius(4px);
		}

		.app-l-lookup-grid-holder {
			margin-left: -16px;
			margin-right: -16px;
			height: 100%;
			min-height: 300px;
			width: auto;

			.app-c-grid {
				height: 100%;
				min-height: 300px;

				.app-l-grid-header {
					.app-l-grid-head-left {
						.app-l-grid-header-text {
							display: none;
						}

						.app-l-header-sub-text {
							display: none;
						}
					}
				}

				> .dx-widget {
					height: 100%;
					min-height: 200px;

					@include flexbox();
					@include flex-direction(column);

					.dx-datagrid-rowsview {
						@media screen and (min-height: 400px) {
							min-height: 230px;
						}
					}
				}

				.app-c-grid--footer {
					.app-l-grid-footer__left {
						.app-l-row-text {
							display: none;
						}

						.form-control {
							display: none;
						}
					}
				}
			}

			.app-l-grid-header {
				padding-top: 0;
			}

			.app-c-grid--footer {
				padding-bottom: 0;
			}
		}
	}

	&.image-upload-popup {
		padding: 0 !important;
		width: 100%;

		.modal-dialog {
			width: 100%;
			padding: 0;

			.modal-content {
				height: 100%;
			}
		}
	}
}

.app-l-modal__dialog {
	&.modal-dialog {
		max-width: 310px;
	}
}

.modal-dialog {
	height: 100%;
	margin: 0 !important;
	padding: 15px;

	@include flexbox();
	@include align-items(center);
	@include justify-content(center);

	.modal-content {
		background: $white;
		border: none;
		max-height: 100%;

		@include border-radius(6px);
		@include boxShadow(0px 0px 10px rgba(35, 59, 88, 0.3));
		@include flexbox();
		@include flex-direction(column);

		.modal-header {
			border-bottom: none;
			padding: 24px 24px 10px;

			@include flexbox();
			@include align-items(center);
			@include flex-shrink(0);

			.app-l-modal-header__left {
				@include flexbox();
				@include align-items(center);

				h4 {
					color: $text-color;
					font-weight: 500;
					font-size: 18px;
					margin: 0;
					padding: 0;
					line-height: 22px;
				}

				.app-c-btn__round {
					margin-left: 8px;
				}
			}

			.app-l-modal-header__right {
				margin-left: auto;

				@include flex-shrink(0);
				@include align-self(flex-start);
			}

			.app-c-modal--close {
				background: transparent;
				width: 20px;
				height: 20px;
				color: $text-dark-normal;
				font-size: 9px;
				outline: none;
				border: none;
				padding: 0;
				position: relative;
				top: -10px;
				right: -6px;
				bottom: auto;
				left: auto;

				@include border-radius(3px);
				@include flexbox();
				@include align-items(center);
				@include justify-content(center);
				@include transition(background ease 0.2s);

				&:hover {
					background: $neutrals-color3;
				}
			}
		}

		.modal-body {
			padding: 10px 24px 20px;
			height: 100%;
			overflow: auto;
			min-height: 0;

			&.no-scroll {
				@include flexbox();
				@include flex-direction(column);
				@include align-items(space-between);
				@include justify-content(space-between);
				overflow: visible;
			}

			.app-c-card__footer {
				padding: 12px 24px 16px 24px;
			}

			h4 {
				color: $text-color;
				font-weight: 500;
				font-size: 15px;
				line-height: 22px;
				margin: 0 0 8px;
			}

			p {
				// color: $text-color;
				font-size: 16px;
				line-height: normal;
				margin: 0;

				+ p {
					margin-top: 15px;
				}
			}
		}

		.modal-footer {
			padding: 8px 16px;
			border-top-color: $app-brand-secondary;

			@include flex-shrink(0);

			> * {
				margin: 0;
			}
		}
	}

	&.app-l-support-modal {
		max-height: 100%;
		min-height: 300px;
		height: 100%;

		@include flexbox();
		@include flex-direction(column);

		.modal-content {
			max-height: 100%;
			min-height: 300px;
			height: 100%;

			@include flexbox();
			@include flex-direction(column);
		}
	}
}

.app-l-filter-builder-modal {
	height: 100%;
	min-height: 0;
	width: 100%;
	min-width: 0;
	opacity: 0;
	filter: alpha(opacity=0);
	visibility: hidden;

	> * {
		opacity: 0;
		filter: alpha(opacity=0);
		visibility: hidden;
	}

	&.show {
		opacity: 1;
		filter: alpha(opacity=100);
		visibility: visible;

		display: -webkit-box !important;
		display: -webkit-flex !important;
		display: -moz-flex !important;
		display: -ms-flexbox !important;
		display: flex !important;

		@include flex-direction(column);

		> * {
			opacity: 1;
			filter: alpha(opacity=100);
			visibility: visible;
		}
	}

	.modal-dialog {
		height: 100%;
		min-height: 0;
		width: 100%;
		min-width: 0;
		margin: 16px auto;

		@include flexbox();
		@include flex-direction(column);
		@include align-items(center);

		@media screen and (min-width: 576px) {
			// max-width: calc(70%);
		}

		@media screen and (max-width: 991px) {
			max-width: calc(100% - 32px);
		}

		@media screen and (max-width: 767px) {
			max-width: calc(100%);
			margin-top: 8px;
			margin-bottom: 8px;
		}

		&.full-height {
			@media screen and (max-width: 767px) {
				height: 100%;
			}
		}

		.modal-content {
			max-height: 100%;
			min-height: 0;
			width: 100%;
			min-width: 0;
			margin-top: auto;
			margin-bottom: auto;
			margin-left: 16px;
			margin-right: 16px;

			@include flexbox();
			@include flex-direction(column);

			@media screen and (max-width: 767px) {
				margin-left: 8px;
				margin-right: 8px;
			}

			&.full-height {
				@media screen and (max-width: 767px) {
					height: 100%;
				}
			}

			.modal-header {
				padding: 16px 16px 8px 16px;
				position: relative;
				width: 100%;
				min-width: 0;
				border-bottom: none;

				@include flexbox();
				@include align-items(center);

				@media screen and (max-width: 767px) {
					padding: 12px 16px 12px 16px;
				}

				.modal-title-header {
					color: $text-color;
					font-weight: 500;
					font-size: 18px;
					line-height: 20px;
				}

				.app-l-header-close {
					background-color: transparent;
					border: transparent;
					cursor: pointer;
					outline: none;
					margin-left: auto;

					@include flexbox();
					@include align-items(center);
					@include justify-content(center);
				}
			}

			.modal-body {
				padding: 12px 0px;
				min-height: 0;
				max-height: 100%;
				width: 100%;
				min-width: 0;

				@include flexbox();
				@include flex-direction(column);

				&.app-l-filter-grid-modal-body {
					padding: 0;
				}

				> form {
					min-height: 0;
					max-height: 100%;
					width: 100%;
					min-width: 0;

					@include flexbox();
					@include flex-direction(column);

					&.scrollable-form {
						overflow: hidden;
						overflow-y: auto;
					}

					&.full-height {
						@media screen and (max-width: 767px) {
							overflow: visible;
							height: 100%;
						}
					}
				}
			}
		}
	}

	&.app-l-organize-filter {
		.modal-dialog {
			.modal-content {
				@media screen and (min-height: 450px) {
					min-height: 400px;
				}

				.modal-body {
					.app-l-filter-grid {
						.dx-widget {
							.dx-datagrid-header-panel {
								.dx-toolbar-items-container {
									padding: 0px;
								}
							}
						}
					}
				}
			}
		}
	}

	&.app-l-filter-builder-widget {
		.modal-dialog {
			width: auto;
			min-width: 0;

			@media screen and (min-width: 576px) {
				max-width: calc(70%);
			}

			.modal-content {
				.modal-body {
					padding-top: 0;
					padding-bottom: 0;
					overflow: visible;

					form {
						.app-l-filter-name-setter {
							width: 100%;
							padding: 8px 16px;

							@include flex-shrink(0);

							.form-group {
								width: 100%;
								margin-bottom: 0;
							}
						}

						.app-l-filter-builder-body {
							padding: 0;
							overflow: visible;
							min-width: 0;
							width: 100%;

							@include flexbox();
							@include flex-direction(column);

							@media screen and (min-height: 400px) {
								min-height: 200px;
							}

							.filter-container {
								@include flexbox();
								@include flex-direction(column);

								padding: 0;
								overflow: auto;
								min-width: 0;
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
}

.app-l-filter-builder-body {
	padding: 8px 16px;
	min-height: 0;
	height: 100%;
	overflow: auto;
	width: 100%;
	min-width: 0;

	@include flexbox();
	@include flex-direction(column);

	@media screen and (max-width: 767px) {
		padding: 0px 16px;
	}
}

.app-l-filter-builder-footer {
	width: 100%;
	padding: 4px 16px;
	border-top: solid 1px $neutrals-color4;

	@include flex-shrink(0);
	@include flex-grow(0);
	@include flexbox();
	@include flex-direction(row);
	@include align-items(flex-end);
	@include flex-wrap(wrap);

	@media screen and (max-width: 767px) {
		padding: 8px 16px;
	}

	.app-l-filter-builder-footer-control {
		padding: 4px 8px 0px 0px;

		@include flexbox();
		@include flex-direction(column);

		.app-c-checkbox {
			+ .app-l-onboard-form-group {
				margin-left: 0;
			}
		}
	}

	.app-l-filter-builder-footer-actions {
		margin-left: auto;
		padding: 0px;

		@include flexbox();
		@include flex-direction(row);
		@include align-items(center);
		@include justify-content(flex-end);

		@media screen and (max-width: 576px) {
			@include flex-wrap(wrap);
		}

		> .app-c-checkbox {
			margin: 0px 16px 0px 0px;

			@media screen and (max-width: 576px) {
				margin: 8px 8px 0px 0px;
			}
		}

		> .app-c-btn {
			margin-top: 4px;
			margin-bottom: 4px;
			margin-left: 8px;
		}
	}
}

.app-l-filter-selectors {
	width: 100%;
	padding: 8px 24px 8px 24px;
	position: relative;
	z-index: 1;

	@include flex-shrink(0);
	@include flex-grow(0);
	@include flexbox();
	@include align-items(center);

	@media screen and (max-width: 991px) {
		@include flex-wrap(wrap);
	}

	.app-l-filter-form {
		margin-right: 16px;

		@include flex-shrink(0);
		@include flex-grow(0);
		@include flexbox();

		.app-l-onboard-form-group {
			padding-bottom: 0;

			.app-l-form-validation-text {
				position: relative;
				text-align: left;
			}
		}
	}

	.app-l-filter-radio-controls {
		@include flex-shrink(0);
		@include flex-grow(0);
		@include flexbox();
		@include align-items(center);
		@include justify-content(flex-start);
		@include flex-wrap(wrap);

		> .form-group {
			margin-top: 4px;
			margin-bottom: 4px;
			margin-right: 16px;

			@media screen and (max-width: 767px) {
				margin-bottom: 8px;
			}

			@media screen and (max-width: 576px) {
				width: 100%;
			}
		}
	}
}

.app-l-filter-grid-holder {
	width: 100%;
	padding: 0;
	height: 100%;
	min-height: 0;

	@media screen and (max-width: 991px) {
		margin-top: 0;
	}

	@include flexbox();
	@include flex-direction(column);

	.app-l-filter-grid {
		height: 100%;
		min-height: 0;

		.app-c-grid {
			.app-l-grid-header {
				padding-top: 0;
			}
		}
	}

	.app-l-filter-grid-controls {
		width: 100%;
		padding: 4px 24px;
		border-top: solid 1px $table-border;

		@include flex-shrink(0);
		@include flex-grow(0);
		@include flexbox();
		@include align-items(center);
		@include justify-content(flex-end);
		@include flex-wrap(wrap);

		.app-c-btn {
			margin: 8px 0 8px 8px;
		}
	}
}

.fade {
	@include transition(opacity 0.05s linear);
}

.app-l-materials-modal {
	.modal-content {
		.modal-body {
			@include flexbox();
			@include flex-direction(column);
		}
	}
}

.app-l-modal__filter-builder-modal {
	.modal-header {
		padding: 16px 16px 8px 16px !important;
		position: relative;
		width: 100%;
		min-width: 0;
		border-bottom: none !important;

		@include flexbox();
		@include align-items(center !important);

		@media screen and (max-width: 767px) {
			padding: 12px 24px 12px 24px;
		}

		.modal-title-header {
			color: $text-color;
			font-weight: 500;
			font-size: 18px;
			line-height: 20px;
			margin-bottom: 0px;
		}

		.app-l-header-close {
			background-color: transparent;
			border: transparent;
			cursor: pointer;
			outline: none;
			margin-left: auto;

			@include flexbox();
			@include align-items(center);
			@include justify-content(center);

			.app-c-btn--icon {
				width: 20px;
				height: 20px;
				font-size: 12px;
			}
		}
	}

	.modal-body {
		padding-bottom: 0px !important;

		.app-l-filter-builder-body {
			padding: 0px;
			min-height: 200px !important;

			ops-filter-builder {
				padding: 0px;
			}

			.app-l-filter-builder-footer {
				padding-left: 0px;
				padding-right: 0px;
			}
		}
	}
}

//mini vertical modal sidebar

.app-c-modal-sidePanel {
	overflow: hidden !important;

	&.full {
		.modal-dialog {
			min-width: 100% !important;
			padding: 0px 15px;

			.modal-content {
				@include border-radius(16px 16px 0px 0px);

				.modal-header {
					border: none;
					padding: 0;
				}
			}
		}
	}

	&.common-modal {
		&.modal-100 {
			.modal-dialog {
				width: 100%;
				min-width: 100%;
				max-width: 100%;

				.modal-content {
					background: #fcfcfe;
					@include border-radius(16px 16px 0px 0px);

					.modal-header {
						max-width: 1086px;
						width: 100%;
						border: none;
						margin: auto;
						@include border-radius(0px);
						padding: 18px 16px 16px 16px;

						&.no-border {
							border-bottom: none;
						}

						.app-c-btn {
							display: inline-flex;
							align-items: center;

							.modal-title {
								font-size: 20px;
								display: inline-block;
								vertical-align: middle;

								@media screen and (max-width: 767px) {
									font-size: $text-lg;
								}
							}

							i {
								font-size: 14px !important;
								display: inline-block;
								vertical-align: middle;
								float: left;
								position: relative;
								top: -1px;
								&.icon-times {
									font-size: 10px !important;
								}

								&.icon-send {
									font-size: 13px !important;
								}
							}
						}
					}

					.modal-body {
						padding: 0px 16px;
					}
				}
			}

			.app-l-content-box {
				max-width: 1086px;
				margin: auto;
				padding: 19px 24px;
			}
		}

		&.modal-75 {
			.modal-dialog {
				width: 75%;

				@media screen and (max-width: 991px) {
					width: 100%;
				}
			}
		}

		&.modal-50 {
			.modal-dialog {
				width: 50%;

				@media screen and (max-width: 991px) {
					width: 90%;
				}
			}
		}
		&.modal-432 {
			.modal-dialog {
				width: 100%;
				max-width: 432px;

				@media screen and (max-width: 991px) {
					width: 100%;
				}
			}
		}
		&.modal-632 {
			.modal-dialog {
				width: 100%;
				max-width: 632px;

				@media screen and (max-width: 991px) {
					width: 100%;
				}
			}
		}
		&.modal-60 {
			.modal-dialog {
				width: 60%;

				@media screen and (max-width: 991px) {
					width: 90%;
				}
				@media screen and (max-width: 767px) {
					width: 100%;
					max-width: 100%;
					padding: 0 10px;
				}
			}
		}

		.modal-body {
			padding: 0;

			p {
				font-size: $text-lg;
				padding-bottom: 4px;
			}
		}

		.modal-header {
			background: #fcfcfe;
		}

		.modal-content {
			background: #fff;
		}

		.app-c-modal-content {
			padding: 24px 24px;
			flex-grow: 1;
			@media screen and (max-width: 767px) {
				padding: 16px 16px;
			}

			.rtl &,
			[dir='rtl'] & {
				text-align: right;
			}

			&.light-bg {
				background: #fcfcfe;
			}

			&.btm-bdr {
				box-shadow: 0px -4px 3px 0px rgba(0, 0, 0, 0.02);
			}

			h5 {
				font-size: 16px;
				color: #1e1919;
				font-weight: $font-weight-semibold;
				margin: 0px 0px 4px;
				line-height: 17px;
			}
		}
	}

	&.file-upload-modal {
		.modal-dialog {
			min-width: 446px !important;

			@media (max-width: 767px) {
				min-width: 100%;
			}
		}

		.app-c-btn {
			line-height: 29px;
			padding: 9px 12px;

			i {
				color: #fff;
				font-size: 18px;
			}
		}
	}
	&.question-set-modal {
		&.only-qn {
			.modal-body {
				height: 100%;
				.app-c-tab {
					height: 100%;
					.app-c-modal-list-stack__row {
						margin-top: 0;
					}
					.aq-set {
						@include flex-direction(column);
						@include flexbox;
						height: 100%;
						.aq-set-question-wrapper {
							overflow-x: hidden;
							overflow-y: auto;
						}
						.aq-set-question,
						.form-group {
							margin-bottom: 0;
							margin-top: 0;
						}
					}
				}
			}
		}
		.app-c-modal-content {
			height: 100%;
		}
		.modal-dialog {
			max-height: calc(100vh - 60px);
		}
	}
	.modal-dialog {
		position: fixed;
		right: 0;
		padding: 0;
		max-height: calc(100vh - 80px);
		bottom: 0;
		min-width: 306px !important;
		margin-bottom: auto;

		@media screen and (max-width: 767px) {
			max-height: calc(100% - 80px) !important;
			min-width: 100%;
		}

		.rtl &,
		[dir='rtl'] & {
			right: auto;
			left: 0;
		}

		.modal-content {
			height: 100%;
			@include border-radius(16px 16px 0 0);

			.rtl &,
			[dir='rtl'] & {
				@include border-radius(0 16px 0 0);
			}

			.modal-header {
				@include border-radius(16px 16px 0 0);

				.rtl &,
				[dir='rtl'] & {
					@include border-radius(0 16px 0 0);
				}

				border: 1px solid #e9ebf4;
				padding: 9px 24px;

				@media screen and (max-width: 767px) {
					padding: 9px 16px;
				}
				@media screen and (max-width: 575px) {
					display: block;
					> div {
						padding-top: 5px;
						text-align: right;
					}
				}

				h5 {
					font-size: $text-lg;
					margin: 0;
					line-height: 26px;
				}

				.app-l-screen-header {
					padding: 0;
				}

				.modal-title {
					color: $text-dark-10;
					font-size: 15px;
					font-weight: $font-weight-semibold;
					padding: 8px 0px;
				}

				button.app-c-modal-sidePanel-close.btn.btn-secondary {
					position: absolute;
					top: -35px;
					right: 50%;
					@include transform(translate(50%, -50%));
					background: $white;
					color: $text-muted;
					@include border-radius(50%);
					width: 36px;
					height: 36px;
					@include flexbox();
					@include justify-content($value: center);
					@include align-items($value: center);

					@media screen and (max-width: 767px) {
						top: -24px;
					}

					.rtl &,
					[dir='rtl'] & {
						right: auto;
						left: 50%;
					}
				}
				.btn-group--modal {
					@media screen and (max-width: 767px) {
						justify-content: flex-end;
					}
					button {
						margin: 5px 4px 4px 4px;
					}
				}
			}

			.modal-body {
				&.app-c-modal-sidePanel-list {
					padding: 0;

					li {
						padding: 15px 16px;
						cursor: pointer;

						@media screen and (max-width: 767px) {
							padding: 6px 16px;
						}

						.app-c-modal-sidePanel-content {
							@include flexbox();
							@include align-items($value: center);
							@include flex-direction($value: row);

							.app-c-modal-sidePanel-icon {
								width: 34px;
								height: 34px;
								@include flexbox();
								@include align-items($value: center);
								@include justify-content($value: center);
								@include border-radius(50%);
								background: $app-brand-primary;
								color: $white;
								font-size: $text-md-plus;
							}

							.app-c-modal-sidePanel-title {
								padding-left: 16px;
								font-size: $text-md-plus;
								font-weight: $font-weight-semibold;
								color: $text-dark-10;

								.rtl &,
								[dir='rtl'] & {
									padding-left: 0;
									padding-right: 16px;
								}
							}
						}

						&:hover,
						.active,
						&:focus {
							background: $gray-shade-100;
						}
					}
				}
			}
		}
	}
	&.set-center {
		.modal-dialog {
			margin: auto !important;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			@include align-items(center);
		}
		.modal-content {
			@include border-radius(16px);
			.modal-footer {
				padding: 16px 16px;
			}
		}
	}
}

// CONFIRM MODAL
.app-c-confirm-modal {
	.modal-content {
		max-width: 650px;
	}
	&.app-c-upload-modal {
		overflow-y: hidden !important;

		@media screen and (min-width: 768px) {
		}

		.modal-content {
			padding-top: 0px;
			width: 59vw;
			height: 70vh;
			text-align: center;

			// overflow: hidden;
			@media screen and (max-width: 767px) {
				//width: calc(100% - 8px);
				width: 96vw;
				min-width: inherit;
			}

			.modal-title {
				font-size: $text-xl;
				font-weight: $font-weight-semibold;
				line-height: 17px;

				@include flexbox();
				@include align-items(center);

				.icon-leftArrow {
					margin-right: $base;
				}
			}

			.modal-body {
				//min-height: 358px;
				@include flexbox();
				flex-direction: column;
				padding: 0px 24px;
				@include align-items(center);
				@include justify-content(center);

				@media screen and (max-width: 991px) {
					//min-height: 260px;
				}

				.app-c-profile-text {
					font-size: $text-xxl;
					line-height: 29px;
					font-weight: $font-weight-semibold;
					margin-top: 2%;
					margin-bottom: 2%;

					@media screen and (max-width: 991px) {
						//margin-top: 30px;
						font-size: $text-xl;
					}
				}

				.app-l-profile {
					background-color: $app-no-pic;
					background: url(../../images/user.png) center center no-repeat $app-no-pic;
					width: 130px;
					height: 130px;
					max-width: 130px;
					flex: 0 0 130px;
					@include border-radius(50%);
					@include flexbox();
					@include align-items(center);
					@include justify-content(center);

					img.user {
						width: 8rem;
						height: 8rem;
						@include border-radius(50%);
						object-fit: cover;
					}
				}

				.app-c-profile-text-bottom {
					font-size: $text-md;
					line-height: 29px;
					font-weight: $font-weight-semibold;
					margin-top: 2%;

					//margin-bottom: 67px;
					@media screen and (max-width: 991px) {
						// margin-top: 20px;
						// margin-bottom: 20px;
					}
				}

				&.app-l-camera {
					background-color: $black01;

					@media screen and (max-width: 767px) {
						//width: 89vw !important;
						//height: 100% !important;
					}

					video {
						//width: 200px;
						height: 100%;

						@media screen and (max-width: 767px) {
						}
					}

					img {
						max-height: 358px;
					}
				}
			}

			.modal-footer {
				padding-bottom: 0px;
				border-top: 1px solid $app-card-border;
				@include justify-content(flex-end);
				padding: 2.3% 24px;

				@media screen and (max-width: 991px) {
				}

				.app-c-btn {
					margin: 0px 0px 0px 8px;
					min-width: 100px;
				}

				&.app-l-camera-footer {
					@include justify-content(center);
					padding: 5px 24px;

					.app-c-camera-footer {
						background: $app-brand-primary;
						width: 40px;
						height: 40px;
						@include border-radius(50%);
						@include flexbox();
						@include align-items(center);
						@include justify-content(center);
						color: $white;
						transition: all 0.5s;
						cursor: pointer;

						&:hover {
							background: $text-color-light;
						}
					}
				}

				&.app-l-open-camera {
					.app-c-open-contain {
						@include flexbox();
						@include align-items(center);
						@include justify-content(center);
						@include flex-direction(column);

						.app-c-addcamera-text {
							color: $black01;
							font-size: $text-md;
							font-weight: $font-weight-normal;
							margin-top: 4px;
						}
					}
				}
			}
		}

		&.app-c-replace-modal {
			.app-c-camera-footer {
				margin: 0px auto;
			}

			.app-c-camera-delete {
				@include flexbox();
				@include flex-direction(column);
			}

			.modal-body {
				@include flexbox();
				@include align-items(center);
				@include justify-content(center);

				.app-l-profile {
					img {
						width: 250px;
						height: 250px;
						@include border-radius(50%);
						object-fit: cover;
					}
				}
			}

			.app-l-camera-footer {
				position: relative;
				padding: 20px 24px !important;

				.app-l-camera-footer-container {
					position: absolute;
					left: 50%;
					transform: translateX(-50%);

					.app-c-addcamera-text {
						color: $black01;
						font-size: $text-md;
						font-weight: $font-weight-normal;
						margin-top: 6px;
					}
				}

				.app-c-camera-delete {
					margin-left: auto;
					position: relative;
					top: $text-sm-plus;
					transition: all 0.5s;
					cursor: pointer;

					span.app-c-deleted-text {
						color: $black01;
						font-size: $text-md;
						font-weight: $font-weight-normal;
						margin-top: 6px;
					}

					&:hover {
						color: $app-brand-primary;

						.app-c-deleted-text {
							color: $app-brand-primary;
						}
					}
				}
			}
		}
	}

	.modal-header {
		padding: 0 !important;
		border-bottom: 1px solid #e6e5e5 !important;
		font-size: 16px !important;
		padding: 16px 24px !important;
		line-height: 17px !important;
		transition: all 0.5s;

		button.camera {
			border: none;
			background: none;
			@include noShadow();
			@include flexbox();

			.icon-leftArrow {
				margin-right: 12px;
			}

			&:hover {
				color: $app-brand-primary;
			}
		}
	}

	.modal-content {
		padding-top: 30px;
		min-width: 462px;
		text-align: center;

		@media (max-width: 767px) {
			min-width: 100%;
		}

		.modal-body {
			&.app-c-modal-profile-wrapper {
				justify-content: flex-start;
			}
			h3,
			h4 {
				font-size: 20px;
				margin-bottom: 15px;
				// max-width: 400px;

				@media (max-width: 767px) {
					font-size: 16px;
				}
			}
			h3 {
				font-size: 24px;
				@media (max-width: 767px) {
					font-size: 18px;
				}
			}

			p {
				font-size: $text-lg;
				line-height: 20px;

				@media (max-width: 767px) {
					font-size: 14px;
				}
			}
		}

		.modal-footer {
			border-top: none;
			text-align: center;
			@include justify-content(center);
			padding-bottom: 40px;

			.app-c-btn {
				padding: 5px 12px;
				line-height: 29px;
				font-size: 14px;
				display: inline-flex;
				align-items: center;
				margin: 3px;
			}
			.stretchContent {
				display: flex;
				justify-content: center;
				width: 70%;
				> * {
					flex: 1;
				}
			}
		}
	}

	.app-c-confirm-modal__close {
		position: absolute;
		top: -35px;
		right: 50%;
		@include transform(translate(50%, -50%));
		background: $white;
		color: $text-muted;
		@include border-radius(50%);
		width: 36px;
		height: 36px;
		@include flexbox();
		@include justify-content($value: center);
		@include align-items($value: center);
	}

	&.editor-modal {
		.modal-content {
			max-width: 300px;
			.modal-header {
				@include justify-content(center);
				border-bottom: 0 !important;
				font-weight: $font-weight-semibold;
				font-size: 19px !important;
			}
		}
	}
	&.alert {
		.modal-content {
			max-width: unset !important;
		}
	}
}

// File Upload Modald
.app-c-modal-flex-head {
	@include flexbox();
	@include justify-content(space-between);
	@include flex-direction(row);
	align-items: center;
}

.app-c-modal-flex-head__back-btn {
	border: none;
	background: none;
	font-size: $text-xl;
	font-weight: $font-weight-semibold;
	line-height: 17px;

	&:hover {
		color: $app-brand-primary;
	}

	i {
		color: rgba(97, 99, 111, 1);
		font-size: 14px;
		margin-right: 13px;
	}
}

// VERSION HISTORY
.version-history-modal {
	.modal-dialog {
		width: 400px;

		@media (max-width: 767px) {
			max-width: 100%;
			width: 100%;
		}
	}
	.modal-header {
		padding-right: 10px;
		button {
			margin-left: auto;
			background: transparent;
		}
	}
	.modal-body {
		padding: 0 !important;
	}
	.modal-footer {
		justify-content: center;
		.dashboard-btn {
			padding: 10px 20px;
			+ .dashboard-btn {
				margin-left: 15px;
			}
		}
	}
	.course-complete {
		text-align: center;
		.cc--table {
			text-align: left;
			width: auto;
			max-width: 200px;
			margin: auto;
			td {
				max-width: fit-content;
				min-width: 0;
				&:first-child {
					width: 36px;
					text-align: center;
					svg,
					img {
						width: 20px;
						height: 20px;
						position: relative;
						top: -1px;
					}
				}
			}
			td {
				vertical-align: middle;
				padding: 0 5px 10px;
			}
		}
		.cc--final-result {
			font-size: 18px;
			margin: 20px 10px 5px;
			font-weight: $font-weight-bold;
		}
		.cc-final-comments {
			margin: 0 auto;
			padding: 0 10px;
		}
		&--label {
			margin: 20px 0;
		}
		&--image {
			margin: 0 0 30px;
			&.incomplete {
				opacity: 0.1;
			}
			img {
				width: 100px;
			}
		}
	}
}

.app-c-history {
	.app-c-history__set {
		padding-top: 16px;

		.rtl &,
		[dir='rtl'] & {
			text-align: right;
		}
	}

	.app-c-history__date {
		text-transform: uppercase;
		font-size: $text-md;
		color: rgba(97, 99, 111, 1);
		line-height: 16px;
		padding: 0px 0px 0px 16px;
		margin-bottom: 9px;

		.rtl &,
		[dir='rtl'] & {
			padding-left: 0;
			padding-right: 16px;
		}
	}

	.app-c-history__tree {
		position: relative;
		display: block;

		&::before {
			position: absolute;
			height: 82%;
			width: 1px;
			background: rgba(190, 193, 210, 1);
			left: 32px;
			top: 0;
			bottom: 0;
			margin: auto;
			display: block;
			content: '';
			z-index: 1;

			.rtl &,
			[dir='rtl'] & {
				left: inherit;
				right: 32px;
			}
		}
	}

	.app-c-history__tags {
		height: 64px;
		position: relative;
		padding-left: 68px;
		@include flexbox();
		@include flex-direction(column);
		@include justify-content(center);
		@include align-items(flex-start);

		.rtl &,
		[dir='rtl'] & {
			padding-right: 68px;
			padding-left: 0;
		}

		&:hover,
		&.active {
			background: rgba(244, 245, 249, 1);

			.app-c-rounded-checkbox-wrapper {
				right: 24px;
				opacity: 1;

				.rtl &,
				[dir='rtl'] & {
					right: inherit;
					left: 24px;
				}
			}
		}
	}

	.app-c-history__version {
		display: inline-block;
		line-height: 13.04px;
		padding: 2px 8px 1px 18px;
		font-size: $text-sm-plus;
		border-radius: 2px;
		position: relative;
		font-weight: $font-weight-normal;
		margin-left: 16px;

		.rtl &,
		[dir='rtl'] & {
			margin-right: 16px;
			margin-left: 0;
			padding: 2px 18px 1px 8px;
		}

		&.current {
			background: rgba(6, 194, 112, 0.12);
			color: rgba(6, 194, 112, 1);

			&::after {
				content: '\e902';
				font-family: 'qualify-tms';
				position: absolute;
				left: 8px;
				top: 1px;
				font-size: 7px;

				.rtl &,
				[dir='rtl'] & {
					right: 8px;
					left: inherit;
				}
			}
		}

		&::before {
			content: ' ';
			position: absolute;
			left: -10px;
			top: 5px;
			display: block;
			width: 4px;
			height: 4px;
			background: rgba(208, 212, 241, 1);
			@include border-radius(50px);

			.rtl &,
			[dir='rtl'] & {
				right: -10px;
				left: inherit;
			}
		}
	}

	.app-c-history__avatar {
		position: absolute;
		z-index: 2;
		margin: auto;
		left: 16px;
		top: 0;
		bottom: 0;
		width: 34px;
		height: 34px;
		background: $app-brand-primary;
		@include border-radius(50%);
		@include flexbox();
		@include flex-direction(column);
		@include justify-content(center);
		@include align-items(center);

		.rtl &,
		[dir='rtl'] & {
			right: 16px;
			left: inherit;
		}

		img {
			width: 100%;
			height: 100%;
			@include border-radius(50%);
		}

		.app-c-history__avatar-name {
			color: #fff;
			line-height: 16px;
			font-size: 12px;
			font-weight: $font-weight-bold;
		}
	}

	.app-c-history__tags-info {
		padding-bottom: 8px;
	}

	.app-c-history__time {
		font-size: $text-md;
		color: rgba(97, 99, 111, 1);
		line-height: 16px;
	}

	.app-c-history__username {
		font-size: $text-lg;
		color: rgba(30, 25, 25, 1);
		font-weight: $font-weight-semibold;
		line-height: 16px;
		display: block;
		max-width: 170px;
	}
}

.app-c-rounded-checkbox-wrapper {
	position: absolute;
	right: 10px;
	top: 0px;
	bottom: 0;
	margin: auto;
	display: block;
	width: 22px;
	height: 22px;
	@include transition(all ease 0.4s);
	opacity: 0;

	.rtl &,
	[dir='rtl'] & {
		left: 10px;
		right: inherit;
	}

	input {
		width: 22px;
		height: 22px;
		position: relative;
		opacity: 0;
		cursor: pointer;

		/* When the checkbox is checked, add a blue background */
		&:checked ~ .app-c-rounded-checkbox__shape {
			border-color: solid $app-brand-primary;

			/* Show the checkmark when checked */
			&::before {
				display: block;
				background: $app-brand-primary;
				content: '\e902';
				color: #fff;
				font-family: 'qualify-tms';
				font-size: 9px;
				text-align: center;
				line-height: 22px;
				@include border-radius(50%);
				width: 100%;
				height: 100%;
			}
		}
	}

	.app-c-rounded-checkbox__shape {
		position: absolute;
		left: 0;
		top: 0;
		width: 22px;
		height: 22px;
		pointer-events: none;
		@include border-radius(50%);
		background: rgba(233, 235, 244, 1);
		border: 1px solid rgba(190, 193, 210, 1);

		.rtl &,
		[dir='rtl'] & {
			left: inherit;
			right: 0;
		}
	}
}

.btn-group--modal {
	display: flex;
	flex-wrap: wrap;

	button {
		margin: 5px;
	}
}

.limit-bold {
	font-weight: $font-weight-semibold;
}

.app-l-group-details-modal {
	.modal-dialog {
		width: 960px !important;
		max-width: 960px !important;

		@media screen and (max-width: 980px) {
			max-width: 90% !important;
		}

		@media screen and (max-width: 768px) {
			.table-width-reset {
				& > div {
					overflow: auto;
				}

				table {
					width: 700px;
				}
			}
		}
	}

	.app-l-table-head {
		min-width: 100%;
	}
}

@media screen and (max-width: 768px) {
	.ct--bottom {
		display: none;
	}
}

.app-c-select-tasks {
	.modal-title {
		font-weight: 500;
	}
	small {
		color: $spunPearl;
		font-size: 11px;
	}
	.app-l-checklist-items {
		width: 100%;
		.list-group-item {
			padding-right: 60px !important;
		}
		@media screen and (max-width: 640px) {
			width: 100%;
			.list-group-item {
				padding-right: 0px !important;
			}
		}
	}
	h2 {
		font-size: 16px !important;
		color: $black;
		margin: 20px 0 8px 0;
		padding: 0;
	}
	.modal-dialog {
		position: fixed;
		right: 0;
		padding: 0;
		// max-height: 100vh;
		max-height: calc(100vh - 80px);
		bottom: 0;
		min-width: 526px !important;
		margin-bottom: auto;
		max-width: 526px !important;

		@media screen and (max-width: 767px) {
			min-width: 100% !important;
			max-height: calc(100vh - 80px) !important;
		}

		.rtl &,
		[dir='rtl'] & {
			right: auto;
			left: 0;
		}

		.modal-content {
			height: 100%;
			// border-radius: 0 !important;
			// -webkit-border-radius: 0 !important;
			// -moz-border-radius: 0 !important;
			// -ms-border-radius: 0 !important;
			// -o-border-radius: 0 !important;
			.app-l-search {
				border-radius: 0px;
				-webkit-border-radius: 0px;
				-moz-border-radius: 0px;
				-ms-border-radius: 0px;
				-o-border-radius: 0px;
				background: $aquaHaze;
				border-color: $aquaHaze;
				box-shadow: none;
				outline: none;
				font-size: 16px !important;
				&::-webkit-input-placeholder {
					/* Chrome/Opera/Safari */
					color: $spunPearl;
				}
				& ::-moz-placeholder {
					/* Firefox 19+ */
					color: $spunPearl;
				}
				&:-ms-input-placeholder {
					/* IE 10+ */
					color: $spunPearl;
				}
				&:-moz-placeholder {
					/* Firefox 18- */
					color: $spunPearl;
				}
			}
			.modal-header {
				border: none !important;
				padding: 16px 24px 6px !important;
				flex-wrap: wrap;
				@media screen and (max-width: 640px) {
					display: flex !important;
				}
				.app-l-search_block {
					width: 100%;
					padding: 16px 0 8px 0;
				}
				.modal-title {
					font-size: 16px;
					font-weight: normal;
				}
				.app-l-button-block {
					display: flex;
					button {
						border-radius: 0px;
						-webkit-border-radius: 0px;
						-moz-border-radius: 0px;
						-ms-border-radius: 0px;
						-o-border-radius: 0px;
						font-size: 16px;
						border-color: $black;
					}
					.btn-link {
						color: $black;
						border: none;
					}
					.btn-primary {
						background: $black;
						color: $white;
						&:hover {
							background: $white;
							color: $black;
							border-color: $black;
						}
					}
				}
			}
			// .app-c-checkbox-icon {
			// 	border-radius: 50px !important;
			// 	-webkit-border-radius: 50px !important;
			// 	-moz-border-radius: 50px !important;
			// 	-ms-border-radius: 50px !important;
			// 	-o-border-radius: 50px !important;
			// }
			.list-group {
				.list-group-item {
					border-left: none !important;
					border-right: none !important;
					padding: 16px 0;
					background: transparent;
					font-size: 16px;
					border-color: $aquaHaze !important;
					&:first-child {
						border-top: none !important;
					}
					.app-l-select-tasks__name {
						margin: 2px 0 0 0;
					}
					// .app-c-checkbox
					// 	> label
					// 	> input[type='checkbox']:checked
					// 	+ .app-c-checkbox-icon {
					// 	background: $white;
					// 	border-color: $black !important;
					// }
					// .app-c-checkbox-icon {
					// 	border-color: $black !important;
					// }
					// .app-c-checkbox-icon::before {
					// 	color: $black !important;
					// }
				}
			}
		}
	}
}

.app-c-new-task {
	.modal-dialog {
		position: fixed;
		right: 0;
		padding: 0;
		max-height: calc(100vh - 80px);
		bottom: 0;
		min-width: 526px !important;
		margin-bottom: auto;
		// max-width: 316px !important;
		min-width: 526px !important;

		@media screen and (max-width: 767px) {
			min-width: 100% !important;
			max-height: calc(100vh - 80px) !important;
		}

		.rtl &,
		[dir='rtl'] & {
			right: auto;
			left: 0;
		}

		.modal-content {
			height: 100%;
			.app-l-search {
				border-radius: 0px;
				-webkit-border-radius: 0px;
				-moz-border-radius: 0px;
				-ms-border-radius: 0px;
				-o-border-radius: 0px;
				background: $aquaHaze;
				border-color: $aquaHaze;
				box-shadow: none;
				outline: none;
				font-size: 16px !important;
				&::-webkit-input-placeholder {
					/* Chrome/Opera/Safari */
					color: $spunPearl;
				}
				& ::-moz-placeholder {
					/* Firefox 19+ */
					color: $spunPearl;
				}
				&:-ms-input-placeholder {
					/* IE 10+ */
					color: $spunPearl;
				}
				&:-moz-placeholder {
					/* Firefox 18- */
					color: $spunPearl;
				}
			}
		}
	}
	.modal-body {
		padding: 19px 24px 14px !important;
		button {
			width: 100%;
			background: $white;
			border: 1px dashed $app-brand-primary;
			box-sizing: border-box;
			border-radius: 4px;
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
			color: $darkGrey;
			padding: 15px 0 10px;
			+ button {
				margin-top: 17px;
			}

			span {
				font-weight: 500;
				font-size: 14px;
				line-height: 24px;
				color: $darkGrey;
				display: flex;
				flex-direction: column;
				p {
					margin: 0 !important;
					padding: 0 !important;
					line-height: 24px !important;
				}
				i {
					width: 42px;
					height: 42px;
					margin: auto;
					@include flexbox();
					@include align-items(center);
					@include justify-content(center);
					background: $app-brand-primary;
					border-radius: 50px;
					color: $white;
					box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
					&::before {
						font-size: 18px;
					}
				}
			}
			&:hover {
				background: $app-brand-primary;
				span {
					color: $white;
					i {
						background: $white;
						color: $app-brand-primary;
					}
				}
			}
		}
	}
}
.close-btn-view {
	.modal-dialog {
		.modal-content {
			@media screen and (max-width: 976px) {
				margin-top: 18%;
				height: 94%;
			}
		}
	}
}
.modal-dialog-centered-type-1 {
	&.-unsplash-modal {
		.modal-dialog .modal-content .modal-body {
			padding: 0;
			.app-c-common-tabs__nav {
				box-shadow: none;
			}
			&.app-c-tab .nav .nav-item .nav-link {
				padding: 0 0px 12px 0px;
				margin-bottom: 10px;
			}
			.nav-item {
				margin-right: 10px;
			}
			.tab-content {
				padding: 6px 24px;
			}
			.button-group {
				padding: 0 24px;
				@include flexbox;
				@include justify-content(flex-end);
				padding-bottom: 20px;
				.app-c-btn {
					padding: 10px 24px;
				}
			}
		}
	}
	&.modal.show .modal-dialog {
		min-width: 506px;
		@media (max-width: 1200px) {
			width: 96%;
			min-width: auto;
		}
	}
	&.media-preview-modal-wrapper {
		.modal-dialog {
			max-width: fit-content;
			min-width: auto;
			width: 100%;
			border-radius: 10px;
		}
		.modal-content {
			border-radius: 10px;
			background: transparent;
		}
		.media-preview-modal {
			padding: 0;
			border-radius: 10px;
			line-height: 0;
			overflow: visible;
			.content-preview {
				border-radius: 10px;
				max-width: 100vw;
				max-height: 90vh;
				object-fit: fill;
				width: 100%;
			}
			.app-c-btn--icon-only {
				position: absolute;
				top: 20px;
				right: 20px;
				color: $dune;
				background: rgba(255, 255, 255, 0.5);
				width: 30px;
				height: 30px;
				border-radius: 0;
				&:hover {
					background: rgba(255, 255, 255, 1);
				}
			}
		}
	}
}
.aq-set {
	.aq-set-header {
		@include flexbox;
		@include align-items(center);
		@include justify-content(space-between);
		margin-bottom: 24px;
	}
	.aq-set-title {
		font-weight: $font-weight-semibold;
		font-size: $text-lg;
	}
	.aq-set-question {
		position: relative;
		margin-bottom: 10px;
		.location-icon {
			position: absolute;
			left: 10px;
			top: 9px;
			+ .aq-set-qn {
				padding-left: 40px;
			}
			path {
				fill: $app-brand-primary;
			}
		}
		.enter-prompt {
			margin-top: 8px;
			color: $spunPearl;
			font-size: $text-sm-plus;
		}
	}
	.add-qn {
		position: absolute;
		right: 10px;
		top: 1px;
		background-color: transparent;
		color: $dune;
		@media (max-width: $screen-md) {
			top: 3px;
		}
		.rtl &,
		[dir='rtl'] & {
			right: auto;
			left: 10px;
		}
		&:disabled {
			cursor: not-allowed;
		}
	}
	.aq-set-qn {
		padding-right: 120px;
		.rtl &,
		[dir='rtl'] & {
			padding-right: 0;
			padding-left: 120px;
		}
	}
}
.question-set-modal {
	.app-l-upload-editor-blk.media-only .app-l-upload-editor-blk__left .app-c-upload-items_blk {
		max-width: 100%;
		height: 325px;
	}
	.app-l-upload-editor-blk {
		padding-bottom: 50px;
		padding-top: 10px;
	}
	@media (max-width: $screen-md) {
		&.app-c-modal-sidePanel.common-modal.modal-60 .modal-dialog {
			width: 100%;
		}
	}
}
.document-viewer-wrapper {
	.app-c-upload-items_blk.file-type.not-empty.app-c-video-only {
		video {
			height: calc(100vh - 270px);
		}
	}
	&.doc-type-1 {
		&.modal-dialog-centered-type-1 .modal-dialog {
			max-width: 960px;
			width: 100%;
		}
	}
	.document-viewer {
		min-width: 100%;
		iframe {
			width: 100%;
			max-width: 960px;
			max-height: 70vh;
		}
		.app-c-pagination {
			max-width: 960px;
			width: 100%;
		}
	}

	.link-tool__image {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		margin: 0 0 0 30px;
		width: 65px;
		height: 65px;
		border-radius: 3px;
		float: right;
	}
}
.skills__table-holder,
.all-course-modal-holder {
	.modal-dialog {
		align-items: flex-end;
		@media screen and (max-width: 640px) {
			padding: 0 10px;
		}
		.modal-content {
			height: auto;
			min-height: 50%;
			.skills-table {
				@media screen and (max-width: 640px) {
					width: 110vw;
				}
			}
			@media screen and (max-width: 640px) {
				.px-4 {
					padding-left: 16px !important;
					padding-right: 16px !important;
				}
			}
		}
	}
}
.all-course-modal-holder {
	.modal-header {
		display: flex !important;
		.modal-title + div {
			padding-top: 0 !important;
		}
	}
}
