.dateRangePickerField {
	position: relative;
	input {
		border: 1px solid $form-control-border;
		@include border-radius(0.25rem);
		padding: 0.55rem 2rem 0.55rem 0.75rem;
		color: #000000;
		color: $input-text-dark;
		font-size: $text-lg;
		width: 100%;
		&:disabled,
		&[readonly] {
			color: #000000;
			color: $input-text-dark;
		}
		&:disabled {
			background: $form-disabled;
		}
		&::-webkit-input-placeholder {
			/* Edge */
			color: #000000;
			color: $input-text-dark;
		}

		&:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			color: #000000;
			color: $input-text-dark;
		}

		&::placeholder {
			color: #000000;
			color: $input-text-dark;
		}
	}
	.date-icon {
		font-size: 18px;
		width: 20px;
		height: 20px;
		position: absolute;
		top: 0;
		right: 0.5rem;
		bottom: 0;
		margin: auto;
		i {
			font-size: 16px;
		}
	}
	.calendar-box {
		position: absolute;
		z-index: 1;
		right: 0;
		top: 44px;
	}
}
