.dx-popup-wrapper {
	.dx-colorbox-overlay {
		border-color: $text-light-normal;

		@include boxShadow(0px 3px 6px rgba(63, 63, 63, 0.1));
		@include border-radius(4px);

		.dx-popup-content {
			padding: 10px;
		}
	}
}
.app-c-colorbox {
	&.dx-colorbox {
		width: 20px;
		height: 20px;
		border: none;
		overflow: hidden;

		.dx-colorbox-input-container {
			.dx-texteditor-container {
				position: relative;

				.dx-texteditor-input-container {
					.dx-colorbox-input {
						display: none;
					}
					.dx-colorbox-color-result-preview {
						position: relative;
						top: 0;
						left: 0;
						width: 20px;
						height: 20px;
						margin: 0;
						border: 1px solid var(--tooltip1);

						@include border-radius(50%);
						@include flexbox();
						@include align-items(center);
						@include justify-content(center);

						&:before {
							content: '\e947';
							font-family: $font-qtms;
							color: $white;
							font-size: 10px;
						}
					}
				}

				.dx-texteditor-buttons-container {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					opacity: 0;
					z-index: 11;
				}
			}
		}
	}
}
