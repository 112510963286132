@import '../core/mixins/animation';
@import '../core/mixins/keyframes';

.app-l-loader-img {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	margin: auto;
	max-width: 100%;
	min-width: 100%;
	max-height: 100%;
	z-index: 1001;
	background-color: transparent;

	@include flexbox();
	@include align-items(center);
	@include justify-content(center);
	@include flex-shrink(0);

	.app-l-loader-box {
		position: relative;
		z-index: 0;
		height: 75px;
		width: 75px;

		@include flexbox();
		@include align-items(center);
		@include justify-content(center);
		@include flex-shrink(0);

		svg {
			height: 75px;
			width: 75px;

			// @include animation(fadeIn 2.5s infinite alternate);

			.app-l-main-logo {
				width: 100%;
				height: 100%;
				stroke-dasharray: 95;
				stroke-dashoffset: 0;
				fill: transparent;
				stroke: $app-brand-secondary;
				stroke-width: 1px;
				stroke-miterlimit: 0;
				stroke-linejoin: round;
				stroke-linecap: round;

				@include animation(
					logo-outline 1.5s alternate infinite,
					logo-fill 2s alternate infinite
				);
				@include transition(fill 0.2s ease);

				&.api-load {
					fill: $app-brand-secondary !important;

					@include animation(
						logo-outline 2s linear infinite,
						logo-fill 2s alternate infinite
					);
					@include transition(fill 2s ease);
				}
			}
		}

		.app-l-logo-loader {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 80%;
			height: 80%;
			margin: auto;
			z-index: 1;
		}

		.app-l-clock {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;

			circle {
				stroke: $app-brand-primary;
			}
			line {
				stroke: $app-brand-primary;
			}
		}
	}
}

$base: 75;
$inc: 20;
$border: 2px;
$duration: 3000ms;
$stagger: 200ms;
$easing: cubic-bezier(0.68, -0.75, 0.265, 1.75);

.app-l-splash-loader-ring {
	@include border-radius(50%);
	@include animation(spin $duration $easing infinite forwards);

	border: 1px solid transparent;
	border-top-color: $app-brand-primary;
	bottom: 0;
	left: 0;
	margin: auto;
	position: absolute;
	right: 0;
	top: 0;

	@for $i from 1 through 100 {
		&:nth-child(#{ $i }) {
			animation-delay: ($i) * $stagger;
			height: $base + ($i - 1px) * $inc;
			width: $base + ($i - 1px) * $inc;
		}
	}
}

.app-l-support-wrap {
	max-width: 100%;
	min-height: 200px;
	height: auto;

	@include flexbox();
	@include flex-grow(1);
	@include flex-shrink(0);

	@media screen and (max-width: 991px) {
		@include flex-direction(column);
	}

	.app-l-support-fields {
		width: 100%;
		min-height: 200px;
		height: auto;

		@include flexbox();
		@include flex-direction(column);

		.app-l-support-field-holder {
			max-width: 100%;
			margin: -8px;

			@include flexbox();
			@include flex-wrap(wrap);
			@include align-content(flex-start);

			.form-group {
				margin: 8px;
			}
		}
	}

	.app-l-attachments-holder {
		width: 458px;
		max-width: 458px;
		padding-left: 32px;
		border-left: solid 1px $generic-border-color;
		min-height: 200px;
		height: auto;

		@include flexbox();
		@include flex-shrink(0);
		@include flex-direction(column);

		@media screen and (max-width: 991px) {
			border-left: none;
			padding-left: 0;
			padding-top: 16px;
			width: 100%;
			max-width: 100%;
		}

		.app-l-attachment-header {
			width: 100%;

			@include flexbox();
			@include align-items(flex-end);
			@include justify-content(flex-start);

			.app-l-heading--h4 {
				margin: 0;
				margin-right: 8px;
				line-height: 20px;
			}

			.app-l-info-muted {
				color: $text-dark-normal;
				font-size: $text-sm-plus;
				line-height: 16px;
				font-weight: 400;
				margin: 0;
			}

			+ .app-l-attachment-listing {
				margin-top: 16px;
			}
		}

		.app-l-attachment-listing {
			width: 100%;
			min-width: 0;

			@include flexbox();
			@include flex-direction(column);

			.app-c-file-upload {
				max-width: 100%;
				min-width: 0;

				@include flex-wrap(wrap);

				.app-l-file-upload__btn {
					margin: 0px 8px 8px 0px;
				}

				.app-l-file__list {
					max-width: 100%;
					min-width: 0;
					padding-left: 0px;
					width: auto;

					.app-c-chips {
						max-width: 100%;
						min-width: 0;
						cursor: pointer;

						.app-c-chips--label {
							max-width: 100%;
							min-width: 0;
							display: block;
							cursor: pointer;

							@include truncate();

							span {
								max-width: 100%;
								min-width: 0;
								display: block;
								cursor: pointer;

								@include truncate();
							}
						}
					}
				}
			}
		}
	}
}

.app-l-form-lable-muted {
	margin: 0;
	color: $text-dark-normal;
	font-size: $text-sm-plus;
	line-height: 16px;
	font-weight: 400;
}

.block-ui-wrapper {
	background: rgba($white, 0.85) !important;
	backdrop-filter: blur(1.5px);

	.block-ui-spinner {
		top: calc(50%) !important;

		.loader {
			position: relative;
			width: 52px;
			height: 52px;
			border: none !important;
			border-color: transparent !important;

			@include noAnimation();
			@include noTransform();

			&:before {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				margin: auto;
				display: none;

				width: 32px;
				height: 32px;

				@include border-radius(50%);
			}

			&:after {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				margin: auto;

				width: 47px;
				height: 47px;
				border-left: 1.1em solid $app-brand-primary !important;
				border-top: 1.1em solid $app-brand-secondary !important;
				border-right: 1.1em solid $app-brand-secondary !important;
				border-bottom: 1.1em solid $app-brand-secondary !important;

				@include animation(clockRotate 1.1s infinite linear);
			}
		}
	}
}

@include keyframes(anticlockRotate) {
	0% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
	100% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
}

@include keyframes(clockRotate) {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
