.app-l-people-stack {
	padding: 4px 0px;
	&.btm-border {
		border-bottom: 1px solid $generic-border-color;
	}
	label {
		color: $text-color;
	}
}
.app-l-people-stack__item {
	margin: 12px;
	text-align: center;
	display: inline-block;
	position: relative;
	.app-l-people-stack__name {
		font-size: $text-sm-plus;
		display: block;
		margin: auto;
		margin-top: 5px;
		line-height: 12px;
		@include truncate();
		max-width: 40px;
	}
}
.app-l-people-stack__avatar {
	min-width: 52px;
	width: 52px;
	min-height: 52px;
	height: 52px;
	@include border-radius(50%);
	&.sm {
		min-width: 34px;
		width: 34px;
		min-height: 34px;
		height: 34px;
	}
	img {
		@include border-radius(50%);
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.app-l-people-stack__close {
	background: $app-brand-primary;
	@include border-radius(50%);
	width: 24px;
	height: 24px;
	text-align: center;
	line-height: 21px;
	color: $white;
	font-size: 7.2px;
	display: inline-block;
	position: absolute;
	top: -6px;
	right: -6px;
	border: 1.5px solid $white;
	font-weight: normal;
}
.app-l-people-check-list {
	@include border-radius(8px);
	max-height: 300px;
	overflow: auto;
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	padding: 0px 0px;
	li {
		padding: 8px 24px;
		position: relative;
		border-top: 1px solid $generic-border-color;
		@include flexbox();
		@include justify-content(flex-start);
		@include align-items(center);

		&:first-child {
			@include border-radius(8px 8px 0px 0px);
			border-top: none;
		}
		@media screen and (max-width: 767px) {
			padding: 8px 15px;
		}

		&.selected,
		&:hover {
			background: rgba(244, 245, 249, 1);
			.app-c-btn--icon-only {
				opacity: 1;
				transform: translate(0px, 0px);
			}
		}
		.app-c-btn--icon-only {
			position: absolute;
			margin: auto;
			top: 0;
			bottom: 0;
			right: 24.5px;
			@include transition(all ease 0.2s);
			opacity: 0;
			transform: translate(5px, 0px);
			font-size: 18px;
		}
	}
	.app-l-people__tag {
		margin-left: 0;
		@include flexbox();
		@include justify-content(flex-start);
		@include align-items(center);
		width: 100%;
		&.inactive {
			img {
				opacity: 0.5;
			}
			.app-l-people__texting {
				.app-l-people__name {
					color: $text-disabled;
				}
				.app-l-people__info {
					span {
						color: $text-disabled;
					}
				}
				.app-c-tiny-status {
					border: none;
					background: none;
					padding: 0;
				}
			}
		}
		.app-l-people__texting {
			padding-left: 14px;
			width: 100%;
			.rtl &,
			[dir='rtl'] & {
				padding-left: 0;
				padding-right: 14px;
			}
			.app-l-people__name {
				font-weight: $font-weight-semibold;
				line-height: 16px;
				display: block;
				font-size: $text-md;
				.app-c-tiny-status {
					margin-left: 6px;
					position: relative;
					//top: -2px;
				}
			}
			.app-l-people__info {
				font-size: $text-sm-plus;

				margin-top: 3px;

				small {
					margin-right: 7px;
					font-size: 11px;
				}
				span {
					color: $text-light;
					position: relative;
					+ * {
						margin-left: 16px;
						&::before {
							position: absolute;
							left: -$text-sm-plus;
							top: 5px;
							width: 4px;
							height: 4px;
							border-radius: 50%;
							content: '';
							background: #d0d4f1;
							display: block;
						}
					}
				}
			}
		}
	}
}
.normal-text {
	color: $text-light;
	font-size: $text-lg;
	&.dark {
		color: $text-color;
	}
}
.text-xl {
	font-size: $text-xl;
	@media screen and (max-width: 767px) {
		font-size: $text-lg;
	}
}

.app-c-search__filter {
	@include boxShadow(0px 9px 21px 0px rgba(0, 0, 0, 0.1316));
	@include border-radius(6px);
	position: absolute;
	top: 42px;
	left: 0;
	width: 100%;
	z-index: 2;
	background: $white;

	.app-c-search__filter-head {
		padding: 32px 24px 24px;
	}
	.app-c-search__filter-footer {
		padding: 24px 24px;
		border-top: 1px solid $generic-border-color;
		text-align: right;
	}
}

.delete-selected {
	all: reset;
	position: relative;
	display: inline-block;
	margin-left: 21px;
	@include border-radius(6px);
	height: 30px;
	width: 30px;
	border: none;
	vertical-align: middle;
	font-size: 18px;
	background: $app-brand-primary-disable;
	color: $text-light;
	line-height: 30px;
	top: -1px;
	&:hover {
		background: rgba(244, 245, 249, 1);
	}
	&::before {
		position: absolute;
		left: -$text-sm-plus;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 1px;
		height: 24px;
		background: $generic-border-color;
		display: inline-block;
		content: '';
	}
}
.wrapper-8-24 {
	padding: 8px 24px;
}
.app-c-aq-set {
	padding-bottom: 50px;
	.app-l-author-card {
		padding: 20px 0 0 !important;
	}
	.app-c-upload-media-blk {
		padding: 0 !important;
		.app-c-upload-media-wrap {
			max-width: 100% !important;
			img {
				object-fit: contain;
				margin-bottom: 20px !important;
			}
			textarea {
				margin-top: 0 !important;
			}
		}
	}
	.codex-editor__redactor {
		padding-bottom: 20px !important;
	}
}
