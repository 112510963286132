.app-l-tree-accordion {
	body {
		margin: 30px;
		font-family: sans-serif;
	}

	#fontSizeWrapper {
		font-size: 16px;
	}

	#fontSize {
		width: 100px;
		font-size: 1em;
	}

	/* ————————————————————–
        Tree core styles
      */
	.tree {
		margin: 30px 0px;
		list-style: none;
		@media screen and (max-width: 991px) {
			margin: 30px 0px;
		}
		@media screen and (max-width: 767px) {
			margin: 20px 5px;
		}
		@media screen and (max-width: 600px) {
			margin: 10px 5px;
		}
		li {
			.first-level-label-heading {
				color: #1e1919;
				font-weight: 500;
				font-size: 16px;
			}
			.second-tree {
				padding-left: 15px;
				list-style: none;
				.rtl &,
				[dir='rtl'] & {
					padding-left: 0px;
					padding-right: 15px;
				}

				li {
					.second-level-label-heading {
						color: #1e1919;
						font-weight: 500;
						font-size: 14px;
					}
				}
			}
			.third-tree {
				padding-left: 25px;
				list-style: none;
				.rtl &,
				[dir='rtl'] & {
					padding-left: 0px;
					padding-right: 25px;
				}
				// list-style: disc;
				li {
					padding: 0 0 0px 0px;

					.third-level-label-heading {
						color: #1e1919;
						font-weight: 500;
						font-size: 14px;
					}

					.rtl &,
					[dir='rtl'] & {
						padding: 0 0 0px 1em;
					}
				}
			}
			.fourth-tree {
				list-style: none;
				padding-left: 44px;

				.rtl &,
				[dir='rtl'] & {
					padding-left: 0px;
					padding-right: 20px;
				}
				.tree_label {
					padding-left: 8px;
					font-size: $text-lg;
					position: relative;
					&::before {
						position: absolute;
						left: 0;
						top: 18.5px;
						background: #61636f;
						width: 4px;
						height: 4px;
						@include border-radius(50%);
						content: '';
					}
				}
			}
		}
	}

	.tree input {
		position: absolute;
		clip: rect(0, 0, 0, 0);
	}

	.tree input ~ ul {
		display: none;
	}

	.tree input:checked ~ ul {
		display: block;
	}

	/* ————————————————————–
        Tree rows
      */
	.tree li {
		line-height: 1.2;
		position: relative;
		padding: 0 0 0 0;

		.rtl &,
		[dir='rtl'] & {
			padding: 0;
		}
	}

	.tree ul li {
		padding: 0;

		.rtl &,
		[dir='rtl'] & {
			padding: 0;
		}
	}

	.tree > li:last-child {
		padding-bottom: 0;
	}

	/* ————————————————————–
        Tree labels
      */
	.tree_label {
		position: relative;
		display: inline-block;
		background: #fff;
		width: 100%;
		border-bottom: 1px solid rgba(190, 193, 210, 0.4);
		padding: 8px 0px 9px 44px;
		margin-bottom: 0px;
		@include flexbox();
		@include align-items(center);

		.app-c-icon-btn {
			margin-left: auto;
			border: none;
			outline: none;
			background: none;
			box-shadow: none;
			padding: 0;
			color: #666;
			min-width: 50px;
			background-color: #fff !important;
			border-color: #fff !important;
			&:focus,
			&:active {
				outline: none;
				box-shadow: none !important;
				border: none;
				outline: none;
				background: none;
				background-color: #fff !important;
				border-color: #fff !important;
			}

			.rtl &,
			[dir='rtl'] & {
				margin-left: 0;
				margin-right: auto;
			}
		}
		.btn-primary:focus {
			outline: none;
			box-shadow: none !important;
		}

		.rtl &,
		[dir='rtl'] & {
			text-align: right;
		}
		&.no-border {
			border-bottom: none;
		}
	}

	label.tree_label {
		cursor: pointer;
	}

	label.tree_label:hover {
		color: #666;
	}

	/* ————————————————————–
        Tree expanded icon
      */
	label.tree_label:before {
		background: transparent;
		color: #000;
		position: relative;
		z-index: 1;
		float: left;
		margin: 0 1em 0 -2em;
		width: 1em;
		height: 1em;
		border-radius: 1em;
		content: '+';
		font-weight: 300;
		text-align: center;
		line-height: 0.9em;
		font-size: 24px;
		color: #61636f;

		.rtl &,
		[dir='rtl'] & {
			float: right;
			margin: 0 -2em 0 1em;
		}
	}

	:checked ~ label.tree_label:before {
		content: '–';
	}

	/* ————————————————————–
        Tree branches
      */
	.tree li:before {
		position: absolute;
		top: 0;
		bottom: 0;
		left: -0.5em;
		display: block;
		width: 0;
		border-left: 1px solid #777;
		content: '';
		display: none;

		.rtl &,
		[dir='rtl'] & {
			left: 0;
			right: -0.5em;
		}
	}

	.tree_label:after {
		position: absolute;
		top: 0;
		left: -1.5em;
		display: block;
		height: 0.5em;
		width: 1em;
		border-bottom: 1px solid #777;
		border-left: 1px solid #777;
		border-radius: 0 0 0 0.3em;
		content: '';
		display: none;
	}

	label.tree_label:after {
		border-bottom: 0;
	}

	:checked ~ label.tree_label:after {
		border-radius: 0 0.3em 0 0;
		border-top: 1px solid #777;
		border-right: 1px solid #777;
		border-bottom: 0;
		border-left: 0;
		bottom: 0;
		top: 0.5em;
		height: auto;
	}

	.tree li:last-child:before {
		height: 1em;
		bottom: auto;
	}

	.tree > li:last-child:before {
		display: none;
	}

	.tree_custom {
		display: block;
		background: #eee;
		padding: 1em;
		border-radius: 0.3em;
	}

	.tree-text-block {
		@include flexbox();
		@include align-items(center);
		.app-c-icon-btn {
			border: none;
			outline: none;
			background: none;
			padding: 0;
			color: #666;
			background-color: #fff !important;
			border-color: #fff !important;
			&:focus,
			&:active {
				outline: none;
				box-shadow: none !important;
				border: none;
				outline: none;
				background: none;
				background-color: #fff !important;
				border-color: #fff !important;
			}
		}
	}
}
