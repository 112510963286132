.faq__wrapper {
	@include flexbox();
	@include justify-content(flex-start);
	@include align-items(flex-start);
	max-width: 100%;
	padding: 11px 40px 11px 0;
	@media (max-width: 768px) {
		@include flex-direction(column);
		margin-top: 10px;
	}
	.app-c-input-set {
		display: flex;
		align-items: flex-start;
		width: 100%;
		.form-group {
			width: 100%;
		}
		button {
			padding: 10px;
			min-width: 80px;
			margin-left: 16px;
			border-radius: 4px;
			@media (max-width: 768px) {
				min-width: 70px;
			}
		}
	}
	.faq--suggestions {
		.csEditorjs.instructions {
			margin: 15px 20px;
		}
		&-item {
			transition: 0.3s all;
			transform: translateY(0);
			padding: 20px 20px;
			background: white;
			margin-bottom: 10px;
			border-radius: 8px;
			color: #000;
			display: block;
			box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
			cursor: pointer;
			&:hover {
				box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px 2px,
					rgba(0, 0, 0, 0.3) 0px 1px 3px 2px;
				text-decoration: none;
				transform: translateY(-3px);
				h4 {
					text-decoration: underline;
				}
			}
			@media (max-width: 768px) {
				margin-bottom: 20px;
			}
			h4 {
				font-weight: 500;
				font-size: 22px;
				margin-bottom: 16px;
			}
			p {
				font-weight: 400;
				font-size: 16px;
				margin-bottom: 16px;
			}
			.faq--path {
				font-size: 14px;
				ul {
					list-style: none;
					padding: 0;
					margin: 0;
					font-weight: 200;
					@include flexbox;
					flex-wrap: wrap;
					li {
						list-style: none;
						+ li {
							&:before {
								content: '/';
							}
						}
					}
				}
			}
		}
		.ce-header {
			padding-top: 0;
		}
		.cdx-alert-info {
			word-break: break-all;
			.cdx-alert__message {
				word-break: break-word;
			}
		}
	}
	.faq__view--left {
		flex-shrink: 0;
		@include flexbox();
		@include flex-direction(column);
		max-width: 250px;
		width: 100%;
		position: sticky;
		top: 90px;
		z-index: 0;
		@media (max-width: 768px) {
			width: 100%;
			max-width: 100%;
			align-items: flex-start;
			position: unset;
			top: 0;
			z-index: unset;
		}
		.btn-plain {
			margin-bottom: 13px;
			text-align: left;
			padding: 0;
			@media (max-width: 768px) {
				margin-bottom: 20px;
			}
			em {
				margin-right: 4px;
				font-size: 12px;
			}
			span {
				font-size: 14px;
			}
		}
	}
	.nav__faq {
		padding: 20px;
		width: 100%;
		background: #f2f3f7;
		box-shadow: none;
		border: none;
		box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14);
		@media (max-width: 768px) {
			max-width: 100%;
		}
		h4 {
			font-size: 18px;
			margin-bottom: 8px;
		}
		&--item {
			// all: unset;
			display: block;
			&.no-arrow {
				a {
					&:after {
						content: '●' !important;
						top: 3px !important;
						width: auto !important;
						height: auto !important;
						background-image: none !important;
						position: absolute !important;
						left: 0 !important;
					}
				}
				ul {
					li {
						a {
							&:after {
								display: none;
							}
						}
					}
				}
			}
		}

		&--link {
			text-decoration: none;
			color: #000;
			transition: 0.3s all;
			margin-bottom: 10px;
			display: block;
			cursor: pointer;
			&.sub {
				position: relative;

				// &:after {
				// 	content: '';
				// 	position: absolute;
				// 	top: 0;
				// }
			}
			&:hover {
				color: $app-brand-primary;
			}
			&.active {
				color: $app-brand-primary;
				font-weight: 500;
			}
			&.main-link {
				position: relative;
				padding-left: 16px;
				&.arrow-up {
					&:after {
						content: '';
						position: absolute;
						width: 6px;
						height: 10px;
						background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='7' height='10' viewBox='0 0 7 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.96967 0.46967C1.26256 0.176777 1.73744 0.176777 2.03033 0.46967L6.03033 4.46967C6.32322 4.76256 6.32322 5.23744 6.03033 5.53033L2.03033 9.53033C1.73744 9.82322 1.26256 9.82322 0.96967 9.53033C0.676777 9.23744 0.676777 8.76256 0.96967 8.46967L4.43934 5L0.96967 1.53033C0.676777 1.23744 0.676777 0.762563 0.96967 0.46967Z' fill='%23382F2F'/%3e%3c/svg%3e ");
						background-repeat: no-repeat;
						top: 7px;
						left: 0px;
					}
				}
				&.arrow-down {
					&:after {
						content: '';
						position: absolute;
						width: 10px;
						height: 6px;
						top: 9px;
						left: -3px;
						background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='11' height='6' viewBox='0 0 11 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.0303 0.46967C10.3232 0.762563 10.3232 1.23744 10.0303 1.53033L6.03033 5.53033C5.73744 5.82322 5.26256 5.82322 4.96967 5.53033L0.96967 1.53033C0.676777 1.23744 0.676777 0.762563 0.96967 0.46967C1.26256 0.176777 1.73744 0.176777 2.03033 0.46967L5.5 3.93934L8.96967 0.46967C9.26256 0.176777 9.73744 0.176777 10.0303 0.46967Z' fill='%23382F2F'/%3e%3c/svg%3e ");
					}
				}
			}
		}
		&--title {
			font-size: 16px;
			display: block;
			white-space: normal;
		}
		&--list {
			@include flexbox();
			@include flex-direction(column);
			margin-bottom: 0;
			align-items: flex-start;
			&:empty {
				display: none;
			}
			.nav__faq--list {
				padding-left: 20px;
				.nav__faq--item {
					.nav__faq--link {
						.nav__faq--title {
							font-size: 12px;
						}
					}
				}
			}
		}
	}
	.faq__view-wrapper {
		width: 100%;
		margin-left: 24px;

		@media (max-width: 768px) {
			margin: 20px 0 0;
		}
	}
}
// iframe {
// 	display: none;
// }
.manageFAQ-btn {
	i {
		font-size: 18px;
	}
}
.FAQ_subnav {
	list-style-type: disc;
	margin-left: 14px;
	li {
		position: relative;
		&::after {
			content: '●';
			position: absolute;
			top: 2px;
			left: -20px;
			line-height: normal;
		}
		a {
			margin-left: -5px;
		}
	}
}
.FAQ--subnav {
	margin-left: 10x;
	li {
		position: relative;
		&::after {
			content: '○';
			position: absolute;
			top: 2px;
			left: -20px;
			line-height: normal;
		}
		a {
			margin-left: -5px;
		}
	}
}

.FAQFullView {
	padding-left: 0 !important;
	.faq__wrapper {
		padding: 0;
	}
}
.faq__view-wrapper {
	.csEditorjs.instructions {
		cursor: default;
	}
}
.pl-40 {
	padding-left: 40px !important;
}
