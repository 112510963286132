.app-l-content-box {
	background-color: #fff;
	@include border-radius(8px);
	@include boxShadow(0px 2px 8px rgba(0, 0, 0, 0.135216));
	padding-bottom: 0px;
	margin-top: 8px;
	.rtl &,
	[dir='rtl'] & {
		text-align: right;
	}
}
.app-l-content__head {
	color: $text-color;
	font-weight: $font-weight-semibold;
	font-size: 16px;
	padding: 24px 24px 0px 24px;
	line-height: 17px;
	@media screen and (max-width: 767px) {
		padding: 16px 16px 0px;
	}

	&.btm-line {
		border-bottom: 1px solid #e9ebf4;
		padding: 12px 24px 10px 24px;
		@media screen and (max-width: 767px) {
			padding: 16px 16px 10px;
		}
	}
}
.app-l-content__body {
	padding: 0px 24px 24px 24px;
	@media screen and (max-width: 767px) {
		padding: 0px 16px 24px 16px;
	}
	&.fullWidth {
		width: 100%;
	}
}
.app-l-info-wrap {
	@include flexbox();
	@include flex-direction(row);
	@include justify-content(flex-start);
	border-bottom: 1px solid #e9ebf4;
	.rtl &,
	[dir='rtl'] & {
		text-align: right;
	}
	@media screen and (max-width: 991px) {
		flex-wrap: wrap;
	}
	&:last-child {
		border-bottom: none;
	}
	.app-l-info__box {
		@include flex-basis(100%);
		padding: 16px 16px 0px 0px;
		word-break: break-word;

		@media screen and (max-width: 991px) {
			@include flex-basis(50%);
		}
		@media screen and (max-width: 600px) {
			@include flex-basis(100%);
			padding-right: 0;
		}
	}
}
.app-c-last-updatedNew {
	background: $faded-blue;
	color: $app-brand-primary;
	font-size: $text-md;
	line-height: 14.3px;
	padding: 6px 8px;
	@include border-radius(3px);
	text-align: center;
	margin-top: 10px;
}
.app-l-title-small {
	font-weight: $font-weight-normal;
	font-size: $text-md !important;
	color: $text-muted;
	display: block;
	padding-bottom: 6px;
	line-height: 16.5px;
	&.lg {
		font-size: $text-lg;
	}
	&.md {
		font-size: $text-md;
	}
}
.app-l-text {
	font-weight: $font-weight-normal;
	font-size: $text-lg !important;
	color: $text-color;
	margin: 0px 0px 16px 0px !important;
	line-height: 22px;
	display: block;
	&.bold {
		font-weight: $font-weight-semibold;
	}
	&.word-wrap {
		word-break: break-word;
	}
	.app-c-tiny-status {
		margin-left: 6px;
	}

	span {
		font-size: $text-md;
		text-decoration: none;
		font-weight: $font-weight-normal;
		position: relative;
		display: inline-block;
		// padding-left: 20px;
		[dir='rtl'] & {
			padding-left: 0px;
			padding-right: 20px;
		}
		// &::before {
		// 	position: absolute;
		// 	left: 6px;
		// 	top: 8px;
		// 	width: 4px;
		// 	height: 4px;
		// 	border-radius: 50%;
		// 	content: '';
		// 	background: #d0d4f1;
		// 	display: block;
		// 	[dir='rtl'] & {
		// 		right: 6px;
		// 		left: inherit;
		// 	}
		// }
		// &::before {
		// 	position: absolute;
		// 	left: 6px;
		// 	top: 8px;
		// 	width: 4px;
		// 	height: 4px;
		// 	border-radius: 50%;
		// 	content: '';
		// 	background: #d0d4f1;
		// 	display: block;
		// }
	}
	.dim {
		color: $text-muted;
	}
	.red {
		position: relative;
		top: 1px;
		color: $default-red;
		&:hover {
			color: $warning-toast;
		}
	}
}

.app-l-tag-wrap {
	display: block;
	position: relative;
	margin-left: -4px;
	margin-right: -4px;
	&.drp {
		padding-right: 40px;
		&::after {
			content: '\e972';
			font-family: 'qualify-tms';
			position: absolute;
			right: 35px;
			top: 14px;
			font-size: 9.33px;
		}
	}
	.app-l-tags {
		margin: 4px !important;
	}
	&.scrolling {
		.app-l-tags {
			white-space: nowrap;
		}
	}
}
.app-l-tags {
	display: inline-block;
	@include border-radius(5px);
	background: $app-brand-secondary;
	padding: 8px 7px 7px 8px;
	font-size: $text-md;
	font-weight: $font-weight-normal;
	color: $text-color;
	line-height: 14.22px;
	text-decoration: none;
	margin: 4px;
	position: relative;
	@include transition(all ease 0.2s);
	.rtl &,
	[dir='rtl'] & {
		text-align: right;
		// float: right;
	}
	&.selected {
		background: #61636f;
		color: #fff;
		&:hover {
			background: #7b7d86;
		}
		.app-l-tags__close {
			color: #fff;
			font-size: 6px;
			vertical-align: middle;
		}
	}
	.bold {
		font-weight: $font-weight-semibold;
		.rtl &,
		[dir='rtl'] & {
			float: right;
		}
	}
	&:hover {
		cursor: default;
		text-decoration: none;
		background: #e0e7f3;
		// text-decoration: none;
		// background: $app-brand-primary-light;
		// color: #fff;
		// .app-l-tags__close {
		// 	color: #fff;
		// }
	}
	.app-l-tags__close {
		border: none;
		background: none;
		font-size: 8px;
		padding: 0;
		margin-left: 14px;
		display: inline-block;
		@include transition(all ease 0.2s);
		.rtl &,
		[dir='rtl'] & {
			float: left;
			margin-left: 0;
			margin-right: 14px;
			position: relative;
			top: 1px;
		}
	}

	.is-primary {
		display: inline-block;
		width: 15px;
		height: 15px;
		padding: 0;
		margin: 0 12px 0px 0px;
		vertical-align: middle;
		@include border-radius(50%);
		border: 1px solid #bec1d2;
		@include transition(all ease 0.2s);
		position: relative;
		top: -1px;
		&:hover {
			border-color: $app-brand-primary;
		}
		&::before {
			content: 'P';
			color: $white;
			background: $app-brand-primary;
			text-align: center;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			@include border-radius(50%);
			font-size: 10px;
			font-weight: $font-weight-semibold;
			opacity: 0;
			@include transition(all ease 0.2s);
		}
	}
	input {
		position: absolute;
		opacity: 0;
		&[type='radio']:checked {
			+ .is-primary {
				border-color: $app-brand-primary;
				&::before {
					opacity: 1;
				}
			}
		}
	}
	.app-c-custom-tooltip {
		top: -32px;
		left: 0px;
		pointer-events: none;
		opacity: 0;
		min-width: 155px;
	}
	&:hover {
		.app-c-custom-tooltip {
			opacity: 1;
			top: -38px;
		}
	}
}

.app-l-content-title {
	font-weight: $font-weight-semibold;
	font-size: $text-xl;
	color: $text-color;
	margin: 24px 0px 8px 0px;
	line-height: 22px;
}
.app-l-wireframing-snap {
	@include inline-flex();
	text-align: center;
	@include justify-content(center);
	@include flex-direction(column);
}
.app-l-wireframing-label {
	display: inline-block;
	text-align: center;
	line-height: 22px;
	margin-top: 8px;
}
.app-l-dscrp {
	padding: 24px 0px;
	h5 {
		font-size: $text-lg;
		color: $text-color;
		font-weight: $font-weight-semibold;
		margin-bottom: 12px;
	}
	li {
		font-weight: $font-weight-normal;
		font-size: $text-lg;
		color: $text-color;
		margin: 0px 0px 8px 0px;
		line-height: 22px;
		display: block;
		span {
			font-weight: $font-weight-semibold;
			.rtl &,
			[dir='rtl'] & {
				text-align: right;
				float: right;
			}
		}
	}
}

.app-l-modal-button-header {
	border: none;
	width: 96%;
	margin-top: 20px;
	margin-left: 2%;
}
.tag-action-group {
	@include flexbox;
	button,
	.tag-action-btn {
		margin-right: 10px;
		background: $white;
		border: 1px solid #e9e7e7;
		@include border-radius(50%);
		color: $text-light;
		@include flexbox;
		@include align-items(center);
		@include justify-content(center);

		min-height: 30px;
		width: 30px;
		&:hover {
			color: $black01;
			background-color: #e9e7e7;
		}
		&[disabled] {
			color: lightgray;
		}
	}
}
.tag-name {
	word-break: break-all;
}
.app-l-groups-view-all {
	.app-c-btn {
		padding: 2px !important;
	}
}
input:disabled {
	cursor: not-allowed;
	+ label {
		cursor: not-allowed;
	}
}
.app-l-update-checklist__item {
	min-height: 70px;
	textarea {
		height: calc(100% - 20px);
		color: #495057;
		word-break: break-word;
		font-weight: normal;
		font-size: 14px;
		width: calc(100% - 20px) !important;
		padding: 10px;
	}
}
