.app-l-nodata-holder {
	width: 100%;
	height: calc(100vh - 180px);
	@include flexbox();
	@include flex-direction(column);
	@include align-items(Center);
	@include justify-content(center);
	h1 {
		font-size: 20px !important;
		line-height: 28px;
		color: $app-brand-primary;
		margin-top: 10px;
		margin-bottom: 10px;
		padding-left: 10px;
	}
	p {
		font-size: 15px;
		line-height: 20px;
		color: $gray05;
	}
	@media screen and (max-width: 1300px) {
		img {
			height: 150px;
		}
	}
	@media screen and (max-height: 600px) {
		img {
			height: 150px;
		}
	}
}

.modal {
	.app-l-nodata-holder {
		height: calc(100vh - 410px);
	}
}
.faq__view-wrapper {
	.app-l-nodata-holder {
		height: calc(100vh - 260px);
	}
}
