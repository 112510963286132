@import '../core/mixins/flexbox';
@import '../core/mixins/border-radius';
@import '../core/mixins/box-shadow';
@import '../core/mixins/filters';

// Drag And Drop

.app-c-drag-drop__box {
	border: 1px dashed $app-brand-primary;
	background: #f8f9fb;
	text-align: center;
	@include border-radius(2px);
	padding: 25px;
	position: relative;
	.app-c-full-cover-preview {
		position: absolute;
		top: 0;
		left: 0;
		//@include boxShadow(0px 0px 0px 2px #fff);
		width: 100%;
		height: 100%;
		outline-style: solid;
		outline-color: #fff;
		//outline-width: medium;
		.close-file {
			@include border-radius(50%);
			@include transition(all ease 0.2s);
			color: #fff;
			display: block;
			width: 20px;
			height: 20px;
			padding: 0;
			margin: 0;
			border: none;
			background: #1e1919;
			position: absolute;
			right: -7px;
			top: -7px;
			z-index: 2;
			&::before {
				content: '\e916';
				font-family: 'qualify-tms';
				color: #fff;
				font-size: 7px;
				line-height: 20px;
				text-align: center;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				pointer-events: none;
			}
			&:hover {
				background: $app-brand-primary;
			}
		}
		img {
			min-height: 100%;
			min-width: 100%;
			max-width: 100%;
			max-height: 100%;
		}
	}
}
.app-c-full-cover-preview {
	position: relative;
}
.app-c-drag-drop__title {
	line-height: 17px;
	font-size: $text-xl;
	font-weight: $font-weight-semibold;
	color: $text-color;
	margin-bottom: 25px;
	padding: 0px 8px;
	&.inline {
		display: inline-block;
	}
}
.app-c-drag-drop__icon {
	display: block;
	margin: auto;
	margin-bottom: 13px;
	i {
		font-size: 44px;
		color: rgba(190, 193, 210, 1);
	}
}
.app-c-drag-drop__file-name {
	line-height: 14.22px;
	color: rgba(97, 99, 111, 1);
	font-size: $text-md;
	margin-bottom: 8px;
	.bold {
		font-weight: $font-weight-bold;
	}
}
.app-c-drag-drop__import {
	color: $text-color;
	font-size: $text-lg;
	line-height: 16.6px;
	&.inline {
		display: inline-block;
	}
	button {
		color: $app-brand-primary;
		border: none;
		background: none;
		padding: 0;
		font-weight: $font-weight-bold;
	}
}
.app-c-drag-drop__preview {
	padding: 16px 0px;
}
.app-c-drag-drop__preview-item {
	@include flexbox();
	@include flex-direction(row);
	@include justify-content(flex-start);
	align-items: flex-start;
	border: 1px solid var(--theme-primary-color-200);
	@include border-radius(9px);
	margin-bottom: 8px;
	padding: 12px 46px 12px 12px;
}
.app-c-drag-drop__Extension {
	margin-right: 19px;
}
.app-c-progress-bar-wrapper {
	position: relative;
	margin-bottom: 6px;
	.app-c-progress-bar__remove {
		border: none;
		background: none;
		padding: 0;
		position: absolute;
		right: -30px;
		top: -6px;
		font-size: 12px;
		&:hover {
			color: $app-brand-primary;
		}
	}
}
.app-c-progress-bar {
	flex-grow: 1;
	flex-basis: 100%;

	.progress {
		height: 5px;
		background: #e9ebf4;
		@include border-radius(10px);
	}
	.progress-bar {
		background: $app-brand-primary;
		@include border-radius(10px);
	}
}
.app-c-progress-bar__file-loaded {
	@include flexbox();
	@include flex-direction(row);
	@include justify-content(space-between);
	align-items: center;
	margin-bottom: 8px;
	&.set-right {
		@include justify-content(flex-end);
	}
}
.app-c-progress-bar__file-name {
	line-height: 16.6px;
	font-size: $text-lg;
	color: rgba(0, 0, 0, 1);
	display: block;
}
.app-c-progress-bar__percent,
.app-c-progress-bar__file-size {
	color: rgba(126, 131, 141, 1);
	font-size: 12px;
	line-height: 13.04px;
	display: block;
}

.app-c-drag-drop__preview-wrapper {
	padding: 14px 0px;
	@include flexbox();
	@include flex-direction(row);
	@include justify-content(flex-start);
	@include align-items(flex-start);
	@include flex-flow(wrap);
	position: relative;
	margin: 0px -9px;
}
.app-c-drag-drop__preview-file {
	position: relative;
	width: 146px;
	height: 146px;
	display: block;
	margin: 9px;
	@include border-radius(2px);
	&.video {
		&::after {
			content: '\e917';
			opacity: 1;
		}
	}
	&.pdf {
		&::after {
			content: '\e914';
			opacity: 1;
		}
	}
	&::after {
		@include flexbox();
		@include flex-direction(column);
		@include justify-content(center);
		@include align-items(center);
		background: rgba(0, 0, 0, 0.5);
		@include border-radius(2px);
		position: absolute;
		width: 100%;
		height: 100%;

		content: '';
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		margin: auto;
		@include transition(all ease 0.2s);
		opacity: 0;
		font-family: 'qualify-tms';
		text-align: center;
		color: #fff;
		font-size: 24px;
	}
	&:hover {
		&::after {
			opacity: 1;
		}
		.close-file {
			opacity: 1;
		}
	}
	img {
		width: 100%;
		height: 100%;
	}
	.close-file {
		@include border-radius(50%);
		@include transition(all ease 0.2s);
		color: #fff;
		display: block;
		width: 20px;
		height: 20px;
		padding: 0;
		margin: 0;
		border: none;
		background: #1e1919;
		position: absolute;
		right: -7px;
		top: -7px;
		z-index: 2;
		opacity: 0;
		&::before {
			content: '\e916';
			font-family: 'qualify-tms';
			color: #fff;
			font-size: 7px;
			line-height: 20px;
			text-align: center;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			pointer-events: none;
		}
	}
}
