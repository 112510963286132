$iconpath: 'assets/fonts';

@font-face {
	font-family: 'qualify-tms';
	src: url('../../../assets/fonts/font-icons/qualify-tms.eot?li4t5w');
	src: url('../../../assets/fonts/font-icons/qualify-tms.eot?li4t5w#iefix')
			format('embedded-opentype'),
		url('../../../assets/fonts/font-icons/qualify-tms.ttf?li4t5w') format('truetype'),
		url('../../../assets/fonts/font-icons/qualify-tms.woff?li4t5w') format('woff'),
		url('../../../assets/fonts/font-icons/qualify-tms.svg?li4t5w#qualify-tms') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'qualify-tms' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.icon-m-faq:before {
	content: '\e982';
}
.icon-stack:before {
	content: '\e981';
}
.icon-gear:before {
	content: '\e980';
}
.icon-close-solid:before {
	content: '\e97d';
}
.icon-close:before {
	content: '\e97f';
}
.icon-cross:before {
	content: '\e97e';
}
.icon-settings:before {
	content: '\e97c';
}
.icon-faqs:before {
	content: '\e97b';
}
.icon-sent-msg:before {
	content: '\e97a';
}
.icon-feedback:before {
	content: '\e96e';
}
.icon-check-mark:before {
	content: '\e96f';
}
.icon-performance-rating:before {
	content: '\e970';
}
.icon-Star:before {
	content: '\e971';
}
.icon-dislike:before {
	content: '\e973';
}
.icon-like:before {
	content: '\e974';
}
.icon-organization:before {
	content: '\e975';
}
.icon-summary:before {
	content: '\e976';
}
.icon-training:before {
	content: '\e977';
}
.icon-all:before {
	content: '\e978';
}
.icon-learners:before {
	content: '\e979';
}
.icon-share:before {
	content: '\e96d';
}
.icon-brain:before {
	content: '\e96c';
}
.icon-collapse:before {
	content: '\e969';
}
.icon-expand:before {
	content: '\e96a';
}
.icon-star:before {
	content: '\e96b';
}
.icon-hamburger:before {
	content: '\e965';
}
.icon-Mobile-phone:before {
	content: '\e966';
}
.icon-Desktop:before {
	content: '\e967';
}
.icon-Arrow-left:before {
	content: '\e968';
}
.icon-generate:before {
	content: '\e964';
}
.icon-add-slide:before {
	content: '\e963';
}
.icon-options:before {
	content: '\e962';
}
.icon-document-new:before {
	content: '\e961';
}
.icon-paint:before {
	content: '\e95f';
}
.icon-duplicate:before {
	content: '\e960';
}
.icon-plus-xl:before {
	content: '\e95b';
}
.icon-attachment-horizontal:before {
	content: '\e95c';
}
.icon-trash-cross:before {
	content: '\e95d';
}
.icon-drag:before {
	content: '\e95e';
}
.icon-upload-solid:before {
	content: '\e959';
}
.icon-link:before {
	content: '\e95a';
}
.icon-headphone:before {
	content: '\e956';
}
.icon-media-solid:before {
	content: '\e957';
}
.icon-video-camera:before {
	content: '\e958';
}
.icon-labelled-graphic:before {
	content: '\e924';
}
.icon-text-right:before {
	content: '\e954';
}
.icon-media:before {
	content: '\e955';
}
.icon-round_close:before {
	content: '\e953';
	color: #fff;
}
.icon-library:before {
	content: '\e952';
}
.icon-edit1:before {
	content: '\e951';
}
.icon-download01:before {
	content: '\e950';
}
.icon-reset-password:before {
	content: '\e94f';
}
.icon-bookmark:before {
	content: '\e94d';
}
.icon-help:before {
	content: '\e94e';
}
.icon-re-sent:before {
	content: '\e94c';
}
.icon-re-send:before {
	content: '\e94b';
}
.icon-minus:before {
	content: '\e94a';
}
.icon-rightArrow:before {
	content: '\e949';
}
.icon-group:before {
	content: '\e948';
}
.icon-continue-arrow:before {
	content: '\e947';
}
.icon-time:before {
	content: '\e946';
}
.icon-img:before {
	content: '\e945';
}
.icon-down-arrow:before {
	content: '\e943';
}
.icon-up-arrow:before {
	content: '\e944';
}
.icon-calender-planned:before {
	content: '\e940';
}
.icon-blocked:before {
	content: '\e941';
}
.icon-calender-unplanned:before {
	content: '\e942';
}
.icon-user:before {
	content: '\e93b';
}
.icon-menu-user:before {
	content: '\e93f';
}
.icon-camera:before {
	content: '\e934';
}
.icon-menu-feedback:before {
	content: '\e939';
}
.icon-menu-schedules:before {
	content: '\e93a';
}
.icon-menu-course:before {
	content: '\e93c';
}
.icon-menu-curriculum:before {
	content: '\e93d';
}
.icon-menu-dashboard:before {
	content: '\e93e';
}
.icon-upload:before {
	content: '\e938';
}
.icon-Copy:before {
	content: '\e937';
}
.icon-publish-item:before {
	content: '\e936';
}
.icon-reload:before {
	content: '\e935';
}
.icon-versions:before {
	content: '\e933';
}
.icon-upload-icon:before {
	content: '\e92c';
}
.icon-pdf-icon:before {
	content: '\e92d';
}
.icon-doc-icon:before {
	content: '\e92e';
}
.icon-mp3-icon:before {
	content: '\e92f';
}
.icon-mp4-icon:before {
	content: '\e930';
}
.icon-image-icon:before {
	content: '\e931';
}
.icon-file-icon:before {
	content: '\e932';
}
.icon-arrow-right:before {
	content: '\e92a';
}
.icon-search:before {
	content: '\e92b';
}
.icon-unArchive:before {
	content: '\e929';
}
.icon-dateRefresh:before {
	content: '\e928';
}
.icon-archive:before {
	content: '\e927';
}
.icon-leftArrow:before {
	content: '\e91f';
}
.icon-send:before {
	content: '\e920';
}
.icon-save:before {
	content: '\e921';
}
.icon-attach:before {
	content: '\e922';
}
.icon-upArrow:before {
	content: '\e923';
}
.icon-downArrow:before {
	content: '\e972';
}
.icon-copyFile:before {
	content: '\e925';
}
.icon-trash:before {
	content: '\e926';
}
.icon-sort-asc:before {
	content: '\e91b';
}
.icon-calender:before {
	content: '\e91c';
}
.icon-filter:before {
	content: '\e91d';
}
.icon-sort-desc:before {
	content: '\e91e';
}
.icon-image:before {
	content: '\e912';
}
.icon-live-video:before {
	content: '\e913';
}
.icon-pdf:before {
	content: '\e914';
}
.icon-text:before {
	content: '\e915';
}
.icon-times:before {
	content: '\e916';
}
.icon-video:before {
	content: '\e917';
}
.icon-question:before {
	content: '\e918';
}
.icon-audio:before {
	content: '\e919';
}
.icon-document:before {
	content: '\e91a';
}
.icon-caret-down:before {
	content: '\e911';
}
.icon-edit:before {
	content: '\e90e';
}
.icon-export:before {
	content: '\e90f';
}
.icon-delete:before {
	content: '\e910';
}
.icon-plus:before {
	content: '\e90c';
}
.icon-download:before {
	content: '\e90d';
}
.icon-info:before {
	content: '\e90b';
}
.icon-attachment:before {
	content: '\e90a';
}
.icon-bell:before {
	content: '\e903';
}
.icon-layers:before {
	content: '\e904';
}
.icon-grid:before {
	content: '\e905';
}
.icon-pie-chart:before {
	content: '\e906';
}
.icon-home:before {
	content: '\e907';
}
.icon-message:before {
	content: '\e908';
}
.icon-search-lens:before {
	content: '\e909';
}
.icon-check:before {
	content: '\e902';
}
.icon-eye:before {
	content: '\e900';
}
.icon-eye-off:before {
	content: '\e901';
}
