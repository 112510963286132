.app-l-topicMastery-list {
	@media screen and (max-width: 767px) {
		table {
			width: 120vw;
		}
	}
	@media screen and (max-width: 480px) {
		table {
			width: 152vw;
		}
	}
}
.image-mcq {
	margin: 10px auto;
	width: 100%;
	height: 200px;
	max-width: 200px;
	position: relative;
	.expand-button {
		position: absolute;
		width: 30px;
		height: 30px;
		background: $newslide-hover;
		border-radius: 50px;
		top: 10px;
		right: 10px;
		font-size: 12px;
		color: #61636f !important;
		z-index: 1;
		font-weight: normal;
		border-radius: 0;
		&:hover {
			background: $app-brand-primary;
			color: $white !important;
		}
		.rtl &,
		[dir='rtl'] & {
			right: auto;
			left: 8px;
		}
	}
	img {
		width: 100%;
		border-radius: 5px;
		object-fit: cover;
		height: 100%;
	}
}
.app-c-topicMastery-card {
	.app-c-card__header {
		font-size: 18px;
		color: #1e1919;
		font-weight: 500;
		padding: 12px 24px;
	}
	.app-c-card__body {
		p {
			font-size: 16px;
		}
	}
	.app-l-topicMastery-question-list {
		margin: 0;
		padding: 10px 0 0 0;
		position: relative;
		display: flex;
		// align-items: center;
		// justify-content: center;
		flex-direction: column;
		min-height: 100px;
		margin-bottom: 20px;
		.app-common-radio-btns {
			display: inline-block;
			width: 100%;
			position: relative;
			font-size: 16px;
			color: #000 !important;
			display: flex;
			input {
				opacity: 0;
				position: absolute;
				&:checked {
					+ label {
						&:before {
							content: '\e902';
							background-color: $app-brand-primary;
						}
					}
				}
				+ label {
					&:before {
						font-size: 9px;
						transition: 0.4s all;
						font-family: 'qualify-tms';
						color: $white;
						position: absolute;
						@include flexbox;
						@include justify-content(center);
						@include align-content(center);
						line-height: 21px;
					}
				}
			}
			&.nodata-radio {
				margin-bottom: 30px;
			}
			& + .app-common-radio-btns {
				margin-top: 18px;
			}
			label {
				font-size: 16px;
				color: #000 !important;
				word-break: break-word;

				&::before {
					content: '';
					position: absolute;
					width: 23px;
					height: 23px;
					border-radius: 50%;
					left: 0;
					top: 1px;
					border: 1px solid var(--theme-primary-color);
				}
				&::after {
					display: none;
				}
			}
		}
		.app-l-answer-confidence-holder {
			position: absolute;
			width: 100%;
			// height: 100%;
			align-items: center;
			justify-content: center;
			display: none;
			transition: 0.4s;
			top: 0;

			.app-c-card--default {
				padding: 24px;
				width: 360px;
				.title,
				p {
					font-size: 18px;
					color: #1e1919;
					font-weight: 500;
					margin-bottom: 10px;
				}
				p {
					font-size: 16px;
					font-weight: normal;
					margin-bottom: 15px;
				}
				.app-l-confidence-rating {
					display: grid;
					grid-auto-flow: column;
					align-content: flex-start;
					justify-content: start;
					font-size: 18px;
					&:hover {
						em {
							color: gold;
							&:hover {
								~ em {
									color: #b3b3b3;
								}
							}
						}
					}
					em {
						color: #b3b3b3;
						cursor: pointer;
						padding-right: 18px;
						transition: 0.4s all;
					}
					em.active {
						color: var(--theme-primary-color);
					}
				}
			}
		}
		&.show-confidence-modal {
			.app-common-radio-btns,
			.image-mcq {
				filter: blur(5px);
				-webkit-filter: blur(5px);
			}
			.app-l-answer-confidence-holder {
				display: flex;
			}
		}
	}
	.app-c-card__footer {
		padding: 24px;
		button {
			margin: auto;
			padding: 0;
			min-width: 150px;
			height: 40px;
		}
	}
}
.app-l-topic-mastery-result-list {
	.card {
		border: none;
		padding: 0;
		margin: 0;

		background: none;
		overflow: inherit;
		@include boxShadow(0px 0px 8px 0px rgba(0, 0, 0, 0.1352));
		margin-top: 8px;
		@include border-radius(8px !important);
		overflow: hidden !important;
		&.app-c-result-true {
			.btn-link {
				&:before {
					content: '\e902';
					color: #1acb16;
				}
			}
		}
		&.app-c-result-false {
			.btn-link {
				&:before {
					content: '\e916';
					color: #f73d11;
				}
			}
		}
		.btn-link {
			padding: 0;
			margin: 0;
			@include boxShadow(none);
			text-decoration: none;
			text-align: left;
			margin: 0;
			color: #1a1e26;
			font-weight: 500;
			font-size: 18px;
			line-height: 28px;
			padding: 24px 54px 26px 58px;
			// white-space: nowrap;
			// text-overflow: ellipsis;
			// overflow: hidden;
			// width: -webkit-fill-available;
			div {
				// white-space: nowrap;
				// text-overflow: ellipsis;
				// overflow: hidden;
				// width: -webkit-fill-available;
			}
			img {
				max-width: 200px;
				display: block;
				margin: 10px auto 0;
				display: none;
			}
			&.active {
				padding-left: 20px;
				&::before {
					display: none;
				}
				&::after {
					transform: rotate(180deg);
				}
				img {
					width: 100%;
					margin: 10px auto 0;
				}
			}
			@media screen and (max-width: 640px) {
				padding: 20px 26px 20px 52px;
				&.active {
					padding-left: 14px;
				}
			}

			&:before {
				position: absolute;
				left: 24px;
				@include border-radius(3px);
				font-family: 'qualify-tms', sans-serif;
				display: block;
				color: rgba(97, 99, 111, 1);
				padding: 0 5px;
				@media screen and (max-width: 640px) {
					left: 12px;
				}
			}
			&:after {
				position: absolute;
				content: '\e911';
				right: 15px;
				@include border-radius(3px);
				font-family: 'qualify-tms', sans-serif;
				display: block;
				color: rgba(97, 99, 111, 1);
				padding: 0 5px;
				top: 26px;
				font-size: 12px;
				@media screen and (max-width: 640px) {
					right: 8px;
				}
			}
			&:hover {
				text-decoration: none;
			}
			&:focus {
				outline: none;
				@include boxShadow(none);
				outline: none;
				text-decoration: none;
			}
		}
		.collapse.show &.btn-link {
			display: none;
		}
		.card-body {
			padding-top: 0;
			margin-top: -25px;
			h6 {
				margin-bottom: -3px;
			}
			.fill-correct {
				margin-bottom: 6px;
			}
			@media screen and (max-width: 640px) {
				padding-left: 14px;
				padding-right: 14px;
				margin-top: -25px;
			}
			.list-group {
				padding-left: 25px;
				margin: 10px 0;

				@media screen and (max-width: 640px) {
					padding-left: 13px;
				}
				.list-group-item {
					border: none;
					padding-right: 0px;
					padding-left: 5px;
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					font-size: $text-xl;
					&.ans-false {
						position: relative;
						padding-left: 20px;
						&::before {
							content: '\e916';
							font-family: 'qualify-tms';
							color: $validation-red;
							font-size: $text-lg;
							top: 15px;
							left: 0;
							position: absolute;
						}
					}
					&.ans-true {
						position: relative;
						padding-left: 20px;
						&::before {
							content: '\e902';
							color: $app-toggle-active-bg;
							font-family: 'qualify-tms';
							font-size: $text-lg;
							top: 15px;
							left: 0;
							position: absolute;
						}
					}
					img {
						max-width: 200px;
						margin: 10px auto 0;
						display: block;
						position: relative;
						@media screen and (max-width: 640px) {
							left: -6px;
						}
					}

					&.ans-correct {
						border: 1px solid $default-green;
						padding: 10px;
						border-radius: 3px;
						img {
							width: 100%;
						}
					}
					@media screen and (max-width: 640px) {
						padding-left: 10px;
					}
					.app-l-content-details {
						position: relative;
						display: flex;
						align-items: center;
						font-size: 18px;
						width: 100%;
						&:before {
							position: absolute;
							left: -24px;
							top: auto;
							@include border-radius(3px);
							font-family: 'qualify-tms', sans-serif;
							display: block;
							color: rgba(97, 99, 111, 1);
							font-size: 14px;
							margin: -1px 0 0 0;
							@media screen and (max-width: 640px) {
								left: -23px;
							}
						}
					}
					// &:before {
					// 	position: absolute;
					// 	left: -24px;
					// 	top: auto;
					// 	@include border-radius(3px);
					// 	font-family: 'qualify-tms', sans-serif;
					// 	display: block;
					// 	color: rgba(97, 99, 111, 1);
					// 	font-size: 14px;
					// 	margin: -1px 0 0 0;
					// 	@media screen and (max-width: 640px) {
					// 		left: -14px;
					// 	}
					// }
					&.ans-true {
						// &:before {
						// 	content: '\e902';
						// 	color: #1acb16;
						// }
						// .app-l-content-details {
						// 	&:before {
						// 		content: '\e902';
						// 		color: #1acb16;
						// 	}
						// }
					}
					&.ans-false {
						// &:before {
						// 	content: '\e916';
						// 	color: #f73d11;
						// }
						// .app-l-content-details {
						// 	&:before {
						// 		content: '\e916';
						// 		color: #f73d11;
						// 	}
						// }
					}
					.image-mcq {
						margin: 10px auto;
						img {
							margin: 0;
						}
					}
				}
			}
		}
	}
}
.app-l-topic-mastery-result {
	width: 100% !important;
	@include boxShadow(0px 2px 8px rgba(0, 0, 0, 0.135216));
	@include border-radius(8px);
	background: #fff;
	margin: 0;
	padding: 24px;
	@include flexbox;
	@include align-items(center);
	@media screen and (max-width: 768px) {
		flex-wrap: wrap;
	}
	@media screen and (max-width: 640px) {
		padding: 24px 12px;
	}
	.title {
		div {
			margin-top: -3px;
		}
	}
	.app-l-description {
		padding-left: 20px;
		@media screen and (max-width: 480px) {
			padding-left: 15px;
		}
		@media screen and (max-width: 480px) {
			width: calc(100% - 38px);
		}
		h5,
		p {
			margin: 0;
		}
		h5 {
			font-size: 20px;
			line-height: 16px;
			margin-bottom: 8px;
			display: block;
			color: var(--theme-primary-color);
			font-weight: 500;
			@media screen and (max-width: 480px) {
				font-size: 16px;
			}
		}
		p {
			color: #1a1e26;
			font-size: 19px;
			line-height: normal;
			font-weight: 600;
			span {
				background: white;
				margin-left: -4px;
				padding-left: 18px;
				font-size: 16px;
				position: relative;
				font-weight: normal;
				color: #61636f;
				@media screen and (max-width: 480px) {
					width: 100%;
					padding-left: 0;
					float: left;
				}
			}
		}
	}
	.app-c-btn--addon {
		margin-left: auto;
		padding: 10px 16px;
		@media screen and (max-width: 480px) {
			margin-left: 58px;
			margin-top: 10px;
		}
	}
}
.app-l-email-id {
	p {
		word-break: break-all;
	}
}

.app-l-pre-result-holder {
	width: 100% !important;
	@include boxShadow(0px 2px 8px rgba(0, 0, 0, 0.135216));
	@include border-radius(8px);
	background: #fff;
	margin: 0;
	padding: 24px;
	@include flexbox;
	@include align-items(center);
	@media screen and (max-width: 760px) {
		flex-direction: column;
		align-items: flex-start;
	}
	@media screen and (max-width: 640px) {
		padding: 24px 12px;
	}
	.app-l-peers-card,
	.app-l-topic-knowledge-card {
		display: flex;
	}
	.title {
		div {
			margin-top: -3px;
		}
	}
	.app-l-description {
		padding-left: 20px;
		@media screen and (max-width: 480px) {
			padding-left: 15px;
		}
		@media screen and (max-width: 480px) {
			width: calc(100% - 38px);
		}
		h5,
		p {
			margin: 0;
		}
		h5 {
			font-size: 20px;
			line-height: 16px;
			margin-bottom: 8px;
			display: block;
			color: var(--theme-primary-color);
			font-weight: 500;
			@media screen and (max-width: 480px) {
				font-size: 16px;
			}
		}
		p {
			color: #1a1e26;
			font-size: 19px;
			line-height: normal;
			font-weight: 600;
			span {
				background: white;
				margin-left: -4px;
				padding-left: 18px;
				font-size: 16px;
				position: relative;
				font-weight: normal;
				color: #61636f;
			}
		}
	}
	.app-l-peers-card {
		min-width: 200px;
		border-right: 1px solid #ddd;
		padding-right: 25px;
		margin-right: 25px;
		@media screen and (max-width: 760px) {
			padding-right: 0;
			margin-right: 0;
			padding-bottom: 15px;
			margin-bottom: 15px;
			border-right: none;
			border-bottom: 1px solid #ddd;
			min-width: 100%;
		}
		.app-l-description {
			padding-left: 0;
			width: 100%;
			.title {
				padding-bottom: 12px;
			}
		}
		.app-peer-progress-holder {
			display: flex;
			img {
				width: 30px;
				margin-right: 10px;
			}
			.app-peer-progress {
				width: 100%;
				.progress {
					height: 8px;
					background: #e9ebf4;
					.progress-bar {
						background: var(--theme-primary-color);
					}
				}
			}
		}
	}
}
