.app-c-input-set {
	position: relative;
	&.width25 {
		max-width: calc((100% / 4) - 12px);
		width: 100%;
		@media screen and (max-width: 991px) {
			max-width: calc(100% / 3);
			margin-left: 16px;
		}
		@media screen and (max-width: 767px) {
			max-width: calc((100% / 2) - 14px);
			margin-left: 0px;
			&.mr-full {
				max-width: 100%;
			}
		}
	}
	.date-fiels {
		@media screen and (max-width: 767px) {
			width: 100%;
		}
	}
}
.sort-filter-dropdown__btn {
	all: unset;
	position: relative;
	border: 1px solid $form-control-border;
	min-height: 40px;
	@include border-radius(6px);
	line-height: 22px;
	padding: 0px 13px;
	font-size: $text-lg;
	&.active {
		&::after {
			@include transform(rotate(180deg));
		}
	}
	&::after {
		content: '\e972';
		font-family: 'qualify-tms';
		right: 17.33px;
		top: 8px;
		font-size: 10px;
		position: absolute;
		@include transition(all ease 0.2s);
	}
}
.sort-filter-dropdown {
	background: $white;
	position: absolute;
	top: 70px;
	right: 0;
	width: 100%;
	z-index: 2;
	@include border-radius(6px);
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	li {
		display: block;
		padding: 5px 0px;
	}
}
.sort-filter-dropdown__boxes {
	padding: 16px 32px 10px 32px;
	border-top: 1px solid $generic-border-color;
	.set-title {
		font-size: $text-md;
		font-weight: $font-weight-normal;
		color: $text-light;
		margin-bottom: 7px;
		display: block;
	}
	&:first-child {
		border-top: none;
	}
}
.trend-graph {
	@include flexbox();
	@include justify-content(flex-start);
	@include align-items(center);
	font-size: $text-md;
	> * {
		+ * {
			margin-left: 8px;
		}
	}
	.trend-graph__holder {
		height: 7.96;
		min-width: 68px;
		@include flexbox();
		margin-right: 13px;
		img {
			width: 68px;
			height: 7.96px;
		}
	}
	&.up {
		i {
			color: $dark-green;
		}
	}
	&.down {
		i {
			color: $dark-red;
		}
	}
}

.date-between {
	&.width-fixed {
		.react-datepicker-wrapper {
			min-width: 243px;
			@media (max-width: 500px) {
				min-width: 100%;
			}
		}
	}
}
.ce-settings--opened {
	left: auto;
	right: 0;
}

.lesson-authoring {
	.ce-toolbox {
		top: -18px !important;
		.ce-popover {
			top: 0 !important;
			left: 62px !important;
		}
	}
	.app-l-upload-editor-blk__right {
		.ce-popover {
			top: 0 !important;
			left: 0px !important;
		}
	}
	&.app-l-labelled-graphics-accordion {
		.ce-popover {
			top: 12px !important;
			left: 0px !important;
		}
	}
}
.ce-popover {
	min-width: auto !important;
	border: none !important;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.14) !important;
	border-radius: 0;
	padding: 0 !important;
	top: -40px !important;
	left: 0 !important;
	right: auto !important;
	position: relative;
	.cdx-search-field {
		display: none;
	}
	.ce-popover__item-icon {
		width: 30px;
		height: 30px;
		svg {
			fill: #707684;
			width: auto;
			height: auto;
		}
		&:hover {
			svg {
				fill: var(--theme-primary-color);
			}
		}
	}
	.cdx-search-field {
		display: none;
	}
	.ce-popover__items {
		display: flex;
		margin-top: 0 !important;
		flex-wrap: wrap;
		.ce-popover__item {
			background: transparent !important;
			border: none !important;
			box-shadow: none !important;
			div {
				border: none !important;
			}
		}
	}
	.ce-popover__item-label {
		display: none;
	}
	.ce-popover__item-icon {
		margin-right: 0;
	}
}
.codex-editor--narrow .ce-toolbox {
	left: 0 !important;
	right: auto !important;
	z-index: 9;
}
.app-l-labelled-graphics-accordion {
	.ce-popover {
		top: 0 !important;
		left: 0 !important;
	}
}
.ce-paragraph[data-placeholder]:empty::before {
	content: url(../../images/tab.png) ' TAB to open content menu... ' !important;
	position: absolute;
	font-size: 18px;
	color: var(--slideText, #382f2f);
}
// .app-c-input-set {
// 	[class*='css-1rhbuit-'] {
// 		padding-right: 8px;
// 	}
// }
.tab-color-white {
	.ce-paragraph[data-placeholder]:empty::before {
		content: url(../../images/tab-white.png) ' TAB to open content menu... ' !important;
		position: absolute;
	}
}
.app-c-common-tabs {
	.inline-image__picture {
		text-align: center;
		img {
			max-width: 50%;
			max-height: 400px;
			@media (max-width: 767px) {
				max-width: 100%;
				max-height: 100%;
			}
		}
	}
	.ce-toolbar__settings-btn {
		top: auto !important;
		margin-right: -25px;
	}
}
