@import '../theme/theme';
@import '../variables/variables';
@import '../core/mixins/box-shadow';
@import '../core/mixins/transitions';
@import '../core/mixins/border-radius';
@import '../core/mixins/transitions';
@import '../core/mixins/flexbox';

.rs-picker-menu {
	.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
		border-color: $app-brand-primary;
		background-color: $app-brand-primary;
	}
	.rs-picker-toolbar {
		.rs-picker-toolbar-right-btn-ok {
			background-color: $app-brand-primary;
		}
	}
	.rs-calendar-month-dropdown-year-active {
		color: $app-brand-primary;
	}
	.rs-calendar-show-month-dropdown .rs-calendar-header-title-date {
		color: $app-brand-primary;
	}
	.rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
		border-color: $app-brand-primary;
		background-color: $app-brand-primary;
	}
	.rs-picker-toolbar-right-btn-ok:not(.rs-btn-disabled):hover {
		background-color: $app-brand-primary-dark;
	}
}
.react-datepicker__tab-loop .react-datepicker-popper {
	z-index: 11;

	.react-datepicker__day--keyboard-selected,
	.react-datepicker__month-text--keyboard-selected,
	.react-datepicker__quarter-text--keyboard-selected,
	.react-datepicker__year-text--keyboard-selected {
		border-color: $app-brand-primary;
		background-color: $app-brand-primary;
	}
}
.rs-picker-date {
	&.rs-picker-disabled {
		opacity: 1;
	}

	&.rs-picker-default.rs-picker-disabled .rs-picker-toggle,
	.rs-picker-input.rs-picker-disabled .rs-picker-toggle,
	.rs-picker-default.rs-picker-disabled .rs-btn,
	.rs-picker-input.rs-picker-disabled .rs-btn {
		border-color: $form-control-disable-border;
		background: #e9ecef !important;
		cursor: not-allowed;
	}
}
.time-picker {
	.rs-picker-default .rs-picker-toggle.rs-btn {
		padding-top: 9px;
	}
	.rs-picker-default .rs-btn,
	.rs-picker-input .rs-btn,
	.rs-picker-default .rs-picker-toggle,
	.rs-picker-input .rs-picker-toggle {
		border: 1px solid #ced4da;
		height: 40px;
	}
	.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
	.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
		border-color: #bcbcc1;
	}
	.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
	.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
	.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
	.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
		top: 10px;
	}
	.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
	.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
	.rs-picker-default:not(.rs-picker-disabled) .rs-btn.active,
	.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active {
		border-color: #bcbcc1;
	}
	.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
	.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
		color: $text-color;
	}
}

.rs-picker-menu {
	.rs-calendar-show-time-dropdown .rs-calendar-header-title-time {
		color: $app-brand-primary;
	}

	.rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell,
	.rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell:hover {
		background-color: $app-brand-primary;
	}
}
.react-datepicker {
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.14);
	border: 1px solid #ded3d3;
	.react-datepicker__header {
		background-color: #f4f5f9;
		border-bottom: 1px solid #e4e6ed;
	}
	.react-datepicker__day--selected,
	.react-datepicker__day--in-selecting-range,
	.react-datepicker__day--in-range,
	.react-datepicker__month-text--selected,
	.react-datepicker__month-text--in-selecting-range,
	.react-datepicker__month-text--in-range,
	.react-datepicker__quarter-text--selected,
	.react-datepicker__quarter-text--in-selecting-range,
	.react-datepicker__quarter-text--in-range,
	.react-datepicker__year-text--selected,
	.react-datepicker__year-text--in-selecting-range,
	.react-datepicker__year-text--in-range {
		background-color: $app-brand-primary;
		transition: 300ms background-color;
		&:hover {
			background-color: $app-brand-primary-dark;
		}
	}
	.react-datepicker__day-name,
	.react-datepicker__day,
	.react-datepicker__time-name {
		font-size: 12px;
	}
	.react-datepicker__day--keyboard-selected,
	.react-datepicker__month-text--keyboard-selected,
	.react-datepicker__quarter-text--keyboard-selected,
	.react-datepicker__year-text--keyboard-selected {
		background-color: $app-brand-primary;
		transition: 300ms background-color;
		&:hover {
			background-color: $app-brand-primary-dark;
		}
	}
}
