.further-reading {
	@include flexbox();
	@include flex-direction(row);
	@include align-items(center);
	@media only screen and (max-width: 767px) {
		flex-wrap: wrap;
	}
	.cm-upload-file {
		min-width: 292px;
		@media screen and (max-width: 575px) {
			min-width: 100%;
		}
	}
	.text-or {
		display: inline-block;
		margin: 16px;
		font-size: $text-md;
		color: $text-color-light;
		@media only screen and (max-width: 767px) {
			display: block;
			flex-basis: 100%;
			flex-shrink: 0;
			margin: 16px 0px 10px 0px;
		}
	}
	.past-url {
		flex-grow: 1;
		flex-basis: 100%;
	}
}
.tiny-heading {
	font-size: $text-lg;
	margin-bottom: 8px;
	line-height: 28px;
	sup {
		color: $validation-red;
		font-size: $text-xl;
		position: relative;
		top: 2px;
	}
}

.app-c-file-attached {
	@include flexbox();
	padding: 24px;
	@include border-radius(6px);
	background: $base-bg;
	@include flexbox();
	@include flex-direction(row);
	@include justify-content(flex-start);
	flex-wrap: wrap;
	position: relative;
	audio {
		width: 100%;
		flex-grow: 1;
	}
	.url-link {
		flex-shrink: 0;
		flex-grow: 1;
		width: 100%;
		padding-bottom: 23px;
		padding-right: 30px;
		a {
			font-size: $text-xl;
			color: $app-brand-primary;
			font-weight: $font-weight-semibold;
			line-height: 17px;
		}
	}
	.action-btns {
		position: absolute;
		right: 20px;
		top: 20px;
		.icon-trash {
			all: unset;
			font-size: 18px;
			color: $text-muted;
			cursor: pointer;
			&:hover {
				color: $app-brand-primary;
			}
		}
	}
	@media (max-width: 767px) {
		@include flex-direction(column);
		.app-c-file-attached__info {
			padding: 10px 0;
		}
	}
}
.app-c-file-attached__file {
	position: relative;
	max-width: 266px;
	width: 100%;
	flex-shrink: 0;
	padding-right: 30px;
	&.-full-width {
		&.-max-width-100 {
			max-width: 100%;
			height: 500px;
		}
		@media (max-width: 767px) {
			width: 100%;
			max-width: 100%;
			padding: 0;
			iframe {
				width: 100%;
			}
		}
	}
	img {
		max-width: 266px;
		max-height: 186px;
	}
}
.app-c-play {
	all: unset;
	position: absolute;
	width: 78px;
	height: 78px;
	background: url(../../images/play-video.svg) no-repeat center center;
	background-size: 100%;
	margin: auto;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	@include transition(all ease 0.2s);
	cursor: pointer;
	background-color: rgba(255, 255, 255, 0.16);
	@include border-radius(50%);
}
.app-c-file-attached__info {
	padding-left: 24px;
	@include flexbox();
	@include flex-direction(column);
	@include align-items(flex-start);
	@include justify-content(space-between);
	flex-grow: 1;
	position: relative;
	word-break: break-all;
	h5 {
		line-height: 29px;
		margin-bottom: 0;
	}
}
.app-c-grey-text {
	font-size: $text-lg;
	color: $text-muted;
	line-height: 22px;
}

.app-border {
	border: 1px solid rgba(190, 193, 210, 0.4);
	@include border-radius(6px);
	&.mt-3 {
		@media screen and (max-width: 767px) {
			margin-top: 0 !important;
		}
	}
}

.editor-theming {
	.app-l-editor-holder {
		.tox {
			min-height: 227px;
			margin-bottom: 0;
		}
	}
	&.theming1 {
		.app-l-editor-holder {
			.tox {
				.tox-edit-area__iframe {
					background: #fffae5 !important;
				}
			}
		}
	}
	&.theming2 {
		.app-l-editor-holder {
			.tox {
				.tox-edit-area__iframe {
					background: #f4f5f9 !important;
				}
			}
		}
	}
	&.theming3 {
		.app-l-editor-holder {
			.tox {
				.tox-edit-area__iframe {
					background: rgba(209, 0, 0, 0.1) !important;
				}
			}
		}
	}
}

.app-c-content-pic {
	text-align: center;
	@include inline-flex();
	@include flex-direction(column);
	// padding-bottom: 24px;
}
.app-c-content-pic__name {
	display: block;
	line-height: 22px;
	margin-top: 8px;
}
h6 {
	&.sm {
		font-size: $text-lg;
		font-weight: $font-weight-semibold;
	}
}
.text-bold {
	font-weight: $font-weight-semibold;
}
p {
	&.text-bold {
		font-weight: $font-weight-semibold;
		font-size: inherit;
	}
}

.editor-content-preview {
	@include border-radius(6px);
	padding: 16px;
	.editor-content-preview__title {
		font-size: $text-xl;
		font-weight: $font-weight-semibold;
		line-height: 17px;
		margin-bottom: 8px;
	}
	.editor-content-preview__text {
		font-size: $text-lg;
		line-height: 22px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	&.theming1 {
		color: rgba(155, 124, 0, 1) !important;
		background: #fffae5;
	}
	&.theming2 {
		color: $app-brand-primary !important;
		background: #f4f5f9;
	}
	&.theming3 {
		color: rgba(209, 0, 0, 1) !important;
		background: #fae5e5;
	}
}

.app-c-hr {
	border-top: 1px solid rgba(190, 193, 210, 0.4);
}
.app-c-file-uploaded {
	background: $login-form-bg;
	@include border-radius(6px);
	@include inline-flex();
	@include flex-direction(row);
	@include justify-content(flex-start);
	@include align-items(center);
	padding: 0px 12.33px;
	height: 26px;

	i {
		font-size: $text-sm-plus;
	}
	.app-c-file-uploaded__name {
		font-size: $text-md;
		color: $text-color;
		line-height: 15.6px;
	}
	.app-c-file-uploaded__close {
		all: unset;
		font-size: 8px;
		width: 14px;
		height: 14px;
		@include inline-flex();
		@include flex-direction(column);
		@include justify-content(center);
		@include align-items(center);
		line-height: normal;
		margin-left: 6px;
		@include border-radius(50%);
		@include transition(all ease 0.2s);
		&:hover {
			background: $app-brand-primary;
			color: $white;
			font-size: 6px;
		}
	}
	> * + * {
		margin-left: 12.22px;
	}
}
.o-question-wrapper-outer {
	&.py-4 {
		@media screen and (max-width: 767px) {
			padding-top: 16px !important;
			padding-bottom: 16px !important;
		}
	}
	&.px-5 {
		@media screen and (max-width: 767px) {
			padding-left: 10px !important;
			padding-right: 5px !important;
		}
	}
}
.o-question-wrapper {
	.m-question-set,
	.m-answer-set {
		@include flexbox();
		@include flex-direction(row);
		@include justify-content(space-between);
		@include align-items(flex-start);
		flex-wrap: wrap;
		.app-c-file-uploaded {
			position: relative;
			bottom: 0px;
			right: 0;
			margin: 10px 0;
			height: auto;
			.app-c-file-uploaded__name {
				padding: 5px 0;
			}
		}
	}
	.m-question-set__input {
		position: relative;
		flex: 1;
		@media only screen and (max-width: 767px) {
			flex-basis: 100%;
			flex-shrink: 0;
		}
	}
	.app-c-checkbox {
		margin-top: 8px;
	}

	.m-actions {
		@include inline-flex();
		@include flex-direction(row);
		@include justify-content(space-between);
		@include align-items(center);
		@media only screen and (max-width: 767px) {
			padding-top: 8px;
		}

		> * {
			background: none;
			border: 1px solid $generic-border-color;
			@include border-radius(6px);
			margin-left: 12px;
			width: 36px;
			height: 40px;
			text-align: center;
			@include inline-flex();
			@include flex-direction(column);
			@include justify-content(center);
			@include align-items(center);
			font-size: 18px;
			@media only screen and (max-width: 767px) {
				margin: 0px 8px 0px 0px;
			}
			&:hover {
				background: $app-brand-primary;
				border-color: $app-brand-primary;
				color: $white;
			}
			i {
				font-size: 18px;
			}
		}
		.app-c-vertical-sorting {
			width: 26px;
			&:hover {
				background: inherit;
				border: 1px solid $generic-border-color !important;
				color: inherit;
			}
		}
	}
	.m-question-set__bottom {
		flex: 1 0;
		flex-basis: 100%;
		min-height: 18px;
		@media only screen and (max-width: 767px) {
			margin-bottom: 10px;
		}
		.app-c-checkbox {
			height: 30px;
			@media only screen and (max-width: 991px) {
				margin-top: 32px;
			}
			@media only screen and (max-width: 767px) {
				margin-top: 12px;
			}
			> label {
				@media only screen and (max-width: 767px) {
					line-height: 14px;
				}
			}
		}
	}
}

.m-answer-options {
	// padding-top: 8px;
	padding-left: 8px;
	.tiny-heading {
		margin-bottom: 0;
	}
	ol {
		counter-reset: li;
		list-style: none;
		*list-style: decimal;
		margin-bottom: 1rem;
	}
	li {
		margin-top: 24px;
		position: relative;
		&:first-child {
			margin-top: 0;
		}
		@media (max-width: 767px) {
			margin-top: 10px;
			&:first-child {
				margin-top: 0;
			}
		}
		&::before {
			content: counter(li);
			counter-increment: li;
			position: absolute;
			left: -24px;
			top: $text-sm-plus;
			font-size: $text-lg;
			color: $text-color;
			font-weight: $font-weight-semibold;
			@media screen and (max-width: 767px) {
				left: -20px;
			}
		}
	}
}

.check-box-list {
	li {
		display: block;
		padding: 8px 0px;
		.form-label {
			color: $text-color;
		}
	}
}

.no-padding-top {
	padding-top: 0px !important;
}
dl,
ol,
ul {
	margin-top: 0;
}

address,
dl,
ol,
ul {
	margin-bottom: 1rem;
}
