.app-c-tab-group {
	background-color: $white;

	.tab-container {
		width: auto;

		.nav-tabs {
			border-bottom: none;

			.nav-item {
				a {
					padding: 9px 0px;
					position: relative;
					border: none;
					background-color: transparent;
					font-weight: 500;
					font-size: 15px;
					line-height: 17px;
					color: $text-muted;

					@include transition(color 0.3s ease-in-out);

					&:hover {
						color: $text-dark-extra;
					}

					&:before {
						content: '';
						width: 0;
						height: 3px;
						position: absolute;
						right: 0;
						bottom: 0;
						left: 0;
						margin: auto;
						background-color: $app-brand-primary;

						@include transition(width 0.3s ease-in-out);
						@include border-radius(20px 20px 0px 0px);
					}

					.app-l-tab-header {
						background-color: transparent;
						font-weight: 500;
						font-size: 15px;
						line-height: 17px;
						color: $text-dark-normal;
						position: relative;

						&:before {
							content: '\e93e';

							// speak: none;
							font-style: normal;
							font-weight: normal;
							font-size: 14px;
							position: absolute;
							right: -16px;
							top: auto;
							bottom: auto;
							left: auto;
							color: $validation-red;
							display: none;
						}

						&.error-tab {
							&:before {
								@include inline-flex();
							}
						}
					}
				}
				&.active {
					a {
						color: $text-dark-extra;

						.app-l-tab-header {
							color: $text-dark-extra;
						}

						&:before {
							width: 100%;
						}
					}
				}
				+ .nav-item {
					margin-left: 22px;
				}
			}
		}
	}

	&.horizontal-scroll {
		.tab-container {
			.nav-tabs {
				overflow-x: auto;
				overflow-y: hidden;
				white-space: nowrap;

				@include flex-wrap(nowrap);
			}
		}
	}

	&.app-l-data-tabs {
		background-color: $white;

		.tab-container {
			.nav-tabs {
				background-color: $tab-header-bg;
				//border-bottom: solid 1px $form-control-border;

				.nav-item {
					a {
						padding: 12px 24px;
					}
				}
			}
		}
		.app-l-tab-contents {
			padding: 0;
		}
	}

	&.app-l-full-height-tab {
		height: 100%;
		min-height: 0;

		.tab-container {
			height: 100%;
			min-height: 0;

			@include flexbox();
			@include flex-direction(column);

			.nav-tabs {
				@include flex-shrink(0);
			}

			.tab-content {
				min-height: 0;
				height: 100%;

				@include flexbox();
				@include flex-direction(column);

				.tab-pane {
					min-height: 0;
					height: 100%;
				}
			}
		}
	}
}

.app-l-tab__cr {
	height: 100%;
	.app-l-grid-holder {
		padding: 0px;
	}
	.app-c-grid {
		.app-l-grid-header {
			padding: 16px 0px 5px;
			.app-l-grid-header-text {
				margin: 0 8px 0 0;
				padding: 0px 0px;
				line-height: 23px;
				max-width: 350px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
}
