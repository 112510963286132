.app-student-course-form {
	&:last-child {
		border-bottom: 0;
	}
	+ .app-student-course-form {
		padding-top: 16px;
	}
	.form-group {
		margin-bottom: 0;
	}
}
.app-student-course-content {
	.app-l-chapter-box {
		color: $black01;
		font-weight: $font-weight-semibold;
		font-size: $text-xl;
	}
	.app-c-btn--addon {
		padding: 10px 16px;
	}
	.lesson-group-wrapper {
		.lesson-group-item {
			padding: 0 0 21px;
			@include flexbox;
			@include align-items(flex-start);
			&:not(:first-child) {
				padding: 21px 0;
			}
			&:last-child {
				border-bottom: 0;
				padding: 21px 0 0;
			}
			.lesson-group-status {
				margin-right: 19px;
			}
			.lesson-status-circle {
				background: $app-brand-primary;
				box-shadow: 0 0 1px 3px rgba($color: $app-brand-primary, $alpha: 0.2);
				border-radius: 50%;
				font-size: $text-md;
				color: $white;
				flex-shrink: 0;
				width: 20px;
				height: 20px;
				@include flexbox;
				@include align-items(center);
				@include justify-content(center);
				margin-top: 5px;
			}
			.lesson-group-header {
				font-weight: $font-weight-semibold;
				font-size: $text-lg;
				width: 100%;
				padding-right: 20px;
			}
			.lesson-group-title {
				color: $black01;
			}
			.lesson-group-caption {
				color: $gray01;
			}
			.green-label {
				color: $default-green;
				font-weight: $font-weight-semibold;
			}
		}
	}
}

.progress-svg {
	width: 20px;
	height: 20px;
	overflow: visible;
	border-radius: 50%;
	background-color: $white;
}
.progress-circle {
	stroke: $app-brand-primary;
	stroke-width: 2px;
	fill: none;
	stroke-dasharray: calc(((2 * 3.14 * 20) / 2) * (var(--score) / 100))
		calc(((2 * 3.14 * 20) / 2) - (((2 * 3.14 * 20) / 2) * (var(--score) / 100)));
	stroke-dashoffset: -173px;
	transition: stroke-dasharray 0.3s 0.3s ease-in-out;
}
.progress-circle-bg {
	stroke: var(--theme-primary-color-200);
	fill: none;
	stroke-width: 2px;
}
